<div *ngIf="tmcdetails.length>0" class="container mt-5 mb-5">
     <div *ngFor="let data of tmcdetails;let i=index">
       <h2>
        {{i+1}}. &nbsp;{{data.tc_data}}
        </h2>
   <br/>
    </div>
</div>
<div *ngIf="tmcdetails.length==0" class="container mb-5 mt-5">

<html *ngIf="project=='ecomtrails'">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
    {font-family:Calibri;
    panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin-top:0cm;
    margin-right:0cm;
    margin-bottom:10.0pt;
    margin-left:0cm;
    line-height:115%;
    font-size:11.0pt;
    font-family:"Calibri","sans-serif";}
h1
    {mso-style-link:"Heading 1 Char";
    margin-top:12.0pt;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:0cm;
    margin-bottom:.0001pt;
    line-height:115%;
    page-break-after:avoid;
    font-size:16.0pt;
    font-family:"Cambria","serif";
    color:#365F91;
    font-weight:normal;}
h2
    {mso-style-link:"Heading 2 Char";
    margin-top:2.0pt;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:0cm;
    margin-bottom:.0001pt;
    line-height:115%;
    page-break-after:avoid;
    font-size:13.0pt;
    font-family:"Cambria","serif";
    color:#365F91;
    font-weight:normal;}
h3
    {mso-style-link:"Heading 3 Char";
    margin-top:2.0pt;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:0cm;
    margin-bottom:.0001pt;
    line-height:115%;
    page-break-after:avoid;
    font-size:12.0pt;
    font-family:"Cambria","serif";
    color:#243F60;
    font-weight:normal;}
span.Heading2Char
    {mso-style-name:"Heading 2 Char";
    mso-style-link:"Heading 2";
    font-family:"Cambria","serif";
    color:#365F91;}
span.Heading1Char
    {mso-style-name:"Heading 1 Char";
    mso-style-link:"Heading 1";
    font-family:"Cambria","serif";
    color:#365F91;}
span.Heading3Char
    {mso-style-name:"Heading 3 Char";
    mso-style-link:"Heading 3";
    font-family:"Cambria","serif";
    color:#243F60;}
.MsoChpDefault
    {font-family:"Calibri","sans-serif";}
.MsoPapDefault
    {margin-bottom:10.0pt;
    line-height:115%;}
@page WordSection1
    {size:595.3pt 841.9pt;
    margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
    {page:WordSection1;}
-->
</style>

</head>

<body lang=EN-IN>

<div class=WordSection1>
<p class=MsoNormal style='line-height:14.15pt;margin-top: 10px'><a name=page1></a><span
style='font-size:11.0pt;font-family:"Verdana","sans-serif"' >
[VENDOR]: <span style="font-weight: 600;">{{compd?.display_name | titlecase}}</span>
[VENDOR'S SITE]: <span style="font-weight: 600;">{{compd?.host_name}}</span>

The above represented as [VENDOR] & [VENDOR'S SITE] in the below document.
</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
text-align:center;line-height:150%;text-autospace:none'><span style='font-size:
14.0pt;line-height:150%'>ONLINE SHOPPING SITE CONDITIONS OF USE</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Welcome to [</span><i><span style='font-size:14.0pt;
line-height:150%'>VENDOR'S SITE]. </span></i><span style='font-size:14.0pt;
line-height:150%'>To use the service offered at [</span><i><span
style='font-size:14.0pt;line-height:150%'>VENDOR] </span></i><span
style='font-size:14.0pt;line-height:150%'>or by any of its affiliates, you must
agree to the following conditions. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>HEREINAFTER in this policy, [VENDOR] shall be referred to as
the “Seller” and the variations of “Seller” as and where applicable, whereas
[VENDOR'S SITE] shall be referred to as the “Seller’s website”.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>If you visit or shop at [</span><i><span style='font-size:
14.0pt;line-height:150%'>VENDOR</span></i><span style='font-size:14.0pt;
line-height:150%'>].</span><i><span style='font-size:14.0pt;line-height:150%'>com</span></i><span
style='font-size:14.0pt;line-height:150%'>, you accept these conditions. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Please read them carefully. In addition, when you use any
current or future Seller’s</span><i><span style='font-size:14.0pt;line-height:
150%'> </span></i><span style='font-size:14.0pt;line-height:150%'>service or visit
or purchase from any business affiliated with the Seller, whether or not
included in this Web Site, you will be subject to both this Conditions of Use
agreement and the guidelines and conditions applicable to such service or
business.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>1.<span
class=Heading2Char><span style='line-height:115%;font-family:"Calibri","sans-serif";
color:windowtext'> PRIVACY</span></span></span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>{{"Privacy Policy" | translate}}</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Please review our Privacy Notice, which also governs your
visit to Seller’s website, to fully understand our practices.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>2. ELECTRONIC
COMMUNICATIONS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>Legal Satisfaction</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>When you visit Seller’s website or send emails to us, you are
communicating with us electronically.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. Consent</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>By communicating with us, you consent to receive
communications from us electronically. We may communicate with you by e-mail or
by posting notices on this site. You agree that all agreements, notices, disclosures,
and other communications that we provide to you electronically satisfy any
legal requirement that such communications be in writing.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>3.
COPYRIGHT</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. Copyright
Protection</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All content included on this site, such as text, graphics,
logos, Button icons, images, audio clips, digital downloads, data compilation,
and software is the property of the Seller or has been licensed to it only for
this website by EcomTrails<sup>TM and</sup> protected by the laws in force in
the jurisdiction of, [VENDOR’s country]/Indian and international copyright
laws. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The compilation of all content on this site is the exclusive
property of the Seller unless it has been licensed from EcomTrails<sup>TM </sup>under
an agreement for license and protected by the laws in force in [Vendor’s
jurisdiction], India and international copyright laws. All software used on
this site is the property of M/s OEPP Innovations Pvt. Ltd. / EcomTrails<sup>TM</sup>
or its software suppliers and are protected by the Indian and international
copyright laws.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>4.
TRADEMARKS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>Protected Marks</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All the marks indicated on our site are trademarks of the
Seller.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B
.Protected Graphics</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All graphics, logos, page headers, button icons, scripts, and
service names are trademarks or trade dress of EcomTrails<sup>TM</sup>. Any of
the trademarks and trade dress may not be used in connection with any product
or service that is not the Seller’s website or EcomTrails<sup>TM</sup>, in any
manner that is likely to cause confusion among customers, or in any manner that
disparages or discredits the Seller or EcomTrails<sup>TM</sup>. All other
trademarks not owned by the Seller or its subsidiaries that appear on this site
are the property of their respective owners, who may or may not be affiliated
with, connected to, or sponsored by the Seller or its subsidiaries.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>5.
LICENSE AND SITE ACCESS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The Seller grants you a limited license to access and make
personal use of this site and not to download (other than page caching) or
modify it, or any portion of it, except with express written consent of the
Seller.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>No License for
Commercial Sale</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>This license does not include any resale or commercial use of
this site or its contents; any collection and use of any product listing,
descriptions, or prices; any derivative use of this site or its contents; any downloading
or copying of account information for the benefit of another merchant; or any
use of data-mining, robots, or similar data gathering and extraction tools.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>C .No
Reproduction</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>This site or any portion of this site may not be reproduced,
duplicated, copied, sold, resold, visited, or otherwise exploited for any
commercial purpose without express written consent of the Seller or EcomTrails<sup>TM</sup>
wherever required.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>D .No
Framing</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You may not frame or utilize framing techniques to enclose
any trademark. Logo, or other proprietary information (including images, text,
page layout, or form) or the Seller and our affiliates without express written
consent.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>E. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>Meta tags</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You may not use any Meta tags or any other hidden text
utilizing the Seller’s name or trademarks without the express written consent
of the Seller. Any unauthorized use terminates that permission or license
granted by the Seller.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>6.
YOUR ACCOUNT</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
Protection of Your Account</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>As discussed further in the site {{"Privacy Policy" | translate}}, by using
this site, you agree that you are responsible for maintaining the
confidentiality of your account and password and for restricting access to your
computer, and you agree to accept responsibility for all activities that occur
under your account or password.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. Use by
Children</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>As discussed further in the site {{"Privacy Policy" | translate}}, the Seller does
sell products for children, but it sells them to adults, who can purchase with
a credit card. If you are under age 18, you may use the Seller only with
involvement of a parent or guardian, the Seller and its affiliates reserve the right
to refuse service, terminate accounts, remove or edit content, or cancel orders
in their sole discretion.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span class=Heading1Char><b><span
style='font-size:16.0pt;line-height:150%;font-family:"Calibri","sans-serif";
color:windowtext'>7. REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER</span></b></span><b><span
style='font-size:14.0pt;line-height:150%'> </span></b><span class=Heading1Char><b><span
style='font-size:16.0pt;line-height:150%;font-family:"Calibri","sans-serif";
color:windowtext'>CONTENT</span></b></span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A .Nature
of Content</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Visitors to the Seller’s website may post reviews, comments
and other content’ send e-cards and other communications; and submit
suggestions, ideas, and comments. Questions or other information, as long as
the content is not illegal. Obscene, threatening, defamatory, invasive of
privacy, infringing of intellectual</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>property rights, or otherwise injurious to third parties or
objectionable and does not consist of or contain software viruses, political
campaigning, commercial solicitation, chain letters, mass mailings, or any form
of spam.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B .False
Information</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You may not use a false e-mail address, impersonate any
person or entity, or otherwise mislead as to the origin of a card or other
content. The Seller reserves the right (but not the obligation) to remove or
edit such content but does not regularly review posted content.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>C. Rights
Granted</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>If you do post content or submit material, and unless we
indicate otherwise, you grant the Seller and its affiliates a non-exclusive,
royalty-free, perpetual, irrevocable, and fully sub licensable right to use,
reproduce, modify, adapt, publish, translate, create derivative works from,
distribute, and display such content throughout the world in any media. You
grant the Seller and its affiliates and sublicenses the right to use the name
that you submit in connection with such content if they choose.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>D .Rights
Owned</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You represent and warrant that you own or otherwise control
all of the rights to the content that you post; that the content is accurate;
that use of the content you supply does not violate this policy and will not cause
injury to any person or entity; and that you will indemnify the Seller or its
affiliates for all claims resulting from content you supply. The Seller has the
right but not the obligation to monitor and edit or remove any activity or
content. The Seller takes no responsibility and assumes no liability for any
content posted by you or any third party.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>8.
COPYRIGHT COMPLAINTS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A
.General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The Seller and its affiliates respect the intellectual
property of others. If you believe that your work has been copied in a way that
constitutes copyright infringement, please refer to the site for instructions
on how to make a claim of Copyright Infringement.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>9.
RISK OF LOSS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All items purchased from the Seller are made pursuant to a
shipment contract. This means that the risk of loss and title for such items
pass to you upon our delivery to the end carrier.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>10.
PRODUCT DESCRIPTIONS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The Seller and its affiliates attempt to be as accurate as
possible. However, the Seller makes no warranties that the product descriptions
and any other content of this site are accurate, complete, reliable, current or
error-free. If a product offered by the Seller itself is not as described, your
sole remedy is to return it in unused condition.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>11.
OTHER BUSINESSES</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A
.General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Parties other than the Seller and its subsidiaries operate
stores, provide services, or sell product lines on this site.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. No
Warranties.</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>We are not responsible for examining or evaluating, and we do
not warrant the offerings of, any of these businesses or individuals or the
content of their Web Sites. The Seller does not assume any responsibility or
liability for the actions, product, and content of all these and any other
third parties. You should carefully review their privacy statements and other
conditions of use.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>12. DISCLAIMER
OF WARRANTIES AND LIMITATION OF LIABILITY</span></b></h1>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>This site is provided by the Seller on an “AS IS” “AS
AVAILABLE” basis. The Seller makes no representations or warranties of any
kind, express or implied, as to the operation of this site or the information,
content, materials, or products included on this site. You expressly agree that
your use of this site is at your sole risk.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>To the full extent permissible by applicable law, the Seller disclaims
all warranties express or implied including but not limited to, implied
warranties of merchantability and fitness for a particular purpose. the Seller,
does not warrant that this site, its servers, or e-mails sent from the Seller are
free of viruses or other harmful components, the Seller will not be liable for
any damages of any kind arising from the use of this site, including, but not
limited to direct, indirect, incidental, punitive, and consequential damages.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Certain state laws do not allow limitations on implied
warranties or the exclusion or limitation of certain damages, if these laws
apply to you, some or all of the above disclaimers, exclusions, or limitations
may not apply to you, and you might have additional rights.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>13. APPLICABLE
LAW</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A
.Governing Law</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>By visiting the Seller’s website, you agree that the laws of
the state of [</span><i><span style='font-size:14.0pt;line-height:150%'>Madhya
Pradesh</span></i><span style='font-size:14.0pt;line-height:150%'> and India],
without regard to principles of conflict of laws, will govern these Conditions
of Use and any dispute of any sort that might arise between you and the Seller
and its affiliates.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>14. DISPUTES</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
Arbitration and Venue</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Any dispute relating in any way to your visit to the Seller’s
website or to products you purchase through the Seller’s website shall be
submitted to confidential arbitration in [</span><i><span style='font-size:
14.0pt;line-height:150%'>Indore</span></i><span style='font-size:14.0pt;
line-height:150%'>], [</span><i><span style='font-size:14.0pt;line-height:150%'>Madhya
Pradesh</span></i><span style='font-size:14.0pt;line-height:150%'>], [INDIA] except
that, to the extent you have in any manner violated or threatened to violate the
Seller’s intellectual property rights, the Seller may seek injunctive or other
appropriate relief in any court, and you consent to exclusive jurisdiction and
venue in such courts. </span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>The Finality of
Arbitration</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The arbitrator’s award shall be binding and may be entered as
a judgment in any court of competent jurisdiction. </span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>C. LIMITATIONS</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>M/s OEPP Innovations Pvt. Ltd. / EcomTrails<sup>TM </sup>which
has provided web hosting services to the Seller shall have no responsibilities,
obligations, liability of any kind which may arise from functioning of the
Seller’s website or otherwise. M/s OEPP Innovations Pvt. Ltd. / EcomTrails<sup>TM
</sup>shall also not be made party to any of the legal proceedings of any kind
against the Seller or the Seller’s website.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>15. SITE
POLICIES, MODIFICATION, AND SEVERABILITY</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. Other
Policies</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>We reserve the right to make changes to our site, policies,
and these Conditions of Use at any time. If any of these conditions shall be
deemed invalid, void, or for any reason unenforceable, that condition shall be
deemed severable and shall not affect the validity and enforceability of any
remaining condition.</span></p>

</div>

</body>

</html>
<div *ngIf="project=='emall'">
    

<section class="contentBlock">
    <div class="container">
        <div class="titleBig">
            <h2>Refund and Return Policy</h2>
        </div>
        <div>
        <p>
        The Firm may, at its discretion arrange to lift the defective products from the customer however the Vendor will still be liable to replace the defective product. Any charges incurred by the Firm for lifting and forwarding such defective goods shall be on account of the Vendor. The Vendor shall make good such charges to the Firm upon receipt of invoice/debit note. No request for any adjustment from future payables to the Vendor from Firm will be made however, the Firm is at its liberty to deduct such amount from future payables of fresh Orders. Such deductions shall not be treated as practice and/or precedent. For avoidance of doubts it is clarified that defective would mean and include but is not limited to wrong product, damaged product, mis-sized product and any other shortcoming which the customer may point out. The Vendor hereby authorizes the Firm to entertain all claims of return of the Product in the mutual interest of the Vendor as well as the Customer.</p>
        <br>
        <p>
        The Vendor shall upon receipt of the order from the Firm immediately arrange to deliver the products to the designated address as early as possible but in any case the dispatch shall be made within 15 (FIFTEEN) days of the receipt of the Order. In case the Vendor fails to dispatch the product within the aforesaid time, it has to return the amount received back to the Firm forthwith. No delay in returning the amount is permissible and any delay caused shall be considered as breach of the agreement and be one of the cause for termination of agreement. The Vendor shall provide to the Firm the consignment number, details of courier/shipment agency immediately followed by proof of delivery.</p>



        </div>
    </div>
</section>


<app-footer></app-footer>
</div>
</div>