import { Component, OnInit } from '@angular/core';
import{NinetoysserviceService} from '../ninetoysservice.service';
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { AlertService, AuthenticationService } from '../_services';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { first } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver-es';

import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
  NgForm,
  AbstractControl,
  FormArray
} from "@angular/forms";

import { environment } from "../../environments/environment";


@Component({
  selector: 'app-catalogue-share',
  templateUrl: './catalogue-share.component.html',
  styleUrls: ['./catalogue-share.component.css']
})

export class CatalogueShareComponent implements OnInit {
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
 project=environment.project;
     registerForm: FormGroup;

 favicon=environment.favicon;
           logo=environment.logo;
             loaderAsset=environment.loader;
               error_img = sessionStorage.getItem('error_image');
               click=0;
               brand_lists=[];
  categories_lists=[];

    ecomtrails = false;
 ninetoys = false;
brand_id=0;
category_id=0;
  loader=false;
  is_members: any;

  constructor(private _formBuilder: FormBuilder,
    private snackbar:MatSnackBar,   
    private cookie: CookieService,
   private router: Router,
   private adminservice: NinetoysserviceService, 
   private translate:TranslateService,
   private authenticationService: AuthenticationService) { }
  public access_token=sessionStorage.getItem('access_token');
   public user_num=sessionStorage.getItem('user_num');
   public comp_num=sessionStorage.getItem('comp_num');
     usertypeid = sessionStorage.getItem('usertype_id');


  ngOnInit(): void {
  	 this.registerForm = this._formBuilder.group({
      category_no: [''],
      brand_id: [''],
      is_member:['N']
    });
  	 if(sessionStorage.getItem('database')=="ecomtrails"){
      this.ecomtrails=true;
    }
    else if(sessionStorage.getItem('database')=="9toys" || sessionStorage.getItem('database')=="maschile"){
      this.ninetoys=true;
    }
    else{
      this.ecomtrails=true;
    }
    this.fetch_brand_all();
    this.fetch_categories_list();
  }
  selectIs_member(e){
    this.is_members=e;
  }
fetch_brand_all() {

 if(this.ecomtrails == true){
  
  this.adminservice
  .fetchBrandsEcom({//for ecom
     comp_num : sessionStorage.getItem('comp_num')
    })
  .subscribe(
    data => {
  
      if (data["status"] == 1) {
        this.brand_lists = data["result"];
         if(this.brand_lists.length>0){
        	this.registerForm.get('brand_id').setValue(this.brand_lists[0].brand_id);
        }
      }
      else if(data['status']==10){
        sessionStorage.clear();
          this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
       else {
      }
    },
    error => {
      
    }
  );
}
else{
  this.adminservice
    .fetchBrands({
      user_num: sessionStorage.getItem("user_num"),
      comp_num: '0'
    })
     .subscribe(
    data => {
  
      if (data["status"] == 1) {
        this.brand_lists = data["result"];
         if(this.brand_lists.length>0){
        	this.registerForm.get('brand_id').setValue(this.brand_lists[0].brand_id);
        }
      }
      
      else if(data['status']==10){
        sessionStorage.clear();
          this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
      else {
      }
    },
    error => {
      
    }
  );
}


}
fetch_categories_list(){
var comps='0';
if(this.ecomtrails == true){
  comps=sessionStorage.getItem('comp_num');

}
let postData = {
    user_num: this.user_num,
    access_token: this.access_token,
    comp_num: comps
  };

this.adminservice.fetch_categories_list(postData).subscribe(
    data => {
  
      if (data["status"] == 1) {
        this.categories_lists = data["result"];
        if(this.categories_lists.length>0){
        	this.registerForm.get('category_no').setValue(this.categories_lists[0].category_no);
        }
      } 
      
      else if(data['status']==10){
        sessionStorage.clear();
          this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
      
      else {
      }
    },
    error => {
      
    }
  );


}
selectBrand(e){
	this.brand_id=e;

}
selectCategory(e){
	this.category_id=e;

}
radioClick(e){
	//e);
	this.click=e;
}
Report_date2=new Date();
  Report_date =(this.Report_date2.getFullYear())+'-'+(this.Report_date2.getMonth()+1)+'-'+(this.Report_date2.getDate());

get_catalogue(){
  this.loader = true;
	let postData = {
    user_num: this.user_num,
    access_token: this.access_token,
    comp_num: this.comp_num,
  };
  postData['is_member']= this.registerForm.controls['is_member'].value
if(this.brand_id!=0 && this.click==1){
postData['brand_id']=this.brand_id;
}
else{
postData['category_no']=this.category_id;

}
postData['is_member']=this.is_members;
this.adminservice.get_catalogue(postData).subscribe(
    data => {
  
     
      	 if (data.headers.get("content-type").search("pdf") != -1) {
            var blob = new Blob([data.body], {
              type: "application/pdf"
            });
            saveAs(blob, this.Report_date+'_catalogue_share'+".pdf");
               this.snackbar.open(this.adminservice.getCloseWord("Download  Successfully."),"ok", {
              duration: 3000
            }); 
this.loader =false;
             this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
         this.router.navigate(['//catalogue-share']));          
        
         
       
        
      } else {
        this.loader =false;
      	  this.snackbar.open(this.adminservice.getCloseWord("Data is not available."),"ok", {
              duration: 3000
            });
      }
    },
    error => {
      
    }
  );


}
back(){
	this.click=0;
}
}
