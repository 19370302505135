<style>
    .modal-header {
    display: flex;
    -ms-flex-align: start;
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    align-items: flex-start;
    z-index: 111111;
    top: 0;
    justify-content: space-between;
    padding: .5rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}


.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 600;
}
.img-circle-style{
    height: 200px; width: 200px; border-radius: 10%; border: 1px solid white; margin-left: 12px;
    margin-top: 6px;
}
.example-full-width{
    width: 100%;
}
</style>


<div *ngIf="deactivatef">
    <img src="{{data.image}}" height="30"  style="max-width: 70px;" (error)="onImgError($event)">

    <!-- <i class="fas fa-user-times text-danger cursor-pointer" ></i> <br/>
 &nbsp;&nbsp;
    <i class="fas fa-user-check cursor-pointer text-success" *ngIf="params?.value?.buisness_verify == false" (click)="fetch_customer_registration_by_id(data?.id,data.bank_id,data.bank_detail_no,data?.address_id,data.address_no,data.buisness_no)"></i>   -->
</div>
<div *ngIf="activate">

    <button mat-icon-button [matMenuTriggerFor]="menu"> 
        <i class="fas fa-ellipsis-v"></i>
      </button>
      
      <mat-menu #menu="matMenu"> 
        <div mat-menu-item class="text-success" (click)="getBrandDetails(data.edit);openXl(content)"   *ngIf="usertype_id=='2' || comp_num=='0'">
            <i class="fas fa-edit"></i>&nbsp;
          <span>{{"Edit" | translate}}</span>
        </div>
        <div mat-menu-item  class="text-primary">
            <i class="fas fa-file-image"></i> &nbsp;
          <span>{{"Want to add Banner" | translate}}?</span>
        </div>
        <div mat-menu-item class="text-danger">
            <i class="fas fa-trash"></i>&nbsp;
          <span>{{"Delete" | translate}}</span>
        </div>
      </mat-menu>
   
  </div>


  
  <ng-template #content let-modal>
    <div class="modal-header">


      <h5 [style.color]="headingcolor" class="modal-title" id="modal-basic-title"  align="center">{{"Update Brand" | translate}}</h5>
     
      <i class="fas fa-times cursor-pointer" title="close modal"  (click)="modal.dismiss('Cross click')"></i>
    </div>
      <!-- modal body starts -->
    <div class="modal-body "  >
           <form [formGroup]="registerForm" >
                        <div class=" ">
                           <p style="font-weight: 100px" class="m-0"> {{"(Note : Image Width must be less than 7000px and Height must be less than 5000px)" | translate}}</p>
                          <div class="row ">
                            <div class="col-md-6">
                                <div class="row" >
                                    <div class="col-sm-10">
                                      <input  type="file" formControlName="image"  (change)="onFileChanged1($event)" />                                       
                                    </div> 
                                      <div *ngIf="fileUploadProgress">
                                      {{"Upload progress" | translate}}: {{fileUploadProgress}}
                                    </div>
                                    <div class="" *ngIf="previewUrl"  >
                                        <img [src]="previewUrl" height="300" class="img-fluid img-circle-style" />                 
                                    </div>
                         
                                    <div class="mb-3" *ngIf="uploadedFilePath">
                                      <div class="row ">
                                        {{uploadedFilePath}}
                                      </div>
                                       
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-md-6">
                              <div class="row " >
                                <div class=" col-lg-12">
                                  <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label >{{"Brand Name" | translate}}</mat-label>
                                    <input  matInput  placeholder="Brand Name" formControlName="name" required>
                                    <mat-icon matSuffix>compass_calibration</mat-icon>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-lg-12">
                                  <mat-form-field appearance="fill" class="example-full-width">
                                    <mat-label>{{"Additional Details" | translate}}</mat-label>
                                    <textarea matInput placeholder="Additional details" rows="2" formControlName="addition_details" ></textarea>
                                    <i class="fas fa-book" matSuffix></i>
                                  </mat-form-field>
                                </div>

                                <!-- about brand -->
<div class="col-lg-12">
                                  <mat-form-field appearance="fill" class="example-full-width">
                                    <mat-label>{{"About Brand" | translate}}</mat-label>
                                    <textarea matInput placeholder="About Brand" rows="2" formControlName="about_brand" ></textarea>
                                    <i class="fas fa-book" matSuffix></i>
                                  </mat-form-field>
                                </div>
                                <!-- about brand -->
                  
                                
                        </div>
                            </div>
                          </div>
                          <div class="row justify-content-center mt-3">
                            <button  class="order-button text-white" mat-raised-button matTooltip="update brand" (click)="onSubmit();modal.close('Save click')" >{{"Update" | translate}}</button>
                          </div>
                        </div>
    
    
                      
                         
    
                        
    
                </form>
    </div>

  </ng-template>



  <ng-template #content3Exep let-modal >
    <div *ngIf ="resV1">
          <div class="modal-header">
            <h5 [style.color]="headingcolor" class="modal-title">{{"Modal title" | translate}}</h5>
           
            <i class="fas fa-times" (click)="modal.dismiss('Cross click')"></i>
          </div>
          <div class="modal-body">
            <div *ngIf ="brandException" class="col-sm-8" >
              <div class="container"  >
                <div class="container">
                    <mat-card>
  
                     
                        
                        <h5 class="text-center"><b><u>{{"These must delete before deleting Brand" | translate}}</u></b></h5>
                          <br><br>
                          
                         
                          
                        <ol class="list-group list-group-flush" *ngFor="let brand_p of brand_products">
                              <li class="list-group-item" >
                                    <div class="col-sm-6">
                                  <b>{{"Product" | translate}}  :   </b>{{brand_p.meta_title}}
                                    </div>
                                   
                                    
                        
                              </li>
                              
                        </ol> 
                         
                      </mat-card>
                </div>
              </div>
  
            </div>
           </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{"Close" | translate}}</button>
          </div>
        </div>
        </ng-template>