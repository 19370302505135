<div *ngIf="view">
    <i class="far fa-eye cursor-pointer"  (click)="
    sendOrderId(params.value.track,params.value.track1)" ></i>
  </div>
  <div *ngIf="invoice">
    <i class="fas fa-users"></i>
    <i class="fas fa-user-pdf cursor-pointer"  (click)="
    print_invoice(params.value)" ></i>
  </div>
  <div *ngIf="track">
    <i class="far fa-user cursor-pointer"  (click)="
    sendOrderId(params.value)" ></i>
  </div>
  <div *ngIf="deactive_member">
    <i class="far fa-ban cursor-pointer"  (click)="
    sendOrderId(params.value)" ></i>
  </div>
   
 