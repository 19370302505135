import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from "../ninetoysservice.service";
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-return-refund-policy-ecom',
  templateUrl: './return-refund-policy-ecom.component.html',
  styleUrls: ['./return-refund-policy-ecom.component.css']
})
export class ReturnRefundPolicyEcomComponent implements OnInit {
  project=environment.project;
     
       loaderAsset=environment.loader;
  host_name;
  serverlink: any;
  host_n: any;
  host_data: any;

  constructor(
  	private adminservice: NinetoysserviceService,
  	) { }
    // public comp_num = sessionStorage.getItem("comp_num");
    user_num = sessionStorage.getItem("user_num");
    access_token = sessionStorage.getItem("access_token");
    // public comp_num = sessionStorage.getItem("comp_num");
    comp_num:any;
   compd;
   tmcdetails=[];
  ngOnInit(): void {
    let l = location.origin;
    var c = l.split("//");
    this.host_name = c[1];
    this.host_n=this.host_name;
    let serv = this.host_name;
    var s = serv.split(".");
    this.serverlink = s[1];

    this.adminservice
    .hostlink({ host_name: this.host_name })
    .subscribe(data => {
      // this.name = data['status'].name;
      // //this.name + "Name");
      if (data["status"] == 1) {
        this.host_data = data['result'];
        this.comp_num = this.host_data.comp_num;
        //this.comp_num);
      
      }
      this.details();
      this.comapny();
    });

     

  
  }
 

  comapny(){
    //this.comp_num);
    this.adminservice.getCompnyBasicDetail({comp_num:this.comp_num}).subscribe(
      (data)=>{
        if(data['status']==1){
          this.compd = data['data']; 
        }
       
       
      })
  }
    //  comp_num=sessionStorage.getItem('comp_num');

  details(){
   
    //this.comp_num);
    this.adminservice.fetch_terms_and_conditions({comp_num:this.comp_num,  type:'2'}).subscribe(data=>{
      if(data['status']==1){ 
        this.tmcdetails = data['result']; 
       
      }
      
      else{
   
      }
    },
    error=>{
      
      }
    );

}
}
