import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from "../ninetoysservice.service";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-shipping-policy-ecom',
  templateUrl: './shipping-policy-ecom.component.html',
  styleUrls: ['./shipping-policy-ecom.component.css']
})
export class ShippingPolicyEcomComponent implements OnInit {
       project=environment.project;
     
       loaderAsset=environment.loader;
  constructor(
  	private adminservice: NinetoysserviceService,
  	) { }

   compd;
   tmcdetails=[];
   host_name;
   serverlink
   host_n;
   comp_num;
   host_data;
  ngOnInit(): void {


    let l = location.origin;
    var c = l.split("//");
    this.host_name = c[1];
    this.host_n=this.host_name;
    let serv = this.host_name;
    var s = serv.split(".");
    this.serverlink = s[1];

    this.adminservice
    .hostlink({ host_name: this.host_name })
    .subscribe(data => {
      // this.name = data['status'].name;
      // //this.name + "Name");
      if (data["status"] == 1) {
        this.host_data = data['result'];
        this.comp_num = this.host_data.comp_num;
      
      }
      this.details();
      this.comapny();
      // this.basicCompany();
    });



   
   
  }

comapny(){
  this.adminservice.getCompnyBasicDetail({comp_num:this.comp_num}).subscribe(
    (data)=>{
      if(data['status']==1){
        this.compd = data['data']; 
      }
     
     
    })
}
  details(){
    this.adminservice.fetch_terms_and_conditions({comp_num:this.comp_num,  type:'1'}).subscribe(data=>{
      if(data['status']==1){ 
        this.tmcdetails = data['result']; 
       
      }
      
      else{
   
      }
    },
    error=>{
      
      }
    );

}


}
