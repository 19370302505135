


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';

import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import {MatSnackBar} from '@angular/material';
import { FormGroup,FormBuilder } from '@angular/forms';

@Component({
    selector: 'button-member-wallet-admin',
    templateUrl: './button-member-wallet-admin.component.html',
  })
  export class ButtonMemberWalletAdminComponent {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
    data: any;
    deactivatef=false;
    activate=false;
    member_no2;
member_name;
member_last_name;
    myRecharge:FormGroup;
    myFormCheck:FormGroup;
  constructor(
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private fb :FormBuilder,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'md' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
        this.myFormCheck = this.fb.group({
      
            buisness_no: this.fb.array([])
          });
       
             this.myRecharge=this.fb.group({
            comp_num:this.comp_num,
            user_num:this.user_num,
            access_token:this.access_token,
            amount:[''],
            txn_id:[''],
            txn_date:[''],
            member_no:[''],
            customer_reg_num: "",
            receiver_name:"",
      
          receiver_last_name: "",
        
      
          currency: "INR",
          receipt: "rcptid_111",
          payment_capture: "1",
          name: "",
          email: "",
          mobile: ""
          });
    }
  agInit(params: ICellRendererParams): void {
      //params);
    this.params = params;
    this.data = params.value;
    //this.data);
    //this.data.id);
    var data1= params.data;

    if(this.params.label =='deactive_member'){
        //'track');
this.deactivatef = true;

    }
    if(this.params.label =='deactive-member'){
        this.deactivatef = true;
this.activate = true;

    }
  }
  saveDetail(member_no,member_name,member_last_name,customer_reg_num){
    this.member_no2=member_no;
    this.member_name=member_name;
    this.member_last_name=member_last_name;
    this.myRecharge.get('member_no').setValue(member_no);
    this.myRecharge.get('customer_reg_num').setValue(customer_reg_num);
    this.myRecharge.get('receiver_name').setValue(member_name);
    this.myRecharge.get('receiver_last_name').setValue(member_last_name);
    
    }

    recharge(){
        if(this.myRecharge.invalid){
        alert("Please fill required fields");
       
       }

       else{

var event= this.myRecharge.controls.txn_date.value;
    var day=event.getDate();
 var month=event.getMonth()+1;
 var year =event.getFullYear();
 var fullD=year+'-'+month+'-'+day
 ;
  
       var post= { comp_num: sessionStorage.getItem("comp_num"),
             
                 user_num:this.user_num,
               access_token:this.access_token,
               member_no:this.myRecharge.controls.member_no.value,
               amount:this.myRecharge.controls.amount.value,
                txn_id:this.myRecharge.controls.txn_id.value,
               txn_date:fullD,
              
               };
   
        //  this.loading = false;
  
   this.adminservice.recharge_wallet_manually(post).subscribe(data => {
    //  this.loading = true;
if(data['status']==1){
    this.modalService.dismissAll('Save click');
  
this.snackbar.open(this.adminservice.getCloseWord("Recharge done Successfully"),"ok", {
       duration: 3000
     }); 
 this.router
                 .navigateByUrl("/", {
                   skipLocationChange: true
                 })
                 .then(() => this.router.navigate(["/member-wallet-admin"]));
          

}
else{
    this.snackbar.open(this.adminservice.getCloseWord("Something went wrong,please try again."),"ok", {
       duration: 3000
     }); 
}
   
   });
//    this.loading = true;
 }
   }
 




}