<div *ngIf="ecomtrails==true || maschile==true || mlm==true" class="d-none d-md-block">

<nav class=" navbar-light fixed-top"   [ngClass]="[project=='emall' ? 'navbar-emall' : 'navbar-ecomtrails']">
  <div class="row w-100">
    <div class="col-md-6 w-100 ">

<!-- for emall start -->

      <a  class="navbar-brand " href="http://{{website}}/" target="_blank" >
        <img src="{{logo}}"  height="50" alt=""  (error)="onImgError($event)"> 
      </a>

      <!-- for emall end  -->

<!-- for ecom start -->

   <!--    <a *ngIf=" project!='emall'" class="navbar-brand  pl-2 pr-2" href="https://www.ecomtrails.com/" target="_blank" >
        <img src="assets/img/ecom-logo.png"  height="40" alt="" > 
      </a> -->

      <!-- for ecom end -->
    </div>
 

</div>
</nav>
<div class="back">
 

  <div *ngIf="signup == 0">

    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
          class="login-heading"
        >
          {{"Login"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="loginForm">
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width" >
                <mat-label>{{"Enter Email or Phone Number"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="username"
                  
                  required
                />
              
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width" >
                <mat-label>{{"Enter Password"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="password"
                  id="myInput"
                  type="password"
                  required
                />
                <i class="far fa-eye cursor-pointer" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="!visibility"  matTooltip="Show password"></i> 
                <i class="fas fa-eye-slash cursor-pointer" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="visibility"  matTooltip="Hide password"></i>
               
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <!-- start for policy -->
        <div class="_term mb-2 text-center" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
          <button
          
            mat-raised-button
            class="button-login w-90"
            (click)="onSubmit()"
            [disabled]="!loading"
          >
            {{"Login"|translate}}
          </button>
          <hr>
          <p class="text-center text-muted small mt-2">
            {{"Don't have an account?"|translate}}
            <a
              (click)="onSubmitSign()"
              class="line-login"
            >
              {{"Sign up here!"|translate}}</a
            >
          </p>
          
       
          <p class="text-center text-muted small mt-2">
            
            <a
            (click)="openXl(content2)"
              class="line-login"
            >
            {{"Forgot Password"|translate}}*</a
            >
          </p>
         
        </mat-card-actions>
      </form>
    </mat-card>
  </div>








  <div *ngIf="signup == 1">
    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
        class="login-heading"
         
        >
          {{"Sign Up"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="registerForm">
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Email/Mobile"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="username"
                  class="pl-2"
                  required
                />
              </mat-form-field>
            </div>
          </div>
         
        </mat-card-content>
          <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
          <button
           
            mat-raised-button
            class="button-login w-90"
            (click)="onSubmitSign()"
            (click)="startTimer(0.5)"
            [disabled]="!loading"
          >
            {{"Get OTP"|translate}} 
          </button>
<hr>
          <p class="text-center text-muted small mt-2">
            {{"Already exist?"|translate}}
            <a
              (click)="onSubmit()" class="text-info cursor-pointer"
              
            >
              {{"Login here!"|translate}}</a
            >
          </p>
      
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  <div *ngIf="signup == 2">
    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
        class="button-login"
        >
          {{"Sign Up"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="otpForm">
        <mat-card-content>
        
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"OTP"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="otp"
                  class="pl-2"
                  
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Create Password"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="password"
                  class="pl-2"
                  type="password"
                   id="myInput2"
                  required
                />
                  <i class="far fa-eye cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="!visibility2" ></i>
                  <i class="fas fa-eye-slash cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="visibility2" ></i> 
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
        
              <mat-form-field class="example-full-width">
                <mat-label>{{"Confirm Password"|translate}}</mat-label>
                <input id="myInput3" 
                  matInput
                  class=" pl-2 form-control"
                  type="password"  placeholder="Confirm Password" 
                  [class.is-invalid]="otpForm.errors?.misMatch"
                  formControlName="confirmPassword" required
                />

                  <i class="far fa-eye cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="!visibility3" ></i>
                  <i class="fas fa-eye-slash cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="visibility3" ></i>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
          <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
        
          <button
         
            mat-raised-button
            class=" order-button" [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
            (click)="onSubmitOTP()"
            [disabled]="!loading"
          >
            {{"Submit"|translate}}
          </button>
          <button *ngIf="state=== 'start'"
            
            mat-raised-button 
            class="button-login"
            (click)="onSubmitResendOtp()"
            disabled
          >
          {{"Resend in"|translate}} {{time|async}}
          </button>

          <button *ngIf="state=== 'stop' "
            mat-raised-button
            class="button-login"
            (click)="onSubmitResendOtp()"
            [disabled]="!loading"
            (click)="startTimer(0.5)"
          >
          {{"Resend OTP"|translate}}
          </button>
          <hr>
          <p class="text-center text-muted small mt-2">
            {{"Already exist?"|translate}}
            <a
              (click)="onSubmit()"
              class="line-login"
            >
              {{"Login here!"|translate}}</a
            >
          </p>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  <div *ngIf="signup == 4">
    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
         class="login-heading"
        >
          {{"Sign Up"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="otpForm">
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Email/Mobile"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="username"
                class="pl-2"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"OTP"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="otp"
                  class="pl-2"
                  
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Create Password"|translate}}</mat-label>
                <input
                  matInput id="myInput2" 
                  formControlName="password"
                  class="pl-2"
                  type="password"
                  
                  required
                />
                  <i class="fas fa-eye cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="!visibility2" ></i>
                  <i class="fas fa-eye-slash cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="visibility2" ></i>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Confirm Password"|translate}}</mat-label>
                <input
                  matInput id="myInput3" 
                  class=" pl-2"
                  type="password"
                  [class.is-invalid]="otpForm.errors?.misMatch"
                  formControlName="confirmPassword"
                 
                  required
                />
                  <i class="fas fa-eye cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="!visibility3" ></i>
                   <i class="fas fa-eye-slash cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="visibility3" ></i>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
          <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
        
          <button
            [disabled]="!loading"
            mat-raised-button
            class="button-login"
            (click)="onSubmitOTP()"
          >
            {{"Submit"|translate}}
          </button>
          <button
            [disabled]="!loading"
            mat-raised-button
            class="button-login"
            (click)="onSubmitResendOtp()"
          >
            {{"Resend OTP"|translate}}
          </button>
          <hr>
          <p class="text-center text-muted small mt-2">
            {{"Already exist?"|translate}}
            <a
              (click)="onSubmit()"
              class="line-login"
            >
              {{"Login here!"|translate}}</a
            >
          </p>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>

  <!-- for emallnet -->
 <footer *ngIf="project=='local' || project=='emall'" id="footer"> 
    <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Keep In Touch"|translate}}</h3>
          
          <h5><i class="far fa-building"></i> {{"Address"|translate}}</h5>
          <p style="color: #ffffffeb; font-size: 13px;">201, White Rose Apt
            J B Nagar circle, Andheri (east)
            Opposite PMC Bank,
            Mumbai - 400059 </p>
  
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>Contact Us</h3>
          <h5><i class="far fa-envelope"></i> {{"Email"|translate}}</h5>
          <a href="info@emallnet.com" style="text-decoration-color: #fff;" target="_blank">
            <p style="color:#ffffffeb; font-size: 13px; ">info@emallnet.com</p>
          </a>
          
          <h5><i class="fas fa-phone-volume"></i> {{"Telephone"|translate}}</h5>
          <p style="color:#ffffffeb; font-size: 13px;">+91-8828146836</p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Social Media"|translate}}</h3>
       
             <h5><a href="https://www.instagram.com/emallnetwork/" 
              style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
               <i class="fab fa-instagram w"></i> {{"Instagram"|translate}}</a></h5> 
               <h5><a href="https://www.facebook.com/networkemall/" 
                style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                 <i class="fab fa-facebook w"></i> {{"Facebook"|translate}}</a></h5> 
                 <h5><a href="https://twitter.com/NetworkEmall/" 
                  style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                   <i class="fab fa-twitter w"></i> {{"Twitter"|translate}}</a></h5>
        </div>
    </div>
    <div class="footer2" >
      <hr style="border-top: 8px dotted white; width: 60%;">
      <p style="color:#ffffffeb; font-size: 13px; line-height: 1.3rem;">© {{"Copyright @2020 Emallnet All Right Reserved"|translate}}</p>
     
    </div>
    
  </footer> 


<!-- for emalllnet end -->



<!-- for ecom trails and all -->

<footer *ngIf="project!='emall'" id="footer">

    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-top: 5%;">
          <h2>{{"EcomTrails"|translate}}</h2>
          <p style="color:#ffffffeb; font-size: 15px;">
            {{"Our EcomTrails app is a basic architecture for any business that has online exchange of goods & services."|translate}}
          </p>
        </div>
  
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Keep In Touch"|translate}}</h3>
          
          <h5><i class="far fa-building"></i> {{"Address"|translate}}</h5>
          <p style="color: #ffffffeb; font-size: 13px;">{{"107, 9930 Spaces, 3 Mangal Nagar, Indore, M.P., India, 452014"|translate}}</p>
          <h5><i class="far fa-envelope"></i> {{"Email"|translate}}</h5>
          <a href="sales@ecomtrails.com" style="text-decoration-color: #fff;" target="_blank">
            <p style="color:#ffffffeb; font-size: 13px; ">sales@ecomtrails.com</p>
          </a>
          
          <h5><i class="fas fa-phone-volume"></i> {{"Telephone"|translate}}</h5>
          <p style="color:#ffffffeb; font-size: 13px;">+91-9009399903</p>
  
  
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"EcomTrails App"|translate}}</h3>
          <a href="https://play.google.com/store/apps/details?id=com.i9930.ecommerce" target="_blank">
            <button type="button" class="btn btn-outline-light btn-lg download-button">
            <i class="fab fa-google-play"></i> {{"Download"|translate}}</button>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Social Media"|translate}}</h3>
         <h5><a href="https://api.whatsapp.com/send?phone=+91-9009399933" 
          style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
           <i class="fab fa-whatsapp w"></i>{{"Whatsapp"|translate}}</a></h5> 
           <h5><a href="https://www.linkedin.com/showcase/ecomtrails/" 
            style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
             <i class="fab fa-linkedin w"></i> {{"LinkedIn"|translate}}</a></h5> 
             <h5><a href="https://www.instagram.com/ecomtrails/" 
              style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
               <i class="fab fa-instagram w"></i> {{"Instagram"|translate}}</a></h5> 
               <h5><a href="https://www.facebook.com/ecomtrails/" 
                style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                 <i class="fab fa-facebook w"></i>{{"Facebook"|translate}}</a></h5> 
                 <h5><a href="https://twitter.com/EcomTrails?s=09" 
                  style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                   <i class="fab fa-twitter w"></i> {{"Twitter"|translate}}</a></h5>
        </div>
        
  
    </div>
    
    <div class="footer2" >
      <hr style="border-top: 8px dotted white; width: 60%;">
      <p style="color:#ffffffeb; font-size: 13px; line-height: 1.3rem;">© {{"Copyright @2020 EcomTrails All Right Reserved"|translate}}</p>
      <p style="color:#ffffffeb; font-size: 12px; line-height: 0.5rem;">{{"Designed by"|translate}}:9930i</p>
      <p style="color:#ffffffeb; font-size: 12px; line-height: 0.5rem; padding-bottom: 1%;">{{"Developed by:ShahSoftSol"|translate}}</p>
    </div>
    
  </footer>
  

<!-- for ecom end  -->


</div>
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
      {{"Forgot Password"|translate}}
    </h6>

    <mat-icon
      (click)="modal.dismiss('Cross click')"
      class="text-danger cursor-pointer"
      >close</mat-icon
    >
  </div>
  <!-- modal body starts -->

   <!-- modal body starts -->
  <div class="modal-body ">
    <div class="container-fluid">
      <form [formGroup]="forgotForm">
        <div class=" ">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <br/>
              <p style="font-weight: 500;
              font-size: 13px;
              letter-spacing: 1px;">
                {{"Enter your username or email/mobile no. and we'll send you a new password to get back into"|translate}} <br/>{{"your account"|translate}}
              </p>
               <div class="input-group mb-3 mt-3 ">
                         
                          <input type="text" style="padding-left: 3%;
                          font-weight: 600;
                          font-size: 13px;" class="form-control forget-pass" formControlName="username" placeholder="Email,Mobile No. or Username" >
                      </div>
            
            </div>
          </div>
         
         
          <div class="row justify-content-center mt-3 mb-3">
            <button
             [disabled]="!loading"
              class="order-button " [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
              mat-raised-button
              matTooltip="Send Password"
              (click)="forgotPassword(); modal.close('Save click')"
            >
              {{"Submit"|translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
</div>
<div *ngIf="ninetoys==true" class="d-none d-md-block">
<app-nineseller></app-nineseller>
</div>


