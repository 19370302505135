export const environment = {
  production: true,
  urls:'/',
  mock: false,
  development: false,
  title: 'prod',
  NodebaseUrl:'https://api.9toys.com/',
  project:"9toys",
  favicon:"assets/img/9toyslogo.jpeg",
  logo:"assets/img/9toyslogo.jpeg",
  loader:"assets/img/loader-ecom.gif",
  error_img:"assets/img/9toyslogo.jpeg",
  website:"9toys.in",
  admintitle:"Sell Online on 9toys | Sell toys and Games online",
  admindescription:"Sell toys and Games, Puzzles, Soft Toys, Toy Vehicles & More games Grow your business with 9toys Register Now.Best online selling platform for toys.",

  UploadUrl : 'https://909corns.in/uploads/', 
  firebase  :{
    apiKey: "AIzaSyDGvvoJf9SlKsC5JS-DdOzJa2JFLNf4fYQ",
    authDomain: "ecomtrails.firebaseapp.com",
    databaseURL: "https://ecomtrails.firebaseio.com",
    projectId: "ecomtrails",
    storageBucket: "ecomtrails.appspot.com",
    messagingSenderId: "918450948543",
    appId: "1:918450948543:web:fa17d5872d02cde555b90a",
    measurementId: "G-KL5BVT2ZG5"
  }
};
