<div *ngIf="edit">
    <i class="far fa-edit cursor-pointer text-success" (click)="gettagDetails(data);openXl(content);"></i>
</div>
<div *ngIf="delete">
    <i class="fas fa-trash-alt text-danger cursor-pointer" (click)="deletingtag(data)"></i>
</div>

<style>
    .example-full-width{
        width: 100%;
    }
</style>

<ng-template #content let-modal >
    <div class="modal-header">
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title"  align="center">{{"Update tag" | translate}}</h6>
      
      <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')" ></i>
    </div>
      <!-- modal body starts -->
    <div class="modal-body "  >
           <form [formGroup]="registerForm" >
                          <div class="row justify-content-center">
                           
                            
                                <div class=" col-lg-12">
                                  <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label >{{"tag Name" | translate}}</mat-label>
                                    <input  matInput  placeholder="tag Name" formControlName="name" required>
                                    <i class="fas fa-tags" matSuffix></i>
                                  </mat-form-field>
                                </div>
                              </div>
                            
                         
    
    
                      
                         <div class="row justify-content-center">
                          <button mat-raised-button class="order-button text-white" (click)="onSubmit();modal.close('Save click')" mat-raised-button >{{"Update" | translate}}</button>
                        </div>
    
                        
    
                </form>
    </div>
  
  </ng-template>
  
  <ng-template #content3Exep let-modal >
<div>
      <div class="modal-header">
        <h6 [style.color]="headingcolor" class="modal-title">{{"Delete Tag" | translate}}</h6>
        <button type="button" class="close text-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf ="tagException" class="col-sm-12 w-100" >
                    
                    <h6 class="text-center text-warning" style="font-size: 10px;"><i class="fas fa-exclamation-triangle"></i>&nbsp; "{{"These must delete before deleting tag" | translate}}"</h6>
                      
                    <ol class="list-group list-group-flush" *ngFor="let tag_p of tag_products">
                          <li class="list-group-item p-0 m-0">
                                <div class="col-sm-12">
                              <b>{{"Product" | translate}}  :   </b>{{tag_p.meta_title}}
                                </div>
                               
                                
                    
                          </li>
                          
                    </ol> 
                     
           

        </div>
       </div>
     
    </div>
    </ng-template>