<div class="container-fluid" *ngIf="actionform=='add'">
    <div class="row justify-content-center">
        <div class="col-md-10">
            <div class="row ml-2">
                <h2 class=" product-border-heading" [style.color]="headingcolor">
                   {{"Add Multiple Pincode"|translate}}
                </h2>
            </div>
            <div class="card background mt-2">
                <div class="container">
                    <div class=" mt-3">
                        <div class="row">
                            <div class="col-md-4 ">
                                <button (click)="addPincode()" style="margin-left: 40%;" mat-mini-fab class="bg-success text-white">
                                    <!-- <mat-icon>add</mat-icon> -->
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                            <form [formGroup]="registerForm2" >
                                <div formArrayName="pincode">
                                    <div *ngFor="let name of pincode.controls; index as idx ;" >
                                     
                                        <mat-form-field appearance="fill" class="example-full-width input">
                                            <mat-label class="mat-label ">{{"Multiple Pincode"|translate}}</mat-label>
                                            <input class="text-left" type="number"   (keypress)="numericOnly($event.target.value.length)"  matInput placeholder="Multiple Pincode"
                                                [formControlName]="idx" maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required >
                                            <mat-icon matSuffix>fiber_pin</mat-icon>
                                        </mat-form-field>
                                        <i class="fas fa-times"
                                            *ngIf="pincode.length !==1" 
                                              class="cursor-pointer"
                                              (click)="deleteMultiplepincode(idx)"
                                              title="Remove"
                                        ></i>
                                            <!-- <mat-icon
                                            *ngIf="pincode.length !==1" 
                                              class="cursor-pointer"
                                              (click)="deleteMultiplepincode(idx)"
                                              title="Remove"
                                              >close</mat-icon> -->
                                <!-- <button mat-mini-fab class="cursor-pointer" 
                                (click)="deleteMultiplepincode(idx)" *ngIf="multiplepincode.length !==1"  >
                                <mat-icon>delete</mat-icon>
                                </button> -->
                                 </div>
                                </div>
                                <div class="justify-content-center row  mt-2 mr-2">
                                    <button (click)="send()"  mat-raised-button
                                        style="margin-bottom: 20px; margin-left: 70px;"
                                        class="order-button text-white text-right">
                                        {{"Save"|translate}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid" *ngIf="actionform=='update'">
    <div class="row justify-content-center">
        <div class="col-md-10">
            <div class="row ml-2">
                <h2 class=" product-border-heading" [style.color]="headingcolor">
                    {{"Update Multiple Pincode"|translate}}
                </h2>
            </div>
            <div class="card background mt-2">
                <div class="container">
                    <div class=" mt-3">
                        <div class="row">
                            <div class="col-md-4 ">
                            </div>
                            <form [formGroup]="registerForm" >
                                <div >
                                    <div  >
                                     
                                        <mat-form-field appearance="fill" class="example-full-width input">
                                            <mat-label class="mat-label ">{{"Multiple Pincode"|translate}}</mat-label>
                                            <input class="text-left" type="number"   matInput placeholder="Multiple Pincode"
                                                formControlName="pincode" maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  required >
                                            <mat-icon matSuffix>fiber_pin</mat-icon>
                                        </mat-form-field>
                                 </div>
                                </div>
                                <div class="justify-content-center row  mt-2 mr-2">
                                    <button (click)="update()"  mat-raised-button
                                        style="margin-bottom: 20px; margin-left: 70px;"
                                        class="order-button text-white text-right">
                                        {{"Update"|translate}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>