<div class="container-fluid">

  <div class="container-fluid">
  
    <div class="row mt-2  mb-3 head-row " >
      <div class="col-md-7">
        <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
          {{"Routes"|translate}}
        </h2>
      </div>
      <div class="col-md-3 " >
        <mat-form-field class="w-100"   style="     margin: -10px 1px -17px;
        ">
          <input matInput type="text" (keyup)="applyFilter($event.target.value)" style="padding-bottom: 5px;" placeholder="&nbsp;  Search.." class="pl-2" > 
          <!-- <mat-icon matPrefix>search</mat-icon> -->
          <i class="fas fa-search" matprefix></i>
        </mat-form-field>
      </div>
      
        <div  class="col-md-2 text-right add-brand">
          <button (click)="action('add');openXl(content)" mat-raised-button  matTooltip="add brand"  class="text-right order-button  mr-3" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
            {{"Add Routes"|translate}}
          </button>
        </div>


      
      </div>
  <div class="row " >
  
    
  
  <!-- start for ecomtrails -->

   <div  class="table table-borderd">


               <mat-table [dataSource]="dataSource "  matSort >
                <ng-container matColumnDef="sno">
                           <mat-header-cell *matHeaderCellDef mat-sort-header> {{"S No."|translate}} </mat-header-cell>
                           <mat-cell *matCellDef="let element">
                             <span class="mobile-label">{{"S No."|translate}} :</span> 
                             {{element.sno}} </mat-cell>
                        </ng-container>

                          <ng-container matColumnDef="name">
                           <mat-header-cell *matHeaderCellDef mat-sort-header> {{"Company"|translate}} </mat-header-cell>
                           <mat-cell *matCellDef="let element">
                             <span class="mobile-label">{{"Company"|translate}} :</span> 
                             
                            
                            <div *ngIf="element.name ==0  || element.name ==null || element.name =='null' ">{{"Not Available"|translate}}</div>
                           <div *ngIf="element.name !=0  && element.name !=null && element.name !='null'">{{element.name}}</div>
                            </mat-cell>
                        </ng-container>
                
                  <ng-container matColumnDef="in_url">
                     <mat-header-cell *matHeaderCellDef  mat-sort-header> {{"Input URL"|translate}} </mat-header-cell>
                     <mat-cell  *matCellDef="let element"> 
                      <span class="mobile-label">{{"Input URL" | translate}} :</span>
                      <div *ngIf = "element.in_url ==0; else branddetails">{{"Not Available"|translate}}</div>
            <ng-template #branddetails >
            <span >{{element.in_url}}</span>  
            
            </ng-template>
                        </mat-cell>
                  </ng-container>
                  <!-- about brand -->
 <ng-container matColumnDef="out_url" >
                     <mat-header-cell *matHeaderCellDef mat-sort-header> {{"Output URL"|translate}} </mat-header-cell>
                     <mat-cell *matCellDef="let element"  >
                      <span class="mobile-label">{{"Output URL" | translate}} :</span>
                       <div *ngIf="element.out_url ==null || element.out_url =='null' || element.out_url ==''">{{"Not Available"|translate}}</div>
          
            <span *ngIf ="element.out_url !=null && element.out_url !='null' && element.out_url !=''">{{element.out_url}}</span>  
            
          </mat-cell>
                      
                  </ng-container>
          
                  <!-- about brand end -->
              

                  <ng-container  matColumnDef="edit" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{"Edit"|translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let element"  >
                     <span class="mobile-label">{{"Edit"|translate}} :</span>
                     
         
            <span (click)="getBrandDetails(element.id,element.in_url,element.out_url,element.comp_id,'update');openXl(content)"  ><i class="fas fa-pen text-success cursor-pointer"></i></span>
           
         </mat-cell>
                     
                 </ng-container>


               
                  <ng-container matColumnDef="delete">
                    <div >
                     <mat-header-cell *matHeaderCellDef  mat-sort-header> {{"Delete"|translate}} </mat-header-cell>
                   </div>
                   
                   <span >
                     <mat-cell *matCellDef="let element">
                      <span class="mobile-label">{{"Delete"|translate}} :</span>
                       <div >
                        
                        <i class="far fa-trash-alt cursor-pointer text-danger" matTooltip="Delete brand"  (click)="deletingBrand(element.id)"></i>
                       
                       
                       </div>
                     
                     </mat-cell>
                   </span>
                
                                      </ng-container>
                 
                                  
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
               </mat-table>
  
  
  
  </div>
<!-- end for ecomtrails -->
  
  <!-- </div> -->
  
  
   <ng-template #content let-modal>
        <div class="modal-header">


          <h5 *ngIf="method=='update'"  [style.color]="headingcolor" class="modal-title" id="modal-basic-title"  align="center">{{"Update"|translate}} </h5>
            <h5 *ngIf="method=='add'"  [style.color]="headingcolor" class="modal-title" id="modal-basic-title"  align="center">{{"Add"|translate}}</h5>
         
          <i class="fas fa-times cursor-pointer" title="close modal"  (click)="modal.dismiss('Cross click')"></i>
        </div>
          <!-- modal body starts -->
         <!--  <div *ngFor="let actual_data of actual_data">
{{actual_data.name}}
          </div> -->
        <div class="modal-body p-0"  >
               <form [formGroup]="registerForm" >
                            <div class=" ">
                                <!-- <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Company</mat-label>
    
                  <mat-select id="country" formControlName="comp_num" required>
                    <mat-option *ngFor="let actual_data of actual_data"
               [value]="actual_data.comp_num" >{{actual_data.name}}</mat-option>
                   
    
                  </mat-select>
                </mat-form-field>
               -->
               <div class="row justify-content-center">
                 <div class="col-md-11">
                  <select
                        
                  id="category"
                  formControlName="comp_num"
                  placeholder="Company"
                >
                  <option
                   *ngFor="let actual_data of actual_data"
                    [value]="actual_data.comp_num"
                    >{{actual_data.name}}
                  </option>
                </select>
                 </div>
              
                  </div>
                  <br />
                               
                                <div class="container-fluid">
                                  <div class="row " >
                                    <div class=" col-lg-12">
                                      <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label > {{"Input URL"|translate}}</mat-label>
                                        <input matInput  placeholder='{{"Input URL"|translate}}' class="pl-0" formControlName="in_url" required>
                                        <mat-icon matSuffix>compass_calibration</mat-icon>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="row" >
                                    <div class="col-lg-12">
                                    <!--   <mat-form-field appearance="fill" class="example-full-width">
                                        <mat-label>Output URL</mat-label>
                                        <textarea matInput placeholder="Output URL" rows="2" formControlName="out_url" ></textarea>
                                        <mat-icon matSuffix>library_books</mat-icon>
                                      </mat-form-field> -->

                                        <mat-form-field class="w-100">
                                          <mat-label>{{"Output UR"|translate}}L</mat-label>
                                          <input matInput required  (keyup)="checkConfirmPassword()" id="myInput3" formControlName="out_url" [class.is-invalid]="registerForm.errors?.misMatch">
                                          
                                          <mat-hint class="text-danger" *ngIf="is_confirm">{{"Output URL must start with"|translate}} https://emallnet.com</mat-hint>
                                        </mat-form-field>
                                    </div>

                                 
                                    
                            </div>
                                </div>
                              <div class="row justify-content-center mt-3">
                                <button *ngIf="method=='update'"  class="order-button " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" mat-raised-button matTooltip="Update " (click)="onSubmit();modal.close('Save click')" >{{"Update"|translate}}</button>
                                <button *ngIf="method=='add'"  class="order-button " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" mat-raised-button matTooltip="update brand" (click)="onSubmit();modal.close('Save click')" >{{"Add"|translate}}</button>
                              </div>
                            </div>
        
        
                          
                             
        
                            
        
                    </form>
        </div>
    
      </ng-template>
     
     
  
  </div>
</div>