<section class="mt-4">
    <div class="container-fluid">
        <div class="row wrapper">
            <div class="preview col-md-5" style="height: 100%;">
                <div class="preview-pic tab-content border p-1">
                    <div class="tab-pane active show-image cursor-pointer" id="pic-1 ">
                        <img src="{{ imageView }}" class="img-1 " (error)="onImgError($event)" alt="Product Image" matTooltip="Product Image" />
                    </div>
                </div>
                <ul class="preview-thumbnail nav nav-tabs  mt-3 ">
                    <li *ngFor="let image of product_image" class="active cursor-pointer">
                        <a data-target="#pic-1" matTooltip="more product image " data-toggle="tab" (click)="Image(image.image_link)">
                            <img src="{{ image.image_link }}" (error)="onImgError($event)" alt="Product Image" class="border p-1" height="60" width="60" /></a>
                    </li>
                </ul>

            </div>
            <div class="details col-md-7">
                <div class="row ">
                    <span class="d-flex ml-3">
          <span class="font-weight-400">  {{"Home" | translate}} /</span>
                    <div *ngFor="let pcat of product_category" class="font-weight-400 text-muted" title="{{pcat.name}}">

                        {{ (pcat.name.length>20)? (pcat.name | slice:0:20)+'...':(pcat.name) | titlecase}} /

                    </div>
                    </span>
                </div>
                <!-- //heading -->
                <h5 class="product-title font-weight-500 pt-1">{{ product.name }} </h5>
                <!-- //subheading -->
                <h5 *ngIf="product.color != null || product.color != null" class="d-flex" style="line-height: 0;">(
                    <p *ngIf="product.color != null"><span class="text-muted pl-2 pr-2">{{ product.color }}</span></p> -
                    <p *ngIf="product.size != null"> <span class="text-muted  pl-2 pr-2">{{ product.size }}</span></p>)</h5>
                <h6 class="product-title">{{ product.subheading }}</h6>

                 <h6 class="product-title">{{"MOQ (Minimum Order Quantity)" | translate}}:{{ product.moq }}</h6>
                  <h6 class="product-title">{{"Increase Quantity" | translate}} :{{ product.increase_quantity }}</h6>

                <span *ngIf="product.txn_quantity >=product.moq" class="font-weight-500 pl-2">
            <h5 class="text-success">{{"In Stock" | translate}} </h5></span>
                <span *ngIf="product.txn_quantity < product.moq " class=" font-weight-500 pl-2">
     <h5 class="text-danger">{{"Out of Stock" | translate}} </h5></span>



                <!-- for rating -->
                <div class="row mt-2 mb-2">
                    <div class="col-md-10 ">
                        <span *ngIf="mrpCheck==false" class=" font-weight-400 text-primary mb-2"><del> {{"MRP" | translate}} : <i class="fas fa-rupee-sign"></i> {{product.mrp  }}</del></span> <br>
                        <div *ngFor="let prates of product_rate" class="">

                            <span class="price font-weight-400 "><div *ngIf="prates.is_member=='Y'">{{"Member" | translate}}&nbsp;</div>{{ prates.name }} :&nbsp; &nbsp;
                <span class="font-weight-500"><i class="fas fa-rupee-sign"></i> {{ prates.rate  }}</span></span>
                        </div>

                    </div>
                </div>

                <div>
                    <p style="line-height: 0; font-size: 13px;" class="pt-2">{{"HSN Code" | translate}} : {{product.hsncode}}</p>
                    <p style="line-height: 0; font-size: 13px;" class="pt-1">{{"Stock keeping unit" | translate}} : {{product.sku}}</p>
                </div>



                <div> {{"Tags" | translate}} :
                    <ul>
                        <li *ngFor="let ptag of product_tags"> <span>{{ ptag.name }} </span></li>
                    </ul>
                </div>


                <div class="">
                    <div class="mt-2 font-weight-500 border-bottom">
                        <h6> {{"Description" | translate}} : - </h6>
                    </div>
                    <span class="product-description">
            
              <p [innerHTML]="product.meta_description"></p>
            </span>
                    <div class="border-bottom mt-1">
                        <h6>{{"Specification" | translate}} : -</h6>
                    </div>
                    <span class="mt-1"> {{"Brand" | translate}} : {{ product.brand_name }} </span> <br>
                    <span> {{"Model" | translate}} : {{ product.model }} </span>
                    <div *ngFor="let pspec of product_specification">
                        <span class="font-weight-400"> {{ pspec.spec_name }} : {{ pspec.spec_value }} </span>
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>