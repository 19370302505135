<div class="container-fluid">
  <div class="container-fluid">
    <div class="row mb-2 shadow-sm pt-3 pb-3 bg-white">
      <div class="col-sm-8">
        <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
          {{"Multiple Pincode" | translate}}
        </h2>
      </div>
      <div class="col-md-4 text-right ">
        <button (click)="addDepoAddress()" mat-raised-button matTooltip="Add Depo"
          class="text-right order-button border-border-info  mr-1" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
          {{"Add Depo" | translate}} 
        </button>
      </div>
    </div>
  </div>
</div>

<div class="mat-card" style="height: 580px; overflow: scroll;">
  <div fxLayout="row wrap">
    <div fxFlex="14%" style="    margin-left:2.5%;" *ngFor="let multiple of multiplepincodes;let index=index">
      <mat-card style="padding-left: 16px; 
padding-right: 16px;
padding-bottom: 8px;
margin-top: 24px;     
margin-left: 0.2em;
background: #f7f7f7">
        <div class="button">
          
            <div class="dropdown dropleft">
              <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v cursor-pointer text-muted" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item cursor-pointer" (click)="update(multiple.depo_service_no );
                            "><i class="fas fa-pencil-alt text-success"></i> {{"Edit" | translate}}</a>
                <a class="dropdown-item cursor-pointer" (click)="removeAddress(multiple.depo_service_no)"><i
                    class="fas fa-trash-alt text-danger"></i> {{"Delete" | translate}}</a>
              </div>
          </div>
        </div>
       

        <div class="row pl-3"  style="    margin-left: -25px;">
          <h5 class="font-weight-400">{{"Pincode" | translate}}:-{{ multiple.pincode }}</h5>
        </div>


      </mat-card>

    </div>
  </div>
</div>
