import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NinetoysserviceService } from '../ninetoysservice.service';

@Component({
  selector: 'app-multiple-pincode',
  templateUrl: './multiple-pincode.component.html',
  styleUrls: ['./multiple-pincode.component.css']
})
export class MultiplePincodeComponent implements OnInit {

  public access_token=sessionStorage.getItem('access_token');
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
  public user_num=sessionStorage.getItem('user_num');
  public comp_num=sessionStorage.getItem('comp_num');
  previewFlag = sessionStorage.getItem("previewFlag");
  public multiplepincodes: any;
  registerForm: FormGroup;

  constructor(
    private adminservice: NinetoysserviceService, 
    private _formBuilder: FormBuilder,
    private snackbar:MatSnackBar,
    private adminService: NinetoysserviceService,
    private cookie: CookieService,
    private router: Router,
   ){}
   ecomtrails = false;
   ecomtrails2 = true;
   ninetoys = false;
   maschile = false;
   mlm = false;
   serverlink;
   host_name;

  ngOnInit(): void {
    if(sessionStorage.getItem('database')=="ecomtrails"){
      this.ecomtrails=true;
    }
    else if(sessionStorage.getItem('database')=="9toys"){
      this.ninetoys=true;
    }

     else if(sessionStorage.getItem('database')=="maschile"){
      this.maschile=true;
    }
     else if(sessionStorage.getItem('database')=="mlm"){
      this.mlm=true;
    }
    else{
      this.ecomtrails=true;
    }
    this.registerForm = this._formBuilder.group({
      user_num:this.user_num,
      access_token:this.access_token,
      comp_num:this.comp_num,
      pincode:[''],
     });
    
    this.getMultiplePincode();
  }

  addDepoAddress() {
    sessionStorage.setItem("form","add");
    this.router.navigate(["/add-multiple-pincode"]);
  }
  update(depo_service_no) {
    sessionStorage.setItem("depo_service_no", depo_service_no);
    sessionStorage.setItem("form","update");

    this.router.navigate(["/add-multiple-pincode"]);
  }

  getMultiplePincode() {
    let postData = {
      user_num: sessionStorage.getItem("user_num"),
      access_token: sessionStorage.getItem("access_token"),
      comp_num: sessionStorage.getItem("comp_num"),

    };
    this.adminservice.fetchMultiplePincode(postData).subscribe(data => {
      this.multiplepincodes = data["result"];
      //this.multiplepincodes)
    }
    );
  }
  removeAddress(depo_service_no) {
    var res = confirm("Are you sure you want to delete this pincode.");
    if (res) {
      let postData = {
        user_num: sessionStorage.getItem("user_num"),
        access_token: sessionStorage.getItem("access_token"),
        depo_service_no: depo_service_no,
        comp_num: this.comp_num

      };
      this.adminservice.delete_multiple_pincode(postData).subscribe(data => {
        if (data["status"] == "1") {
          this.ngOnInit();
        } else if (data["status"] == "10") {
          if (this.previewFlag == "1") {
            if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "localhost:4209")) {
          // if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "www.ecomtrails.com")) {
              this.router
              . navigateByUrl("/", {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(["/preview/home"]));
            } else {
              this.router
                . navigateByUrl("/", {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(["/home"]));
            }
          } else {
            this.router
              . navigateByUrl("/", {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(["/home"]));
          }
        } else {
          if (sessionStorage.getItem("access_token") == "") {
            if (this.previewFlag == "1") {
              if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "localhost:4209")) {
          // if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "www.ecomtrails.com")) {
                this.router
                  . navigateByUrl("/", {
                    skipLocationChange: true
                  })
                  .then(() => this.router.navigate(["/preview/home"]));
              } else {
                this.router
                  . navigateByUrl("/", {
                    skipLocationChange: true
                  })
                  .then(() => this.router.navigate(["/home"]));
              }
            } else {
              this.router
                . navigateByUrl("/", {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(["/home"]));
            }
          }
        }
      });
    }

  }
}
