 
<div class="container-fluid">
    <div class="pl-3  row justify-content-between">
      <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
        {{"Catalogue"|translate}}
      </h2> 
     
    </div>
   
    <div class="row justify-content-center mt-2" *ngIf="brand_lists.length>0 && categories_lists.length>0">
      <div class="col-lg-6">
        <img src="../../assets/img/cataloge.png" height="120" class="ml-auto mr-auto text-center">
        <div  class="mt-1">
       <span class="font-weight-600" [style.color]="headingcolor">   {{"Do you want to download Brand or category catalogue"|translate}} </span>
          	<br/> 
            <p *ngIf="click==0" [style.color]="headingcolor">{{"Please Select catalogue option"|translate}}</p>
            
     <mat-radio-group style="position: relative;
     left: 15px;
     top: 2px;
     font-size: 13px;"  *ngIf="click==0">
                   <mat-radio-button  (click)="radioClick(1)"
                   matTooltip="Brand" 
                   value="1"
                   > {{"Brand"|translate}} 
                 </mat-radio-button> <br/>
                  <mat-radio-button  (click)="radioClick(2)"
                   matTooltip="Category" 
                   value="2"
                   > {{"Category"|translate}}
                 </mat-radio-button>
     </mat-radio-group>
     </div>
      </div>
    </div>
    
<form [formGroup]="registerForm">
  <div class="row justify-content-center">
    <div class="col-lg-3" *ngIf="click==1 || click==2">
      <span>{{"Show Price For"|translate}}</span>
      <br/>
      <select  class="mt-1" id="is_member" formControlName="is_member" >
        <option 
          value="Y" selected
          >{{"Member"|translate}}
        </option>
        <option 
          value="N"
          >{{"Normal User"|translate}}
        </option>
  </select>
    </div>
    <br/>
                <div *ngIf="brand_lists.length>0 && click==1" class="col-md-6">
               <span>{{"For Brand"|translate}} </span>
               <br/>
                    <select (change)="selectBrand($event.target.value)" class="mt-1" id="brand" formControlName="brand_id" >
                     <option value="choose brand" selected >{{"choose brands"|translate}}</option>
                      <option 
                        *ngFor="let brands of brand_lists"
                        [value]="brands.brand_id"
                        >{{ brands.name }}
                      </option>
            </select>
                </div>
 				  <div *ngIf="categories_lists.length>0 && click==2" class="col-md-6">
               <span> {{"For Category"|translate}}</span>
               <br/>
                    <select (change)="selectCategory($event.target.value)" class="mt-1" id="category" formControlName="category_no" >
                      <option 
                        *ngFor="let brands of categories_lists"
                        [value]="brands.category_no"
                        >{{ brands.name }}
                      </option>
            </select>
              
          
  </div>

 

</div>
<div class="row justify-content-center">
<div class="col-lg-6 text-center">
  <div *ngIf="click !=0" class="mt-3">
    <!-- <button  class="text-white order-button cursor-pointer" (click)="back()" mat-raised-button>Cancel</button>&nbsp; -->
    <button  class="order-button cursor-pointer" (click)="get_catalogue()" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" mat-raised-button>
      <i class="fa fa-spinner fa-spin fa-2x" *ngIf="loader"></i>
      <span *ngIf="!loader">{{"Catalogue Download"|translate}}</span>
      
      </button>

   </div>
</div></div>
</form>
                  
  </div>