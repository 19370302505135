


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import {MatSnackBar} from '@angular/material';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

@Component({
    selector: 'cust-grid-module',
    templateUrl: './button2.component.html',
  })
  export class Button2Component {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
    orders: any;
    data: any;
  constructor(
 private route: ActivatedRoute,
 private router: Router,
 private adminservice: NinetoysserviceService,
 ) {
    
  }
    ngOnInit(): void {
       
    }
  agInit(params: ICellRendererParams): void {
      //params);
    this.params = params;
    this.data = params.value;
    var data1= params.data;

    if(this.params.label =='track'){
        //'track');
this.track = true;
this.view = false;
this.invoice = false;

    }
     if(this.params.label =='invoice'){
        //'invoice');
        this.track = false;
this.view = false;
this.invoice = true;
       
    }
    if(this.params.label =='view'){
        //'view');
        this.track = false;
this.view = true;
this.invoice = false;
      
    }
    if(this.params.label == 'deactive_member'){
        this.deactive_member=true;
        //'deactive');
        this.track = false;
        this.view = true;
        this.invoice = false;
    }
    if(this.params.label == 'delete'){
        this.delete=true;
        //'delete');
        this.track = false;
        this.view = true;
        this.invoice = false;
    }
  }
  
 
  sendOrderId(order_id,orderRandomId){
    sessionStorage.setItem('order_id',order_id);
    this.router.navigate(['/home_profile/order-details',orderRandomId]);
}
}