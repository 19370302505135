import { Component, HostListener } from '@angular/core';
import { MessagingService } from './messaging.service';
import { Router } from "@angular/router";
import { NinetoysserviceService } from "./ninetoysservice.service";
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  message1: string;
  project = environment.project;
  user_num = sessionStorage.getItem("user_num");
  access_token = sessionStorage.getItem("access_token");
  public usertype_id = sessionStorage.getItem('usertype_id');
  public comp_num = sessionStorage.getItem('comp_num');
  bgcolorr = this.cookie.get('bgcolorr');
  buttonbgcolor=this.cookie.get('buttonbgcolor');
  buttontextcolor =this.cookie.get('buttontextcolor');
  isShow: boolean;
  isLogin: boolean;
  topCategories;
  categories;
  topPosToStartShowing = 100;
  megaMenu = false;
  title = 'push-notification';
  message;
  playstorelink: any;
  applestorelink: any;
  theme_type: any;
  currentloaction;
  courier_key_exist: any;
  diccolor= '#000000';
  lanauge_type: any;
  language='english';
 
  quickSetting: any;
  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;


    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

 
  public is_logged_in = false;
  public is_logged_out = false;
  constructor(private messagingService: MessagingService, private router: Router, private translate: TranslateService, private adminservice: NinetoysserviceService, private cookie: CookieService) {
    this.get_language();

  }
  popup = false;
  get_language() {
    this.translate.addLangs([
      "english",
      "hindi",
      "gujrati",
      "Burmese",
      "kannada",
      "marathi",
      "telegu",
      "bengali",
      "Albanian",
      "Filipino",
      "Malayalam",
      "Kashmiri",
      "Tamil",
      "Punjabi",
      "Urdu",
      "Nepali",
      "en-Filipino"
    ]);
    this.translate.setDefaultLang("english");
    const browserLang = this.translate.getBrowserLang();

    this.translate.use(
      browserLang.match(
        /english|hindi|gujrati|Burmese|kannada|marathi|telegu|bengali|Albanian|Filipino|Malayalam|Kashmiri|Tamil|Punjabi|Urdu|Nepali/
      )
        ? browserLang
        : this.currentloaction == "Philippines"
          ? "en-Filipino"
          : "english"
    );
  }
  host_name;
  linkshowbox= false;
  innerWidth;
  ngOnInit() {

    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth)
    

   
    if(this.project == 'emall'){
       this.diccolor = '#FF0000'  
    }
    else{
this.diccolor = '#006400'
    }
    this.cookie.set('discount-color', this.diccolor);
  
  
    
    if (window.screen.width < 600) {
      this.popup = true;
    }
    else {
      this.popup = false;
    }
   


    if (!this.user_num && !this.access_token) {
      this.is_logged_out = true;
      this.is_logged_in = false;

      
    } else {
      this.is_logged_in = true;
      this.is_logged_out = false;

    }
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }

 
   
  
  
  myBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.DOCUMENT_NODE == true)) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }
  customer() {
    this.router.navigate(["/customer-support"]);
  }
  openinplay() {
    this.popup = false;
    window.open(this.playstorelink);
  }
  continue() {
    this.popup = false;
  }
}
