import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-greating-dialog',
  templateUrl: './greating-dialog.component.html',
  styleUrls: ['./greating-dialog.component.css']
})
export class GreatingDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GreatingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
    sessionStorage.setItem('greettime','0');
  }

  ngOnInit(): void {
    setTimeout(() => {
this.onNoClick();
    }, 5000);
  }

}
