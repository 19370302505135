<div class="container-fluid">
  <div class="row mb-3 pl-3 mt-2 justify-content-start">
    <h2 class=" product-border-heading" [style.color]="headingcolor" >
    <i class="fas fa-arrow-circle-left text-primary" (click)="back()"></i>   {{"Add Variants"|translate}}
    </h2>
    
  </div>

      <mat-card class="mt-2">
        <div class=" ">
          <h5 class="text-center font-weight-500 ml-3 mt-1 mb-1 text-info">
            <!-- Variation Theme : ColorSize -->
            {{"Variation Theme"|translate}} : {{variant_color | titlecase}}-{{variant_size | titlecase}}
          </h5>
          <h5 class="text-center font-weight-500 ml-5 mr-3 mt-1 mb-1 text-primary">
            <div *ngIf="this.product!=null">
            {{"Product"|translate}} : {{product.name}} &nbsp;&nbsp;
            {{"Product Group Name"|translate}} : {{product.prod_group}}
            </div>
          </h5>
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                 <span class="text-primary ">{{"Add Mutiple Variation"|translate}}</span> 
                </mat-panel-title>
              </mat-expansion-panel-header>
            

              <form [formGroup]="leagueForm" >
                <div formArrayName="variants" >
                  <div 
                    *ngFor="
                      let team of leagueForm.get('variants')['controls'];
                      let teamIndex = index
                    "
                    [formGroupName]="teamIndex"
                  >

                    <mat-card class="mt-1 border-secondary border">
                     
                      <div class="row justify-content-between">
                        <mat-form-field class="col-md-4 col-10">
                          <mat-label>{{"Choose"|translate}} {{variant_color | titlecase}}</mat-label>
                          <input class="text-primary" matInput formControlName="color" />
                        
                        </mat-form-field>
                        <i class="fas fa-times cursor-pointer text-danger text-right col-md-2 col-2"
                        (click)="removeItemColor(teamIndex)"
                        title="Remove Variant"
                        ></i>
                        <!-- <mat-icon
                        (click)="removeItemColor(teamIndex)"
                        title="Remove Variant"
                        class="cursor-pointer text-danger text-right col-md-2 col-2"
                        >close</mat-icon
                      > -->
                      </div>

                      <div formArrayName="sizes" class="row">
                        <div
                          class=" players col-md-4"
                          *ngFor="
                            let player of team.get('sizes').controls;
                            let playerIndex = index
                          "
                          [formGroupName]="playerIndex"
                        >
                          <mat-form-field class="example-full-width">
                            <mat-label>{{"Choose"|translate}} {{variant_size | titlecase}}</mat-label>
                            <input matInput formControlName="size" />
                            <i class="fas fa-times cursor-pointer text-danger" matSuffix
                            (click)="removeItemSize(teamIndex,playerIndex)"
                            title="Remove {{variant_size | titlecase}}"
                            
                            ></i>
                            <!-- <mat-icon matSuffix
                            (click)="removeItemSize(teamIndex,playerIndex)"
                            title="Remove {{variant_size | titlecase}}"
                            class="cursor-pointer text-danger"
                            >close</mat-icon
                          > -->
                          </mat-form-field>
                         
                        </div>
                      </div>
<div class="row justify-content-between">
                      <button
                        *ngIf="tableCheck"
                        mat-mini-fab
                        class="text-white bg-info cursor-pointer ml-2"
                        (click)="addPlayer(team)"
                        title="add more {{variant_size | titlecase}}"
                      >
                      <i class="fas fa-plus"></i>
                        <!-- <mat-icon>add</mat-icon>  -->
                      </button>

                    

                      <button
                        *ngIf="tableCheck"
                        mat-raised-button
                        [disabled]="!loading"
                        (click)="addProductVariant(teamIndex)"
                        class="order-button text-white justify-content-end mr-2"
                      >
                        {{"Save"|translate}}
                      </button>
                    </div>
                    </mat-card>
                  </div>
                </div>
              </form>

              <div>
                <button
                  *ngIf="tableCheck"
                  mat-raised-button
                  class="order-button text-white cursor-pointer mt-2"
                  (click)="addTeam()"
                  title="add more variants"
                >
                   {{"add more"|translate}}
                </button>
              </div>


              <!-- <div class=" mt-2"  > -->
                <form [formGroup]="tableForm" *ngIf="showvartable">
                  <div formArrayName="variants">
                    <table class="table  table-bordered table-responsive mt-2" style=" overflow: auto;">
                      <thead>
                        <tr>
                      
                          <th>"{{"SKU"|translate}}</th>
                          <th>{{variant_color | titlecase}}</th>
                          <th>{{variant_size | titlecase}}</th>
                           <th *ngIf="product_no_var_flag == 1 && usertype_id == '3'">{{"Qty"|translate}}</th>
                           <th *ngIf="usertype_id != '3'">{{"Qty"|translate}}</th>

                            <th *ngIf="product_no_var_flag == 1 && usertype_id == '3'">{{"MRP"|translate}}</th>
                           <th *ngIf="usertype_id != '3'">{{"MRP"|translate}}</th>
                          <!-- <th>MRP</th> -->
                          <th style="flex: 0 0 13% !important;" *ngIf="product_no_var_flag == 1">{{"Tax"|translate}} %</th>



                          
                            <th *ngIf="product_no_var_flag == 1 && usertype_id == '3'">{{"Rates"|translate}}</th>
                           <th *ngIf="usertype_id != '3'">{{"Rates"|translate}}</th>

                          <th *ngIf="tableCheck">{{"Delete"|translate}}</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          *ngFor="
                            let team2 of tableForm.get('variants')['controls'];
                            let teamIndex2 = index
                          "
                          [formGroupName]="teamIndex2"
                        >
                       
                           <td>
                            <input title="{{sku}}"
                              formControlName="sku"
                              class="form-control"
                              type="text"
                            />
                          </td>
                          <td >
                            <input 
                              formControlName="color"
                              class="form-control text-primary"
                              type="text" 
                            />
                          </td>
                          <td>
                            <input
                              formControlName="size"
                              class="form-control"
                              type="text" style="width: 80px;"
                            />
                          </td>

                          <td *ngIf="product_no_var_flag == 1 && usertype_id == '3'"><input
                              formControlName="qty"
                              class="form-control"
                              type="number" style="width:40px"
                            /></td>
                           <td *ngIf="usertype_id != '3'"><input
                              formControlName="qty"
                              class="form-control"
                              type="number" style="width: 40px;"
                            /></td>
                           
                           <td *ngIf="product_no_var_flag == 1 && usertype_id == '3'"><input
                              formControlName="mrp"

                              class="form-control"
                              type="number"
                            /></td>
                           <td *ngIf="usertype_id != '3'"><input
                              formControlName="mrp"

                              class="form-control"
                              type="number"
                            /></td>
                        
                          <td *ngIf="product_no_var_flag == 1">
                           

                    <select id="tax" placeholder="Tax%" formControlName="tax_num" style="width: 50px;" class="form-control cursor-pointer">
                          <option  *ngFor="let tax of taxes" 
                          [value]="tax.tax_num">{{ tax.rate }}</option>
                         
                        </select>

                          </td>

                          
                        
                          <td *ngIf="(product_no_var_flag == 1 && usertype_id == '3') || usertype_id != '3'">
                            <!-- start for rate -->
                            <div formArrayName="prices">
                              <div
                                class="players"
                                *ngFor="
                                  let player2 of team2.get('prices').controls;
                                  let playerIndex2 = index
                                "
                                [formGroupName]="playerIndex2"
                              >
                                <div class="row mb-1">
                                  <div class="col-lg-6 col-6 text-left font-weight-500">
                                    <span>
                                     {{player2.controls.rate_name.value}} :-
                                    </span>
                                    
                               
                                  </div>
                                  <div class="col-lg-6 col-6 text-center">
                                    <input
                                    
                                    placeholder="Rate"
                                    formControlName="rate" 
                                    class="form-control"
                                    type="number"
                                  />
                                
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end for rate -->
                            <!-- start for member rate -->
 <div formArrayName="member_prices">
                              <div
                                class="players"
                                *ngFor="
                                  let player2Member of team2.get('member_prices').controls;
                                  let playerIndex2Member = index
                                "
                                [formGroupName]="playerIndex2Member"
                              >
                                <div class="row mb-1">
                                  <div class="col-lg-6 col-6 text-left font-weight-500">
                                    <span>
                                     {{"Member"|translate}}&nbsp;{{player2Member.controls.rate_name.value}} :-
                                    </span>
                                    
                               
                                  </div>
                                  <div class="col-lg-6 col-6 text-center">
                                    <input
                                    
                                    placeholder="Member Rate"
                                    formControlName="rate" 
                                    class="form-control"
                                    type="number"
                                  />
                                
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end for member rates -->
                          </td>
                          <td
                          *ngIf="tableCheck"
                          (click)="deleteRow(teamIndex2)" class="text-center"
                        >
                          <i class="fa fa-trash fa-1x text-danger text-center cursor-pointer"></i>
                        </td>


                        </tr>
                      </tbody>
                    </table>
                    <div class="row justify-content-end pr-2">
                      <button
                      *ngIf="saveCheck && tableCheck"
                      mat-raised-button
                      [disabled]="!loading"
                      (click)="add_product_variation()"
                      class="order-button text-white mr-1 ml-1 text-right "
                    >
                      Save
                    </button>
                    </div>
                 
                  </div>
                </form>
              
              <div
                *ngIf="tableCheck == false"
                class="container"
                style="margin-top: 2%"
              >
               
              <div class="row justify-content-between border-bottom">
                <div class="col-lg-12">
                  <p class="text-info">
                    <i class="fas fa-exclamation"></i>{{"Note : a) Image Width must be less than 7000px and Height must be less than 5000px."|translate}} <br/>
                   {{"b) Image size should not be more than 2MB."|translate}} <br/>
                   {{"c) It is necessary to add the image on the first index, then you can upload the video on the second index."|translate}}
                  </p>
                   <!-- start for background clear image -->
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" (click)="onChangeDemo($event.target.checked)" type="checkbox"  id="inform-me">
                    <label class="custom-control-label" for="inform-me">{{"Want a background clear Image"|translate}}</label>
                </div>
                </div>
          <!-- end for background clear image -->
              </div>
              
             
 
   
    <form [formGroup]="imageForm" >
      <div formArrayName="variants" class="row">
        <div  *ngFor=" let imageteam of imageForm.get('variants').controls;let teamIndex = index "[formGroupName]="teamIndex"  class="col-md-12 mt-2" >
          <span class="p-2  mb-2 colors" *ngIf="imageteam.controls.color.value != ''">{{variant_color | titlecase}} : {{imageteam.controls.color.value}}</span>

          <span class="p-2 mb-2 colors" *ngIf="imageteam.controls.color.value == ''">{{variant_color | titlecase}} : -</span>
        
           <mat-card  class="border-mat mt-1 mb-1 row" >
          <div formArrayName="sizes" class=" row">
            <div class=" col-md-4  players " *ngFor="  let imageplayer of imageteam.get('sizes').controls;  let playerIndex = index    " [formGroupName]="playerIndex"  >
      
              <div class="row">
               
                <div class="p-2 text-center col-md-10" >
                 

                  <label class="mat-label" >{{"Choose Image"|translate}} </label>
                  <input
                    class="text-center "
                    type="file"
                    formControlName="size"
                    (change)="fileProgressArray2($event, playerIndex,teamIndex,color)"
                  />
                </div>
                <div class="col-md-2 text-right" *ngIf="playerIndex > 0">
                  <i class="fas fa-times cursor-pointer"
                  (click)="removeimageItemSize(teamIndex,playerIndex)"
                  title="Remove"
                  
                  ></i>
                  <!-- <mat-icon
                  
                  (click)="removeimageItemSize(teamIndex,playerIndex)"
                  title="Remove"
                  class="cursor-pointer"
                  >close</mat-icon
                > -->
                </div>
                
                <div class="image-preview text-center mb-3" *ngIf="imageArray22Preview[teamIndex][playerIndex]">
                  <img [src]="imageArray22Preview[teamIndex][playerIndex]" height=150  class="image-preview text-center mt-1" />
                </div>
              </div>
            </div>
          </div>
           <div class="mt-2">
            <button  mat-mini-fab class="bg-primary text-white cursor-pointer"  (click)="addimagePlayer(imageteam)"  title="add more image"  >
              <!-- <mat-icon>add</mat-icon> -->
              <i class="fas fa-plus"></i>
            </button>
          </div>
           </mat-card >
           <!-- start for exist image checkbox 31/08/2020 -->
          <div class="row" *ngIf="existImage==true">

          
            <div *ngFor="let x of product_imag;let xn=index" class="col-md-4">
               <div class="">
                  <input  class="" (click)="onChangeDemoC($event.target.checked,xn)" type="checkbox" id="inform-me2" />
                 &nbsp; <label class="font-weight-500" for="inform-me2">{{"Do you want to add this image."|translate}}</label>
                </div>
               <div class="image-preview text-center mb-3" >
                
                  <img [src]="product_imag_prev[xn]" height=150  class="image-preview text-center mt-1" />
                </div>
            </div>
          </div>
           <!-- end for exist image checkbox 31/08/2020 -->
                <div class="justify-content-end row mb-2 pr-2">
              <button
              mat-raised-button
              [disabled]="!loading"
              (click)="add_product_variation_image2(teamIndex,imageteam.controls.color.value)"
              class="order-button text-white mt-2 mr-2 justify-content-end text-right"
            >
             {{"Upload"|translate}}
            </button>
           
            </div>
        </div>
      </div>
         
          
        </form>
               
            
              <!-- </div> -->
              <!-- </div> -->
           
              </div>
              <!-- end for image -->
             
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- </form> -->
      </mat-card>
    <!-- </mat-step> -->
  <!-- </mat-horizontal-stepper> -->
</div>
