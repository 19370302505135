


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'button-non-register-customer',
    templateUrl: './button-non-register-component.html',
  })
  export class ButtonNonRegisterCustomerComponent {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public usertype_id= parseInt(sessionStorage.getItem("usertype_id"));
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
    data: any;
    deactivatef=false;
 
  constructor(
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'md' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
       
    }
  agInit(params: ICellRendererParams): void {
      //params);
    this.params = params;
    this.data = params.value;
    var data1= params.data;

    if(this.params.label =='delete'){
this.deactivatef = true;
// this.view = false;
// this.invoicenot = false;
// this.invoice = false;

    }

  }
  //--opm pe check operational manager//
  deleting(user_num){
    if(this.usertype_id==3){
      alert("you dont have an access to delete customers please contact to admin.")
    }
    else{
    var res = confirm("Are you sure you want to delete this Customer.");
    if(res){
     
     this.adminservice.customer_deactivation({access_token:this.access_token,user_num:this.user_num, comp_num: this.comp_num,cust_no:user_num}).subscribe(data=>{
      
       if(data['status']==1){ 
         this.snackbar.open(this.adminservice.getCloseWord('This Customer is deleted successfully. '),'' ,{
           duration: 3000,
           horizontalPosition:'center',
       }); 
       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
       this.router.navigate(['//non-registered-customer']));          
         
       }
       else if(data['status']==10){
       sessionStorage.clear();
        this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                 duration: 3000,
                 horizontalPosition:'center',
         });      
       this.router.navigate(['/']);
       }
      
       else{
         this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again.'),'' ,{
           duration: 3000,
           horizontalPosition:'center',
         }); 
 
       }
     },
     error=>{
        this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again.'),'' ,{
           duration: 3000,
           horizontalPosition:'center',
         });
       }
     );

    }
   }
  }





}