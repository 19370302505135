<div *ngIf="view" class="">
    <i class="far fa-eye cursor-pointer" title="View Order Details" (click)="
    sendOrderId(params.value.track,params.value.track1)" ></i>
    
  </div>
  <div *ngIf="invoice" class="">
    <i class="fas fa-file-pdf cursor-pointer text-danger" title="Download Invoice" (click)="
    print_invoice(params.value.track,params.track1)"></i>
  </div>
  <div *ngIf="invoicenot" style="width: 100px;">
   <span class="text-muted" style="font-size: 12px;">{{"to be generated"|translate}}</span>
  </div>
  <div *ngIf="track">
    <i class="fas fa-chart-line cursor-pointer" title="Track Your Product" (click)="trackStatus(params.value.track,params.value.track1,params.value.track2,params.value.track3,params.value.track4,params.value.track5);openXl(contentTrack)"></i>
  </div>
 
   
  
  <ng-template #contentTrack let-modal>
    <div class="modal-header">
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
       {{"Tracking Details"|translate}}
      </h6>
  
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
       {{"Order ID :"|translate}} {{status_order_number}}
      </h6>
      <!-- <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
       Product : {{status_product_name}}
      </h6> -->
      <i class="fas fa-times cursor-pointer"
        title="close modal"
        (click)="modal.dismiss('Cross click')"
        ></i
      >
    </div>
    <!-- modal body starts -->
    <div class="modal-body ">
      <!-- start table -->
   <table class="table">
    <thead>
      <tr>
        <th scope="col">{{"S. No."|translate}}</th>
        <th scope="col">{{"Status"|translate}} </th>
        <th scope="col">{{"TimeStamp"|translate}}</th>
        <!-- <th scope="col">Handle</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let status_flow of status_flow;let k=index">
        <th scope="row">{{k+1}}</th>
        <td>{{status_flow.status}}</td>
        <td>{{status_flow.added_on | date: "d/MM/yyyy, h:mm a"}}</td>
        <!-- <td>@mdo</td> -->
      </tr>
     
    </tbody>
  </table>
  <div class="col-lg-4">
    <!-- start for tracking url -->
   
     <a *ngIf="courier_method_id==1 && tracking_url!=null && tracking_url!=''"
          href="tracking_url"
          target="_blank"
         
        >
          <i class="fas fa-info-circle"></i> {{"Click Here to More info"|translate}}
        </a>
     <!-- end for tracking url -->
    <button *ngIf="courier_method_id==2 && awb_no!=null && track_more==false"
                  (click)="shipyari_awb_track_lifecycle()" mat-raised-button class="order-button w-100 mt-2">{{"View More"|translate}}
                </button> 
                </div>
                  <!-- start table -->
                  <div  *ngIf="track_more==true">
                  <h5>{{"tracking_number :"|translate}}{{track.result[0]?.msg.tracking_number}}</h5>
   <table class="table">
    <thead>
      <tr>
        <th scope="col">{{"S. No."|translate}}</th>
        <th scope="col">{{"Tag"|translate}}</th>
        <th scope="col">{{"TimeStamp"|translate}}</th>
        <th scope="col">{{"Location"|translate}}</th>
        <!-- <th scope="col">Handle</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let track2 of track.result[0]?.msg.checkpoints;let k2=index">
        <th scope="row">{{k2+1}}</th>
        <td>{{track2.tag}}</td>
        <td>{{track2.checkpoint_time | date: "d/MM/yyyy, h:mm a"}}</td>
        <td>{{track2.location}}</td>
        <!-- <td>@mdo</td> -->
      </tr>
     
    </tbody>
  </table>
</div>
               
  </div>
  </ng-template>