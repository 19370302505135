<div *ngIf="ecomtrails==true || maschile==true || mlm==true" class="d-none d-md-block">


<!-- <div *ngIf="!loading">
  <mat-spinner
    style="margin-left: 35%; margin-top: 20vh;position: fixed;z-index: 20;"
    [diameter]="100"
  ></mat-spinner>
</div> -->
<nav class=" navbar-light fixed-top"  class=" navbar-light fixed-top" [ngClass]="[project=='emall' ? 'navbar-emall' : 'navbar-ecomtrails']">
  <div class="row w-100">
    <div class="col-md-6 w-100 ">
      <!-- for emall start -->

      <a class="navbar-brand  pl-2 pr-2" href="https://www.{{website}}/" target="_blank" >
        <img src="{{logo}}"  height="40" alt="" (error)="onImgError($event)"> 
      </a>
  <!-- for emall end -->

<!-- for ecom start -->
  <!--     <a *ngIf="project!='emall'" class="navbar-brand  pl-2 pr-2" href="https://www.ecomtrails.com/" target="_blank" >
        <img src="assets/img/ecom-logo.png"  height="40" alt="" > 
      </a> -->
<!-- for ecom end -->

    </div>
 
  

</div>
</nav>
<div class="back">
 

  <div *ngIf="signup == 0">

    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
          class="login-heading"
        >
          {{"Login"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="loginForm">
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width" >
                <mat-label>{{"Enter Email or Phone Number"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="username"
                  
                  required
                />
              
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width" >
                <mat-label>{{"Enter Password"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="password"
                  id="myInput"
                  type="password"
                  required
                />
                <i class="far fa-eye cursor-pointer" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="!visibility"  matTooltip="Show password"></i>
                <i class="far fa-eye-slash cursor-pointer" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="visibility"  matTooltip="Hide password"></i>
               
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
          <button
          
            mat-raised-button
            class="button-login w-90"
            (click)="onSubmit()"
            [disabled]="!loading"
          >
            {{"Login"|translate}}
          </button>
          <hr>
          <!-- <a href="/" [disabled]="!loading" color="primary"  (click)="onSubmitSign()">Want to Register as vendor</a> -->
          <p class="text-center text-muted small mt-2">
            {{"Don't have an account?"|translate}}
            <a
              (click)="onSubmitSign()"
              class="line-login"
            >
              {{"Sign up here!"|translate}}</a
            >
          </p>
          
         <!--  <p class="text-center text-muted small">
            Already exist,but not verify OTP?
            <a
              (click)="onSubmitResend()"
              class="line-login"
            >
              Click here!</a
            >
          </p> -->
         <!--   <div class="row pl-3 text-center">
                          <p class="forget-p">Forgot Password ?
                          <a
                          (click)="openXl(content2)"
                          style="font-size: 13px; color: blue;cursor:pointer; padding-left: 3px;"
                        >
                          Click here!</a
                        ></p>
                      </div> -->
          <p class="text-center text-muted small mt-2">
            
            <a
            (click)="openXl(content2)"
              class="line-login"
            >
            {{"Forgot Password ?"|translate}}</a
            >
          </p>
         
        </mat-card-actions>
      </form>
    </mat-card>
  </div>








  <div *ngIf="signup == 1">
    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
        class="login-heading"
         
        >
          {{"Sign Up"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="registerForm">
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Email/Mobile"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="username"
                  class="pl-2"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <!--   <div class="row">
                  <div class="col-md-12">
                      <mat-form-field class="example-full-width">
                          <mat-label>Password</mat-label>
                          <input matInput formControlName="password" placeholder="password" type="password" name="password" required>
                      </mat-form-field>
                  </div>
              </div>    -->
        </mat-card-content>
        <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
          <!--   <button [disabled]="!loading" mat-raised-button color="primary"  (click)="onSubmitSign()">Sign Up</button>
              <p class="text-center text-muted small">Already exist?  <a (click)="onSubmit()" style="font-size: 16px; color: blue;cursor:pointer; padding-left: 3px;"> Login here!</a></p> -->
          <button
           
            mat-raised-button
            class="button-login w-90"
            (click)="onSubmitSign()"
            [disabled]="!loading"
          >
            {{"Get OTP"|translate}}
          </button>
<hr>
          <p class="text-center text-muted small mt-2">
            {{"Already exist?"|translate}}
            <a
              (click)="onSubmit()" class="text-info"
              
            >
              {{"Login here!"|translate}}</a
            >
          </p>
         <!--  <p class="text-center text-muted small">
            Already exist,but not verify OTP?
            <a
              (click)="onSubmitResend()"
              class="line-login"
            >
              Click here!</a
            >
          </p> -->
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  <div *ngIf="signup == 2">
    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
        class="button-login"
        >
          {{"Sign Up"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="otpForm">
        <mat-card-content>
          <!-- <div class="row">
                  <div class="col-md-12">
                      <mat-form-field class="example-full-width">
                          <mat-label>Email/Mobile</mat-label>
                          <input matInput formControlName="username" placeholder="Email" name="username" required>
                      </mat-form-field>
                  </div>
              </div> -->
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"OTP"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="otp"
                  class="pl-2"
                  
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Create Password"|translate}}</mat-label>
                <input
                  matInput id="myInput2" 
                  formControlName="password"
                  class="pl-2"
                  type="password"
                  
                  required
                />
                  <i class="far fa-eye cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="!visibility2" ></i>
                          <i class="far fa-eye-slash cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="visibility2" ></i>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
             <!--  <div class="form-group">
          <input type="password" class="form-control" [class.is-invalid]="registerEmailForm.errors?.misMatch" formControlName="confirmPassword"  placeholder="Confirm Password" required="required">
      </div> -->
     <!--  <div class="form-group">
          <input type="password" class="form-control" [class.is-invalid]="registerEmailForm.errors?.misMatch" formControlName="confirmPassword"  placeholder="Confirm Password" required="required">
      </div> -->
              <mat-form-field class="example-full-width">
                <mat-label>{{"Confirm Password"|translate}}</mat-label>
                <input
                  matInput id="myInput3" 
                  class=" pl-2 form-control"
                  type="password"  placeholder="Confirm Password" 
                  [class.is-invalid]="otpForm.errors?.misMatch"
                  formControlName="confirmPassword" required
                />
                  <i class="far fa-eye cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="!visibility3" ></i>
                        <i class="far fa-eye-slash cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="visibility3" ></i>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
          <!--   <button [disabled]="!loading" mat-raised-button color="primary"  (click)="onSubmitSign()">Sign Up</button>
              <p class="text-center text-muted small">Already exist?  <a (click)="onSubmit()" style="font-size: 16px; color: blue;cursor:pointer; padding-left: 3px;"> Login here!</a></p> -->
          <button
            mat-raised-button
            class="button-login"
            (click)="onSubmitOTP()"
            [disabled]="!loading"
          >
            {{"Submit"|translate}}
          </button>
          <button
            
            mat-raised-button
            class="button-login"
            (click)="onSubmitResendOtp()"
            [disabled]="!loading"
          >
            {{"Resend OTP"|translate}}
          </button>
          <hr>
          <p class="text-center text-muted small mt-2">
            {{"Already exist?"|translate}}
            <a
              (click)="onSubmit()"
              class="line-login"
            >
              {{"Login here!"|translate}}</a
            >
          </p>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  <div *ngIf="signup == 4">
    <mat-card class="example-card border shadow">
      <mat-card-actions class="text-center">
        <h3
         class="login-heading"
        >
          {{"Sign Up"|translate}}
        </h3>
      </mat-card-actions>

      <form [formGroup]="otpForm">
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Email/Mobile"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="username"
                class="pl-2"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"OTP"|translate}}</mat-label>
                <input
                  matInput
                  formControlName="otp"
                  class="pl-2"
                  
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Create Password"|translate}}</mat-label>
                <input
                  matInput id="myInput2" 
                  formControlName="password"
                  class="pl-2"
                  type="password"
                  
                  required
                />
                  <i class="far fa-eye cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="!visibility2" ></i>
                          <i class="far fa-eye-slash cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="visibility2" ></i>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>{{"Confirm Password"|translate}}</mat-label>
                <input
                  matInput id="myInput3" 
                  class=" pl-2"
                  type="password"
                  [class.is-invalid]="otpForm.errors?.misMatch"
                  formControlName="confirmPassword"
                 
                  required
                />
                  <i class="far fa-eye cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="!visibility3" ></i>
                        <i class="far fa-eye-slash cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="visibility3" ></i>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <!-- start for policy -->
        <div class="_term mb-2" *ngIf="project=='ecomtrails'">
        {{"By continuing,you agree to EcomTrails"|translate}} <br /><a  routerLink="/term-conditions-vendor" target="_blank" class="_termlink">{{"Terms of Use"|translate}}</a> {{"and"|translate}} <a  href="https://ecomtrails.com/privacy%20policy.html" target="_blank" class="_termlink">{{"Privacy Policy"|translate}}</a>
      </div>
      <!-- end for policy -->
        <mat-card-actions class="text-center">
          <!--   <button [disabled]="!loading" mat-raised-button color="primary"  (click)="onSubmitSign()">Sign Up</button>
              <p class="text-center text-muted small">Already exist?  <a (click)="onSubmit()" style="font-size: 16px; color: blue;cursor:pointer; padding-left: 3px;"> Login here!</a></p> -->
          <button
            [disabled]="!loading"
            mat-raised-button
            class="button-login"
            (click)="onSubmitOTP()"
          >
            {{"Submit"|translate}}
          </button>
          <button
            [disabled]="!loading"
            mat-raised-button
            class="button-login"
            (click)="onSubmitResendOtp()"
          >
            {{"Resend OTP"|translate}}
          </button>
          <hr>
          <p class="text-center text-muted small mt-2">
            {{"Already exist?"|translate}}
            <a
              (click)="onSubmit()"
              class="line-login"
            >
              {{"Login here!"|translate}}</a
            >
          </p>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
<!-- for ecom and all -->

  <footer *ngIf="project!='emall'" id="footer">

    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6" style="padding-top: 5%;">
          <h2>{{"EcomTrails"|translate}}</h2>
          <p style="color:#ffffffeb; font-size: 15px;">
            {{"Our EcomTrails app is a basic architecture for any business that has online exchange of goods & services."|translate}}
          </p>
        </div>
  
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Keep In Touch"|translate}}</h3>
          
          <h5><i class="far fa-building"></i> {{"Address"|translate}}</h5>
          <p style="color: #ffffffeb; font-size: 13px;">{{"107, 9930 Spaces, 3 Mangal Nagar, Indore, M.P., India, 452014"|translate}}</p>
          <h5><i class="far fa-envelope"></i> {{"Email"|translate}}</h5>
          <a href="sales@ecomtrails.com" style="text-decoration-color: #fff;" target="_blank">
            <p style="color:#ffffffeb; font-size: 13px; ">sales@ecomtrails.com</p>
          </a>
          
          <h5><i class="fas fa-phone-volume"></i> {{"Telephone"|translate}}</h5>
          <p style="color:#ffffffeb; font-size: 13px;">+91-9009399903</p>
  
  
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"EcomTrails App"|translate}}</h3>
          <a href="https://play.google.com/store/apps/details?id=com.i9930.ecommerce" target="_blank">
            <button type="button" class="btn btn-outline-light btn-lg download-button">
            <i class="fab fa-google-play"></i> {{"Download"|translate}}</button>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Social Media"|translate}}</h3>
         <h5><a href="https://api.whatsapp.com/send?phone=+91-9009399933" 
          style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
           <i class="fab fa-whatsapp w"></i> {{"Whatsapp"|translate}}</a></h5> 
           <h5><a href="https://www.linkedin.com/showcase/ecomtrails/" 
            style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
             <i class="fab fa-linkedin w"></i> {{"LinkedIn"|translate}}</a></h5> 
             <h5><a href="https://www.instagram.com/ecomtrails/" 
              style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
               <i class="fab fa-instagram w"></i> {{"Instagram"|translate}}</a></h5> 
               <h5><a href="https://www.facebook.com/ecomtrails/" 
                style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                 <i class="fab fa-facebook w"></i> {{"Facebook"|translate}}</a></h5> 
                 <h5><a href="https://twitter.com/EcomTrails?s=09" 
                  style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                   <i class="fab fa-twitter w"></i> {{"Twitter"|translate}}</a></h5>
        </div>
        
  
    </div>
    
    <div class="footer2" >
      <hr style="border-top: 8px dotted white; width: 60%;">
      <p style="color:#ffffffeb; font-size: 13px; line-height: 1.3rem;">© {{"Copyright @2020 EcomTrails All Right Reserved"|translate}}</p>
      <p style="color:#ffffffeb; font-size: 12px; line-height: 0.5rem;">{{"Designed by"|translate}}:9930i</p>
      <p style="color:#ffffffeb; font-size: 12px; line-height: 0.5rem; padding-bottom: 1%;">{{"Developed by:ShahSoftSol"|translate}}</p>
    </div>
    
  </footer>
  
  
  <!-- for ecom end -->
  
  <!-- for emall satrt -->
   <footer *ngIf="project=='local' || project=='emall'" id="footer">

    <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Keep In Touch"|translate}}</h3>
          
          <h5><i class="far fa-building"></i> {{"Address"|translate}}</h5>
          <p style="color: #ffffffeb; font-size: 13px;">{{"201, White Rose Apt.J.B Nagar circle, Andheri (east) Opposite PMC Bank, Mumbai - 400059"|translate}}</p>
     
  
  
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>Contact Us</h3>
          <h5><i class="far fa-envelope"></i> {{"Email"|translate}}</h5>
          <a href="info@emallnet.com" style="text-decoration-color: #fff;" target="_blank">
            <p style="color:#ffffffeb; font-size: 13px; ">info@emallnet.com</p>
          </a>
          
          <h5><i class="fas fa-phone-volume"></i> {{"Telephone"|translate}}</h5>
          <p style="color:#ffffffeb; font-size: 13px;">+91-8828146836</p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6"  style="padding-top: 5%;">
          <h3>{{"Social Media"|translate}}</h3>
       
             <h5><a href="https://www.instagram.com/emallnetwork/" 
              style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
               <i class="fab fa-instagram w"></i> {{"Instagram"|translate}}</a></h5> 
               <h5><a href="https://www.facebook.com/networkemall/" 
                style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                 <i class="fab fa-facebook w"></i> {{"Facebook"|translate}}</a></h5> 
                 <h5><a href="https://twitter.com/NetworkEmall/" 
                  style="color:#ffffffeb; text-decoration-color: #fff;" target="_blank">
                   <i class="fab fa-twitter w"></i> {{"Twitter"|translate}}</a></h5>
        </div>
        
  
    </div>
    
    <div class="footer2" >
      <hr style="border-top: 8px dotted white; width: 60%;">
      <p style="color:#ffffffeb; font-size: 13px; line-height: 1.3rem;">© {{"Copyright @2020 Emallnet All Right Reserved"|translate}}</p>
     
    </div>
    
  </footer> 
  <!-- for emall end -->
  
 
</div>
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
      {{"Forgot Password"|translate}}
    </h6>

      <i class="fas fa-times"
      (click)="modal.dismiss('Cross click')"
      class="text-danger cursor-pointer">
      </i>
  </div>
  <!-- modal body starts -->

   <!-- modal body starts -->
  <div class="modal-body ">
    <div class="container-fluid">
      <form [formGroup]="forgotForm">
        <div class=" ">
          <div class="row ">
            <div class="col-md-10">
               <div class="input-group mb-3 mt-3 ml-3 mr-auto">
                         
                          <input type="text" style="padding-left: 2%;" class="form-control" formControlName="username" placeholder="Please Enter Email/Mobile" >
                      </div>
             
               <!--  <div >
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Email/Mobile</mat-label>
                    <input
                      matInput
                      placeholder="Email/Mobile"
                      formControlName="username"
                      required
                    />
                  </mat-form-field>
                </div> -->
             
            </div>
          </div>
         
         
          <div class="row justify-content-center mt-3 mb-3">
            <button
             [disabled]="!loading"
              class="bg-warning text-white"
              mat-raised-button
              matTooltip="Send Password"
              (click)="forgotPassword(); modal.close('Save click')"
            >
              OK
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
</div>
<div *ngIf="ninetoys==true" class="d-none d-md-block">
<!-- <app-ninetoys-admin></app-ninetoys-admin> -->
<!-- <app-ninetoys-admin-signin></app-ninetoys-admin-signin> -->
<app-nineseller></app-nineseller>

</div>


<div style="width: 100%; height: 100%; padding: 30px;  text-align: center;" class="d-block  d-lg-none d-md-none">
  <p class="text-center">{{"Wait"|translate}}...</p> 
  </div>