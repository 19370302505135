


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import {MatSnackBar} from '@angular/material';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'button-view-user',
    templateUrl: './button-view-user-component.html',
  })
  export class ButtonViewUserComponent {
    headingcolor = this.cookie.get('headingcolor');
    buttonbgcolor = this.cookie.get('buttonbgcolor');
   buttontextcolor = this.cookie.get('buttontextcolor');
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
    data: any;
    deactivatef=false;
 
  constructor(
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private router: Router,private cookie: CookieService,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'md' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
       
    }
  agInit(params: ICellRendererParams): void {
      //e.log(params);
    this.params = params;
    this.data = params.value;
    //console.log(this.data);
    var data1= params.data;

    if(this.params.label =='delete'){
this.deactivatef = true;


    }

  }
  deleteuser(user_id,usertype_id,comp_num){
    var res = confirm("Are you sure you want to delete this user.");
     if(res){
      
      this.adminservice.delete_users({access_token:this.access_token,user_num:this.user_num, user_id: user_id,usertype_id:usertype_id,comp_num:comp_num}).subscribe(data=>{
       
    if(data['status']==1){ 
        this.snackbar.open(this.adminservice.getCloseWord('User is successfully delete.'),'' ,{
               duration: 3000,
               horizontalPosition:'center',
       }); 
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
         this.router.navigate(['/view-users']));          
        
     }
     else if(data['status']==10){
     sessionStorage.clear();
      this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
               duration: 3000,
               horizontalPosition:'center',
       });      
     this.router.navigate(['/']);
     }
     else{
        this.snackbar.open(this.adminservice.getCloseWord('Something went wrong, Please try again . '),'' ,{
               duration: 3000,
               horizontalPosition:'center',
       }); 
     }
   },
   error=>{
     this.snackbar.open(this.adminservice.getCloseWord('Something went wrong, Please try again . '),'' ,{
               duration: 3000,
               horizontalPosition:'center',
       }); 
     }
   );
    }

 }





}