import { Injectable } from "@angular/core";
import {HttpClient,HttpHeaders,HttpErrorResponse} from "@angular/common/http";
import { timer, Subject, Observable, BehaviorSubject } from "rxjs";
import { environment } from '../environments/environment';
import { TranslateService } from "@ngx-translate/core";
@Injectable({providedIn: "root"})

export class NinetoysserviceService {

  constructor(private https: HttpClient ,private translate:TranslateService) {}
  private options = {
    headers: new HttpHeaders().set(
    "Authorization",
    sessionStorage.getItem("jwtoken")
    )};

  buy: boolean = false;
  referral:boolean = false;
  NodebaseUrl=environment.NodebaseUrl;
  uploadUrlApi = environment.UploadUrl; 
  NodeBaseUrl=environment.NodebaseUrl;
  public database = sessionStorage.getItem('database');
  public usertype_id = sessionStorage.getItem('usertype_id');
  comp_num = sessionStorage.getItem('comp_num');
  public cartCount = new Subject<any>();
  public compd = new Subject<any>();
  public wishlistCount = new Subject<any>();
  public loginUpdateSubject = new Subject<any>();
  loginUpdateObservable$ = this.loginUpdateSubject.asObservable();
  cartCount$ = this.cartCount.asObservable();
  compd$ = this.compd.asObservable();
  wishlistcount$ = this.wishlistCount.asObservable();
  public cartShow = new Subject<any>();
  cartShow$ = this.cartShow.asObservable();
  private productSource = new BehaviorSubject({});
  currentList = this.productSource.asObservable();
  //***************************Start BULK UPLOAD APIS **********************************************
  datafetchfromurl(value){
    return this.https.post("https://py.9930i.com/call_python",{"script_name":"scrapping","params":[value]});
  }
  changeProductList(list) { 
    this.productSource.next(list);  
  }
  image_upload(data) {
    return this.https.post(this.NodebaseUrl + "bulk/image_upload_portal", data);
  }
  download_excel(data) {
    return this.https.post(this.NodebaseUrl + "bulk/download_excel", data);
  }
  download_excel_specific(data) {
    return this.https.post(this.NodebaseUrl + "bulk/download_excel_specific", data);
  }
  download_excel_viaurl(data) {
    return this.https.post(this.NodebaseUrl + "bulk/download_excel_viaurl", data);
  }
  upload_excel(data) {
    return this.https.post(this.NodebaseUrl + "bulk/upload_products", data);
  }
  upload_products_specific(data) {
    return this.https.post(this.NodebaseUrl + "bulk/upload_products_specific", data);
  }
  upload_products_viaurl(data) {
    return this.https.post(this.NodebaseUrl + "bulk/upload_products_viaurl", data);
  }
  all_category(data) {
    return this.https.post(this.NodebaseUrl + "bulk/all_category", data);
  }
  zip_upload_portal(data) {
    return this.https.post(this.NodebaseUrl + "bulk/zip_upload_portal", data);
  }

  //***************************END BULK UPLOAD APIS **********************************************
  
  //***************************Start BULK replicate UPLOAD APIS **********************************************

  datafetchfromurlr(value){
    return this.https.post("https://py.9930i.com/call_python",{"script_name":"scrapping","params":[value]});
  }
  changeProductListr(list) { 
    this.productSource.next(list);  
  }
  image_uploadr(data) {
    return this.https.post(this.NodebaseUrl + "bulk/image_upload_portal", data);
  }
  download_excelr(data) {
    return this.https.post(this.NodebaseUrl + "bulk/download_excel_rate_update", data);
  }
  upload_excelr(data) {
    return this.https.post(this.NodebaseUrl + "bulk/udpate_products_rate", data);
  }
  download_excelq(data) {
    return this.https.post(this.NodebaseUrl + "bulk/download_excel_qty_update", data);
  }
  upload_excelq(data) {
    return this.https.post(this.NodebaseUrl + "bulk/udpate_products_qty", data);
  }
  all_categoryr(data) {
    return this.https.post(this.NodebaseUrl + "bulk/all_category", data);
  }
  zip_upload_portalr(data) {
    return this.https.post(this.NodebaseUrl + "bulk/zip_upload_portal", data);
  }
   
  //***************************END BULK replicate UPLOAD APIS **********************************************

  //***************************Start CLIENT SITE APIS **********************************************

  loginuser(data) {
    return this.https.post(
      "http://ecom.9toys.in/ecom_api/index.php/registration/login",data);
  }
 

  register(data) {
    return this.https.post(this.NodebaseUrl + "registration/register", data);
  }
  forgotPassword(data){
    return this.https.post(this.NodebaseUrl + "registration/forgotPassword", data);
  }
 
  add_product_variation_images(data){
    return this.https.post(this.NodebaseUrl + "admin/add_product_variation_images2", data);
  }
  add_product_variation(data){
    return this.https.post(this.NodebaseUrl + "admin/add_product_variation2", data);
  }

 
  hostlink(data) {
    return this.https.post(this.NodebaseUrl + "user/get_host_details", data);
  }
   fetch_host_route(data) {
     console.log('Called sevice');
    return this.https.get(this.NodebaseUrl + "user/get_out_url?" + data);
  }
  search(data) {
    return this.https.post(this.NodebaseUrl + "user/searching", data);
  }
  searching_ecom(data){
    return this.https.post(this.NodebaseUrl + "user/searching_ecom", data);
  }
 
  fetch_categories(data) {
    return this.https.post(this.NodebaseUrl + "user/fetch_categories", data);
  }
  fetch_categories_ecom(data) {
    return this.https.post(this.NodebaseUrl + "user/fetch_categories_ecom", data);
  }
 
 

  
  //***************************END CLIENT SITE APIS **********************************************

  // ***************************Start Cart Apis***********************************

  updateCartCount() {
    this.cartCount.next();
  }
  updateCompd() {
    this.compd.next();
  }
  updatewishlistCount() {
    this.wishlistCount.next();
  }
  loginUpdate() {
    this.loginUpdateSubject.next();
  }
  fetch_user_product_reviews(data){
        return this.https.post(this.NodebaseUrl + "user_log/fetch_user_product_reviews", data);
  }
  add_slots(data){
    return this.https.post(this.NodeBaseUrl +"user_log/add_slots",data );
  }
  fetch_booked_slots(data)
  {
    return this.https.post(this.NodeBaseUrl + "user_log/fetch_booked_slots" , data);
  }
  fetch_product_group(data)
  {
    return this.https.post(this.NodeBaseUrl + "user_log/fetch_product_group" , data);
  }
  fetch_order_summary(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_member_ledger", data);
  }
  insertReview(data) {
    return this.https.post(this.NodebaseUrl + "user_log/insert_reviews", data);
  }
  fetchReview(data) {
    return this.https.post(this.NodebaseUrl + "user/fetch_reviews", data);
  }
  check_order_product(data) {
    return this.https.post(this.NodebaseUrl + "user_log/check_order_product", data);
  }
  fetchCart(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_cart", data);
  }
  updateCart(data) {
    return this.https.post(this.NodebaseUrl + "user_log/update_cart", data);
  }
  addCart(data) {
    return this.https.post(this.NodebaseUrl + "user_log/insert_cart", data);
  }
  deleteCart(data) {
    return this.https.post(this.NodebaseUrl + "user_log/delete_cart", data);
  }
  getParticularCart(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_cart", data);
  }
  fetch_cart_count(data){
        return this.https.post(this.NodebaseUrl + "user_log/fetch_cart_count", data);
  }
  fetch_cart_specific(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_cart_specific", data);
  }

  // ***************************END Cart Apis***********************************

  //****************Start With Logins Cart and Wishlist Apis*****************************

  fetchWishlist(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_wishlist", data);
  }
  // updateWishlist(data){
  //   return this.https.post(this.NodebaseUrl + "user_log/delete_wishlist", data);
  // }
  addWishlist(data) {
    return this.https.post(this.NodebaseUrl + "user_log/insert_wishlist", data);
  }
  deleteWishlist(data) {
    return this.https.post(this.NodebaseUrl + "user_log/delete_wishlist", data);
  }

  //****************END With Logins Cart and Wishlist Apis*****************************

  //***********************Start Payment Api********************************** */

  paymentList(data) {
    return this.https.post(
      this.NodebaseUrl + "user_log/fetch_payment_method",data);
  }
  fetch_payment_method_specific(data){
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_payment_method_specific",data);
  }
  fetch_courier_method_specific(data){
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_courier_method_specific",data);
  }
  paymentListAdmin(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_payment_method",data);
  }
  courierListAdmin(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_courier_method",data);
  }
  checkUserExist(data) {
    return this.https.post(
      this.NodebaseUrl + "company/checkUserExist",data);
  }
   
  //***********************END Payment Api********************************** */


  //*******************Start Without Logins Cart and Wishlist Apis*****************************

  addToWishlistWL(data) {
    return this.https.post(this.NodebaseUrl + "user_log/insert_wishlist_all", data);
  }
  addToCartWL(data) {
    return this.https.post(this.NodebaseUrl + "user_log/insert_cart_all", data);
  }
  fethcProductWishlist(data) {
    return this.https.post(this.NodebaseUrl + "user/fetch_product_wishlist", data);
  }
  fetchBrands(data) {
    return this.https.post(
      this.NodebaseUrl + "user/fetch_brand_all_for_comp",data);
  }
  fetchBrandsEcom(data) {
    return this.https.post(
      this.NodebaseUrl + "user/fetch_brand_all_for_comp_ecom",data);
  }

//*******************END Without Logins Cart and Wishlist Apis*****************************

  //*******************Start of OTP Apis *****************************


  
  //*******************END of OTP Apis *****************************

  //**********************************ADMIN APIS*************************************************

  
  //**********************************Login and Registration Apis*************************************************

  estimate_time_delivery(data){
    return this.https.post(this.NodebaseUrl + "user/estimate_days_courier", data);
  }
  otpVerification(data) {
    return this.https.post(this.NodebaseUrl + "company/otpVerification", data);
  }
  ResendOtpVerification(data) {
    return this.https.post(this.NodebaseUrl + "company/resendOtp", data);
  }
  login(data) {
    return this.https.post(this.NodebaseUrl + "registration/login", data);
  }
  registerVendor(data) {
    return this.https.post(this.NodebaseUrl + "company/registerVendor", data);
  }
  ifsc_api(data) {
    return this.https.post(this.NodebaseUrl + "company/ifsc_api", data);
  }
  pincode_api(data){
    return this.https.post(this.NodebaseUrl + "company/pincode_api", data);
  }
  pincode_country_state_city_api(data){
    return this.https.post(this.NodebaseUrl + "company/pincode_country_state_city_api", data);
  }
  check_brand_exist(data){
    return this.https.post(this.NodebaseUrl + "admin/check_brand_exist", data);
  }
  fetch_brand_all(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand_all", data);
  }
  fetch_product_tax_all(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_product_tax_all", data);
  }
  fetch_category_all(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_category_all", data);
  }
  fetch_category_all_ecom(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_category_all_ecom", data);
  }
  addProduct(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_product", data);
  }

  ShortProductAddtion(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_product/v2", data);
  }
  quick_add_product(data) {
    return this.https.post(this.NodebaseUrl + "quick_admin/quick_add_product", data);
  }
  quick_update_product(data) {
    return this.https.post(this.NodebaseUrl + "quick_admin/quick_update_product", data);
  }
  fetch_quick_product(data) {
    return this.https.post(this.NodebaseUrl + "quick_admin/fetch_quick_product", data);
  }
  add_product_categories(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_product_categories", data);
  }
  countProductActiveness(data){
        return this.https.post(this.NodebaseUrl + "admin/countProductActiveness", data);
  }
  add_product_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_product_tags", data);
  }
  add_product_rates(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_product_rates", data);
  }
  add_product_specifications(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/add_product_specifications",
      data
    );
  }
  fetch_all_comp_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_all_comp_tags", data);
  }
  add_product_images(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_product_images", data);
  }
  fetch_product_rates_all(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_product_rates_all",
      data
    );
  }

  //**********************************Endof Login Registration Apis*************************************************
  
  
  //**********************************Addition of Company Apis*************************************************
  
  displayIns(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/insert_company_display_information",
      data
    );
  }
  domainInsert(data){
     return this.https.post(
      this.NodebaseUrl + "company_details/insert_company_domain_info",
      data
    );
  }
  buisnessIns(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/insert_company_buisness_information",
      data
    );
  }
  bankIns(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/insert_company_bank_information",
      data
    );
  }
  RegAddressIns(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/insert_company_register_address",
      data
    );
  }
  displayUpdate(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_display_information",
      data
    );
  }
  buisnessUpdate(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_buisness_information",
      data
    );
  }
  bankUpdate(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_bank_information",
      data
    );
  }
  RegAddressUpdate(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_register_address",
      data
    );
  }

 //**********************************End Of Addition of Company Apis*************************************************
  
 
 //**********************************Company Information Apis*************************************************
  
  viewCompanyDetailsInfo(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/viewCompanyDetailsInfo",
      data
    );
  }
  viewCompDetailsInfo(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/viewCompDetailsInfo",
      data  
    );
  }
  kyc_all(data) {
    return this.https.post(this.NodebaseUrl + "company_details/kyc_all", data);
  }
  update_company_display_information(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_display_information",
      data
    );
  }
  update_company_register_address(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_register_address",
      data
    );
  }
  update_company_bank_information(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_bank_information",
      data
    );
  }
  update_company_buisness_information(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/update_company_buisness_information",
      data
    );
  }

//**********************************End Of Company Information Apis*************************************************
  


//**********************************State Citycountry Fetch Apis*************************************************
  
  getState(data) {
    return this.https.post(this.NodebaseUrl + "profile/get_state", data);
  }
  getCity(data) {
    return this.https.post(this.NodebaseUrl + "profile/get_city", data);
  }
  getCountry(data) {
    return this.https.post(this.NodebaseUrl + "profile/get_country", data);
  }

  
//**********************************End Of StatCountryCityApis*************************************************
  
   //**************************Comp_parameters, company registry apis start****
 createParameter(data){
  return this.https.post(this.NodebaseUrl + "comp_parameter/create_parameter", data);
 }
 fetchParameter(data){
  return this.https.post(this.NodebaseUrl + "comp_parameter/fetch_comp_parameter", data);
 }
 deleteParameter(data){
  return this.https.post(this.NodebaseUrl + "comp_parameter/delete_comp_parameter", data);
 }
 updateParameter(data){
  return this.https.post(this.NodebaseUrl + "comp_parameter/update_comp_parameter", data);
 }
 fetchAllParameter(data){
  return this.https.post(this.NodebaseUrl + "comp_parameter/fetchall_comp_parameter", data);
 }
  //**************************Comp_parameters, company registry apis end****
//**********************************Controller Apis*************************************************
  
  get_greeting() {
    return this.https.get(this.NodebaseUrl + "user/get_greeting");
  }
  add_greeting(data) {
    return this.https.post(this.NodebaseUrl + "controller/add_greeting", data);
  }
  delete_greeting(data) {
    return this.https.post(this.NodebaseUrl + "controller/delete_greeting", data);
  }
  CompanyList(data) {
    return this.https.post(this.NodebaseUrl + "controller/get_company_all", data);
  }
  get_company_all_with_kyc(data) {
    return this.https.post(
      this.NodebaseUrl + "controller/get_company_all_with_kyc",
      data
    );
  }
  add_company_commission(data){
    return this.https.post(this.NodebaseUrl + "controller/add_company_commission", data);
  }
  VerifyCompany(data) {
    return this.https.post(this.NodebaseUrl + "controller/verify_company", data);
  }
  VerifyCompanyBuisness(data) {
    return this.https.post(
      this.NodebaseUrl + "controller/verify_company_buisness2",
      data
    );
  }
  VerifyCompanyBank(data) {
    return this.https.post(
      this.NodebaseUrl + "controller/verify_company_bank2",
      data
    );
  }
  VerifyCompanyKyc(data) {
    return this.https.post(
      this.NodebaseUrl + "controller/verify_company_kyc2",
      data
    );
  }
  verify_product(data) {
    return this.https.post(this.NodebaseUrl + "controller/verify_product", data);
  }
  deverify_product(data) {
    return this.https.post(this.NodebaseUrl + "controller/deverify_product", data);
  }
  deverified_list_product_comp(data){
    return this.https.post(this.NodebaseUrl + "admin/deverified_list_product_comp", data);
  }
  fetch_tax(data) {
    return this.https.post(this.NodebaseUrl + "controller/fetch_tax", data);
  }
  delete_tax(data) {
    return this.https.post(this.NodebaseUrl + "controller/delete_tax", data);
  }
   duplicateProduct(data) {
    return this.https.post(this.NodebaseUrl + "admin/duplicate_product", data);
  }
  update_tax(data) {
    return this.https.post(this.NodebaseUrl + "controller/update_tax", data);
  }
  insert_tax(data) {
    return this.https.post(this.NodebaseUrl + "controller/insert_tax", data);
  }

  //**********************************End Of Controllers Apis*************************************************
  

  //********************Brands Related Apis On Admin Side ***********************************

  AddBrand(data) {
    return this.https.post(this.NodebaseUrl + "admin/insert_brand", data);
  }
  AddBrandEcom(data) {
    return this.https.post(this.NodebaseUrl + "admin/insert_brand_ecom", data);
  }
  FetchBrand(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand_all", data);
  }
  FetchBrandEcom(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand_all_ecom", data);
  }
  fetch_brand_with_name(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand_with_name", data);
  }
  UpdateBrand(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_brand", data);
  }
  delete_brand(data) {
    return this.https.post(this.NodebaseUrl + "admin/delete_brand", data);
  }
  delete_brand2(data){
    return this.https.post(this.NodebaseUrl + "admin/delete_brand2", data);

  }
  fetch_brand_all_only_comp(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand_all_only_comp", data);

  }
  fetch_brand_all_only_comp_ecom(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand_all_only_comp_ecom", data);
  }
  fetch_brand(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_brand", data);
  }

  //********************End Of Brands Related Apis On Admin Side ***********************************


 //********************Start Tags Apis On Admin Side ***********************************
   

  insert_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/insert_tags", data);
  }
  update_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_tags", data);
  }
  fetch_comp_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_comp_tags", data);
  }
  delete_comp_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/delete_comp_tags", data);
  }

  //********************End Of Start Tags Apis On Admin Side ***********************************


  //********************Start of Admin Products Apis***********************************

  fetch_product_all_active_comp(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_product_all_active_comp",
      data
    );
  }
  fetch_product_all_inactive_comp(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_product_all_inactive_comp",
      data
    );
  }
  fetch_product_all_pending_comp(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_product_all_pending_comp",
      data
    );
  }
  outofstock_all_comp(data) {
    return this.https.post(this.NodebaseUrl + "admin/outofstock_all_comp", data);
  }
  instock_all_comp(data) {
    return this.https.post(this.NodebaseUrl + "admin/instock_all_comp", data);
  }
  deactivate_product(data) {
    return this.https.post(this.NodebaseUrl + "admin/deactivate_product", data);
  }
  activate_product(data) {
    return this.https.post(this.NodebaseUrl + "admin/activate_product", data);
  }
  deletedProduct(data) {
    return this.https.post(this.NodebaseUrl + "admin/delete_product", data);
  }
  getProduct(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_product", data);
  }
  getProductbycategory(data) {
    return this.https.post(this.NodebaseUrl + "user/categoryProducts", data);
  }
  fetch_subcategory_all_new(data) {
    return this.https.post(this.NodebaseUrl + "user/fetch_subcategory_all", data);
    // return this.https.post("http://localhost/9toys/index.php/user/fetch_subcategory_all", data);
  }
  fetch_subcategory_all(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_subcategory_all", data);
  }
  fetch_parent_all(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_parent_all", data);
  }
  fetch_subcategory_all_new_ecom(data) {
    return this.https.post(this.NodebaseUrl + "user/fetch_subcategory_all_ecom", data);
    // return this.https.post("http://localhost/9toys/index.php/user/fetch_subcategory_all", data);
  }
  fetch_subcategory_all_ecom(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_subcategory_all_ecom", data);
  }
  fetch_parent_all_ecom(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_parent_all_ecom", data);
  }
  update_product(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_product", data);
  }
  update_product_categories(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/update_product_categories",
      data
    );
  }
  update_product_tags(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_product_tags", data);
  }
  update_product_rates(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_product_rates", data);
  }
  update_product_specifications(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/update_product_specifications",
      data
    );
  }
  update_product_images(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_product_images", data);
  }
  addPost(data) {
    return this.https.post(this.NodeBaseUrl + "admin/addPost", data);
  }
  editPost(data) {
    return this.https.post(this.NodeBaseUrl + "admin/editPost", data);
  }
  deletePost(data) {
    return this.https.post(this.NodeBaseUrl + "admin/deletePost", data);
  }
 
  live_user_count(data) {
    return this.https.post(this.NodeBaseUrl + "admin/live_user_count", data);
  }
  fetchPost(data) {
    return this.https.post(this.NodeBaseUrl + "user_log/fetchPost", data);
  }
  fetchSinglePost(data) {
    return this.https.post(this.NodeBaseUrl + "user_log/fetchSinglePost", data);
  }
  searchPost(data) {
    return this.https.post(this.NodeBaseUrl + "user_log/searchPost", data);
  }
  viewPost(data) {
    return this.https.post(this.NodeBaseUrl + "user_log/viewPost", data);
  }
  liveUserCount(data) {
    return this.https.post(this.NodeBaseUrl + "admin/live_user_count", data);
  }

  //********************End Of Start of Admin Products Apis***********************************


  //********************Start of Admin Category Start Apis***********************************

  insert_category(data) {
    return this.https.post(this.NodebaseUrl + "admin/insert_category", data);
  }
  insert_category_ecom(data) {
    return this.https.post(this.NodebaseUrl + "admin/insert_category_ecom", data);
  }
  update_category(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_category", data);
  }
  delete_category(data) {
    return this.https.post(this.NodebaseUrl + "admin/delete_category", data);
  }
  // delete_category2(data){
  //   return this.https.post(this.NodebaseUrl + "admin/delete_category2", data);
  // }
  delete_category2(data){
    return this.https.post(this.NodebaseUrl + "admin/hide_show_category", data);
  }
  delete_category_array(data) {
    return this.https.post(this.NodebaseUrl + "admin/delete_category_array", data);
  }

  //********************END of Admin Category Start Apis***********************************

  //********************Start of Function To get Images Apis***********************************

  getImage(image): string {
    return this.uploadUrlApi + "product/gallery/images/" + image;
  }
  getFile(image): string {
    return this.uploadUrlApi + image;
  }
  getThumbnail1(thumbnail1): string {
    return this.uploadUrlApi + "product/gallery/thumbnail1/" + thumbnail1;
  }
  getIcon(icon, id): string {
    return this.uploadUrlApi + "company_" + id + "/icon_image_link/" + icon;
  }
  getSignature(icon, id): string {
    return (
      this.uploadUrlApi + "company_" + id + "/signature_image_link/" + icon
    );
  }
  getThumbnail2(thumbnail2): string {
    return this.uploadUrlApi + "product/thumbnail2/" + thumbnail2;
  }
  getGalleryImage(image): string {
    return this.uploadUrlApi + "product/gallery/images/" + image;
  }
  getGalleryThumbnail1(thumbnail1): string {
    return this.uploadUrlApi + "product/gallery/thumbnail1/" + thumbnail1;
  }
  getGalleryThumbnail2(thumbnail2): string {
    return this.uploadUrlApi + "product/gallery/thumbnail2/" + thumbnail2;
  }
  getbrandImage(image): string {
    return this.uploadUrlApi + "brand/image_location/" + image;
  }
  // viewCompanyDetails(data){
  //   return this.https.post(this.NodebaseUrl + "company_details/viewCompanyDetails", data);
  // }

  //********************End of Function To get Images Apis***********************************


  //********************Start Of Aman Verma User Apis***********************************
viewComp(data){
  return this.https.post(
    this.NodebaseUrl + "company/viewCompany", data
  );
}

  userprofile(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/viewCompanyDetails",
      data
    );
  }
  viewCompanyDetail(data) {
    return this.https.post(
      this.NodebaseUrl + "company_details/viewCompanyDetail",
      data
    );
  }
  checkCompanyStatus(data) {
    return this.https.post(this.NodebaseUrl + "company/checkCompany", data);
  }

  //********************Start Of User Checkout Apis***********************************

  insertAddress(data) {
    return this.https.post(this.NodebaseUrl + "profile/insert_address", data);
  }
  updateAddress(data) {
    return this.https.post(this.NodebaseUrl + "profile/update_address", data);
  }
  fetchAddress(data) {
    return this.https.post(this.NodebaseUrl + "profile/fetchAddresses", data);
  }
  getAddressSpecific(data) {
    return this.https.post(this.NodebaseUrl + "profile/getAddressSpecific", data);
  }
  reset_password(data) {
    return this.https.post(this.NodebaseUrl + "profile/resetPassword", data);
  }
  get_profile(data) {
    return this.https.post(this.NodebaseUrl + "profile/get_profile", data);
  }
  removeAddress(data) {
    return this.https.post(this.NodebaseUrl + "profile/deleteAddress", data);
  }
  removeFromWishlist(data) {
    return this.https.post(this.NodebaseUrl + "user_log/delete_wishlist", data);
  }
  payment(data) {
    return this.https.post(this.NodebaseUrl + "user_log/payment", data);
  }
  transaction_of_orders_razorpay(data) {
    return this.https.post(
      this.NodebaseUrl + "user/transaction_of_order_razor_pay",
      data
    );
  }
  transaction_of_order_payumony_web(data){
     return this.https.post(
      this.NodebaseUrl + "user/transaction_of_order_payumony_web",
      data
    );
  }
  paymentMethod(data) {
    return this.https.post(
      this.NodebaseUrl + "user_log/fetch_payment_method",
      data
    );
  }
  payment_status_check(data) {
    return this.https.post(this.NodebaseUrl + "user/payment_status_check", data);
  }
  quick_register_company(data) {
    return this.https.post(this.NodebaseUrl + "company_details/quick_register_company", data);
  }

  //********************End Of Aman Verma User Apis***********************************

  
  //**********************Start Of Order Vendor Apis********************************

  getOrdersPlaced(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_placed", data);
  }
  getOrdersConfirmed(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_confirm", data);
  }
  getOrdersLabelGenerate(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_labelled", data);
  }
  getOrdersRtd(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_rtd", data);
  }
  getOrdersHandovers(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_handover", data);
  }
  getOrdersRts(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_orders_ready_to_ship",
      data
    );
  }
  getOrdersdelivered(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_deliver", data);
  }
  getOrderStatus(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_status", data);
  }
  updateOrderStatus(data) {
    return this.https.post(this.NodebaseUrl + "order_vendor/updateOrderStatus", data);
  }
  
   updateOrderStatus2(data) {
    return this.https.post(this.NodebaseUrl + "order_vendor/updateOrderStatus", data);
  }
  GetOrderDetailArray(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/fetch_orders_array_details",
      data
    );
  }
  orderDetail(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_order_detail", data);
  }
  fetch_orders_new(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_orders_new", data);
  }
  orderStatus(data) {
    return this.https.post(this.NodebaseUrl + "user_log/fetch_order_status_flow", data);
  }
  updateMultipleOrderStatus(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/status_orders_array_update",
      data
    );
  }

  updateMultiOrdersCourier(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/status_orders_array_update2",
      data
    );
  }

  
  //**********************END Of Order Vendor Apis********************************


  //**********************END Of Checkout Apis********************************


  //**********************Start Of Order User Apis********************************

 
  orderrejected(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_rejected", data);
  }
  ordercancelled(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_orders_cancelled", data);
  }
 
  fetch_orders_placed(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_placed", data);
  }
  fetch_orders_rtd(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_rtd", data);
  }
  fetch_orders_handover(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_handover", data);
  }
  fetch_orders_ready_toship(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_ready_toship", data);
  }
  fetch_orders_labelled(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_labelled", data);
  }
  fetch_orders_deliver(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_deliver", data);
  }
  fetch_orders_rejected(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_rejected", data);
  }
  fetch_orders_cancelled(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/fetch_orders_cancelled", data);
  }

  
  //**********************End Of Order User Apis********************************


  addqtyUpdateProduct(data) {
    return this.https.post(this.NodebaseUrl + "admin/addqtyUpdateProduct", data);
  }
  subtractqtyUpdateProduct(data) {
    return this.https.post(
      this.NodebaseUrl + "admin/subtractqtyUpdateProduct",
      data
    );
  }

  //*************Start Of CompanySetting Apis******************************

  getCompSetting(data) {
    return this.https.post(
      this.NodebaseUrl + "company/fetch_company_registry",
      data
    );
  }
  fetch_categories_list(data){
     return this.https.post(
      this.NodebaseUrl + "user/fetch_categories_list",
      data
    );
  }
  fetch_product_comp_list(data){
    return this.https.post(
      this.NodebaseUrl + "user/fetch_product_comp_list",
      data
    );
  }
  getparticularCompSetting(data) {
    return this.https.post(
      this.NodebaseUrl + "user/fetch_particular_company_registry",
      data
    );
  }
  // 24/08/2020
  fetch_particular_company_registry_with_sno(data){
    return this.https.post(
      this.NodebaseUrl + "company/fetch_particular_company_registry_with_sno",
      data
    );
  }
  addCompanyRegistry(data) {
    return this.https.post(this.NodebaseUrl + "company/add_company_registry", data);
  }
  bannerImageUpload(data) {
    return this.https.post(this.NodebaseUrl + "admin/banner_image_upload", data);
  }
  getCompnyBasicDetail(data) {
    return this.https.post(
      this.NodebaseUrl + "user/get_company_basic_details",
      data
    );
  }

  fetch_company_type(data){
    return this.https.post(this.NodebaseUrl + "user/fetch_company_type", data);
  }
  search_product(data){
    return this.https.post(this.NodebaseUrl + "user/searching", data);
  }
  replicate_product(data){
    return this.https.post(this.NodebaseUrl + "admin/replicate_product", data);
  }
  publish_company(data){
    return this.https.post(this.NodebaseUrl + "user/publish_company", data);
  }
  get_host_link_of_comapny(data){
    return this.https.post(this.NodebaseUrl + "user/get_host_link_of_comapny", data);
  }
  get_host_link(data){
        return this.https.post(this.NodebaseUrl + "user/get_host_link", data);
  }
  updateCompanyRegistry(data) {
    return this.https.post(this.NodebaseUrl + "company/update_comp_registry", data);
  }
  checkHostDetails(data){
    return this.https.post(this.NodebaseUrl + "company/check_host_name_exist", data);
  }
 
  fetch_users(data){
    return this.https.post(this.NodebaseUrl + "admin/view_users", data);
  }
  add_user(data){
    return this.https.post(this.NodebaseUrl + "admin/add_user", data);
  }
  delete_users(data){
    return this.https.post(this.NodebaseUrl + "admin/delete_users", data);
  }
  companywiseLoginReport(data){
    return this.https.post(this.NodebaseUrl + "controller/get_company_wise_login_report", data);
  }
  get_company_list(data){
    return this.https.post(this.NodebaseUrl + "controller/get_company_list", data);
  }
  monthWiseSale(data){
    return this.https.post(this.NodebaseUrl + "admin/year_month_wise_sell", data);
  }

  
  //*************END Of CompanySetting Apis******************************


  //*************Start Of Reports Apis******************************
  
  productReport(data) {
    return this.https.post(
      this.NodebaseUrl + "reports/productReport",
      data
     
    );
  }
  company_invoice_report_mail(data) {
    return this.https.post(
      this.NodebaseUrl + "reports/company_invoice_report_mail",
      data
     
    );
  }
 
  getPickupReport(data) {
    return this.https.post(
      this.NodebaseUrl + "reports/getPickupReport",
      data,
      { responseType: "blob", observe: "response" }
    );
  }
  orderReport(data) {
    return this.https.post(
      this.NodebaseUrl + "reports/orderReport",
      data,
      { responseType: "blob", observe: "response" }
    );
  }
  orderReturnReport(data) {
    return this.https.post(
      this.NodebaseUrl + "reports/orderReturnReport",
      data,
      { responseType: "blob", observe: "response" }
    );
  }
  getSalesReport(data) {
    return this.https.post(
      this.NodebaseUrl + "reports/getSalesReport",
      data,
      { responseType: "blob", observe: "response" }
    );
  }

  //*************END Of Reports Apis******************************


  //*************Start Of Login By OTP Start Apis******************************

  otpSendForLogin(data){
    return this.https.post(this.NodebaseUrl + "registration/otpSendForLogin", data);
  }
  resendOtpForLogin(data){
    return this.https.post(this.NodebaseUrl + "registration/resendOtpForLogin", data);
  }
  otpVerificationForLogin(data){
    return this.https.post(this.NodebaseUrl + "registration/otpVerificationForLogin", data);
  }

  
  //*************END Of Login By OTP Start Apis******************************


  //*************Start Of Prints Apis******************************

  fetch_category_specific(data){
        return this.https.post(this.NodebaseUrl + "admin/fetch_category_specific", data);
  }
  print_labels(data){
    return this.https.post(this.NodebaseUrl + "admin/print_labels", data);
  }
  print_labels_courier(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/print_labels_courier", data);
  }
  // print_menifests(data){
  //   return this.https.post(this.NodebaseUrl + "admin/print_menifests", data);
  // }
  print_menifests(data){
    return this.https.post(this.NodebaseUrl +"get_manifest", data,{responseType: "blob",
    observe: "response"});
  }
  print_invoice(data){
  
 if(this.database=='maschile'){
   ////console.log(this.database + 'database view ');
  return this.https.post(this.NodebaseUrl +"get_invoice_b2c", data,{responseType: "blob",
  observe: "response"});
 }
 else{
  return this.https.post(this.NodebaseUrl +"get_invoice", data,{responseType: "blob",
  observe: "response"});
 }
  }
  get_label_with_invoice(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/get_label_with_invoice", data,{responseType: "blob",
    observe: "response"});
 }
  get_order_memo(data){
    return this.https.post(this.NodebaseUrl +"get_order_memo", data,{responseType: "blob",
    observe: "response"});
  }
  
  
  //*************End Of Prints Apis******************************


  //*************Start Of Coupon Apis******************************

   add_coupon(data) {
    return this.https.post(this.NodebaseUrl + "admin/add_coupon", data);
  }
   fetch_coupons(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_coupons", data);
  }
  delete_coupon(data) {
    return this.https.post(this.NodebaseUrl + "admin/delete_coupon", data);
  }
  update_coupon(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_coupon", data);
  }
  fetch_coupons_list_upcoming(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_coupons_list_upcoming", data);
  }
  fetch_coupons_list_expire(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_coupons_list_expire", data);
  }
  fetch_coupons_list_current(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_coupons_list_current", data);
  }
  fetch_specific_coupon(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_specific_coupon", data);
  }
  fetch_coupon_code(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_coupon_code", data);
  }
  
  //*************END Of Coupon Apis******************************

  //*************Start Of Admin Order And Banner Apis******************************

 
  subscribe_topic(data){
    return this.https.post(this.NodebaseUrl + "notification/topic_subscribe_notification", data);
  }
  unsubscribe_topic(data){
    return this.https.post(this.NodebaseUrl + "notification/topic_unsubscribe_notification", data);
  } 
  bulk_list(data){
    return this.https.post(this.NodebaseUrl + "bulk/fetch_file_table", data);
  }
  upload(){
    return this.uploadUrlApi;
  }
  bannerAdd(data){
    return this.https.post(this.NodebaseUrl + "admin/add_slider", data);
    // return this.https.post("http://localhost/9toys/index.php/admin/add_slider", data);
  }
  updatetierslider(data){
   return this.https.post(this.NodeBaseUrl + "admin/update_slider", data);
  }
  viewBannerall(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_all_content_slider",data);
  }
  viewBannerbyType(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_content_slider_byType",data);
  }
  deleteBanner(data){
    return this.https.post(this.NodebaseUrl + "admin/delete_content_slider_image",data);
  }
  ninetoysBanner(data){
    return this.https.post(this.NodebaseUrl + "user/fetch_all_content_slider",data);
  }

  //*************END Of Admin Order And Banner Apis******************************

  //8/09/2020 
  //*************Start Of 9Toys Specification Template Apis******************************

  fetch_parameter_content_with_type(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_parameter_content_with_type",data);
  }
  fetch_parameter_content_with_type_multi(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_parameter_content_with_type_multi",data);
  }
  fetch_parameter_specification_content(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_parameter_specification_content",data);
  }
  fetch_parameter_content_with_key_type(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_parameter_content_with_key_type",data);
  }

  //*************END Of 9Toys Specification Template Apis******************************
  
 
  //*************Start Of Vendor Side Apis******************************

  parcel_inventories(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/parcel_inventories",data);
  }
  order_split(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/order_split",data);
  }
   updateOrderStatusVendor(data) {
    return this.https.post(this.NodebaseUrl + "order_vendor/updateOrderStatus", data);
  }
  process_group_order(data) {
    return this.https.post(this.NodebaseUrl + "order_vendor/process_group_order", data);
  }
 
  productAnalysisDashboard(data){
    return this.https.post(this.NodebaseUrl + "analysis/productAnalysisDashboard", data);
  }
  //*************Start Of Vendor Order Side Apis******************************

  fetch_refund_completed(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_refund_completed", data);
  }
  fetch_refund_initiate(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_refund_initiate", data);
  }
  fetch_orders_rto(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_rto", data);
  }
  fetch_order_depo(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_depo", data);
  }
  orderrejectedC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_rejected", data);
  }
  ordercancelledC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_cancelled", data);
  }
  getOrdersPlacedC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_placed", data);
  }
  getOrdersRtdC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_rtd", data);
  }
  getOrdersPKGC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_labelled", data);
  }
  getOrdersHandoversC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_handover", data);
  }
  getOrdersdeliveredC(data) {
    return this.https.post(this.NodebaseUrl + "courier/fetch_orders_deliver", data);
  }
  cancel_order_by_user_multiple_parcel(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/cancel_order_by_user_multiple_parcel", data);
  }
  cancel_order_by_user(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/cancel_order_by_user", data);
  }
  shipyari_awb_track_lifecycle(data){
    return this.https.post(this.NodebaseUrl + "user_log/shipyari_awb_track_lifecycle", data);
  }

  
  //*************END Of Vendor Order Side Apis******************************

  
  //*************END Of Vendor Side Apis******************************

  
  //*************Start Of Return Apis******************************

  reason_dropdown(data){
    return this.https.post(this.NodebaseUrl + "user_log/reason_dropdown", data);
  }
  complete_with_refund(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/complete_with_refund", data);
  }
  update_return_initiate(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/update_return_initiate", data);
  }
  returnInitiate(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_initiate", data);
  }
  returnIntransit(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_intransit", data);
  }
  returnAccept(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_accepted", data);
  }
  returnReject(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_reject", data);
  }
  returnReceived(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_completed", data);
  }
  returnComplete(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_completed_full", data);
  }
  returnDispute(data) {
    return this.https.post(this.NodebaseUrl + "admin/return_disputed", data);
  }
  email_broadcast(data) {
    return this.https.post(this.NodebaseUrl + "admin/email_broadcast", data);
  }
  getproduct(data){
    return this.https.post(this.NodebaseUrl+"barcode/get_product_with_stock",data);
  }
  
  //*************END Of Return Apis******************************

  //*************Start Of Associate Apis On Admin Side******************************

  updateOrderStatusMulti(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/updateOrderStatusMulti", data);
  }
  add_dimension_parcel(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/add_dimension_parcel", data);
  }
 
  process_withdrawal_request(data){
    return this.https.post(this.NodebaseUrl + "associates/process_withdrawal_request", data,{ responseType: "blob", observe: "response" });
  }
 
 
  member_wallet_report(data){
    return this.https.post(this.NodebaseUrl + "associates/member_wallet_report", data,{ responseType: "blob", observe: "response" });
  }
  member_ledger_report(data){
    return this.https.post(this.NodebaseUrl + "associates/member_ledger_report", data,{ responseType: "blob", observe: "response" });
  }
 
  fetch_withdrawal_request(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_withdrawal_request", data);
  }
  withdrawal_request(data){
    return this.https.post(this.NodebaseUrl + "associates/withdrawal_request", data);
  }
  fetch_customer_registration_by_id(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_customer_registration_by_id", data);
  }
  add_customer_registration(data){
    return this.https.post(this.NodebaseUrl + "associates/add_customer_registration", data);
  }
  update_customer_registration(data){
    return this.https.post(this.NodebaseUrl + "associates/update_customer_registration", data);
  }
  insert_bank_information(data){
    return this.https.post(this.NodebaseUrl + "associates/insert_bank_information", data);
  }
  update_bank_information(data){
    return this.https.post(this.NodebaseUrl + "associates/update_bank_information", data);
  }
  fetch_customer_registration(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_customer_registration", data);
  }
  fetch_customers_list(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_customers_list", data);
  }
  customer_information_verification(data){
    return this.https.post(this.NodebaseUrl + "associates/customer_information_verification", data);
  }
  group_by_courier_no(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/group_by_courier_no", data);
  }
  parcel_inventories_details_courier(data){
    return this.https.post(this.NodebaseUrl + "user/parcel_inventories_details_courier", data);
  }
  parcel_inventories_details(data){
    return this.https.post(this.NodebaseUrl + "user/parcel_inventories_details", data);
  }
  getBarcode(data){
    return this.https.post(this.NodebaseUrl+"barcode/get_product",data)
  }
  quick_update_product_rates(data){
    return this.https.post(this.NodebaseUrl+"admin/quick_update_product_rates",data)
  }
  fetch_pin_card(data){
    return this.https.post(this.NodebaseUrl+"admin/fetch_pin_card",data)
  }
  non_registered_member_list(data){
    return this.https.post(this.NodebaseUrl+"associates/non_registered_member_list",data)
  }
  otpVerificationUserReferral(data) {
    return this.https.post(this.NodebaseUrl + "associates/otpVerificationReferral", data);
  }
  sendOtpVerifyReferral(data) {
    return this.https.post(this.NodebaseUrl + "associates/sendOtpVerifyReferral", data);
  }
  updateReturnOrder(data) {
    return this.https.post(this.NodebaseUrl + "order_vendor/updateReturnOrder", data);
  }
  updateReturnOrderMulti(data) {
    return this.https.post(this.NodebaseUrl + "order_vendor/updateReturnOrderMulti", data);
  }
  referral_details(data) {
    return this.https.post(this.NodebaseUrl + "associates/referral_details", data);
  }
  customer_deactivation(data) {
    return this.https.post(this.NodebaseUrl + "associates/customer_deactivation", data);
  }
  fetch_member_wallet_list(data) {
    return this.https.post(this.NodebaseUrl + "associates/fetch_member_wallet_list", data);
  }
  member_wallet_recharge_initiate(data) {
    return this.https.post(this.NodebaseUrl + "associates/member_wallet_recharge_initiate", data);
  }
  transaction_of_order_wallet_razor_pay(data) {
    return this.https.post(this.NodebaseUrl + "user/transaction_of_order_wallet_razor_pay", data);
  }
  payment_status_check_recharge(data) {
    return this.https.post(this.NodebaseUrl + "user/payment_status_check_recharge", data);
  }
  check_user(data){
    return this.https.post(this.NodebaseUrl + "associates/check_user", data);
  }
  fetch_member_wallet_list_vendor(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_member_wallet_list_vendor", data);
  }
  fetch_wallet_list(data){
    return this.https.post(this.NodebaseUrl + "Associates/add_withdrawal_wallet", data);
  }
  recharge_wallet_manually(data){
    return this.https.post(this.NodebaseUrl + "associates/recharge_wallet_manually", data);
  }
  fetch_member_monthly_count(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_member_monthly_count", data);
  }
  fetch_member_referral_list_tree(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_member_referral_list_tree", data);
  }
  

  //*************END Of Associate Apis On Admin Side******************************


  //*************Start Of Terms And Conditions Apis******************************

  // fetch_terms_and_conditions(data){
  //   return this.https.post(this.NodebaseUrl+"company_details/fetch_terms_and_conditions",data);
  // }
  ShortProductUpdate(data){
    return this.https.post(this.NodeBaseUrl + "Admin/update_product/v2", data)
 }
  fetch_terms_and_conditions(data){
    return this.https.post(this.NodebaseUrl+"user/fetch_terms_and_conditions",data);
  }
  delete_terms_and_conditions(data){
    return this.https.post(this.NodebaseUrl+"company_details/delete_terms_and_conditions ",data);
  }
  update_terms_and_conditions(data){
    return this.https.post(this.NodebaseUrl+"company_details/update_terms_and_conditions",data);  
  }
  add_terms_and_conditions(data){
    return this.https.post(this.NodebaseUrl+"company_details/add_terms_and_conditions",data);  
  }
  verified_pending_list_product_comp(data){
    return this.https.post(this.NodebaseUrl + "admin/verified_pending_list_product_comp", data);
  }
  update_profile_vendor(data){
    return this.https.post(this.NodebaseUrl + "company_details/update_profile_vendor", data);
  }
  fetchMemberCount(data){
    return this.https.post(this.NodebaseUrl + "associates/fetch_member_count", data);
  }
  add_pickup_address(data){
    return this.https.post(this.NodebaseUrl+"company_details/add_company_pickup_address",data);  
  }
  update_pickup_address(data){
    return this.https.post(this.NodebaseUrl + "company_details/update_company_pickup_address", data);
  }
  fetchPickupAddress(data){
    return this.https.post(this.NodebaseUrl + "company_details/fetch_company_pickup_address", data);
  }
  fetchParticularPickupAddressCount(data){
    return this.https.post(this.NodebaseUrl + "company_details/fetch_particular_company_pickup_address", data);
  }
  delete_pickup_address(data){
    return this.https.post(this.NodebaseUrl+"company_details/delete_particular_company_pickup_address ",data);
  }
  add_depo_address(data){
    return this.https.post(this.NodebaseUrl+"company_details/add_depo_serviceability",data);  
  }
  fetchMultiplePincode(data){
    return this.https.post(this.NodebaseUrl + "company_details/fetch_depo_serviceability", data);
  }
  fetchParticularMultiplePincode(data){
    return this.https.post(this.NodebaseUrl + "company_details/fetch_particular_depo_serviceability", data);
  }
  update_multiple_pincode(data){
    return this.https.post(this.NodebaseUrl + "company_details/update_depo_serviceability", data);
  }
  delete_multiple_pincode(data){
    return this.https.post(this.NodebaseUrl+"company_details/delete_depo_serviceability",data);
  }
  
  //*************END Of Terms And Conditions Apis******************************

  //*************Start Of Package Coupon Apis******************************

  fetch_package_coupon_code(data){
    return this.https.post(this.NodebaseUrl+"package/fetch_package_coupon_code",data);
  }
  fetch_package_coupons_list_upcoming(data){
    return this.https.post(this.NodebaseUrl+"package/fetch_package_coupons_list_upcoming",data);
  }
  fetch_package_coupons_list_expire(data){
    return this.https.post(this.NodebaseUrl+"package/fetch_package_coupons_list_expire",data);
  }
  fetch_package_coupons_list_current(data){
    return this.https.post(this.NodebaseUrl+"package/fetch_package_coupons_list_current",data);
  }
  fetch_particular_package_coupon(data){
    return this.https.post(this.NodebaseUrl+"package/fetch_particular_package_coupon",data);
  }
  add_package_coupon(data){
    return this.https.post(this.NodebaseUrl+"package/add_package_coupon",data);
  }
  update_package_coupon(data){
    return this.https.post(this.NodebaseUrl+"package/update_package_coupon",data);
  }
  delete_package_coupon(data){
    return this.https.post(this.NodebaseUrl+"package/delete_package_coupon",data);
  }

  //*************END Of Package Coupon Apis******************************

  //*************Start Of Package  Apis******************************

  add_package(data){
    return this.https.post(this.NodebaseUrl+"package/insert_package",data);  
  }
  fetchPackage(data){
    return this.https.post(this.NodebaseUrl + "package/fetch_all_package", data);
  }
  delete_package(data){
    return this.https.post(this.NodebaseUrl+"package/delete_package ",data);
  }
  fetchParticularPackage(data){
    return this.https.post(this.NodebaseUrl + "package/fetch_by_package_id", data);
  }
  update_package(data){
    return this.https.post(this.NodebaseUrl + "package/update_package", data);
  }
  package_transaction_razorpay(data){
    return this.https.post(this.NodebaseUrl + "user/package_transaction_razorpay", data);
  }
  package_transaction_initiate(data){
    return this.https.post(this.NodebaseUrl + "package/package_transaction_initiate", data);
  }
  dynamic_product_filter(data){
    return this.https.post(this.NodebaseUrl + "bulk/dynamic_filters", data);
  }
  delete_company_data(data){
    return this.https.post(this.NodebaseUrl + "controller/delete_company_data", data);
  }

  //*************END Of Package Apis******************************

  //*************Start Of Routes Apis******************************

  fetch_all_host(data){
    return this.https.post(this.NodebaseUrl + "controller/fetch_all_host", data);
  }
  insert_host(data){
    return this.https.post(this.NodebaseUrl + "controller/insert_host", data);
  }
  update_host(data){
    return this.https.post(this.NodebaseUrl + "controller/update_host", data);
  }
  delete_host(data){
    return this.https.post(this.NodebaseUrl + "controller/delete_host", data);
  }
  fetch_by_host_Id(data){
    return this.https.post(this.NodebaseUrl + "controller/fetch_by_host_Id", data);
  }
  add_particular_company_registry(data){
    return this.https.post(this.NodebaseUrl + "company/add_particular_company_registry", data);
  }

  //*************END Of Routes Apis******************************

  reports(data){
    return this.https.post(this.NodebaseUrl + "Bulk/oepp", data, { responseType: "blob", observe: "response" });
  }

  //*************Start Of Shipping Tabs Apis******************************

  client_courier_registration(data){
    return this.https.post(this.NodebaseUrl + "company_details/client_courier_registration", data);
  }
  check_courier_registered(data){
    return this.https.post(this.NodebaseUrl + "company_details/check_courier_registered", data);
  }
  check_balance(data){
    return this.https.post(this.NodebaseUrl + "company_details/check_balance", data);
  }
  wallet_transaction(data){
    return this.https.post(this.NodebaseUrl + "company_details/wallet_transaction", data);
  }
  // shipping tab
  fetch_package_by_company(data){
    return this.https.post(this.NodebaseUrl + "package/fetch_package_by_company", data);
  }
  fetch_package_by_company_user(data){
    return this.https.post(this.NodebaseUrl + "user/fetch_package_by_company", data);
  }
  verify_deverify_company(data){
    return this.https.post(this.NodebaseUrl + "controller/verify_deverify_company", data);
  }
  get_product_count(data){
    return this.https.post(this.NodebaseUrl + "admin/get_product_count", data);
  }
  get_feature_product(data){
    return this.https.post(this.NodebaseUrl + "admin/featuredProduct", data);
  }
  send_contact_us_mail(data){
    return this.https.post(this.NodebaseUrl + "user/send_contact_us_mail", data);
  }
  search_product_list(data){
    return this.https.post(this.NodebaseUrl + "admin/search_product_list", data);
  }
  search_brand_all_ecom(data){
    return this.https.post(this.NodebaseUrl + "admin/search_brand_all_ecom", data);
  }
  wallet_recharge(data){
    return this.https.post(this.NodebaseUrl + "company_details/client_courier_recharge", data);
  }
  add_tracking_url(data){
    return this.https.post(this.NodebaseUrl + "order_vendor/add_tracking_url", data);
  }
  fetch_bank_detail(data){
    return this.https.post(this.NodebaseUrl + "user_log/fetch_bank_detail", data);
  }
  insert_bank_details(data){
    return this.https.post(this.NodebaseUrl + "user_log/insert_bank_details", data);
  }
  fetch_product_group_buyers(data){
    return this.https.post(this.NodebaseUrl + "user_log/fetch_product_group_buyers", data);
  }

  //*************END Of Shipping Tabs Apis******************************

  //*************Start Of Search Apis******************************

  searchOrder(data) {
    return this.https.post(this.NodebaseUrl + "courier/searchOrder", data);
  }
  fetch_pickup_name_email(data){
    return this.https.post(this.NodebaseUrl + "Associates/fetch_member", data);
  }
  xml_report(data){
    return this.https.post(this.NodebaseUrl + "bulk/xml_report", data,  { responseType: "blob", observe: "response" });
  }
  non_registered_member_report(data){
    return this.https.post(this.NodebaseUrl + "Associates/non_registered_member_report", data,  { responseType: "blob", observe: "response" });
  }
  fetch_customer_registration_report(data){
    return this.https.post(this.NodebaseUrl + "Associates/fetch_customer_registration_report", data,  { responseType: "blob", observe: "response" });
  }
  fetch_customers_report(data){
    return this.https.post(this.NodebaseUrl + "Associates/fetch_customers_report", data,  { responseType: "blob", observe: "response" });
  }
  get_catalogue(data){
    return this.https.post(this.NodebaseUrl + "get_catalogue", data,  { responseType: "blob", observe: "response" });
  }
  unpublish_company(data){
    return this.https.post(this.NodebaseUrl + "admin/unpublish_company", data);
  }

  
  //*************END Of Search Apis******************************


  //*************Start Of Testimonials Apis******************************

  fetch_selected_testimonials(data){
    return this.https.post(this.NodebaseUrl + "user/fetch_selected_testimonials", data);
  }
  fetch_testimonials(data) {
    return this.https.post(this.NodebaseUrl + "admin/fetch_testimonials", data);
  }
  aud_product_group(data) {
    return this.https.post(this.NodebaseUrl + "admin/aud_product_group", data);
  }
  update_testimonial(data) {
    return this.https.post(this.NodebaseUrl + "admin/update_testimonial", data);
  }
  add_testimonial(data){
    return this.https.post(this.NodebaseUrl + "user_log/add_testimonial", data);
  }
 
  //*************END Of Testimonials Apis******************************
 
  //*************Start Of New Company Fetch Registory Apis******************************

  fetch_particular_company_registry_array(data){
    return this.https.post(this.NodebaseUrl + "user/fetch_particular_company_registry_array", data);  
  }
  add_comp_registry_new(data){
    return this.https.post(this.NodebaseUrl + "company/add_comp_registry_new", data);
  }
  
  //*************END Of New Company Fetch Registory Apis******************************


  //*************Start Of Chart & Size Apis******************************

  
  delete_chart(data){
    return this.https.post(this.NodebaseUrl + "admin/delete_chart", data);
  }
  add_size_chart(data){
    return this.https.post(this.NodebaseUrl + "admin/add_size_chart", data);
  }
  fetch_all_size_chart(data){
    return this.https.post(this.NodebaseUrl + "admin/fetch_all_size_chart", data);
  }
  fetch_size_chart(data){
    return this.https.post(this.NodebaseUrl + "user/fetch_size_chart", data);
  }
  check_mobile_email_exist(data){
    return this.https.post(this.NodebaseUrl + "profile/check_user_exist", data);
  }
  get_member_by_randomId(data){
    return this.https.post(this.NodebaseUrl + "user/get_member_by_randomId", data);
  }
  delete_account(data){
    return this.https.post(this.NodebaseUrl + "profile/delete_account", data);
  }
  process_depo_request(data){
    return this.https.post(this.NodebaseUrl + "admin/process_depo_request", data);
  }
  depo_request(data){
    return this.https.post(this.NodebaseUrl + "associates/depo_request", data);
  }
  Error_image(data){
    return this.https.post(this.NodebaseUrl + "user/get_company_icon", data);
  }
  pickup_search(data){
    return this.https.post(this.NodebaseUrl + "company_details/pickup_search", data);
  }
  Compare_products(data){
    return this.https.post(this.NodebaseUrl + "user/compare_product", data);
  }
  
  //*************END Of Chart & Size Apis******************************

 
  fetchcommunication(data){
    return this.https.post(this.NodebaseUrl + "communication/fetchcommunication", data);
  }
  insertcommunication(data){
    return this.https.post(this.NodebaseUrl + "communication/insertcommunication", data);
  }
  updatestatus(data){
    return this.https.post(this.NodebaseUrl + "communication/updatestatus", data);
  }
  update_message_read(data){
    return this.https.post(this.NodebaseUrl + "communication/update_message_read", data);
  }
  update_message_sent(data){
    return this.https.post(this.NodebaseUrl + "communication/update_message_sent", data);
  }
  fetchChats(data){
    return this.https.post(this.NodebaseUrl + 'communication/fetchChats',data);
  }

  //*************START of customer cart & POS integration API'S  ***************
  searchUserByNum(data){
    return this.https.post(this.NodebaseUrl + 'Admin/searchCustomer',data);
  }
  fetchCustomerCarts(data){
    return this.https.post(this.NodeBaseUrl + "user_log/fetch_all_cart" , data)
  }
  add_customer(data){
    return this.https.post(this.NodebaseUrl + "Admin/addCustomer", data);
  }
  fetch_all_invoices(data){
    return this.https.post(this.NodeBaseUrl + "Admin/fetch_invoices", data);
  }
  add_customer_cart(data){
    return this.https.post(this.NodebaseUrl + "Admin/add_customer_cart", data);
  }
  adminCheckout(data){
    return this.https.post(this.NodebaseUrl + "user_log/adminCODPayment", data);
  }
  delete_customer_cart(data){
    return this.https.post(this.NodebaseUrl + "Admin/delete_customer_cart", data);
  }
//*************END of customer cart & POS integration API'S  ***************

//**************START of FAQ  and help & support API'S *********************  
fetchFaq(data){
 return this.https.post(this.NodebaseUrl + 'faq/fetch',data)
}
addFaq(data){
  return this.https.post(this.NodebaseUrl + 'faq/add',data)
}
updateFaq(data){
  return this.https.post(this.NodebaseUrl + 'faq/update',data)
}
deleteFaq(data){
  return this.https.post(this.NodebaseUrl + 'faq/delete',data)
}
fetchCategoryFaq(data){
  return this.https.post(this.NodebaseUrl + 'faq/fetchCategory',data) 
}

//**************END of FAQ  and help & support API'S *********************  
  add_wall_topic(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/add_wall_topics',data)
  }
  fetch_wall_topic(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/fetch_wall_topics',data)
  }
  delete_wall_topic(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/delete_wall_topics',data)
  }
  update_wall_topic(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/update_wall_topics',data)
  }
  create_wall_post(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/create_wall_post',data)
  }
  fetch_wall_post(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/fetch_wall_posts',data)
  }
  delete_wall_post(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/delete_wall_post',data)
  }
  update_wall_post(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/update_wall_post',data)
  }
  verify_wall_post(data){
    return this.https.post(this.NodebaseUrl + 'wall_topics/verify_post',data)
  }

  add_del_agent(data){
    return this.https.post(this.NodebaseUrl + 'delboyAdmin/add_del_agent', data)
  }
  update_del_agent_profile(data){
    return this.https.post(this.NodebaseUrl + 'delboyAdmin/update_del_agent_profile', data)
  }
  get_del_agent_profile(data){
    return this.https.post(this.NodebaseUrl + 'delboyAdmin/get_del_agent_profile', data)
  }
  get_comp_del_agent(data){
    return this.https.post(this.NodebaseUrl + 'delboyAdmin/get_comp_del_agent', data)
  }
  assign_del_agent(data){
    return this.https.post(this.NodebaseUrl + 'delboyAdmin/assign_del_agent', data)
  }


  s_no(index,offset,paginationValue){ 
    var temp;
      if(offset !=1){
        temp=index+ parseInt(paginationValue);
      }
      else{
        temp=index;
      }
    
      return temp
      
  }
  getCloseWord(key: string): string {
    var translateValue='';
   this.translate.get(key).subscribe((res: string) => {
     translateValue=res
  });
  return translateValue;
  }

 
}

