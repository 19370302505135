

<div class="container-fluid">
	<div class="row justify-content-center">
		<div class="col-md-11 shadow bg-white p-3">
			<form [formGroup]="registerForm">
  
				<div class="row">
					<div class="col-md-6">
						<mat-form-field appearance="fill" class="example-full-width">
							<mat-label class="mat-label">{{"Title" | translate}}</mat-label>
							<input matInput placeholder="Title" formControlName="title" required />
							<mat-icon matSuffix>subtitles</mat-icon>
						</mat-form-field>
						<mat-form-field appearance="fill" class="example-full-width">
							<mat-label class="mat-label">{{"Message" | translate}}....</mat-label>
							<textarea matInput rows="2" placeholder="Message" formControlName="message" required>
					</textarea>
					<i class="fas fa-closed-captioning" matSuffix></i>
							<!-- <mat-icon matSuffix>closed_caption</mat-icon> -->
						</mat-form-field>
						


					</div>
					<div class="col-md-6">
                        <div class="row">
							<div class="col-md-6">
								<mat-form-field class="w-100" appearance="fill">
									<mat-label>1</mat-label>
									<mat-select (selectionChange)="getFirst ($event.value)">
										<mat-option value="1">1</mat-option>
		
									</mat-select>
									<i class="fas fa-flag" matSuffix></i>
								</mat-form-field>
									<mat-form-field class="w-100" appearance="fill" *ngIf="box">
										<mat-label>1</mat-label>
										<input matInput placeholder="Landmark">
										<i class="fas fa-flag" matSuffix></i>
									</mat-form-field>
									<mat-form-field class="w-100" appearance="fill" *ngIf="dropdown">
										<mat-label>1</mat-label>
										<mat-select>
											<mat-option value="1">1</mat-option>
			
										</mat-select>
										<i class="fas fa-flag" matSuffix></i>
									</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field class="w-100" appearance="fill">
									<mat-label>1</mat-label>
									<mat-select formControlName="country_id" (selectionChange)="getSecond ($event.value)">
										<mat-option value="2">2</mat-option>
		
									</mat-select>
									<i class="fas fa-flag" matSuffix></i>
								</mat-form-field>
									<mat-form-field class="w-100" *ngIf="box2" appearance="fill">
										<mat-label>2</mat-label>
										<input matInput placeholder="Landmark">
										<i class="fas fa-flag" matSuffix></i>
									</mat-form-field>
									<mat-form-field class="w-100" *ngIf="dropdown2" appearance="fill">
										<mat-label>1</mat-label>
										<mat-select>
											<mat-option value="2">2</mat-option>
			
										</mat-select>
										<i class="fas fa-flag" matSuffix></i>
									</mat-form-field>
							</div>
						</div>

					
							

					</div>
				</div>
				<div class="row">
					<div class="col-md-6 text-center">
						<p class="text-primary" style="font-size: 12px;"> <i class="fas fa-exclamation"></i> {{"Note : Image Width must be less than 7000px and Height must be less than 5000px." | translate}}</p>
		
						
		
		
							<label class="mat-label">{{"Choose Image" | translate}} </label>
							<input class="text-center " type="file" formControlName="image" (change)="onFileChanged1($event)" />
						
						<div class="image-preview mb-1" *ngIf="previewUrl">
							<img [src]="previewUrl" class="image-preview1 mt-1" />
						</div>
					</div>
					<div class="col-md-6">
						<div class="custom-control custom-checkbox">
							<input class="custom-control-input" (click)="onChangeDemo($event.target.checked)" type="checkbox"
								id="inform-me">
							<label class="custom-control-label " for="inform-me"><span class="text-info"> {{"Send instantly" | translate}}</span></label>
						</div>
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="fill" *ngIf="is_checked == false || !is_checked" class="example-full-width "
								>
								<input matInput [matDatepicker]="picker" autocomplete="off" [min]="minDate" 
									(dateChange)="search2($event.target.value)" (keyup)="search2($event.target.value)"
									placeholder="Notification" formControlName="app_date">
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field *ngIf="is_checked == false || !is_checked" class="example-full-width" appearance="fill" >
									<mat-label>{{"click to change time" | translate}}</mat-label>
								
									<input formControlName="app_time" matInput [ngxTimepicker]="picker" matInput min={{minimumTime}} >
									<ngx-material-timepicker #picker></ngx-material-timepicker>
								
								</mat-form-field>
							</div>
							<button mat-raised-button [disabled]="!loading" class="bg-success text-white ml-auto mr-3 mb-0 mt-1">
								{{"Save" | translate}}</button> 
						</div>
							
					

					</div>
				</div>
			
			</form>
		</div>
	</div>
</div>