


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit,ElementRef, EventEmitter,ViewChild, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'button-tags-view',
    templateUrl: './button-tags-view.component.html',
  })
  export class ButtonTagsViewComponent {
    @ViewChild('content') content: ElementRef;
    invoice = false;
    track= false;
    view= false;
    delete = false;
    edit = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
    data: any;
    deactivatef=false;
    tag_products;
    loading=true;
    
    resV1=false;
     tagException;
     tagExceptionNo;
    tagdata;
    tagdata2;
    closeResult: string;
    registerForm:FormGroup;
    registerForm2:FormGroup;
  constructor(
      private formBuilder:FormBuilder,
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'sm' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
        	this.registerForm = this.formBuilder.group({
      name : [''],
      user_num:this.user_num,
      access_token:this.access_token,
      tag_no:['']
      
    });
    this.registerForm2 = this.formBuilder.group({
      name : [''],
      user_num:this.user_num,
      access_token:this.access_token,
      
    });
    }
  agInit(params: ICellRendererParams): void {
      //params);
    this.params = params;
    this.data = params.value;
    var data1= params.data;

    if(this.params.label =='edit'){
this.edit = true;


    }
    if(this.params.label =='delete'){
        this.delete = true;
        
        
            }

  }
 
  deletingtag(tag_no){
    this.resV1=false;
    var res = confirm("Are you sure you want to delete this Tag.");
    this.resV1=false;
    if(res){
       this.resV1=false;

     
     this.adminservice.delete_comp_tags({access_token:this.access_token,user_num:this.user_num, tag_no: tag_no}).subscribe(data=>{
      
       if(data['status']==1){ 
          this.modalService.dismissAll('Save click');
           this.snackbar.open(this.adminservice.getCloseWord('This Tag is deleted successfully. '),'' ,{
            duration: 3000,
           horizontalPosition:'center',
       }); 
       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
       this.router.navigate(['//view-tags']));          
         
       }
       else if(data['status']==10){
       sessionStorage.clear();
        this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                 duration: 3000,
                 horizontalPosition:'center',
         });      
       this.router.navigate(['/']);
       }
       else if(data['status']==2){
         this.tag_products = data['result'];    
         this.modalService.open(this.content, { size: 'md' });
         this.tagException=true;
         this.tagExceptionNo=false;
        this.snackbar.open(this.adminservice.getCloseWord('Firstly you have to delete these Products. '),'' ,{
                 duration: 3000,
                 horizontalPosition:'center',
         }); 
        
       }
       else{
         this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again.'),'' ,{
           duration: 3000,
           horizontalPosition:'center',
         }); 
 
       }
     },
     error=>{
         // this.loading = false;
       }
     );

    }
   }

onSubmit(){
      if (this.registerForm.invalid) {
        return;
    }   

    let data2 = this.registerForm.value;
   
   
    this.adminservice.update_tags(data2)
        .pipe(first())
        .subscribe(
            data2 => {
                if (data2['status']==1) {
                  this.snackbar.open(this.adminservice.getCloseWord('Tag is updated Successfully '),'' ,{
                    duration: 3000,
                    horizontalPosition:'center',
                  });
                  
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.router.navigate(["/view-tags"]));             
                           
                        

                }else {
                  this.snackbar.open(this.adminservice.getCloseWord('Unable to update Tag'),'' ,{
                    duration: 3000,
                    
                  });
                  
                }
            },
            error => {
                this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '),'' ,{
                  duration: 3000,
                  
                });
                
            });
}

gettagDetails(tag_no){
    this.adminservice.fetch_comp_tags({access_token:this.access_token,user_num:this.user_num,tag_no:tag_no}).subscribe(data=>{
     this.loading=false

     if(data['status']==1){ 
       this.tagdata2 = data['result'];   
        this.registerForm.get('name').setValue(this.tagdata2.name);
       
       this.registerForm.get('tag_no').setValue(this.tagdata2.tag_no);
       
       
       
     }
     else if(data['status']==10){
     sessionStorage.clear();
      this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
               duration: 3000,
               horizontalPosition:'center',
       });      
     this.router.navigate(['/']);
     }
     else{

     }
   },
   error=>{
     this.loading=false
     
     }
   );

 }


}