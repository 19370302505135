import { getNumberOfCurrencyDigits } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import Charset from '@zxing/library/esm/core/util/Charset';
import { max } from 'rxjs/operators';
import { first } from "rxjs/operators";

import { NinetoysserviceService } from '../ninetoysservice.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-multiple-pincode',
  templateUrl: './add-multiple-pincode.component.html',
  styleUrls: ['./add-multiple-pincode.component.css']
})
export class AddMultiplePincodeComponent implements OnInit {

  public access_token = sessionStorage.getItem('access_token');
  public user_num = sessionStorage.getItem('user_num');
  public comp_num = sessionStorage.getItem('comp_num');
  public depo_service_no = sessionStorage.getItem("depo_service_no");
  public address_no = sessionStorage.getItem("address_no");
  public depo_id = sessionStorage.getItem("depo_id");

  

  public actionform = sessionStorage.getItem('form');


  constructor(
    private adminservice: NinetoysserviceService,
    private _formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private router: Router,
    private translate: TranslateService
  ) { }
  ecomtrails = false;
  ecomtrails2 = true;
  ninetoys = false;
  maschile = false;
  mlm = false;
  dataall;
  ngOnInit(): void {

    if (sessionStorage.getItem('database') == "ecomtrails") {
      this.ecomtrails = true;
    }
    else if (sessionStorage.getItem('database') == "9toys") {
      this.ninetoys = true;
    }

    else if (sessionStorage.getItem('database') == "maschile") {
      this.maschile = true;
    }
    else if (sessionStorage.getItem('database') == "mlm") {
      this.mlm = true;
    }
    else {
      this.ecomtrails = true;
    }

    this.registerForm = this._formBuilder.group({
      user_num: this.user_num,
      access_token: this.access_token,
      comp_num: this.comp_num,
      depo_service_no: this.depo_service_no,
      // FormArray:this._formBuilder,
      pincode: [''],

    });
    if (this.actionform == 'add') {

    }
    else if (this.actionform == 'update') {
      this.getdataupdate();

    }
  }
  regAddForm2: FormGroup;
  registerForm: FormGroup;
  getdataupdate() {
    let postData = { user_num: this.user_num, access_token: this.access_token, comp_num: this.comp_num, depo_service_no: this.depo_service_no }
    this.adminservice.fetchParticularMultiplePincode(postData).subscribe(data => {
      if (data["status"] == 1) {
        this.dataall = data['result'];
        //this.dataall)
        this.registerForm.get('pincode').setValue(data['result'].pincode);
        this.registerForm.get('pincode').setValue((this.registerForm.get('pincode').value).toString());
        //this.registerForm.controls.pincode.value);
      }

      else if (data['status'] == 10) {

        sessionStorage.clear();
        this.snackbar.open(this.getCloseWord(
          "Multiple login with this ID has been detected, Logging you out."),
          "",
          {
            duration: 2000,
            horizontalPosition: "center"
          }
        );
        this.router.navigate(["/"]);

      }
      else if (data['status'] == 0) {


      }
    },
      error => {
        //31);
        this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
          duration: 3000
        });
      }
    );
  }


  registerForm2 = new FormGroup({
    pincode: new FormArray([
      new FormControl('', Validators.required),
    ])
  });

  numericOnly(event) {
    if (event == 5) {
      this.pincode.push(new FormControl('', Validators.required));
    }
  }
  get pincode(): FormArray {
    return this.registerForm2.get('pincode') as FormArray;
  }
  send(): void {
    for (let i = 0; i < this.pincode.length; i++) {
      //this.pincode.at(i).value);
      //this.registerForm2.value)
    }
    if (this.registerForm2.invalid) {
      this.snackbar.open(this.getCloseWord('Please fil required '), '', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    } else {
      let data2 = this.registerForm2.value;
      data2.access_token = this.access_token;
      data2.user_num = this.user_num;
      data2.comp_num = this.comp_num;
      data2.depo_id = this.address_no;

      this.adminservice.add_depo_address(data2)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] == 1) {
              this.snackbar.open(this.getCloseWord('Successfully Add Depo Address'), '', {
                duration: 3000,

              });
              this.router.navigate(['//multiple-pincode']);
              this.registerForm2.reset(this.pincode)
            }
            else if (data['status'] == 10) {

              sessionStorage.clear();
              this.snackbar.open(this.getCloseWord(
                "Multiple login with this ID has been detected, Logging you out."),
                "",
                {
                  duration: 2000,
                  horizontalPosition: "center"
                }
              );
              this.router.navigate(["/"]);

            } else if (data['status'] == 0) {
              this.snackbar.open(this.getCloseWord('Unable to add'), '', {
                duration: 3000,
              });
            }
          });
    }
  }
  update() {
    let data = this.registerForm.value;
    data.access_token = this.access_token;
    data.user_num = this.user_num;
    data.comp_num = this.comp_num;
    data.depo_id = this.depo_id;
    data.depo_service_no = this.depo_service_no;

    this.adminservice.update_multiple_pincode(data)
      .pipe(first())
      .subscribe(
        data => {
          {
            this.snackbar.open(this.getCloseWord('Successfully update Pincode'), '', {
              duration: 3000,
            });
            this.router.navigate(['//multiple-pincode']);
          }

        });

  }

  addPincode() {
    this.pincode.push(new FormControl('', Validators.required));
  }

  deleteMultiplepincode(index: number) {
    if (this.pincode.length !== 1) {
      this.pincode.removeAt(index);
    }
  }
  getCloseWord(key: string): string {
    var translateValue='';
   this.translate.get(key).subscribe((res: string) => {
     translateValue=res
 });
 return translateValue;
 }
}
