

import { Component, OnInit, ViewChild, ViewChildren,ViewEncapsulation ,QueryList,ElementRef} from '@angular/core';
import { Router } from "@angular/router";
import { NinetoysserviceService } from "../ninetoysservice.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AlertService, AuthenticationService } from "../_services";
import { 
  MatCardModule,   MatStepperModule,      MatDialogModule,   MatRippleModule, MatDatepickerModule, MatNativeDateModule, MatChipsModule ,MatPaginatorModule
} from '@angular/material';
import { environment } from "../../environments/environment";

import {
  FormControl,
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { first } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
// import { AmazingTimePickerService } from "amazing-time-picker";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Location } from "@angular/common";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-package-coupons',
  templateUrl: './package-coupons.component.html',
  styleUrls: ['./package-coupons.component.css']
})
export class PackageCouponsComponent implements OnInit {
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
  favicon=environment.favicon;
           logo=environment.logo;
             loaderAsset=environment.loader;
               error_img = sessionStorage.getItem('error_image');
               website=environment.website;
                              project=environment.project;

  public showLoader = true;
  flag1=2;
  flagU=2;
  flagE=2;
  public visible = true;
  public visible1 = false;
  public visible2 = false;
  public nodata = false;
  services;
  package_id;
  packages;
  valid_fromU;
  valid_toU;
  count;
  e2=4;
  usercheck;
  flag: boolean;
    public usertype_id = parseInt(sessionStorage.getItem("usertype_id"));

  public access_token = sessionStorage.getItem("access_token");
  public comp_num = sessionStorage.getItem("comp_num");
  public user_num = sessionStorage.getItem("user_num");

 displayedColumns: string[] = [
    "sno",
    
    "coupon_code",
    "description",
    "valid_from",
    "valid_to",
    "max_disc",
    "discount_percentage",
    "min_cart_value",

    "max_no_times",
    "max_no_of_times_per_user",
    "action"
  ];

  displayedColumnsU: string[] = [
    "sno",
    
    "coupon_code",
    "description",
    "valid_from",
    "valid_to",
    "max_disc",
    "discount_percentage",
    "min_cart_value",

    "max_no_times",
    "max_no_of_times_per_user",
    "action"
  ];

  displayedColumnsE: string[] = [
    "sno",
    
    "coupon_code",
    "description",
    "valid_from",
    "valid_to",
    "max_disc",
    "discount_percentage",
    "min_cart_value",

    "max_no_times",
    "max_no_of_times_per_user",
    "action"
  ];
dataSource;
  dataSourceU;
  dataSourceE;
   allDeviceData: any;
  allDeviceDataU: any;
  allDeviceDataE: any;

  offset = 1;
  offsetU = 1;
  offsetE = 1;

  pages ;
  pagesU ;
  pagesE ;

  cards;
  items: FormArray;
  items1: FormArray;
  couponFormU: FormGroup;
  couponForm: FormGroup;
  minDate = new Date();
  minDate1;
  countU;
  countE;
  constructor(
    private router: Router,
    private adminService: NinetoysserviceService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private cookie: CookieService,
    private location: Location,
     private authenticationService: AuthenticationService,
        private modalService: NgbModal

  ) {}

  checkpass(event) {
    if (event >= this.usercheck) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }
  checkuser(event) {
    this.usercheck = event;
  }
@ViewChild('closeBtn') closeBtn: ElementRef;
  closeResult;

open(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
       
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
      openXl(content) {
      this.modalService.open(content, { size: 'md' });
    }
      openXl2(content) {
      this.modalService.open(content, { size: 'lg' });
    }
     private getDismissReason(reason: any): string { 
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }
  ngOnInit() {
    this.couponForm = this.formBuilder.group({
      discount_percentage: ["", [Validators.required, Validators.min(1)]],
      max_disc: ["", [Validators.required]],
      description: ["", [Validators.required]],
      min_cart_value: ["", [Validators.required, Validators.min(1)]],
      max_no_times: ["", [Validators.required, Validators.min(1)]],
      coupon_code: ["", [Validators.required, Validators.min(1)]],
      max_no_of_times_per_user: ["", [Validators.required, Validators.min(1)]],
      valid_from: ["", [Validators.required, Validators.min(1)]],
      valid_to: ["", [Validators.required, Validators.min(1)]]
    });
     this.couponFormU = this.formBuilder.group({
       comp_num:this.comp_num,
       package_cup_no:[""],
      discount_percentage: ["", [Validators.required, Validators.min(1)]],
      max_disc: ["", [Validators.required]],
      description: ["", [Validators.required]],
      min_cart_value: ["", [Validators.required, Validators.min(1)]],
      max_no_times: ["", [Validators.required, Validators.min(1)]],
      coupon_code: ["", [Validators.required, Validators.min(1)]],
      max_no_of_times_per_user: ["", [Validators.required, Validators.min(1)]],
      valid_from: ["", [Validators.required, Validators.min(1)]],
      valid_to: ["", [Validators.required, Validators.min(1)]]
    });
    // (this.couponForm.value);

    // this.adminService
    //   .fetch_coupons({
    //     user_num: sessionStorage.getItem("user_num"),
    //     access_token: sessionStorage.getItem("access_token"),
    //     comp_num: sessionStorage.getItem("comp_num")
    //   })
    //   .subscribe(data => {
    //     if (data["status"] == 1) {
    //       this.showLoader = false;

    //       this.visible = true;
    //       this.cards = data["result"];
    //     } else {
    //       this.showLoader = false;
    //       this.visible = false;
    //     }
    //   });
     this.coupenCurrent(this.offset);
   this.coupenUpcoming(this.offsetU);
   this.coupenExpire(this.offsetE);

  }

  changeOffset(offset) {
    if (offset <= 0) {
      this.offset = 1;
    } else {
      this.offset = offset;
      document.documentElement.scrollTop = 0;
    }

    this.coupenCurrent(offset);
  }
   changeOffsetE(offset) {
    if (offset <= 0) {
      this.offsetE = 1;
    } else {
      this.offsetE = offset;
      document.documentElement.scrollTop = 0;
    }

    this.coupenExpire(offset);
  }
   changeOffsetU(offset2) {
    if (offset2 <= 0) {
      this.offsetU = 1;
    } else {
      this.offsetU = offset2;
      document.documentElement.scrollTop = 0;
    }

    this.coupenUpcoming(offset2);
  }
  addEvent(event: MatDatepickerInputEvent<Date>) {
   
    this.minDate1 = event.value;
  }
  addCoupon() {
var event= this.couponForm.controls.valid_from.value;
     var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var fullD=year+'-'+month+'-'+day
  ;
     
       // this.couponForm.get('valid_from').setValue(fullD);
  var event2= this.couponForm.controls.valid_to.value;
     var day2=event2.getDate();
  var month2=event2.getMonth()+1;
  var year2 =event2.getFullYear();
  var fullD2=year2+'-'+month2+'-'+day2;
     
       // this.couponForm.get('valid_to').setValue(fullD2);
  
    let data = this.couponForm.value;
    data.valid_from=fullD;
    data.valid_to=fullD2;
    // var stringified = JSON.stringify(this.couponForm.controls.valid_from.value);
    // var dob = stringified.substring(1, 11);
  
 // var stringified2 = JSON.stringify(this.couponForm.controls.valid_to.value);
 //    var dob2 = stringified2.substring(1, 11);
  // this.couponForm.get('valid_to').setValue(dob);
  
    data.user_num = sessionStorage.getItem("user_num");
    data.access_token = sessionStorage.getItem("access_token");
    data.comp_num = sessionStorage.getItem("comp_num");
    if (this.couponForm.invalid) {
      this.snackbar.open(this.adminService.getCloseWord("*marked fields are required "), "", {
        duration: 5000,

        horizontalPosition: "center"
      });
    } else {
      this.adminService.add_package_coupon(data).subscribe(data => {
        if (data["status"] == 1) {
          this.snackbar.open(this.adminService.getCloseWord("Coupon added Successfully. "), "", {
            duration: 3000,
            horizontalPosition: "center"
          });
                         this.modalService.dismissAll('Save click');

          this.router
            . navigateByUrl("/", {
              skipLocationChange: true
            })
            .then(() => this.router.navigate(["/package-coupons"]));
        } else if (data["status"] == 10) {
          sessionStorage.removeItem("type");
    sessionStorage.removeItem("user_num");
    sessionStorage.removeItem("person_id");
    sessionStorage.removeItem("access_token");
          localStorage.clear();
          this.snackbar.open(this.adminService.getCloseWord(
            "Multiple login with this ID has been detected, Logging you out. "),
            "",
            {
              duration: 5000,
              horizontalPosition: "center"
            }
          );
          this.router.navigate(["/"]);
        } else if (data["status"] == 0) {
          this.snackbar.open(this.adminService.getCloseWord("Failed to add coupon"), "", {
            duration: 5000,
            horizontalPosition: "center"
          });
          this.router.navigate(["/package-coupons"]);
        }
      });
    }
  }

  updateCoupon() {
    var fullD2;
    var fullD;
    var event= this.couponFormU.controls.valid_from.value;
    if(event!=this.valid_fromU){
     var day=event.getDate();

    var month=event.getMonth()+1;
    var year =event.getFullYear();
     fullD=year+'-'+month+'-'+day;
     }
     else{
       fullD= this.couponFormU.controls.valid_from.value;
       // this.couponFormU.get('valid_from').setValue(fullD);
     }
  var event2= this.couponFormU.controls.valid_to.value;
       if(event2!=this.valid_toU){
     var day2=event2.getDate();
  var month2=event2.getMonth()+1;
  var year2 =event2.getFullYear();
   fullD2=year2+'-'+month2+'-'+day2;
     }
     else{
        fullD2= this.couponFormU.controls.valid_to.value;
       // this.couponFormU.get('valid_to').setValue(fullD2);
     }
  
    let data = this.couponFormU.value;
    data.valid_from=fullD;
    data.valid_to=fullD2;
    data.user_num = sessionStorage.getItem("user_num");
    data.access_token = sessionStorage.getItem("access_token");
    data.comp_num = sessionStorage.getItem("comp_num");
    if (this.couponFormU.invalid) {
      this.snackbar.open(this.adminService.getCloseWord("*marked fields are required "), "", {
        duration: 5000,

        horizontalPosition: "center"
      });
    } else {
      this.adminService.update_package_coupon(data).subscribe(data => {
        if (data["status"] == 1) {
          this.snackbar.open(this.adminService.getCloseWord("Coupon updated Successfully. "), "", {
            duration: 3000,
            horizontalPosition: "center"
          });
                         this.modalService.dismissAll('Save click');

         if(this.e2==1){
                this.coupenCurrent(this.offset);
              }
              else if(this.e2==2){
                 this.coupenUpcoming(this.offsetU);
              }
              else if(this.e2==3){
                 this.coupenExpire(this.offsetE);
              }
              else{
                this.router
      . navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/package-coupons"]));
  
              }
        } else if (data["status"] == 10) {
          sessionStorage.removeItem("type");
    sessionStorage.removeItem("user_num");
    sessionStorage.removeItem("person_id");
    sessionStorage.removeItem("access_token");
          localStorage.clear();
          this.snackbar.open(this.adminService.getCloseWord(
            "Multiple login with this ID has been detected, Logging you out. "),
            "",
            {
              duration: 5000,
              horizontalPosition: "center"
            }
          );
          this.router.navigate(["/"]);
        } else if (data["status"] == 0) {
          this.snackbar.open(this.adminService.getCloseWord("Failed to add coupon"), "", {
            duration: 5000,
            horizontalPosition: "center"
          });
          this.router.navigate(["/package-coupons"]);
        }
      });
    }
  }

  // ========================Add Button=====================
  now() {
    this.visible = false;
  }
  // ========================Edit Button=====================
  editCoupon(data) {
    sessionStorage.setItem("cup_id", data);
    this.router
      . navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/profiles/edit-coupon"]));
  }
coupenCurrent(offset){
     this.adminService
      .fetch_package_coupons_list_current({
         offset: offset,
      page_items: "20",

        access_token: this.access_token,
        user_num: this.user_num,
        comp_num: this.comp_num
      })
      .subscribe(
        data => {

          if (data["status"] == 1) {
            this.flag1=1;
            this.pages = data["pages"];
            this.count = data["no_of_coupen"];
            this.allDeviceData = data["result"];
            for (let i = 0; i < this.allDeviceData.length; i++) {
              this.allDeviceData[i].sno = i + 1;
              
            }
            this.showLoader = false;

            this.dataSource = new MatTableDataSource(this.allDeviceData);
         


       
          } else if (data["status"] == 10) {
            this.flag1=0;
            sessionStorage.clear();
            this.snackbar.open(this.adminService.getCloseWord(
              "Multiple login with this ID has been detected, Logging you out. "),
              "",
              {
                duration: 3000,
                horizontalPosition: "center"
              }
            );
            this.router.navigate(["/"]);
          } else {
            if(this.offset>1){
              this.flag1=1;
            }
            else{
              this.flag1=0;
            }
            
            this.showLoader = false;

          }
        },
        error => {
          this.snackbar.open(this.adminService.getCloseWord(
            "Something went wrong due to network, please try again. "),
            "",
            {
              duration: 3000,
              horizontalPosition: "center"
            }
          );
          this.showLoader = false;
        }
      );
  }
coupenUpcoming(offset){
     this.adminService
      .fetch_package_coupons_list_upcoming({
         offset: offset,
      page_items: "20",

        access_token: this.access_token,
        user_num: this.user_num,
        comp_num: this.comp_num
      })
      .subscribe(
        data => {

          if (data["status"] == 1) {
            this.flagU=1;
            this.pagesU = data["pages"];
            this.countU = data["no_of_coupen"];
            this.allDeviceDataU = data["result"];
            for (let i = 0; i < this.allDeviceDataU.length; i++) {
              this.allDeviceDataU[i].sno = i + 1;
              
            }
            this.showLoader = false;

            this.dataSourceU = new MatTableDataSource(this.allDeviceDataU);
         


       
          } else if (data["status"] == 10) {
            this.flagU=0;
            sessionStorage.clear();
            this.snackbar.open(this.adminService.getCloseWord(
              "Multiple login with this ID has been detected, Logging you out. "),
              "",
              {
                duration: 3000,
                horizontalPosition: "center"
              }
            );
            this.router.navigate(["/"]);
          } else {
            if(this.offsetU>1){
              this.flagU=1;
            }
            else{
              this.flagU=0;
            }
            
            this.showLoader = false;

          }
        },
        error => {
          this.snackbar.open(this.adminService.getCloseWord(
            "Something went wrong due to network, please try again. "),
            "",
            {
              duration: 3000,
              horizontalPosition: "center"
            }
          );
          this.showLoader = false;
        }
      );
  }
  coupenExpire(offset){
     this.adminService
      .fetch_package_coupons_list_expire({
         offset: offset,
      page_items: "20",

        access_token: this.access_token,
        user_num: this.user_num,
        comp_num: this.comp_num
      })
      .subscribe(
        data => {

          if (data["status"] == 1) {
            this.flagE=1;
            this.pagesE = data["pages"];
            this.countE = data["no_of_coupen"];
            this.allDeviceDataE = data["result"];
            for (let i = 0; i < this.allDeviceDataE.length; i++) {
              this.allDeviceDataE[i].sno = i + 1;
              
            }
            this.showLoader = false;

            this.dataSourceE = new MatTableDataSource(this.allDeviceDataE);
         


       
          } else if (data["status"] == 10) {
            this.flagE=0;
            sessionStorage.clear();
            this.snackbar.open(this.adminService.getCloseWord(
              "Multiple login with this ID has been detected, Logging you out. "),
              "",
              {
                duration: 3000,
                horizontalPosition: "center"
              }
            );
            this.router.navigate(["/"]);
          } else {
            if(this.offsetE>1){
              this.flagE=1;
            }
            else{
              this.flagE=0;
            }
            
            this.showLoader = false;

          }
        },
        error => {
          this.snackbar.open(this.adminService.getCloseWord(
            "Something went wrong due to network, please try again. "),
            "",
            {
              duration: 3000,
              horizontalPosition: "center"
            }
          );
          this.showLoader = false;
        }
      );
  }

  applyFilter(filterValue: string) {
   
    this.dataSource.filter = filterValue.trim().toLowerCase();


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyFilterU(filterValue: string) {
    this.dataSourceU.filter = filterValue.trim().toLowerCase();
    // this.dataSource1.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceU.paginator) {
      this.dataSourceU.paginator.firstPage();
    }
  }
  applyFilterE(filterValue: string) {
    this.dataSourceE.filter = filterValue.trim().toLowerCase();
    // this.dataSource1.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceE.paginator) {
      this.dataSourceE.paginator.firstPage();
    }
  }
  deleteCoupon(cup_no,e){
    var res = confirm("Are you sure you want to delete this coupen.");
    if (res) {
      //this.loading = true;
      this.adminService
        .delete_package_coupon({
          access_token: this.access_token,
          user_num: this.user_num,
          cup_no: cup_no,
          comp_num: this.comp_num
        })
        .subscribe(
          data => {
            this.showLoader = false;
            if (data["status"] == 1) {
              this.snackbar.open(this.adminService.getCloseWord("This coupon is deleted successfully. "), "", {
                duration: 3000,
                horizontalPosition: "center"
              });
              if(e==1){
                this.coupenCurrent(this.offset);
              }
              if(e==2){
                 this.coupenUpcoming(this.offsetU);
              }
              if(e==3){
                 this.coupenExpire(this.offsetE);
              }
              // this.router
              //   .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
              //   .then(() => this.router.navigate(["/package-coupons"]));

            } else if (data["status"] == 10) {
              sessionStorage.clear();
              this.snackbar.open(this.adminService.getCloseWord(
                "Multiple login with this ID has been detected, Logging you out. "),
                "",
                {
                  duration: 3000,
                  horizontalPosition: "center"
                }
              );
              this.router.navigate(["/"]);
            } else {
              this.showLoader = false;

              this.snackbar.open(this.adminService.getCloseWord(
                "Something went wrong please try again. "),
                "",
                {
                  duration: 3000,
                  horizontalPosition: "center"
                }
              );
            }
          },
          error => {
            this.snackbar.open(this.adminService.getCloseWord(
              "Something went wrong due to network, please try again. "),
              "",
              {
                duration: 3000,
                horizontalPosition: "center"
              }
            );
            this.showLoader = false;
          }
        );
    }
  }
 

   fetch_specific_coupon(cup_no,e){
      this.e2=e;
     this.adminService.fetch_particular_package_coupon({user_num:this.user_num,comp_num:this.comp_num,package_cup_no:cup_no,access_token:this.access_token}).subscribe(
          data => {
            if (data["status"] == 1) {

                  var fetch=data['result'];
                  if(fetch!=null || fetch!=''){
      
   
                        this.couponFormU.get('discount_percentage').setValue(fetch.discount_percentage);
                        this.couponFormU.get('max_disc').setValue(fetch.max_disc);
                      this.couponFormU.get('description').setValue(fetch.description);
                      this.couponFormU.get('min_cart_value').setValue(fetch.min_cart_value);
                        this.couponFormU.get('max_no_times').setValue(fetch.max_no_times);
                       this.couponFormU.get('coupon_code').setValue(fetch.coupon_code);

                       this.couponFormU.get('max_no_of_times_per_user').setValue(fetch.max_no_of_times_per_user);
                       this.couponFormU.get('valid_from').setValue(fetch.valid_from);
                       this.couponFormU.get('valid_to').setValue(fetch.valid_to);
                       this.couponFormU.get('package_cup_no').setValue(fetch.package_cup_no);
                       this.valid_fromU=fetch.valid_from;
                       this.valid_toU=fetch.valid_to;
                 }
              
            }
            else if(data['status']==10){
              sessionStorage.clear();
               this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                        duration: 3000,
                        horizontalPosition:'center',
                });      
              this.router.navigate(['/']);
              }
            
            else {
               
              
            }
          },
          error => {
            this.snackbar.open(this.adminService.getCloseWord("Something Went wrong please try again. "), "", {
              duration: 3000
            });
          }
        );
              
 
    }
}


