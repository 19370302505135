


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import {MatSnackBar} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: '_ag-grid-module',
    templateUrl: './button.component.html',
  })
  export class ButtonComponent {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
    orders: any;
    data: any;
    order_status;
    awb_no;
    tracking_url;
    track_more=false;
    courier_method_id;
    status_order_number;
status_product_name;
  total_mrp;
  status_flow=[];
  name: any;
  constructor(
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 private translate: TranslateService
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'md' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
       
    }
  agInit(params: ICellRendererParams): void {
      //.log(params);
    this.params = params;
    this.data = params.value;
    // ////console.log(this.data);
    var data1= params.data;
////console.log(this.data);
////console.log(this.data.track3)
    if(this.params.label =='track'){
        //.log('track');
this.track = true;
this.view = false;
this.invoicenot = false;
this.invoice = false;

    }
    //.log(this.params.value.track1);
     if(this.params.value.track1 =='multi'){
        ////console.log(this.invoice);
        this.track = false;
this.view = false;
this.invoice = true;
this.invoicenot = false;
    }
    if(this.params.value =='to be generated'){
      //.log('invoice1');
      this.track = false;
this.view = false;
this.invoicenot = true;
     
  }
    if(this.params.label =='view'){
        //.log('view');
        this.track = false;
this.view = true;
this.invoicenot = false;
this.invoice = false;
      
    }
    if(this.params.label == 'deactive_member'){
        this.deactive_member=true;
        //.log('deactive');
        this.track = false;
        this.view = true;
        this.invoice = false;
    }
    if(this.params.label == 'delete'){
        this.delete=true;
        //.log('delete');
        this.track = false;
        this.view = true;
        this.invoice = false;
    }
  }
  
 
  sendOrderId(order_id,orderRandomId){
    sessionStorage.setItem('order_id',order_id);
    this.router.navigate(['/home_profile/order-details',orderRandomId]);
}



print_invoice(courier_no,dd){
  let checkboxes=true;
       let post={
          access_token: this.access_token,
          user_num: this.user_num,
          comp_num: sessionStorage.getItem("comp_num"),
          parcel_no:courier_no
        }
         if(dd=="single"){
       let y=[];
       y.push(courier_no);
       post['parcel_no']=y;
         }
         else{
          if(courier_no.length==0){
            checkboxes=false;
              alert(this.getCloseWord("No Data"));
         
          }
          else{
           let invoice_array=[];
  
            let invoice_check=false;
              for(let i=0;i<courier_no.length;i++){
                 
                  for(let s=0;s<invoice_array.length;s++){
                    if(invoice_array[s]==courier_no[i]['parcel_no']){
                      invoice_check=true;
                    }
                  }
                  if(invoice_check==false){
                    invoice_array.push(courier_no[i]['parcel_no']);
                  }
              }
             post['parcel_no']=invoice_array;
          }
         }
         checkboxes=true;
         const formData = new FormData();
         formData.append("comp_num", post.comp_num);
         formData.append("parcel_no", JSON.stringify(post['parcel_no']));
         formData.append("user_num", post.user_num);
         formData.append("access_token", post.access_token);
         if(checkboxes==true){

       this.adminservice.print_invoice(post).subscribe(
         data => {
           
               var blob :any= new Blob([data['body']], { type: "application/pdf" });
               
               ////console.log(data['headers'].get('Content-Disposition'));
               ////console.log(data['headers']);
              //  ////console.log(data.headers.get('content-disposition')[filename]);
               ////console.log(data.headers.get('content-disposition.filename'));
              saveAs(blob,this.data.track3);
              this.snackbar.open(this.getCloseWord("Invoice Download Successfully"), "", {
                duration: 5000
              });
             if (data['headers'].get("content-type").search("pdf") != -1) {
              // var blob = new Blob([data['body']], { type: "application/pdf" });
              // saveAs(blob, "invoice.pdf");
            } else {
  
              this.snackbar.open(this.getCloseWord("NO Data Available"), "", {
                duration: 5000
              });
              // this.router
              //   . navigateByUrl("/", { skipLocationChange: true })
              //   .then(() => this.router.navigate(["/dashboard-reports"]));
            }
  
             
          
}   
,
      error => {
      
      }      
        );
      }
   }


   trackStatus(status,name,order_number,awb_no,courier_method_id,tracking_url){
    this.track_more=false;
  this.status_flow=status;
  this.status_order_number=order_number;
  this.status_product_name=name;
  this.awb_no=awb_no;
  this.courier_method_id=courier_method_id;
  this.tracking_url=tracking_url;
  //.log(this.awb_no);
  }
  getCloseWord(key: string): string {
    var translateValue='';
   this.translate.get(key).subscribe((res: string) => {
     translateValue=res
 });
 return translateValue;
 }
}