import { Component, Input, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { NinetoysserviceService } from '../ninetoysservice.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Router } from '@angular/router';
import { first } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';


@Component({
  selector: 'app-add-company-pickup-address',
  templateUrl: './add-company-pickup-address.component.html',
  styleUrls: ['./add-company-pickup-address.component.css']
})
export class AddCompanyPickupAddressComponent implements OnInit {
  firstFormGroup: FormGroup;
  country_fetch;
  state_fetch;
  city_fetch;
  states;
  cities;
  country;
  verified=false;
  flag=0;
  Company_num;
  pickupAddresses=null;
  registerAddresses=null;
  buisnessInfo=null;
  bankInfo=null;
  regAddForm:FormGroup;
  
 
  countries;
  dataall;
  company=null;
  kyc_data;
  public access_token=sessionStorage.getItem('access_token');
  public user_num=sessionStorage.getItem('user_num');
  public comp_num=sessionStorage.getItem('comp_num');
  public usertype_id = sessionStorage.getItem("usertype_id");
  public address_no = sessionStorage.getItem("address_no");
  public actionform=sessionStorage.getItem('form');
 
  constructor(
    private adminservice: NinetoysserviceService, 
    private _formBuilder: FormBuilder,
    private snackbar:MatSnackBar,
    private router:Router,private location: Location,
    private translate: TranslateService

  ) { }
  ecomtrails = false;
  ecomtrails2 = true;
  ninetoys = false;
  maschile = false;
  mlm = false;
  serverlink;
  receiver_name;
  host_name;
  data
  ngOnInit() {
    if(sessionStorage.getItem('database')=="ecomtrails"){
      this.ecomtrails=true;
    }
    else if(sessionStorage.getItem('database')=="9toys"){
      this.ninetoys=true;
    }

     else if(sessionStorage.getItem('database')=="maschile"){
      this.maschile=true;
    }
     else if(sessionStorage.getItem('database')=="mlm"){
      this.mlm=true;
    }
    else{
      this.ecomtrails=true;
    }

  // let l = location.origin;
  // var c = l.split("//");
  // this.host_name = c[1];
  // let serv = this.host_name;
  // var s = serv.split(".");
  // this.serverlink = s[1];
  //  this.adminservice
  //       .get_host_link({
  //       comp_num : 0
  //       })
  //       .subscribe(datan => {
  //         if(datan['status']==1){
  //           var h= JSON.parse(datan['result']['value']);
  //            this.serverlink=h['host_link'];
  //         }

  //     })
  //  if(this.serverlink == 'ecomtrails'){
  //               this.ecomtrails = true;
  //             }else if(this.serverlink == '9toys'){
  //               this.ninetoys = true;
  //             }else{
  //               this.ecomtrails = true;

  //             }

 

   this.regAddForm = this._formBuilder.group({
    user_num:this.user_num,
    access_token:this.access_token,
    comp_num:this.comp_num,
    address_no:this.address_no,
    country_id:[''],
    state_id:[''],
    city_id:[''],
    line1:[''],
    line2:[''],
    landmark:[''],
    pin_code:[''],
    receiver_name:[''],

    contact_no:[''],
    // receiver_nae:this.receiver_name,
    // pincode:this.pin_code,
    // line:this.line1,
    // lin2:this.line2,
    // email:this.email,
    // contact_num:this.contact_no,
    // countryid:this.country_id,
    // stateid:this.state_id,
    // cityid:this.city_id,
    // land_mark:this.landmark

    
    email:['',[
    Validators.pattern("^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
    ]],
    
   });
 
 
 if (this.actionform=='add'){

 }
 else if (this.actionform=='update'){
 this.getdataupdate();
  
 }


 this.getCountry();
}
getfillform(event){
  let postData = {user_num:this.user_num,access_token:this.access_token,username:event}
  this.adminservice.fetch_pickup_name_email(postData).subscribe(data => {
    this.data = data['result'];
    this.regAddForm.get('email').setValue(data['result'].address.email);
    this.regAddForm.get('contact_no').setValue(data['result'].address.contact_no);
    this.regAddForm.get('line1').setValue(data['result'].address.line1);
    this.regAddForm.get('line2').setValue(data['result'].address.line2);
    this.regAddForm.get('receiver_name').setValue(data['result'].address.receiver_name);
    this.regAddForm.get('pin_code').setValue(data['result'].address.pin_code);
    this.regAddForm.get('landmark').setValue(data['result'].address.landmark);
    this.regAddForm.get('country_id').setValue(data['result'].address.country_id.toString());
    this.getStates((this.regAddForm.get('country_id').value).toString());
    this.regAddForm.get('state_id').setValue(data['result'].address.state_id.toString());
    this.getCity((this.regAddForm.get('state_id').value).toString(),(this.regAddForm.get('country_id').value).toString());
    this.regAddForm.get('city_id').setValue(data['result'].address.city_id.toString());

  });
}

getdataupdate(){
  let postData = {user_num:this.user_num,access_token:this.access_token,comp_num:this.comp_num,address_no:this.address_no}
  this.adminservice.fetchParticularPickupAddressCount(postData).subscribe(data => {
   if (data["status"] == 1) {
    this.dataall = data['result'];
    //this.dataall)
    this.regAddForm.get('receiver_name').setValue(data['result'].receiver_name);
    this.regAddForm.get('pin_code').setValue(data['result'].pin_code);
    this.regAddForm.get('line1').setValue(data['result'].line1);
    this.regAddForm.get('line2').setValue(data['result'].line2);
    this.regAddForm.get('email').setValue(data['result'].email);
    this.regAddForm.get('contact_no').setValue(data['result'].contact_no);
    this.regAddForm.get('landmark').setValue(data['result'].landmark);
    this.regAddForm.get('country_id').setValue(data['result'].country_id);
    this.regAddForm.get('state_id').setValue(data['result'].state_id);
    this.regAddForm.get('city_id').setValue(data['result'].city_id);
    this.regAddForm.get('country_id').setValue((this.regAddForm.get('country_id').value).toString());
    this.regAddForm.get('state_id').setValue((this.regAddForm.get('state_id').value).toString());
    this.regAddForm.get('city_id').setValue((this.regAddForm.get('city_id').value).toString());
    this.getStates((this.regAddForm.get('country_id').value).toString());
    this.regAddForm.get('city_id').setValue((this.regAddForm.get('city_id').value).toString());
    this.getCity((this.regAddForm.get('state_id').value).toString(),(this.regAddForm.get('country_id').value).toString());
    this.regAddForm.get('city_id').setValue((this.regAddForm.get('city_id').value).toString());
    //this.regAddForm.value);
    //this.regAddForm.controls.city_id.value);
  }
  
  else if(data["status"]==10) {

    sessionStorage.clear();
            this.snackbar.open(this.getCloseWord(
              "Multiple login with this ID has been detected, Logging you out."),
              "",
              {
                duration: 2000,
                horizontalPosition: "center"
              }
            );
            this.router.navigate(["/"]);

 }
 else if(data['status']== 0){


 }
},
error => {
   //31);
 this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
   duration: 3000
 });
}
);
}
  getCountry(){
    let postData = {user_num:this.user_num,access_token:this.access_token}
    this.adminservice.getCountry(postData).subscribe(data => {
      this.countries = data['result'];
     
    });
  }

  getStates(country_id){
    this.country=country_id;
    let postData = {user_num:this.user_num,
      access_token:this.access_token,
    country_id: country_id}
  
    this.adminservice.getState(postData).subscribe(data => {
     
      this.states = data['result'];
      
    });
  }
  getCity(state_id,country_id){
    var postData=null; 

    if(country_id==null ){
        postData = {user_num:this.user_num,
      access_token:this.access_token,
      country_id: this.country,
      state_id:state_id}
    }
    else{
        postData = {user_num:this.user_num,
       access_token:this.access_token,
      country_id: country_id,
      state_id:state_id}
    }
   
  
    this.adminservice.getCity(postData).subscribe(data => {
     
      this.cities = data['result'];
    });
  }
  pincode_api(data) {
    let postData = { pin_code: data };
    this.adminservice.pincode_api(postData).subscribe(data => {
      if (data["status"] == 1) {
        if (data["result"]["PostOffice"] != null) {
          this.country_fetch = data["result"]["PostOffice"][0]["Country"];
          this.state_fetch = data["result"]["PostOffice"][0]["State"];
          this.city_fetch = data["result"]["PostOffice"][0]["District"];
          // this.registerForm.get("state").setValue(this.state_fetch);

          // this.registerForm.get("city").setValue(this.city_fetch);

          this.adminservice
            .pincode_country_state_city_api({
              Country: this.country_fetch,
              State: this.state_fetch,
              District: this.city_fetch
            })
            .subscribe(data => {
              if (data["status"] == 1) {
                 this.regAddForm.get("country_id").setValue(data["country"]["country_id"]);
                 this.getStates(data["country"]["country_id"]) ;
                 this.regAddForm.get("state_id").setValue(data["state"]["state_id"]);
                //this.regAddForm.value)

                this.getCity(data["state"]["state_id"],data["country"]["country_id"]);
                this.regAddForm.get("city_id").setValue(data["city"]["city_id"]);

               // data["city"]["city_id"])
               //this.regAddForm.controls.city_id.value);
              
               this.regAddForm.get('country_id').setValue((this.regAddForm.get('country_id').value).toString());
               this.regAddForm.get('state_id').setValue((this.regAddForm.get('state_id').value).toString());
               this.regAddForm.get('city_id').setValue((this.regAddForm.get('city_id').value).toString());
               this.getStates((this.regAddForm.get('country_id').value).toString());
               this.regAddForm.get('city_id').setValue((this.regAddForm.get('city_id').value).toString());
               this.getCity((this.regAddForm.get('state_id').value).toString(),(this.regAddForm.get('country_id').value).toString());
               this.regAddForm.get('city_id').setValue((this.regAddForm.get('city_id').value).toString());
               //this.regAddForm.value);
               //this.regAddForm.controls.city_id.value);
                 
              }
              else if(data["status"]== 10)
              {
                sessionStorage.clear();
            this.snackbar.open(this.getCloseWord(
              "Multiple login with this ID has been detected, Logging you out."),
              "",
              {
                duration: 2000,
                horizontalPosition: "center"
              }
            );
            this.router.navigate(["/"]);
              }
           

            //   this.displayForm
            //  .get("state_id")
            // .setValue((this.pickupAddresses.state_id).toString());
              
            }); 
            
     

        } else {
          // this.regAddForm.get("state").setValue("");

          // this.regAddForm.get("city").setValue("");

          // this.regAddForm.get("state_id").setValue(this.registerAddresses.state_id);
          // this.regAddForm.get("country_id").setValue(this.registerAddresses.country_id);
          // this.regAddForm.get("city_id").setValue(this.registerAddresses.city_id);
        }
        // this.countries = data['result'];
        // this.bankForm.get('ifsc').setValue(this.bankInfo.ifsc);
      }

      else if(data["status"]== 10)
      {
        sessionStorage.clear();
    this.snackbar.open(this.getCloseWord(
      "Multiple login with this ID has been detected, Logging you out."),
      "",
      {
        duration: 2000,
        horizontalPosition: "center"
      }
    );
    this.router.navigate(["/"]);
      }
    });
  }
  update(){
      let data = this.regAddForm.value;
      data.access_token = this.access_token;
      data.user_num = this.user_num;
      data.comp_num = this.comp_num;
      data.account_no=this.address_no

      this.adminservice.update_pickup_address(data)
          .pipe(first())
          .subscribe(
              data => {
            {
                  this.snackbar.open(this.getCloseWord('Successfully update Address'),'' ,{
                    duration: 3000,
                  });
                  this.router.navigate(['//depo-request']);
                }

            });
          
          }
          
  
  onSubmit(){
    if (this.regAddForm.invalid) {
      this.snackbar.open(this.getCloseWord('Please fil required'),'' ,{
        duration: 3000,
        horizontalPosition:'center'
      });
    }   else{
      let data2 = this.regAddForm.value;
      data2.access_token = this.access_token;
      data2.user_num = this.user_num;
      data2.comp_num = this.comp_num;
      this.adminservice.add_pickup_address(data2)
          .pipe(first())
          .subscribe(
              data => {
                if(data['status']== 1){
                  this.snackbar.open(this.getCloseWord('Successfully Add Address'),'' ,{
                    duration: 3000,
                    
                  });
                  
                  this.router.navigate(['//depo-request']);

                }
                else if(data["status"]== 10)
                {
                  sessionStorage.clear();
              this.snackbar.open(this.getCloseWord(
                "Multiple login with this ID has been detected, Logging you out."),
                "",
                {
                  duration: 2000,
                  horizontalPosition: "center"
                }
              );
              this.router.navigate(["/"]);
                }
                
                
                
                else if(data['status']==0){
                  this.snackbar.open(this.getCloseWord('Unable to add'),'' ,{
                    duration: 3000,
                    
                  });

                }
                

            });
          

    
          }
          
  }
  goBack() {
    this.location.back();
  }
  getCloseWord(key: string): string {
    var translateValue='';
   this.translate.get(key).subscribe((res: string) => {
     translateValue=res
 });
 return translateValue;
 }
}
