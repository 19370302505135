
<div class="container m-0" style="    position: relative;
top: -8px;">
<div class="row justify-content-center">
    <div class="col-lg-12 mt-0">
        <div class="row justify-content-between p-2 ">
            <div>
          &nbsp;  <span  (click)="Back()" class="text-left  cursor-pointer m-0 p-0" title="back" >
                <i class="fas fa-arrow-left text-left"></i>
            </span>
        </div>
               
    
        <h5 class="pl-3 font-weight-600">  {{"Profile" | translate}} </h5>
        <div>
            <span  *ngIf="allResult?.buisness_verify==false" class="text-right  cursor-pointer m-0 p-0" title="Update  details" (click)="customer_update()">
                <i class="far fa-edit"></i>
            </span>
            &nbsp;
        
    </div>
       </div>
       <div class="row">
        <div class="col-md-12 mb-2">
            <div class="card">
                <div class="card-header ">
                    <span>{{"Personal Details" | translate}}</span>
                    <span *ngIf="referral_ask==true" class="offset-md-7 justify-content-end pl-2 pr-2 font-weight-500" style="font-size: 14px;"><i class="fas fa-code-branch"></i>&nbsp; My Referral Code : {{buisness_details?.customer_reg_num}}</span>
                </div>
    
           <div *ngIf="buisness_details==null" class="card-body">
            {{"Not found" | translate}}
           </div>
            <div *ngIf="buisness_details!=null" class="card-body">
                <div *ngIf="referral_ask==true && updateAddres?.referral_code !='000000'" class="row mb-0">
                   
                         <div  class="col-md-6" *ngIf="buisness_details?.referral_code !=null">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 class="font-weight-500 "><i class="fas fa-code-branch"></i>&nbsp; {{"Referrer Code" | translate}}</h6>
                                </div>
                                <div class="col-lg-12" >
                                    <h6 class="font-weight-500 box" >&nbsp;{{buisness_details?.referral_code}}</h6>
                                  
                                </div>
                            </div> 
                            
                          
                         </div>
                           <div  class="col-md-6" *ngIf="buisness_details?.referral_name !=null"> 
                               
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 class="font-weight-500">
                                        <i class="fas fa-file-signature"></i>&nbsp;    {{"Referrer Name" | translate}}
                                    </h6>
                                </div>
                                <div class="col-lg-12">
                               
                                <h6 class="font-weight-500 box">
                             <span *ngIf="allResult?.buisness_details?.referral_name !=null">&nbsp;{{allResult?.buisness_details?.referral_name}}</span>  &nbsp;<span *ngIf="allResult?.buisness_details?.referral_last_name !=null">{{allResult?.buisness_details?.referral_last_name}}</span> 
                            </h6>
                        </div>
                                </div>
                        
                        </div>
                     
                    </div>
                
                <div class="row mt-0">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-lg-12">
                                <h6 class="font-weight-500"><i class="fas fa-user-tag"></i>&nbsp; {{"First Name" | translate}} </h6>
                            </div>
                            <div class="col-lg-12">
                                <h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.receiver_name}}</h6>
                            </div>
                        </div>
                     </div>
                    <div class="col-md-6"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <h6 class="font-weight-500"><i class="fas fa-user-tag"></i>&nbsp; {{"Last Name" | translate}} </h6>
                            </div>
                            <div class="col-lg-12">
                                <h6 class="font-weight-500 box">&nbsp; {{updateAddres?.receiver_last_name}}</h6>
                            </div>
                        </div>
                  
                    </div>
                </div>
               <div class="row">
                   <div class="col-md-6">
    
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500"><i class="fas fa-mobile-alt"></i> &nbsp;{{"Mobile No." | translate}} </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.contact_no}}</h6>
                        </div>
                    </div>
                   
                   </div>
                   <div class="col-md-6">
    
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500"><i class="fas fa-envelope-open-text"></i>&nbsp; {{"Email Id" | translate}} </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box"> &nbsp; {{updateAddres?.email}}</h6>
                        </div>
                    </div>
    
    
                </div>
    
               </div>
           
             
               <div class="row">
                   <div class="col-md-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500"><i class="fas fa-street-view"></i> &nbsp;{{"Address" | translate}} </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box pl-3">  <span class="">
                                {{updateAddres?.line1}},{{updateAddres?.line2}},{{updateAddres?.landmark}},{{updateAddres?.city}},{{updateAddres?.state}},{{updateAddres?.country}},{{updateAddres?.pin_code}}
                            </span>  </h6>
                        </div>
                    </div>
    
            </div>
              <div class="col-md-6" *ngIf="updateAddres?.pan !=null && updateAddres?.pan !='' && updateAddres?.pan != 0">
    <div class="row" *ngIf="updateAddres?.pan !=null">
    <div class="col-lg-12 ">
        <h6 class="font-weight-500"> <i class="fas fa-address-card"></i>&nbsp;   {{"PAN" | translate}}  </h6>
    </div>
    
    <div class="col-lg-12"><h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.pan}}</h6></div>
    </div>
    
             </div>


             <div class="col-md-6" *ngIf="updateAddres?.pan ==null || updateAddres?.pan ==''" >
                <div class="row" >
                <div class="col-lg-8 col-9">
                    <h6 class="font-weight-500"> <i class="fas fa-address-card"></i>&nbsp;   {{"PAN" | translate}}  </h6>
                </div>
                <div class="col-lg-3 col-3 text-right">  <span *ngIf="allResult?.buisness_verify==true" class="cursor-pointer " title="Edit pan Card" (click)="customer_update()">
                                <i class="far fa-edit"></i> 
                            </span></div>
                <div class="col-lg-12"><h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.pan}}</h6></div>
                </div>
                
                         </div>

            </div> 
<hr/>
            <div class="row " *ngIf="buisness_details!=null">
                <div class="col-lg-6 p-0">
                    <h5 class="font-weight-600 p-0">&nbsp;&nbsp;{{"Business Details" | translate}}</h5>
                </div>
                <div class="col-lg-6 text-right">  <span *ngIf="allResult?.buisness_verify==true" class="cursor-pointer " title="Update Buisness details" (click)="customer_update()">
                    <i class="far fa-edit"></i> 
                </span></div>
            </div>
            <hr/>
            <div class="row mt-2" *ngIf="buisness_details!=null">
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-md-12"><h6   class="font-weight-500 "><i class="fas fa-user-tie"></i>&nbsp; {{"Buisness Name" | translate}} </h6></div>
                        <div class="col-md-12"><h6 *ngIf="buisness_details?.buisness_name==null"  class="font-weight-500 box1 "> &nbsp; </h6><h6 *ngIf="buisness_details?.buisness_name!=null" class="box1"> &nbsp;{{buisness_details?.buisness_name}}</h6> </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="col-md-12">  <h6  class="font-weight-500"><i class="fas fa-address-book"></i>&nbsp; {{"GST" | translate}}  </h6></div>
                    <div class="col-md-12"> <h6 *ngIf="buisness_details?.gst !=null" class="font-weight-500 box1"> &nbsp;{{buisness_details?.gst}}</h6>
                     <h6 *ngIf="buisness_details?.gst ==null" class="font-weight-500 box1">&nbsp; </h6></div>
                </div>
                <div class="col-lg-4">
                    <div class="col-md-12">
                        <h6  class="font-weight-500"><i class="fas fa-address-card"></i>&nbsp;{{"TAN" | translate}}  </h6>
                    </div>
                    <div class="col-md-12">
                        <h6 *ngIf="buisness_details?.tan !=null" class="font-weight-500 box1"> &nbsp;{{buisness_details?.tan}}</h6>
                    <h6 *ngIf="buisness_details?.tan ==null" class="font-weight-500 box1">&nbsp; </h6>
                    </div>
                </div>
            </div>
<hr/>
            <div class="row" *ngIf="buisness_details!=null">
                <div class="col-lg-6">
                    <h5 class="font-weight-600">
                        &nbsp;{{"Bank Details" | translate}}
                    </h5>
                </div>
                <div class="col-lg-6 text-right">
                    <span *ngIf="allResult.bank_status==false" class="text-right  cursor-pointer m-0 p-0" title="Update bank details" (click)="bankU()">
                        <i class="far fa-edit"></i>
                    </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <hr/>

            <div class="row" *ngIf="buisness_details!=null">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500">
                                <i class="fas fa-id-card"></i>&nbsp;  {{"Account Holder Name" | translate}}
                            </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box"*ngIf="bank_details?.account_name !=null">&nbsp;{{bank_details?.account_name}}</h6>
                            <h6 class="font-weight-500 box"*ngIf="bank_details?.account_name ==null">&nbsp;</h6>
                        </div>
                    </div>
                </div>  
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500">
                                <i class="fas fa-university"></i>&nbsp;   {{"Bank Name" | translate}}
                            </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_name !=null">
                                 &nbsp; {{bank_details?.bank_name}}
                            </h6>
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_name ==null">
                                &nbsp; 
                           </h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500">
                                <i class="fab fa-quinscape"></i>&nbsp;  {{"IFSC Code" | translate}}
                            </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.ifsc !=null">
                               &nbsp; {{bank_details?.ifsc}}
                            </h6>
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.ifsc ==null">
                                &nbsp; 
                             </h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500">
                                <i class="far fa-dot-circle"></i>&nbsp;    {{"Account No." | translate}}
                            </h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.account_no !=null">
                              &nbsp;  {{bank_details?.account_no}}
                            </h6>
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.account_no ==null">
                                &nbsp;  
                              </h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500"><i class="fas fa-code-branch"></i>&nbsp; {{"Branch Name" | translate}}</h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_address !=null">
                               &nbsp; {{bank_details?.bank_address}}
                            </h6>
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_address ==null">
                                &nbsp; 
                             </h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-500"><i class="fas fa-id-badge"></i>&nbsp; {{"UPI Id" | translate}}</h6>
                        </div>
                        <div class="col-lg-12">
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.upi !=null">
                               &nbsp; {{bank_details?.upi}}
                            </h6>
                            <h6 class="font-weight-500 box" *ngIf="bank_details?.upi ==null">
                                &nbsp; 
                             </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    
    </div>
    </div>
</div>

  

</div>