// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-host-routes',
//   templateUrl: './host-routes.component.html',
//   styleUrls: ['./host-routes.component.css']
// })
// export class HostRoutesComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }


import {Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NinetoysserviceService } from '../ninetoysservice.service';
import {MatSnackBar} from '@angular/material';
import {MatTableDataSource} from '@angular/material/table';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

export interface dataSource {
  sno: string;
  name:string;
  in_url: string;
  out_url:string;
  update: string;
  delete: string;

}
export interface dataSource2 {
  sno: string;
  brand: string;
  image: string;
  // update: string;
  // delete: string;
}

@Component({
  selector: 'app-host-routes',
  templateUrl: './host-routes.component.html',
  styleUrls: ['./host-routes.component.css']
})

export class HostRoutesComponent implements OnInit {
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
 method='add';
 is_confirm=false;
 data=[];
 actual_data=[];
  public access_token=sessionStorage.getItem('access_token');
  public user_num=sessionStorage.getItem('user_num'); 
  public comp_num = sessionStorage.getItem('comp_num'); 
  public usertype_id = sessionStorage.getItem('usertype_id');
  branddata;
//  sort;
  brandsno=0;
  branddata3;
  branddataTest=[];
  branddata2;
  dataSource;
   dataSource2;
  imgHeight=0;
  imgWidth=0;
  // displayedColumns: string[] = ['sno','image','brand', 'addition_details','update','delete'];
displayedColumns: string[] = ['sno','name','in_url','out_url', 'edit','delete'];
displayedColumns2: string[] = ['sno1','image1','brand1','about_brand1', 'addition_details1'];
@ViewChild(MatSort, {static: true}) sort: MatSort;
  filteredItems;
  searchtext='';
  closeResult: string;
  registerForm:FormGroup;
   
   resV1=false;
   datam;
  brand_products ;    
           
 


  constructor(
    private snackbar:MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cookie: CookieService,
    private adminService: NinetoysserviceService,
    private modalService: NgbModal
  ) { }
 open(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
       
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
      openXl(content) {
      this.modalService.open(content, { size: 'md' });
    }
     private getDismissReason(reason: any): string { 
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }
 ecomtrails = false;
 maschile = false;
 mlm = false;
 ecomtrails2 = true;
  ninetoys = false;
  serverlink;
  host_name;

  ngOnInit() {
   
    
  // let l = location.origin;
  //   var c = l.split("//");
  //   this.host_name = c[1];
  //   let serv = this.host_name;
  //   var s = serv.split(".");
  //   this.serverlink = s[1];
  //    this.adminService
  //         .get_host_link({
  //         comp_num : 0
  //         })
  //         .subscribe(datan => {
  //           if(datan['status']==1){
  //             var h= JSON.parse(datan['result']['value']);
  //              this.serverlink=h['host_link'];
  //           }

  //       })
  //    if(this.serverlink == 'ecomtrails'){
  //       this.ecomtrails = true;
  //     }else if(this.serverlink == '9toys'){
  //       this.ninetoys = true;
  //     }else{
  //       this.ninetoys = true;

  //     }
     
      if(sessionStorage.getItem('database')=="ecomtrails"){
        this.ecomtrails=true;

         // this.mlm=true;
      }
      else if(sessionStorage.getItem('database')=="9toys"){
        this.ninetoys=true;
      }

      else if(sessionStorage.getItem('database')=="maschile"){
        this.maschile=true;
      }
      else if(sessionStorage.getItem('database')=="mlm"){
        this.mlm=true;
      }
      else{
        this.ecomtrails=true;
      }
     
    this.registerForm = this.formBuilder.group({
      user_num:this.user_num,
      access_token:this.access_token,
comp_num:[''],
      out_url : ['' ,[
          Validators.pattern(
            "^(https://emallnet.com)*$"
          )
        ]],
      id:[''],
      in_url:['']
     });
   
      this.datam={access_token:this.access_token,user_num:this.user_num};
   
    this.adminService.fetch_all_host(this.datam).subscribe(data=>{//for ecom
    // this.adminService.FetchBrand(this.datam).subscribe(data=>{//for 9toys
      if(data['status']==1){
      // this.branddataTest = data['result'];
      // if(((this.usertype_id=='2' || this.comp_num=='0') && this.mlm==false) || ( this.mlm==true && this.comp_num=='0')){
        this.branddata = data['result'];
         for(let i=0;i<this.branddata.length;i++){
          this.branddata[i].sno=i+1;
        }   
       // }
       // else{
       //     this.branddata3=data['result'];
       //    for(let n=0;n<this.branddata3.length;n++){
       //      if(this.branddata3[n].status=='Y'){
       //      //
       //      this.brandsno++;
       //      this.branddata3[n].sno=this.brandsno;
       //        this.branddataTest.push(this.branddata3[n]);
       //         // this.branddataTest[n].sno=n+1;

       //      }
         
             
        // } 
        // this.branddata=this.branddataTest;

       // }  
        // this.filteredItems=data['result']; 
         
        
        this.dataSource = new MatTableDataSource(this.branddata);
        
        
        this.dataSource.sort = this.sort;
        this.dataSource2 = new MatTableDataSource(this.branddata);
        this.dataSource2.sort = this.sort;
        
      }
      else if(data['status']==10){
      sessionStorage.clear();
       this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                duration: 3000,
                horizontalPosition:'center',
        });      
      this.router.navigate(['/']);
      }
      else{

      }
    },
    error=>{
        // this.loading = false;
      }
    );
  this.companyList();
  }

 action(method){
 	this.method='add';
 	  this.registerForm.get('in_url').setValue('');
       this.registerForm.get('out_url').setValue('');
       // this.registerForm.get('in_url').setValue(in_url);
     
 }
  getBrandDetails(id,in_url,out_url,comp_num,method){
  	this.method='update';
    this.registerForm.get('id').setValue(id);
     
     if(in_url !=null && in_url !='null' && in_url !=''){
      this.registerForm.get('in_url').setValue(in_url);
       
      }
       if(out_url!=null && out_url!='null' && out_url!=''){
        this.registerForm.get('out_url').setValue(out_url);
      
      }
        this.registerForm.get('comp_num').setValue(comp_num);
      
  }
  get f() { 
    return this.registerForm.controls; 
  }
  
   // deleting the brand
    deletingBrand(brand_id){
      this.resV1=false;
      var res = confirm(this.adminService.getCloseWord("Are you sure you want to delete this."));
      this.resV1=false;
      if(res){
         this.resV1=false;
       
       this.adminService.delete_host({access_token:this.access_token,user_num:this.user_num,id: brand_id}).subscribe(data=>{
        
         if(data['status']==1){ 
            this.modalService.dismissAll('Save click');
           this.snackbar.open(this.adminService.getCloseWord('This is deleted successfully. '),'' ,{
             duration: 3000,
             horizontalPosition:'center',
         }); 
         this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
         this.router.navigate(['//host-routes']));          
           
         }
         else if(data['status']==10){
         sessionStorage.clear();
          this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                   duration: 3000,
                   horizontalPosition:'center',
           });      
         this.router.navigate(['/']);
         }
        
         else{
           this.snackbar.open(this.adminService.getCloseWord('Something Went wrong please try again.'),'' ,{
             duration: 3000,
             horizontalPosition:'center',
           }); 
   
         }
       },
       error=>{
           // this.loading = false;
         }
       );
 
      }
     }

  onSubmit(){
        if (this.registerForm.invalid) {
           let string=this.registerForm.controls.out_url.value;
  let substring='https://emallnet.com';
//this.registerForm.controls.out_url.value);
let k=string.startsWith(substring);
//k);
if(!k){
   this.snackbar.open(this.adminService.getCloseWord('Output URL must start with ')+substring,'' ,{
                      duration: 3000,
                      horizontalPosition:'center',
                    });
  return;
}
else{
   this.snackbar.open(this.adminService.getCloseWord('* fields are required.'),'' ,{
                      duration: 3000,
                      horizontalPosition:'center',
                    });
          return; 
}
         
      }   
  
      let data2 = this.registerForm.value;
     
   //data2);
   if(this.method=='update'){
  this.adminService.update_host(data2)
          .pipe(first())
          .subscribe(
              data2 => {
                  if (data2['status']==1) {
                    this.snackbar.open(this.adminService.getCloseWord('Updated Successfully '),'' ,{
                      duration: 3000,
                      horizontalPosition:'center',
                    });
                    
                    this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["/host-routes"]));             
                             
                          

                  }else {
                    this.snackbar.open(this.adminService.getCloseWord('Unable to update.'),'' ,{
                      duration: 3000,
                      
                    });
                    
                  }
              },
              error => {
                  this.snackbar.open(this.adminService.getCloseWord('Something Went wrong please try again. '),'' ,{
                    duration: 3000,
                    
                  });
                  
              });
   }
   	else{
   		  this.adminService.insert_host(data2)
          .pipe(first())
          .subscribe(
              data2 => {
                  if (data2['status']==1) {
                    this.snackbar.open(this.adminService.getCloseWord('Added Successfully '),'' ,{
                      duration: 3000,
                      horizontalPosition:'center',
                    });
                    
                    this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["/host-routes"]));             
                             
                          

                  }else {
                    this.snackbar.open(this.adminService.getCloseWord('Unable to add.'),'' ,{
                      duration: 3000,
                      
                    });
                    
                  }
              },
              error => {
                  this.snackbar.open(this.adminService.getCloseWord('Something Went wrong please try again. '),'' ,{
                    duration: 3000,
                    
                  });
                  
              });

   	}
    
  }
    
 

   applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
       
        
      }
   
companyList(){
	this.adminService.get_company_all_with_kyc({user_num : this.user_num,access_token : this.access_token}).subscribe(
      data=>{
        if(data['status'] == 1){
         
         var z=0;
          this.data = data['result'];

          for(let count=0;count<this.data.length;count++){
             if(this.data[count].verify==true){
             	z++;
             	if(z==1){
             		this.registerForm.get('comp_num').setValue(this.data[count].comp_num);
             	}
               // this.actual_data[count].display_name=this.data[count]?.current?.displayInfo?.display_name;
          this.actual_data.push({comp_num:this.data[count].comp_num,name:this.data[count]?.current?.displayInfo?.display_name});
            }
           
           
          
          }
          //this.actual_data);
          
        }
        else if(data['status'] == 0){
         
        }
        else if(data['status'] == 10){
          sessionStorage.clear();
          this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
            duration: 3000,
            horizontalPosition:'center',
              });      
          this.router.navigate(['/']);
        }
       
      },
      error => {
        this.snackbar.open(this.adminService.getCloseWord("Failed Try Again"), this.adminService.getCloseWord("Ok"), {
          duration: 3000
        });
        this.router.navigate(["/Admin"]);
});
}
    
checkConfirmPassword() {
  let string=this.registerForm.controls.out_url.value;
  let substring='https://emallnet.com';
//this.registerForm.controls.out_url.value);
let k=string.startsWith(substring);
//k);
    if(k){
      this.is_confirm=false;
    }
    else{
      this.is_confirm=true;
    }
  }

}
