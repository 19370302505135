<p class="ribbon" >
  <span class="text cursor-pointer"  routerLink="/package-checkout"><strong class="bold">{{"Want to upgrade company package" | translate}} &nbsp;&nbsp;</strong> &nbsp;  <button 
    mat-raised-button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
    class="order-button " 
     routerLink="/package-checkout"
  >
    {{"Upgrade" | translate}} 
  </button> &nbsp;</span>
</p>
 
 
<div class="container-fluid mt-2" *ngIf="method=='add'">
  <div class="row justify-content-center">
    <div class="col-md-8">
       


      <div class="row ml-2">
        <h2 class=" product-border-heading " [style.color]="headingcolor">
          {{"Add Package" | translate}}
        </h2>
      </div>
      <div class="card background mt-2">
        <form [formGroup]="regAddForm">

          <div class="container" >
            <div class=" mt-3">

                 <div class="row">
              <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Package Name" | translate}}</mat-label>
                    <input formControlName="package_name" matInput placeholder="Package Name" required>
                    <i class="fas fa-file-signature" matSuffix></i>
                    <mat-error ngxErrors="regAddForm.package_name">
                      <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
                    </mat-error>

                  </mat-form-field>

                </div>
                 <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Price" | translate}}</mat-label>
                    <input type="number" formControlName="price" matInput placeholder="Price" required>
                    <i class="fas fa-rupee-sign" matSuffix></i>
                    <mat-error ngxErrors="regAddForm.price">
                      <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
                    </mat-error>

                  </mat-form-field>

                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Product Count" | translate}}</mat-label>
                    <input type="number" matInput placeholder="Product Count" formControlName="product_count" required>
               
                    <i class="fab fa-contao" matSuffix></i>
                    <mat-error ngxErrors="regAddForm.product_count">
                      <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Order Count" | translate}}</mat-label>
                    <input type="number" matInput placeholder="Order Count" formControlName="order_count" required>
                    <i class="fab fa-contao" matSuffix></i>
                    <mat-error ngxErrors="regAddForm.order_count">
                      <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
                    </mat-error>
                  </mat-form-field>
                </div>
             
                   <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-full-width">
                    
                    <mat-label>{{"Expiry period" | translate}}</mat-label>
                    <input formControlName="monthly_period" type="number" matInput placeholder="Expiry period" required>
                     <span matSuffix>{{"Month" | translate}}</span>
                    <mat-error ngxErrors="regAddForm.monthly_period">
                      <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
                    </mat-error>

                  </mat-form-field>
                </div>
                                </div>
                </div>


                 <div class="col-md-12">
                  <div class="row">
                    <div class="col-4 " >
               
               <div class="row">
                <div class="col-lg-9">{{"Package Checkout" | translate}} &nbsp;</div>
                <div class="col-lg-3"><mat-slide-toggle formControlName="allowed_checkout" required></mat-slide-toggle></div>
              </div>
                </div>
                    <div class="col-4">
                
                  <div class="row">
                    <div class="col-lg-9">{{"Web Publish" | translate}} &nbsp;</div>
                    <div class="col-lg-3"><mat-slide-toggle formControlName="web_publish" required></mat-slide-toggle></div>
                  </div>
                </div>
                 <div class="col-4">
                 
                  <div class="row">
                    <div class="col-lg-9">{{"Sub-User" | translate}} &nbsp;</div>
                    <div class="col-lg-3"><mat-slide-toggle formControlName="sub_user" required></mat-slide-toggle></div>
                  </div>
                </div>
                 <div class="col-4">
                  <div class="row">
                    <div class="col-lg-9">{{"Commission" | translate}} &nbsp;</div>
                    <div class="col-lg-3"><mat-slide-toggle formControlName="commission" required></mat-slide-toggle></div>
                  </div>
                
                </div>
              
              
                    <div class="col-4">
                      <div class="row">
                        <div class="col-lg-9">{{"Online Payment" | translate}} &nbsp;</div>
                        <div class="col-lg-3"><mat-slide-toggle formControlName="online_payment" required></mat-slide-toggle></div>
                        
                      </div>
               
                </div>
                 <div class="col-4">
                   <div class="row">
                     <div class="col-lg-9">{{"Coupon" | translate}} &nbsp;</div>
                     <div class="col-lg-3"><mat-slide-toggle formControlName="coupen" required></mat-slide-toggle></div>
                   </div>
                  
                </div>
              
                 <div class="col-4">
                  <div class="row">
                    <div class="col-lg-9">{{"Member" | translate}} &nbsp;</div>
                    <div class="col-lg-3"><mat-slide-toggle formControlName="member" required></mat-slide-toggle></div>
                  </div>
             
                </div>

                  <div class="col-4">
                    <div class="row">
                      <div class="col-lg-9">{{"Shipping" | translate}} &nbsp;</div>
                      <div class="col-lg-3"><mat-slide-toggle formControlName="shipping" required></mat-slide-toggle></div>
                    </div>
                
                </div>
               
                </div>


              <br>
             
              
              <div class="row justify-content-center">
                <div class="col-md-4">
                  <button *ngIf="method=='update'" class="order-button " mat-raised-button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
                    matTooltip="Update " (click)="onSubmit();">{{"Update" | translate}}</button>
                  <button *ngIf="method=='add'" class="order-button" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" mat-raised-button matTooltip="Add"
                    (click)="onSubmit();">{{"Add" | translate}}</button>

                     &nbsp;<button  class="order-button " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" mat-raised-button matTooltip="Back"
                    (click)="action('fetch')">{{"Cancel" | translate}}</button>
                </div>
                <br>
                <br>
                <br>

               

              </div>

              <div class="row">
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div>
  <div *ngIf="method=='fetch'">
    <div class="container-fluid">
      <div class="container-fluid">
        <div class="row mb-2 shadow-sm pt-3 pb-3 bg-white">
          <div class="col-sm-8">
            <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
              {{"Package" | translate}}
            </h2>
          </div>
          <div class="col-md-4 text-right ">

            <button mat-raised-button matTooltip="Add Package" (click)="action('add')"
              class="text-right order-button border-border-info  text-white mr-1" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
              {{"Add Package" | translate}}
            </button>


          </div>

        </div>

      </div>

    </div>
   

    <div class="mat-card p-0" style="height: 480px; overflow: scroll; background: #f7f7f7;">
      <div fxLayout="row wrap">
        <div fxFlex="32%" style="    margin-left:1%;" *ngFor="let address of packagedata;let index=index">
          <mat-card style="padding-left: 7px;

    padding-right: 7px; 
    padding-bottom: 2px; 
    padding-top: 5px;
    margin-top: 24px;     
    /* margin-left: 0.2em; */
    background: #fff" class="shadow">
            <div class=" d-flex border-bottom justify-content-between mt-0">
              <div class="m-0 product-border-heading" [style.color]="headingcolor" style="font-size: 14px; font-weight: 600; letter-spacing: 1px;">
                {{"Package Details" | translate}} :
              </div>
              <div class="dropdown dropleft">
                <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-ellipsis-v cursor-pointer " style="color: brown;" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item cursor-pointer"
                    (click)="updateaction( address.package_id,address.price,address.product_count,address.web_publish
        ,address.commission,address.sub_user,address.member,address.coupen,address.online_payment,address.shipping,address.package_name,address.monthly_period,'update',address.order_count )"><i
                      class="fas fa-pencil-alt text-success"></i> {{"Edit" | translate}}</a>
                  <a *ngIf="address.package_id!=1 && address.package_id!='1'" class="dropdown-item cursor-pointer" (click)="removePackage(address.package_id)"><i
                      class="fas fa-trash-alt text-danger"></i> {{"Delete" | translate}}</a>

                </div>
              </div>
            </div>
<!-- start package -->
 
         
            <div class="row mt-1">

              <h6 class="col-md-12">{{"Package Name" | translate}} : {{ address?.package_name }}</h6>
              <h6 class="col-md-6">{{"Price" | translate}} : {{ address?.price | currency:'INR'}}</h6>
           
               <h6  *ngIf="address?.order_count !=-1"class="col-md-6" >{{"Order Count" | translate}} : {{ address?.order_count }}</h6>
                 <h6  *ngIf="address?.order_count ==-1"class="col-md-6" >{{"Order Count" | translate}} : {{"Unlimited" | translate}}</h6>
              
              <h6 *ngIf="address?.product_count !=-1" class="col-md-6">{{"Product Count" | translate}} : {{ address?.product_count }}</h6>
                <h6 *ngIf="address?.product_count ==-1" class="col-md-6" >{{"Product Count" | translate}} : {{"Unlimited" | translate}}</h6>
           
              <div class="col-md-6">
              <h6 class="font-weight-500">{{"Web Publish" | translate}} : &nbsp; 
              <span *ngIf="address?.web_publish=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.web_publish=='N' "><i class="fas fa-times  text-danger"></i></span>
</h6>
</div>
 <div class="col-md-6">
              <h6 class="font-weight-500" >{{"Commission" | translate}} : &nbsp;
                 <span *ngIf="address?.commission=='Y'"> <i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.commission=='N' "> <i class="fas fa-times  text-danger"></i></span>
            </h6>
</div>

          
               <div class="col-md-6">
              <h6 class="font-weight-500">{{"Subuser" | translate}} :&nbsp;
<span *ngIf="address?.sub_user=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.sub_user=='N' "><i class="fas fa-times  text-danger"></i></span>
             </h6>
           </div>
              <div class="col-md-6">
              <h6 class="font-weight-500">{{"Member" | translate}} :&nbsp;
                  <span *ngIf="address?.member=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.member=='N' "><i class="fas fa-times  text-danger"></i></span>
              </h6>
</div>
           
               <div class="col-md-6">
              <h6 class="font-weight-500">{{"Coupon" | translate}} :&nbsp;
                  <span *ngIf="address?.coupen=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.coupen=='N' "><i class="fas fa-times  text-danger"></i></span>
             </h6>
           </div>
            <div class="col-md-6">
              <h6 class="font-weight-500" >{{"Online Payment" | translate}} :&nbsp;
                <span *ngIf="address?.online_payment=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.online_payment=='N' "><i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>

          
               <div class="col-md-6">
              <h6 class="font-weight-500">{{"Shipping" | translate}}: &nbsp;
               <span *ngIf="address?.shipping=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.shipping=='N' "><i class="fas fa-times text-danger"></i></span>
            </h6>
          </div>
           <div class="col-md-6">
              <h6 class="font-weight-500" >{{"Expiry period" | translate}}:-
                <span *ngIf="address?.monthly_period==0 || address?.monthly_period=='0'">{{"Today" | translate}}</span>
                <span *ngIf="address?.monthly_period==-1 || address?.monthly_period=='-1'">{{"Unlimited" | translate}}</span>
                <span *ngIf="address?.monthly_period!=0 && address?.monthly_period!='0' && address?.monthly_period!=-1 && address?.monthly_period!='-1'">{{ address?.monthly_period }} Month</span>
            </h6>
          </div>

              <div class="col-md-6">
              <h6 class="font-weight-500" >{{"Package Checkout" | translate}}: &nbsp;
                <span *ngIf="address?.allowed_checkout=='Y'"><i class="fas fa-check  text-success"></i></span>
                <span *ngIf="address?.allowed_checkout=='N' "><i class="fas fa-times text-danger"></i></span>
            </h6>
          </div> 

            </div>

            <!-- 
            <div class="row pl-3">

              <h6 class="font-weight-500 col-6">Package Name:-{{ address.package_name }}</h6>
               <h6 class="font-weight-500 col-6">Price:-{{ address.price }}<i class="fas fa-rupee-sign"></i></h6>
            </div>

            <div class="row pl-3">
             
              <h6 class="font-weight-500 col-6" >{{"Product Count" | translate}}:-{{ address.product_count }}</h6>

              <h6 class="font-weight-500 col-6" >{{"Order Count" | translate}}:-{{ address.order_count }}</h6>
            </div>
           
            <div class="row pl-3">
              <h6 class="font-weight-500">{{"Web Publish" | translate}}:-{{ address.web_publish }}</h6>
              <h6 class="font-weight-500" style="margin-left:19%;">Commission:-{{ address.commission }}</h6>

            </div>
            <div class="row pl-3">
              <h6 class="font-weight-500">Subuser:-{{ address.sub_user }}</h6>
              <h6 class="font-weight-500" style="margin-left:27%;">Member:-{{ address.member }}</h6>

            </div>
            <div class="row pl-3">
              <h6 class="font-weight-500">{{"Coupon" | translate}}:-{{ address.coupen }}</h6>
              <h6 class="font-weight-500" style="margin-left:28%;">Online Payment:-{{ address.online_payment }}</h6>

            </div>
            <div class="row pl-3">
              <h6 class="font-weight-500">Shipping:-{{ address.shipping }}</h6>
              <h6 class="font-weight-500" style="margin-left:26%;">{{"Expiry period" | translate}}:-{{ address.monthly_period }} Month</h6>

              

            </div> -->

          </mat-card>

        </div>
      </div>
    </div>

  </div>
</div>
<div class="container-fluid" *ngIf="method=='update'">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="row ml-2">
        <h2 class=" product-border-heading ">
          {{"Update Package" | translate}}
        </h2>
      </div>
      <div class="card background mt-2">
        <form [formGroup]="regAddForm">
<div class="container" style="height:500px;">
   <div class=" mt-3">
      <div class="row">
         <div class="col-md-6">
            <mat-form-field appearance="fill" class="example-full-width">
               <mat-label>{{"Package Name" | translate}}</mat-label>
               <input formControlName="package_name" matInput placeholder="Package Name" required>
               <!-- <i class="fas fa-rupee-sign" matSuffix></i> -->
               <mat-error ngxErrors="regAddForm.package_name">
                  <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
               </mat-error>
            </mat-form-field>
         </div>
         <div class="col-md-6">
            <mat-form-field appearance="fill" class="example-full-width">
               <mat-label>{{"Price" | translate}}</mat-label>
               <input type="number" formControlName="price" matInput placeholder="Price" required>
               <i class="fas fa-rupee-sign" matSuffix></i>
               <mat-error ngxErrors="regAddForm.price">
                  <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
               </mat-error>
            </mat-form-field>
         </div>
      </div>
      <div class="row">
         <div class="col-md-6">
            <mat-form-field appearance="fill" class="example-full-width">
               <mat-label>{{"Product Count" | translate}}</mat-label>
               <input type="number" matInput placeholder="Product Count" formControlName="product_count" required>
               <mat-icon matSuffix>recent_actors</mat-icon>
               <mat-error ngxErrors="regAddForm.product_count">
                  <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
               </mat-error>
            </mat-form-field>
         </div>
         <div class="col-md-6">
            <mat-form-field appearance="fill" class="example-full-width">
               <mat-label>{{"Order Count" | translate}}</mat-label>
               <input type="number" matInput placeholder="Order Count" formControlName="order_count" required>
               <mat-icon matSuffix>recent_actors</mat-icon>
               <mat-error ngxErrors="regAddForm.order_count">
                  <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
               </mat-error>
            </mat-form-field>
         </div>
      </div>
      <div class="row">
         <div class="col-md-6">
            <mat-form-field appearance="fill" class="example-full-width">
               <mat-label>{{"Expiry period" | translate}}</mat-label>
               <input formControlName="monthly_period" type="number" matInput placeholder="Expiry period" required>
               <span matSuffix>{{"Month" | translate}}</span>
               <mat-error ngxErrors="regAddForm.monthly_period">
                  <p ngxError="required" [when]="['dirty', 'touched']">{{"Please fill" | translate}}</p>
               </mat-error>
            </mat-form-field>
         </div>
      </div>
   </div>
   <div class="col-md-12">
      <div class="row">
         <div class="col-3 row" >
            {{"Allowed Package Checkout" | translate}} &nbsp;
            <mat-slide-toggle formControlName="allowed_checkout" [checked]="regAddForm.controls.allowed_checkout.value=='false'?true:false "></mat-slide-toggle>
         </div>
         <div class="col-3">
            {{"Web Publish" | translate}} &nbsp;
            <mat-slide-toggle formControlName="web_publish"
               [checked]="regAddForm.controls.web_publish.value=='false'?true:false ">
            </mat-slide-toggle>
         </div>
         <div class="col-3">
            {{"Sub-User" | translate}} &nbsp;
            <mat-slide-toggle formControlName="sub_user"
               [checked]="regAddForm.controls.sub_user.value=='false'?true:false "></mat-slide-toggle>
         </div>
         <div class="col-3">
            {{"Commission" | translate}} &nbsp;
            <mat-slide-toggle formControlName="commission"
               [checked]="regAddForm.controls.commission.value=='false'?true:false "></mat-slide-toggle>
         </div>
      </div>
      <div class="row">
         <div class="col-3">
            {{"Online Payment" | translate}} &nbsp;
            <mat-slide-toggle formControlName="online_payment"
               [checked]="regAddForm.controls.online_payment.value=='false'?true:false "></mat-slide-toggle>
         </div>
         <div class="col-3">
            {{"Coupon" | translate}} &nbsp; 
            <mat-slide-toggle formControlName="coupen"
               [checked]="regAddForm.controls.coupen.value=='false'?true:false "></mat-slide-toggle>
         </div>
         <div class="col-3">
            {{"Member" | translate}} &nbsp;
            <mat-slide-toggle formControlName="member"
               [checked]="regAddForm.controls.member.value=='false'?true:false "></mat-slide-toggle>
         </div>
         <div class="col-3">
            {{"Shipping" | translate}} &nbsp;
            <mat-slide-toggle formControlName="shipping"
               [checked]="regAddForm.controls.shipping.value=='false'?true:false ">
               <!-- {{shipping ? 'YES' : 'NO'}} -->
            </mat-slide-toggle>
         </div>
      </div>
      <br>
      <div class="row">
         <div class="col-md-4">
            <button *ngIf="method=='update'" class="order-button text-white" mat-raised-button
            matTooltip="Update " (click)="onSubmit();">{{"Update" | translate}}</button>
            <button *ngIf="method=='add'" class="order-button text-white" mat-raised-button matTooltip="Add"
            (click)="onSubmit();">{{"Add" | translate}}</button>
            &nbsp;<button  class="order-button text-white" mat-raised-button matTooltip="Back"
               (click)="action('fetch')">{{"Cancel" | translate}}</button>
         </div>
         <br>
         <br>
         <br>
      </div>
      <div class="row">
        <!--  <div class="row">
                <div class="col-md-4">
                  <button *ngIf="method=='update'" class="order-button text-white" mat-raised-button
                    matTooltip="Update " (click)="onSubmit();">Update</button>
                  <button *ngIf="method=='add'" class="order-button text-white" mat-raised-button matTooltip="Add"
                    (click)="onSubmit();">Add</button>
                    &nbsp;
                     <button class="order-button text-white" mat-raised-button
                    matTooltip="Back " (click)="action('fetch');">{{"Cancel" | translate}}</button>
                </div>
      </div> -->
   </div>
</div>
</div>
</form>
        <!-- </form> -->
      </div>
    </div>
  </div>
</div>