


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'button-view-comapny-list',
    templateUrl: './button-view-company-list.component.html',
  })
  export class ButtonViewCompanyListComponent {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
    data: any;
    deactivate=false;
    activate=false;
    not_verified=false;
 
  constructor(
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'md' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
       
    }
  agInit(params: ICellRendererParams): void {
      //params);
    this.params = params;
    this.data = params.value;
  //this.data);
    //.log(this.data.id);
    var data1= params.data;
    

    if(this.data.vate =='verified'){
        //.log('track');
this.deactivate = true;

    }
    if(this.data.vate =='not verified'){
        this.not_verified = true;
    }
    if(this.params.label =='view'){
        this.view = true;


    }
  }
  viewmore(comp_num){
    sessionStorage.setItem('comp_id',comp_num);
    this.router.navigate(["/company-profile/", comp_num]);

  }
  verify_deverify_company(comp_num,method){
      
    var i=0;
    if(method=='1'){
      i=1;
      var res = confirm("Are you sure you want to Activate this company.");
    
    }
    else{
      var res = confirm("Are you sure you want to Deactivate this company.");
    
    }
     if(res){
    var post={user_num : this.user_num,
      access_token : this.access_token,
      comp_num: comp_num,
      verify:i
    };
     this.adminservice.verify_deverify_company(post).subscribe(
        data=>{
          if(data['status'] == 1){
            if(method=='1'){
               this.snackbar.open(this.adminservice.getCloseWord('Company Activated Successfully '),'' ,{
              duration: 3000,
              horizontalPosition:'center',
                });  
            }
            else{
               this.snackbar.open(this.adminservice.getCloseWord('Company Deactivate Successfully '),'' ,{
              duration: 3000,
              horizontalPosition:'center',
                });  
            }
            
             this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
           this.router.navigate(['//view-company-list']));          
           
            
            
          }
          else if(data['status'] == 0){
              if(method=='1'){
                 this.snackbar.open(this.adminservice.getCloseWord('Unable to Activate.'),'' ,{
              duration: 3000,
              horizontalPosition:'center',
                });  
              }
              else{
                 this.snackbar.open(this.adminservice.getCloseWord('Unable to Deactivate.'),'' ,{
              duration: 3000,
              horizontalPosition:'center',
                });  
  
              }
            
            
          }
          else if(data['status'] == 10){
            sessionStorage.clear();
            this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
              duration: 3000,
              horizontalPosition:'center',
                });      
            this.router.navigate(['/']);
          }
         
        },
        error => {
          this.snackbar.open(this.adminservice.getCloseWord("Failed Try Again"), "Ok", {
            duration: 3000
          });
          this.router.navigate(["/Admin"]);
  });
   }
  
  
  }
  


  delete_company_data(comp_num){
    var res = confirm("Are you sure you want to Delete this company data.");
     if(res){
    var post={user_num : this.user_num,
      access_token : this.access_token,
      comp_num: comp_num,
    };
    this.adminservice.delete_company_data(post).subscribe(
      data=>{
        if(data['status'] == 1){
        
             this.snackbar.open(this.adminservice.getCloseWord('Company Data Deleted Successfully '),'' ,{
            duration: 3000,
            horizontalPosition:'center',
              });  
          
           this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
         this.router.navigate(['//view-company-list']));          
        }
        else if(data['status'] == 0){
               this.snackbar.open(this.adminservice.getCloseWord('Unable to Deleted.'),'' ,{
            duration: 3000,
            horizontalPosition:'center',
              });  
          
        }
        else if(data['status'] == 10){
          sessionStorage.clear();
          this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
            duration: 3000,
            horizontalPosition:'center',
              });      
          this.router.navigate(['/']);
        }
       
      },
      error => {
        this.snackbar.open(this.adminservice.getCloseWord("Failed Try Again"), "Ok", {
          duration: 3000
        });
        this.router.navigate(["/Admin"]);
});
   }
  
  
  }


}