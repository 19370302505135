import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./_guards";
import { AdminLoginComponent } from "./admin-login/admin-login.component";
import { AdminSigninComponent } from "./admin-signin/admin-signin.component";
import { HostRoutesComponent } from "./host-routes/host-routes.component";
import { AdminHomeComponent } from "./admin-home/admin-home.component";
import { MainRootComponent } from "./main-root/main-root.component";
import { ViewProductComponent } from "./view-product/view-product.component";
import { ProfileComponent } from "./profile/profile.component";
import { NoPageSetupComponent } from './no-page-setup/no-page-setup.component';
import { NotificationComponent } from './notification/notification.component';
import { AddProductVariantComponent } from './add-product-variant/add-product-variant.component';
import { MenifestComponent } from './menifest/menifest.component';
import { AddCompanyPickupAddressComponent } from "./add-company-pickup-address/add-company-pickup-address.component";
import { AddMultiplePincodeComponent } from "./add-multiple-pincode/add-multiple-pincode.component";
import { MultiplePincodeComponent } from "./multiple-pincode/multiple-pincode.component";
import { PackageComponent } from "./package/package.component";
import { TermConditionsComponent } from "./term-conditions/term-conditions.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ReturnRefundPolicyComponent } from "./return-refund-policy/return-refund-policy.component";
import { PackageCheckoutComponent } from "./package-checkout/package-checkout.component";
import { PackageCouponsComponent } from './package-coupons/package-coupons.component';
import { TermConditionsEcomComponent } from "./term-conditions-ecom/term-conditions-ecom.component";
import { PrivacyPolicyEcomComponent } from "./privacy-policy-ecom/privacy-policy-ecom.component";
import { ReturnRefundPolicyEcomComponent } from "./return-refund-policy-ecom/return-refund-policy-ecom.component";
import { ShippingPolicyEcomComponent } from "./shipping-policy-ecom/shipping-policy-ecom.component";
import { CatalogueShareComponent } from './catalogue-share/catalogue-share.component';
import { TermsOfServiceVendorComponent } from './terms-of-service-vendor/terms-of-service-vendor.component';
import { RegCustomerProfileComponent } from "./reg-customer-profile/reg-customer-profile.component";
const routes: Routes = [
  
  {path:"",component:AdminLoginComponent},
  { path: "signup", component: AdminSigninComponent },
  { path: "signup/:id", component: AdminSigninComponent },
  {
    path: "",
    component: MainRootComponent,
    pathMatch: "prefix",
    canActivate: [AuthGuard],
    children: [
      
      {
        path: "dashboard",
        component: AdminHomeComponent
      },
      { path: 'Greating-us', loadChildren: () => import('./greating-us/greating-us.module').then(m => m.GreatingUsModule), canActivate: [AuthGuard], },
      {
        path: "package-checkout",
        component: PackageCheckoutComponent
      },
      { path: "banners/:id", loadChildren: () => import('./bannertierwise/bannertierwise.module').then(m => m.BannertierwiseModule) },

      { path: 'bannertierwise', loadChildren: () => import('./bannertierwise/bannertierwise.module').then(m => m.BannertierwiseModule) },
      {
        path: "dashboard-reports",
        loadChildren: () =>
          import('./report-dashboard/report-dashboard.module').then(m => m.ReportDashboardModule)

      },
      { path: 'reports-on-mail', loadChildren: () => import('./reports-on-mail/reports-on-mail.module').then(m => m.ReportsOnMailModule) },
      {
        path: "Bulk",
        loadChildren: () =>
          import('./bulk/bulk.module').then(m => m.BulkModule)

      },
      {
        path: "company-profile",
        loadChildren: () =>
          import('./company/company.module').then(m => m.CompanyModule)
      },
      {
        path: "company-profile/:id",
        loadChildren: () =>
          import('./company/company.module').then(m => m.CompanyModule)
      },
      {
                  path: "reg-cutomer-profile",
                  component: RegCustomerProfileComponent
                },
      {
        path: 'member-wallet-admin',
        loadChildren: () =>
          import('./member-wallet-admin/member-wallet-admin.module').then(m => m.MemberWalletAdminModule)
      },
      {path:'_product_addition', loadChildren:() => import ('./short-product-addition/short-product-addition.module').then(m => m.ShortProductAdditionModule)}
      ,
      
      {path: 'short-product-update', loadChildren:()=> import('./short-product-update/short-product-update.module').then(m=>m.ShortProductUpdateModule) },
      {
        path: 'tmc-details',
        loadChildren: () =>
          import('./tmc-details/tmc-details.module').then(m => m.TMCDetailsModule)
      },
      {
        path: "shipping-tab",
        loadChildren: () =>
          import('./shipping-tab/shipping-tab.module').then(m => m.ShippingTabModule)

      },
      {
        path: "customer-verification",

        loadChildren: () => import('./members/members.module').then(m => m.MembersModule)

      },
      {
        path: "catalogue-share",
        component: CatalogueShareComponent
      },
      {
        path: "host-routes",
        component: HostRoutesComponent
      },

      {
        path: "add-company-pickup-address",
        component: AddCompanyPickupAddressComponent
      },
      {
        path: "add-multiple-pincode",
        component: AddMultiplePincodeComponent
      },
      {
        path: "multiple-pincode",
        component: MultiplePincodeComponent
      },
      {
        path: "package",
        component: PackageComponent
      },
      {
        path: "package-coupons",
        component: PackageCouponsComponent
      },
      {
        path: "update-company",
        loadChildren: () =>
          import('./update-company/update-company.module').then(m => m.UpdateCompanyModule)
      },
      {
        path: "wall-post",
        loadChildren: () =>
          import('./wall-post/wall-post.module').then(m => m.WallPostModule)
      },
      {
        path: "wall-topic-post",
        loadChildren: () =>
          import('./wall-topic-post/wall-topic-post.module').then(m => m.WallTopicPostModule)
      },
      {
        path: "product-addition",
        loadChildren: () =>
          import('./product-addition/product-addition.module').then(m => m.ProductAdditionModule)
      },
      { path: 'add-services',
       loadChildren: () =>
         import('./add-services/add-services.module').then(m => m.AddServicesModule) 
      },
      {
        path: "add-product",
        loadChildren: () =>
          import('./add-product/add-product.module').then(m => m.AddProductModule)
      },
      {
        path: "add-productsearch",
        loadChildren: () =>
          import('./add-product-via-search/add-product-via-search.module').then(m => m.AddProductViaSearchModule)
      },
      {
        path: "add-productfromurl",
        loadChildren: () =>
          import('./add-productfromurl/add-productfromurl.module').then(m => m.AddProductfromurlModule)
      },
      {
        path: "update-product",
        loadChildren: () =>
          import('./update-product/update-product.module').then(m => m.UpdateProductModule)
      },
      {
        path: "view-company-list",
        loadChildren: () =>
          import('./view-company-list/view-company-list.module').then(m => m.ViewCompanyListModule)
      },
      {
        path: "add-product",
        loadChildren: () =>
          import('./add-product/add-product.module').then(m => m.AddProductModule)
      },
      {
        path: "new-add-product",
        loadChildren: () =>
          import('./new-add-product/new-add-product.module').then(m => m.NewAddProductModule)
      },
      {
        path: "add-brands",
        loadChildren: () =>
          import('./add-brands/add-brands.module').then(m => m.AddBrandsModule)
      },
    
      {
        path: "coupons", loadChildren: () => import('./coupens/coupens.module').then(m => m.CoupensModule),
      },
      {
        path: 'view-brands',
        loadChildren: () =>
          import('./view-brands/view-brands.module').then(m => m.ViewBrandsModule)
      },
      {
        path: "product-analysis-dashboard-view",

        loadChildren: () =>
          import('./products-analysis-dashboard-view/products-analysis-dashboard-view.module').then(m => m.ProductsAnalysisDashboardViewModule)
      },
      {
        path: "list-product",
        loadChildren: () =>
          import('./list-product/list-product.module').then(m => m.ListProductModule)
      },
      { path: 'list-services',
       loadChildren: () =>
        import('./list-services/list-services.module').then(m => m.ListServicesModule)
      },
      {
        path: "view-product",
        component: ViewProductComponent
      },
      {
        path: "view-category",
        loadChildren: () =>
          import('./view-category/view-category.module').then(m => m.ViewCategoryModule)
      },
      { path: 'controller-settings', loadChildren: () => import('./controller-sttings/controller-sttings.module').then(m => m.ControllerSttingsModule) },
      { path: 'company-registry', loadChildren: () => import('./create-registory-by-controller/create-registory-by-controller.module').then(m => m.CreateRegistoryByControllerModule) },     
      {
        path: "view-tags",
        loadChildren: () =>
          import('./tags-view/tags-view.module').then(m => m.TagsViewModule)
      },
      {
        path: "user-profile",
        component: ProfileComponent
      },

      { path: 'non-registered-customer', loadChildren: () => import('./non-register-customer/non-register-customer.module').then(m => m.NonRegisterCustomerModule) },
      {
        path: "view-tax",
        loadChildren: () =>
          import('./tax-view/tax-view.module').then(m => m.TaxViewModule)
      },
     

      {
        path: "order-vendor-new",
        loadChildren: () =>
          import('./order-vendor-new/order-vendor-new.module').then(m => m.OrderVendorNewModule)
      },
     
    
      {
        path: "notification",
        component: NotificationComponent
      },
      { path: 'preview-mode', loadChildren: () => import('./preview-mode/preview-mode.module').then(m => m.PreviewModeModule) },
      {
        path: "return-orders",

        loadChildren: () =>
          import('./return-order/return-order.module').then(m => m.ReturnOrderModule)
      },
      {
        path: "add-product-variants",
        component: AddProductVariantComponent
      },
      {
        path: "add-user",
        loadChildren: () =>
          import('./add-user/add-user.module').then(m => m.AddUserModule)
      },
      {
        path: "view-users",
        loadChildren: () =>
          import('./view-users/view-users.module').then(m => m.ViewUsersModule)
      },
      { path: 'order-refunds', loadChildren: () => import('./order-refunds/order-refunds.module').then(m => m.OrderRefundsModule) },
      { path: 'noticeboard', loadChildren: () => import('./noticeboard/noticeboard.module').then(m => m.NoticeboardModule) },
      { path: 'customer-cart', loadChildren: () => import('./customer-cart/customer-cart.module').then(m => m.CustomerCartModule) },
      { path: 'delboy-admin', loadChildren: () => import('./delboy-admin/delboy-admin.module').then(m => m.DelboyAdminModule) },
      { path: 'invoices', loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule) },
      { path: 'admin-pincard', loadChildren: () => import('./admin-pincard/admin-pincard.module').then(m => m.AdminPincardModule) },
      { path: 'company-faq', loadChildren: () => import('./company-faq/company-faq.module').then(m => m.CompanyFaqModule) },
      {
        path: "quick-addition",
        loadChildren: () =>
          import('./quick-product-addition/quick-product-addition.module').then(m => m.QuickProductAdditionModule)
      },
      {
        path: "quick-update-product",
        loadChildren: () =>
          import('./quick-product-update/quick-product-update.module').then(m => m.QuickProductUpdateModule)
      },
      {
        path: 'menifest',
        component: MenifestComponent
      },
      { path: 'vendor-member-tree', loadChildren: () => import('./vendor-member-tree/vendor-member-tree.module').then(m => m.VendorMemberTreeModule) },
      { path: 'quick-company-addition', loadChildren: () => import('./quick-company-addition/quick-company-addition.module').then(m => m.QuickCompanyAdditionModule) },
      { path: 'quick-setting', loadChildren: () => import('./quick-setting/quick-setting.module').then(m => m.QuickSettingModule) },
      { path: 'user_to_admin_communications', loadChildren: () => import('./user-to-admin-communications/user-to-admin-communications.module').then(m => m.UserToAdminCommunicationsModule) },
      { path: 'broadcast', loadChildren: () => import('./broadcast/broadcast.module').then(m => m.BroadcastModule) },

      { path: 'admin-testimonials', loadChildren: () => import('./admin-testimonials/admin-testimonials.module').then(m => m.AdminTestimonialsModule) },
      { path: 'company-settings', loadChildren: () => import('./company-settings/company-settings.module').then(m => m.CompanySettingsModule) },
      {
        path: "update-company-settings",
        loadChildren: () =>
          import("./company-settings/update-settings/update-settings.module").then(
            (m) => m.UpdateSettingsModule
          ),
      },
    
      { path: 'Group-buying', loadChildren: () => import('./group-buying/group-buying.module').then(m => m.GroupBuyingModule) },
      { path: 'company-pickup-address', loadChildren: () => import('./company-depo-address/company-depo-address.module').then(m => m.CompanyDepoAddressModule) },
      { path: 'depo-request', loadChildren: () => import('./depo-request/depo-request.module').then(m => m.DepoRequestModule) },
      { path: 'admin-size-chart', loadChildren: () => import('./admin-size-chart/admin-size-chart.module').then(m => m.AdminSizeChartModule) },
    ]
  },
  {
    path: 'term-and-conditions',
    component: TermConditionsEcomComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyEcomComponent,
  },
  {
    path: 'return-and-refund-policy',
    component: ReturnRefundPolicyEcomComponent,
  },
  {
    path: 'shipping-policy',
    component: ShippingPolicyEcomComponent,
  },
  {
    path: 'privacy-policies',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'term-conditions',
    component: TermConditionsComponent,
  },
  {
    path: 'return-refund-policy',
    component: ReturnRefundPolicyComponent,
  },
  {
    path: 'term-and-conditions',
    component: TermConditionsEcomComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyEcomComponent,
  },
  {
    path: 'return-and-refund-policy',
    component: ReturnRefundPolicyEcomComponent,
  },
  {
    path: 'shipping-policy',
    component: ShippingPolicyEcomComponent,
  },
  { path: 'thank-you', loadChildren: () => import('./thank-you/thank-you.module').then(m => m.ThankYouModule) },
  {
    path: 'term-conditions-vendor',
    component: TermsOfServiceVendorComponent,
  },
  { path: "/", component: AdminLoginComponent },
  
  {
    path: "404-page-not-found",
    loadChildren: () =>
      import('./error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  { path: "page-not-working", component: NoPageSetupComponent },
  { path: 'admin-size-chart', loadChildren: () => import('./admin-size-chart/admin-size-chart.module').then(m => m.AdminSizeChartModule) },
  { path: 'Greating-dialog', loadChildren: () => import('./greating-dialog/greating-dialog.module').then(m => m.GreatingDialogModule) },
  { path: 'add-product-via-search', loadChildren: () => import('./add-product-via-search/add-product-via-search.module').then(m => m.AddProductViaSearchModule) },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }