<div class="container ">
    <!-- <div class="container "  type="hidden" style="position: absolute; left: -1500px; top: 0; overflow: hidden;"> -->
        <div class="row text-center" style="margin: 18px;">
<!--                    <button
              mat-raised-button
              kendo-button
              style=" background-color: blue ; color: white; "
              (click)="pdf.saveAs('invoice.pdf')"
              >
           pdf download
           </button> -->
        </div>
        <div class="row">
           <div class="col-lg-12">
            <kendo-pdf-export
               #pdf
               paperSize="A4"
               margin="2px"
               forcePageBreak=".page-break"
               >
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="font-weight-bold text-center first-heading">{{"Logistics Manifest" | translate}}</h4>
                        <h6 class="text-muted text-center second-heading">{{"Generated on" | translate}}: Oct 21, 2020 06:26 PM</h6>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-4">
                        <h4>{{"SdRetails" | translate}}</h4>
                        <h6 class="second-heading2">{{"Sapna Dresses, Shope No 21, Ground Floor, Samrat Estate," | translate}}
                             </h6>
                        <h6 class="second-heading2">  {{"R.R.T Road. Mulund(w)" | translate}} </h6>
                         <h6 class="second-heading2"> {{"MUMBAI" | translate}} - 400080
                        </h6>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h6 class="second-heading2">{{"Document No." | translate}}: 202010211826 </h6>
                          <h6 class="second-heading2">  {{"Total Shipments to Dispatch" | translate}}: 1 </h6>
                           <h6 class="second-heading2"> {{"Total Shipments to Check" | translate}}: 0</h6>
                    </div>
                </div>
            </kendo-pdf-export>
           </div>
        </div>
      </div>