<div *ngIf="edit">
    <i class="far fa-edit cursor-pointer text-success" (click)="gettaxDetails(data);openXl(content);"></i>
</div>
<div *ngIf="delete">
    <i class="fas fa-trash-alt text-danger cursor-pointer" (click)="deletingtax(data)"></i>
</div>

<style>
    .example-full-width{
        width: 100%;
    }
</style>

<ng-template #content let-modal>
    <div class="modal-header">
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title"  align="center">{{"Update tax" | translate}}</h6>
    
      <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')"></i>
    </div>
      <!-- modal body starts -->
    <div class="modal-body "  >
           <form [formGroup] = "registerForm" >
                            
                              <div class="row justify-content-center" >
                                <div class=" col-lg-8">
                                  <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label >{{"tax Rate" | translate}}</mat-label>
                                    <input  matInput  placeholder="tax Rate" formControlName="rate" required>
                                  
                                    <i matSuffix class="fas fa-percentage"></i>
                                  </mat-form-field>
                                </div>
                              </div>
                              
                      
                         <div class="row justify-content-center">
                          <button  class="order-button text-white" (click)="onSubmit()" mat-raised-button style="background-color: #20a8d8;color: #fff; font-family: 'Titillium web',sans-serif;">{{"Update" | translate}}</button>
                        </div>
    
                        
    
                </form>
    </div>
  
  </ng-template>

  <ng-template #content3Exep let-modal >
<div *ngIf ="resV1">
      <div class="modal-header">
        <h6 [style.color]="headingcolor" class="modal-title">{{"Delete Taxs" | translate}}</h6>
        <button type="button" class="close text-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf ="taxException" class="col-sm-12" >
                    <h6 class="text-center text-warning" style="font-size: 10px;"><i class="fas fa-exclamation-triangle"></i>&nbsp; "{{"These must delete before deleting tax" | translate}}"</h6>
                      
                    <ol class="list-group list-group-flush" *ngFor="let tax_p of tax_products">
                          <li class="list-group-item m-0 " style="cursor : pointer">
                                <div class="col-sm-12">
                              <b>{{"Product" | translate}}  :   </b>{{tax_p.meta_title}}
                                </div>
                               
                                
                    
                          </li>
                          
                    </ol> 
        </div>
       </div>
      
    </div>
    </ng-template>