<style>
    .modal-header {
    display: flex;
    -ms-flex-align: start;
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    align-items: flex-start;
    z-index: 111111;
    top: 0;
    justify-content: space-between;
    padding: .5rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}


.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 600;
}
h6{
    font-size: 12px;
}
h5{
    font-size: 14px;
}
.box{
    margin-bottom: 10px;
    background: #f7f7f7;
    width: 95%;
    padding-left: -3px;
    padding-left: 5px;
    padding-top: 10px;
    border-radius: 4px;
    padding-bottom: 10px;
    box-shadow: 0 0 1px lightgrey;
}
.box1{
    margin-bottom: 10px;
    background: #f7f7f7;
    width: 98%;
    padding-left: -3px;
    padding-left: 5px;
    padding-top: 10px;
    border-radius: 4px;
    padding-bottom: 10px;
    box-shadow: 0 0 1px lightgrey;
}
.table th {
    background:#f7f7f7 ;
}
.table td, .table th {
    padding: .75rem;
    font-size: 12px;
    text-align: center;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
tr:hover{
    background:#f7f7f7 ;
}
.ag-theme-alpine .ag-cell {
    border: 1px solid transparent;
    line-height: 40px;
    padding-left: 17px;
    padding-right: 17px;
    font-size: 12px;
}
</style>


<div *ngIf="deactivatef">
    <!-- <button mat-raised-button *ngIf="params?.value?.buisness_verify == false    " class="" (click)="fetch_customer_registration_by_id(data?.id,data.bank_id,data.bank_detail_no,data?.address_id,data.address_no,data.buisness_no)" style="height: 30px; font-size: 12px; background: #831551; border: 1px solid #831551; overflow: hidden; color: #fff; margin-top: 5px;"><span style="position: relative;
        top: -5px;
        font-weight: 600;">Verify</span></button> &nbsp; -->
    <!-- <button mat-raised-button class="" (click)="deactivate(params?.value?.buisness_no)" style="height: 30px; font-size: 12px; border: 1px solid #831551; overflow: hidden; color: #000000; margin-top: 5px;"><span style="position: relative;
        top: -5px;
        font-weight: 600;">Deactivate</span></button> -->
        
        
        <button mat-icon-button [matMenuTriggerFor]="menu"> 
            <i class="fas fa-ellipsis-v"></i>
          </button>
          
          <mat-menu #menu="matMenu"> 
            <div mat-menu-item class="text-success" *ngIf="params?.value?.buisness_verify == false"  (click)="fetch_customer_registration_by_id(data?.id,data.bank_id,data.bank_detail_no,data?.address_id,data.address_no,data.buisness_no)">
                <i class="fas fa-user-check"></i>&nbsp;
              <span>{{"Verify"|translate}}</span>
            </div>
            <div mat-menu-item  class="text-danger" (click)="deactivate(params?.value?.buisness_no)">
                <i class="fas fa-ban"></i> &nbsp;
              <span>{{"Deactivate"|translate}}</span>
            </div>
            <div mat-menu-item class="text-primary" *ngIf="params?.value?.buisness_verify == true" (click)="openXl1(content_statement)">
                <i class="fas fa-file-alt"></i>&nbsp;
              <span>{{"Statements"|translate}}</span>
            </div>
          
            <div mat-menu-item class="text-primary"  (click)="openXlL(content_statement_wallet);get_user_num(data.buisness_no)">
                <i class="fas fa-file-alt"></i>&nbsp;
              <span>{{"Wallet List"|translate}}</span>
            </div>
            <div mat-menu-item class="text-primary"  (click)="openXlL(content_statement_ledger);get_user_num(data.buisness_no)">
                <i class="fas fa-file-alt"></i>&nbsp;
              <span >{{"Ledger"|translate}}</span>
            </div>
          </mat-menu>

    <!-- <i class="fas fa-user-times text-danger cursor-pointer" ></i> <br/>
 &nbsp;&nbsp;
    <i class="fas fa-user-check cursor-pointer text-success" *ngIf="params?.value?.buisness_verify == false" (click)="fetch_customer_registration_by_id(data?.id,data.bank_id,data.bank_detail_no,data?.address_id,data.address_no,data.buisness_no)"></i>   -->
</div>

<div *ngIf="activate">
    &nbsp;   <i class="fas fa-user-plus cursor-pointer text-success" (click)="fetch_customer_registration_by_id(data?.id,data.bank_id,data.bank_detail_no,data?.address_id,data.address_no,data.buisness_no)"></i>
   
  </div>
  <div *ngIf="view">
    <i class="far fa-eye cursor-pointer" (click)="VIEW(data.user_num)"></i>
  </div>

  <div (click)="openXl(content_add,index);get_user_num(data.buisness_no)" *ngIf="profile" title="View More Details" class="text-primary font-weight-600 cursor-pointer">{{data.id}}</div>


<!-- member statement -->
  <ng-template #content_statement let-modal>
    <div class="modal-header">
      <h5 [style.color]="headingcolor" class="modal-title">{{"Monthly Statements"|translate}}</h5>
  
      <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')"
        ></i
      >
    </div>
    <div class="modal-body">
        <div class="container-fluid" *ngIf="Statement?.length>0">
            <div class="row">
                <div class="col-lg-12">
                    <h6 class="product-border-heading" style="font-size: 16px;">{{"Monthly Statements"|translate}}</h6>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12">
                    <table class="table table-bordered">
                        <thead>
                            <th>{{"Sno."|translate}}</th>
                            <th>{{"Date of Statements"|translate}}</th>
                            <th>{{"Download Statements"|translate}}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data  of Statement; let i=index">
                              <td>{{i+1}}</td>
                              <td>{{data.month}}</td>
                              <td><a href="{{data.link}}" download="{{data.link}}" target="_blank"  type="pdf">
                                <i class="far fa-file-pdf text-danger cursor-pointer fa-1x"></i>
                              </a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        
        <div class="container" *ngIf="Statement?.length==0">
            <div class="row justify-content-center">
                <div class="col-lg-5 text-center pt-5 pb-5">
                    <img src="assets/img/no-transaction.png" height="100">
            <h6 class="text-center mt-3">
              {{"No data Available"|translate}}
            </h6>
                </div>
            </div>
        </div>
    </div>
  </ng-template>

<!-- member wallet -->
<ng-template #content_statement_wallet let-modal>
    <div class="modal-header">
      <h5 [style.color]="headingcolor" class="modal-title">{{"Wallet Statements"|translate}}</h5>
  
      <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')"
        ></i
      >
    </div>
    <div class="modal-body">
        <div class="container-fluid" >
            <div class="row justify-content-end">
                <div class="col-lg-8">
                    <form [formGroup]="FormU" class="w-100">
                        <div class="row justify-content-end">
                          <div class="col-md-4">
                              <mat-form-field
                              appearance="fill"
                              class="" style="width: 100%;"
                            >
                             <mat-label>{{"From Date"|translate}}</mat-label>
                              <input
                                matInput
                                [matDatepicker]="picker3"
                                autocomplete="off"
                                datePickerFormat="DD/MM/YYYY"
                                  [max]="currentDate"
                                 [min]="minimum"
                                (dateInput)="addEvent($event)"
                                (dateChange)="addEvent($event);loadApi()"
                                placeholder="Start Date"
                                formControlName="from_date"
                                required
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker3"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                          
                          </div>
                  
                      <div class="col-md-4">
                            <mat-form-field
                              appearance="fill"
                              class="" style="width: 100%;"
                            >
                             <mat-label>{{"To Date"|translate}}</mat-label>
                              <input
                                matInput
                                [matDatepicker]="picker2"
                                autocomplete="off"
                                datePickerFormat="DD/MM/YYYY"
                                [max]="currentDate"
                                 [min]="this.FormU.controls.from_date.value"
                                (dateInput)="addEvent2($event)"
                                (dateChange)="addEvent2($event);loadApiW()"
                                placeholder="End Date"
                                formControlName="to_date"
                                required
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker2"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                         </div>
                         <div   class="col-md-4 ">
                            <button (click)="downloadReportW()" mat-raised-button class="order-button text-white">{{"Download Report"|translate}}</button>
                          </div>
                  </div>
                  </form>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12">
                    <ag-grid-angular
  style="height: 355px;"
  class="ag-theme-alpine"
  [columnDefs]="columnDefsW"
  [rowData]="rowDataW"
  (gridReady)="onGridReady($event)"
  [gridOptions]= "gridOptions"
  >
  </ag-grid-angular>
                </div>
            </div>
        </div>
        
        
        <div class="container" *ngIf="Statement?.length==0">
            <div class="row justify-content-center">
                <div class="col-lg-5 text-center pt-5 pb-5">
                    <img src="assets/img/no-transaction.png" height="100">
            <h6 class="text-center mt-3">
              {{"No data Available"|translate}}
            </h6>
                </div>
            </div>
        </div>
    </div>
  </ng-template>
<!-- member ledger -->
<ng-template #content_statement_ledger let-modal>
    <div class="modal-header">
      <h5 [style.color]="headingcolor" class="modal-title">{{"Ledger Statements"|translate}}</h5>
  
      <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')"
        ></i
      >
    </div>
    <div class="modal-body">
        <div class="container-fluid" >
            <div class="row justify-content-end">
                <div class="col-lg-8">
                    <form [formGroup]="FormU" class="w-100">
                        <div class="row justify-content-end">
                          <div class="col-md-4">
                              <mat-form-field
                              appearance="fill"
                              class="" style="width: 100%;"
                            >
                             <mat-label>{{"From Date"|translate}}</mat-label>
                              <input
                                matInput
                                [matDatepicker]="picker3"
                                autocomplete="off"
                                datePickerFormat="DD/MM/YYYY"
                                  [max]="currentDate"
                                 [min]="minimum"
                                (dateInput)="addEvent($event)"
                                (dateChange)="addEvent($event);loadApi()"
                                placeholder="Start Date"
                                formControlName="from_date"
                                required
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker3"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                          
                          </div>
                  
                      <div class="col-md-4">
                            <mat-form-field
                              appearance="fill"
                              class="" style="width: 100%;"
                            >
                             <mat-label>{{"To Date"|translate}}</mat-label>
                              <input
                                matInput
                                [matDatepicker]="picker2"
                                autocomplete="off"
                                datePickerFormat="DD/MM/YYYY"
                                [max]="currentDate"
                                 [min]="this.FormU.controls.from_date.value"
                                (dateInput)="addEvent2($event)"
                                (dateChange)="addEvent2($event);loadApi()"
                                placeholder="End Date"
                                formControlName="to_date"
                                required
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker2"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                         </div>
                         <div   class="col-md-4 ">
                            <button (click)="downloadReport()" mat-raised-button class="order-button text-white">{{"Download Report"|translate}}</button>
                          </div>
                  </div>
                  </form>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12">
                    <ag-grid-angular
                    style=" height: 398px;"
                    class="ag-theme-alpine"
                    [columnDefs]="columnDefs"
                    [rowData]="rowData"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]= "gridOptions"
                    >
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
  </ng-template>

<!-- member profile -->
  <ng-template #content_add let-modal>
    <div class="modal-header">
      <h5 [style.color]="headingcolor" class="modal-title" (click)="ConvertPDFwithDataonly()">{{"Member Profile"|translate}}</h5>
      <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')"
        ></i
      >
    </div>
    <div class="modal-body" id="contentToConvert">
        <div class="container-fluid m-0">
            <div class="row justify-content-center">
                    <div class="col-md-12 p-0">
                        <div class="card m-0">
                            <div class="card-header ">
                                <span>{{"Personal Details"|translate}}</span>
                                <span  class="offset-md-6 justify-content-end pl-2 pr-2 font-weight-500" style="font-size: 14px;"><i class="fas fa-code-branch"></i>&nbsp; {{"My Referral Code"|translate}} : {{buisness_details?.customer_reg_num}} &nbsp;&nbsp;<span (click)="downloadMemberprofile()" title="pdf download"><i class="fas fa-file-pdf cursor-pointer text-primary"></i></span></span>
                            </div>
                
                       <div *ngIf="buisness_details==null" class="card-body">
                        {{"Not found"|translate}}
                       </div>
                        <div *ngIf="buisness_details!=null" class="card-body">
                            <div  class="row mb-0">
                                <div  class="col-md-2">
                                    <div class="profile_image" style="overflow: hidden;
                                    height: 91px;
                                    border-radius: 50%;
                                    border: 2px solid wheat;">
                                    <img src="{{allResult.profile_image}}" style="height: inherit;" (error)="onImgError($event)">
                                </div>
                                 </div>

                                 <div  class="col-md-3">
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <h6 class="font-weight-500 "><i class="far fa-calendar-alt"></i>&nbsp; {{"Date of Joining"|translate}}</h6>
                                        </div>
                                        <div class="col-lg-12" >
                                            <h6 class="font-weight-500 box" >&nbsp;{{buisness_details?.added_on | date:'dd/MM/yyyy'}}</h6>         
                                        </div>
                                    </div> 
                                 </div>

                                     <div  class="col-md-3">
                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <h6 class="font-weight-500 "><i class="fas fa-code-branch"></i>&nbsp; {{"Referrer Code"|translate}}</h6>
                                            </div>
                                            <div class="col-lg-12" >
                                                <h6 class="font-weight-500 box" >&nbsp;{{buisness_details?.referral_code}}</h6>   
                                            </div>
                                        </div> 
                                     </div>

                                       <div  class="col-md-4" >      
                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <h6 class="font-weight-500">
                                                    <i class="fas fa-file-signature"></i>&nbsp; {{"Referrer Name"|translate}}
                                                </h6>
                                            </div>
                                            <div class="col-lg-12">      
                                            <h6 class="font-weight-500 box">
                                         <span *ngIf="allResult?.buisness_details?.referral_name !=null">&nbsp;{{allResult?.buisness_details?.referral_name}}</span>  &nbsp;<span *ngIf="allResult?.buisness_details?.referral_last_name !=null">{{allResult?.buisness_details?.referral_last_name}}</span> 
                                        </h6>
                                    </div>
                                   </div>
                                    </div>
                                </div>
                            
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="font-weight-500"><i class="fas fa-user-tag"></i>&nbsp; {{"First Name"|translate}} </h6>
                                        </div>
                                        <div class="col-lg-12">
                                            <h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.receiver_name}}</h6>
                                        </div>
                                    </div>
                                 </div>

                                <div class="col-md-6"> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="font-weight-500"><i class="fas fa-user-tag"></i>&nbsp; {{"Last Name"|translate}} </h6>
                                        </div>
                                        
                                        <div class="col-lg-12">
                                            <h6 class="font-weight-500 box">&nbsp; {{updateAddres?.receiver_last_name}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                           <div class="row">
                               <div class="col-md-6">
                
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500"><i class="fas fa-mobile-alt"></i> &nbsp;{{"Mobile No"|translate}}. </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.contact_no}}</h6>
                                    </div>
                                </div>                     
                               </div>

                               <div class="col-md-6">           
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500"><i class="fas fa-envelope-open-text"></i>&nbsp; {{"Email Id"|translate}} </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box"> &nbsp; {{updateAddres?.email}}</h6>
                                    </div>
                                </div>  
                            </div>  
                           </div>               
                         
                           <div class="row">
                               <div class="col-md-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500"><i class="fas fa-street-view"></i> &nbsp;{{"Address"|translate}} </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box pl-3">  <span class="">
                                            {{updateAddres?.line1}},{{updateAddres?.line2}},{{updateAddres?.landmark}},{{updateAddres?.city}},{{updateAddres?.state}},{{updateAddres?.country}},{{updateAddres?.pin_code}}
                                        </span>  </h6>
                                    </div>
                                </div>
                        </div>

                          <div class="col-md-6">
                <div class="row" *ngIf="updateAddres?.pan !=null">
                <div class="col-lg-12">
                    <h6 class="font-weight-500"> <i class="fas fa-address-card"></i>&nbsp;   {{"PAN"|translate}}  </h6>
                </div>
                <div class="col-lg-12"><h6 class="font-weight-500 box">  &nbsp; {{updateAddres?.pan}}</h6></div>
                </div>
                         </div>
                        </div> 
                        <div class="row mt-2">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-10">
                                        <h6 class="font-weight-500 box">{{"Address Proof"|translate}}</h6>
                                        <img src="{{updateAddres.address_proof}}" height="100" onError="this.src='https://img.icons8.com/wired/64/000000/pdf.png'" (error)="onImgError($event)">
                                    </div>
                                    <div class="col-lg-2">
                                        
                                            <a href="{{updateAddres.address_proof}}" title="download address proof" download="{{updateAddres.address_proof}}" > <i class="fas fa-file-download fa-2x"></i> </a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-10">
                                        <h6 class="font-weight-500 box">{{"PAN Proof"|translate}}</h6>
                               
                                        <img src="{{updateAddres.pan_proof}}" height="100" onError="this.src='https://img.icons8.com/wired/64/000000/pdf.png'" (error)="onImgError($event)"/>
                                      
                                    </div>
                                    <div class="col-lg-2">
                                            <a href="{{updateAddres.pan_proof}}" title="download pan proof" download="updateAddres.pan_proof" > <i class="fas fa-file-download fa-2x"></i> </a>
                                        
                                    </div>
                                </div>
                            </div>
                           
                        </div>
            

 <!-- ************************* Start Buisness Details **************** -->

                        <!-- <div class="row " *ngIf="buisness_details!=null">
                            <div class="col-lg-6 ">
                               <h5 class="font-weight-600 ">&nbsp;Business Details</h5>
                            </div>
                            <div class="col-lg-6 text-right"> </div>
                        </div> -->
                        
                        <!-- <div class="row mt-2" *ngIf="buisness_details!=null">
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-md-12"><h6   class="font-weight-500 "><i class="fas fa-user-tie"></i>&nbsp; Buisness Name </h6></div>
                                    <div class="col-md-12"><h6 *ngIf="buisness_details?.buisness_name==null"  class="font-weight-500 box1 "> &nbsp; </h6><h6 *ngIf="buisness_details?.buisness_name!=null" class="box1"> &nbsp;{{buisness_details?.buisness_name}}</h6> </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="col-md-12">  <h6  class="font-weight-500"><i class="fas fa-address-book"></i>&nbsp; GST  </h6></div>
                                <div class="col-md-12"> <h6 *ngIf="buisness_details?.gst !=null" class="font-weight-500 box1"> &nbsp;{{buisness_details?.gst}}</h6>
                                 <h6 *ngIf="buisness_details?.gst ==null" class="font-weight-500 box1">&nbsp; </h6></div>
                            </div>
                            <div class="col-lg-4">
                                <div class="col-md-12">
                                    <h6  class="font-weight-500"><i class="fas fa-address-card"></i>&nbsp;TAN  </h6>
                                </div>
                                <div class="col-md-12">
                                    <h6 *ngIf="buisness_details?.tan !=null" class="font-weight-500 box1"> &nbsp;{{buisness_details?.tan}}</h6>
                                <h6 *ngIf="buisness_details?.tan ==null" class="font-weight-500 box1">&nbsp; </h6>
                                </div>
                            </div>
                        </div> -->
            

 <!-- ************************* End Buisness Details **************** -->

                        <div class="row" *ngIf="buisness_details!=null">
                            <div class="col-lg-6">
                                <h5 class="font-weight-600">
                                {{"Bank Details"|translate}}
                                </h5>
                            </div>
                            <div class="col-lg-6 text-right">       
                            </div>
                        </div>
                      
            
                        <div class="row" *ngIf="buisness_details!=null">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500">
                                            <i class="fas fa-id-card"></i>&nbsp;  {{"Account Holder Name"|translate}}
                                        </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box"*ngIf="bank_details?.account_name !=null">&nbsp;{{bank_details?.account_name}}</h6>
                                        <h6 class="font-weight-500 box"*ngIf="bank_details?.account_name ==null">&nbsp;</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500">
                                            <i class="fas fa-university"></i>&nbsp;   {{"Bank Name"|translate}}
                                        </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_name !=null">
                                             &nbsp; {{bank_details?.bank_name}}
                                        </h6>
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_name ==null">
                                            &nbsp; 
                                       </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500">
                                            <i class="fab fa-quinscape"></i>&nbsp;  {{"IFSC Code"|translate}}
                                        </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.ifsc !=null">
                                           &nbsp; {{bank_details?.ifsc}}
                                        </h6>
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.ifsc ==null">
                                            &nbsp; 
                                         </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500">
                                            <i class="far fa-dot-circle"></i>&nbsp;    {{"Account No"|translate}}.
                                        </h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.account_no !=null">
                                          &nbsp;  {{bank_details?.account_no}}
                                        </h6>
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.account_no ==null">
                                            &nbsp;  
                                          </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500"><i class="fas fa-code-branch"></i>&nbsp; {{"Branch Name"|translate}}</h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_address !=null">
                                           &nbsp; {{bank_details?.bank_address}}
                                        </h6>
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.bank_address ==null">
                                            &nbsp; 
                                         </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500"><i class="fas fa-id-badge"></i>&nbsp; {{"UPI Id"|translate}}</h6>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.upi !=null">
                                           &nbsp; {{bank_details?.upi}}
                                        </h6>
                                        <h6 class="font-weight-500 box" *ngIf="bank_details?.upi ==null">
                                            &nbsp; 
                                         </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>  
            </div>
            </div>
    </div>
  </ng-template>