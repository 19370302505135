

<section class="contentBlock">
    <div class="container">
        <div class="titleBig">
            <h2>{{"Refund and Return Policy" | translate}}</h2>
        </div>
        <div>
        <p>
        {{"The Firm may, at its discretion arrange to lift the defective products from the customer however the Vendor will still be liable to replace the defective product. Any charges incurred by the Firm for lifting and forwarding such defective goods shall be on account of the Vendor. The Vendor shall make good such charges to the Firm upon receipt of invoice/debit note. No request for any adjustment from future payables to the Vendor from Firm will be made however, the Firm is at its liberty to deduct such amount from future payables of fresh Orders. Such deductions shall not be treated as practice and/or precedent. For avoidance of doubts it is clarified that defective would mean and include but is not limited to wrong product, damaged product, mis-sized product and any other shortcoming which the customer may point out. The Vendor hereby authorizes the Firm to entertain all claims of return of the Product in the mutual interest of the Vendor as well as the Customer." | translate}}</p>
        <br>
        <p>
        {{"The Vendor shall upon receipt of the order from the Firm immediately arrange to deliver the products to the designated address as early as possible but in any case the dispatch shall be made within 15 (FIFTEEN) days of the receipt of the Order. In case the Vendor fails to dispatch the product within the aforesaid time, it has to return the amount received back to the Firm forthwith. No delay in returning the amount is permissible and any delay caused shall be considered as breach of the agreement and be one of the cause for termination of agreement. The Vendor shall provide to the Firm the consignment number, details of courier/shipment agency immediately followed by proof of delivery." | translate}}</p>



        </div>
    </div>
</section>


<app-footer></app-footer>