
    <nav class="navbar navbar-expand-md fixed-top" id="navbar-color">
      <!-- Brand -->
      <a class="navbar-brand">
        <img src="assets/img/seller-log.png" width="110px" />
      </a>

      <!-- Toggler/collapsibe Button -->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span class="navbar-toggler-icon">
          <i class="fas fa-bars"></i>
        </span>
      </button>

      <!-- Navbar links -->
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav" id="navbar-link">
          <li class="nav-item ">
            <a class="nav-link"  (click)="goTo('whyUs')">{{"Why Us ?" | translate}} </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link"  (click)="goTo('benefits')">{{"Benefits" | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="goTo('offer')">{{"Launch Offers" | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link cursor-pointer"  (click)="goTo('myComponent')">{{"Contact" | translate}}</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#contact">FAQ</a>
          </li> -->
        </ul>
        <form class="form-inline ml-auto my-lg-0" >
     
            <button class="famie-btn btn" style="color: #fff; cursor: pointer;" (click)="openXl(content2)">
              
              {{"Login for existing sellers" | translate}}
              </button>
         
        </form>
     
      </div>
    </nav>

    <div class="modal" id="exampleModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border: 1px solid #e9ecef00">
            <div class="text-center">
              <img
                src="assets/img/9toys.jpeg"
                class="rounded-circle img-fluid"
                alt="profile image"
                style="width: 42%"
              />
              <h3>{{"Login" | translate}} </h3>
            </div>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div
            class="modal-body body-design"
            style="padding: 4rem; margin-top: -60px"
          >
            <form
              id="myform"
              method="post"
            >
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      style="background-color: #eea849;
                      color: #fff;"
                    >
                      <i class="fa fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    name="username"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Email or Mobile no."
                  />
                </div>
              </div>
              <button
                onclick="login()"
                class="btn btn-block btn-primary"
                style="
                  background-color: #eea849;
                 color: #fff;
                "
              >
                {{"Login" | translate}}
              </button>
            </form>
            <p id="msg"></p>
          </div>

        
        </div>
      </div>
    </div>

    <!---carousel-->
    <section id="home">
      <div class="banner-section mt-5">
        <div class="banner-overlay"></div>
        <div class="banner-img">
          <img class="" src="assets/img/sellerbg.jpeg"  alt="Seller bg image" />
        </div>
        <div class="homepage-banner-content offset-lg-6 offset-md-6">
          <a  style="text-decoration: none;">
          <!-- <h1>Sign Up Now</h1> -->
        </a>
        <form [formGroup]="registerForm">
          <div style="color: #fff;" class=" row mt-3">

            <div class="form-group col-lg-3" >
                <input required="required" class="form-control pl-3" placeholder="enter 10 digit mobile no or email"  formControlName="username"/>
              </div>
              <div class="form-group col-lg-4 p-0">
              
                <a  class=" ml-3 animated-button1 mt-0 cursor-pointer" *ngIf="!loading"  (click)="onSubmitSign();openXl(content4);startTimer(0.5)" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {{"Sign Up Now" | translate}}</a>
                    <a  class=" ml-3 animated-button1 mt-0 cursor-pointer" *ngIf="loading"   (click)="onSubmitSign();startTimer(0.5)" >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      {{"Sign Up Now" | translate}}</a>

              </div>
          
               
               
                
              
            </div>
        </form>
        </div>
      </div>
 
    </section>

    <section id="whyUs">
      <div class="common-section">
          <div class="text-center">
            <h1>{{"Why?" | translate}}  <img src="assets/img/seller-log.png" width="150px" style="position: relative; right: 10px;"> <span style="    position: relative;
              right: 28px;
              top: 5px;"></span></h1>
            <div class="border-1"></div>
            <p class="mt-5" style="font-size: 24px;">{{"We aim to be India's Biggest Exclusive Toys' Marketplace." | translate}}</p>
          </div>
      <div class="container mt-5">
        <div class="row text-center">
          <div class="col-md-3">
            <div class="card  text-white sell-card" style="background-color: #F47722;">
              <div class="card-body icon">
                <!-- <i class="far fa-star"></i> -->
                <i class="fas fa-star"></i>
                <h4 class="mt-4" >{{"Premium Toys along wide Brands and Categories" | translate}}</h4>
              </div>
            </div>
            
          </div>
         
          <div class="col-md-3">
            <div class="card  text-white sell-card" style="background-color: #D01939;">
              <div class="card-body icon">
                <i class="fas fa-universal-access"></i>
               <h4 class="mt-4" style="    position: relative;
               top: 16px;
           "> {{"Millions of Buyers Across India" | translate}}</h4>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card  text-white sell-card" style="background-color: #8B2782;">
              <div class="card-body icon">
                <i class="fas fa-hand-holding-usd"></i>
                <h4 class="mt-4">{{"Quick Payments & Transparent Process" | translate}}</h4>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card  text-white sell-card" style="background-color: #3F3F81;">
              <div class="card-body icon">
                <i class="fas fa-shopping-basket"></i>
                <h4 class="mt-4" style="position: relative;
                top: 31px;
            ">{{"Sell 24*7" | translate}}</h4>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    </section>
  

    <!---Features-->
    <section id="benefits">
      <div class="common-section our-verticals-section" id="verticle-link">
        <div class="text-center">
          <h1>{{"Seller Benefits" | translate}}</h1>
          <div class="border-1"></div>
          <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="circles mt-5">
              
    
                <div class="circle-2 col-md-5">
                  <h4>{{"Features for sellers" | translate}}</h4>
                  <ul style="text-align: left">
                    <li>
                      {{"Get your sales a boost with wide customer base across India." | translate}}
                    </li>
                    <li>{{"Hassle free operational process for sellers." | translate}}</li>
                    <li>{{"Short payout cycles for your better financials." | translate}}</li>
                    <li>{{"No hidden charges." | translate}}</li>
                    <li>{{"Lowest commission rate in ecom industry." | translate}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class=" offset-md-1 col-md-5 mt-5">
              <img class="img-fluid" src="assets/img/k.png" alt="">
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
    <!----Offsers---->
    <section id="offer">
      <div class="common-section our-verticals-section" id="verticle-link">
        <div class="text-center">
          <h1>{{"Launch Offers" | translate}}</h1>
          <div class="border-1"></div>
        </div>
        <div class="container">
          <div class="row mt-5">
            <div class="col-md-5">
              <img class="img-fluid" src="assets/img/5.png" alt="" />
            </div>
            <div class="offset-md-1 col-md-6">
              <div class="card text-dark1">
                <div class="card-body">
                  <h4>
                    <img
                      src="assets/img/6.png"
                      alt=""
                      style="width: 100px; height: 100px"
                    />
                    {{"Premium Membership Offer" | translate}}
                  </h4>
                  <ul class="offer-list">
                    <li>{{"Trade Commission On Revenue" | translate}} <s>12% </s> <span class="ml-2"> 6%</span></li>
                    <li>{{"Payouts In 7 days" | translate}}</li>
                    <li>{{"Dedicated Seller Support Executive" | translate}}</li>
                    <li>{{"RTO & Shipping Return On Us" | translate}}</li>
                    <li>{{"Priority Listing (Higher Visibility To Customers)" | translate}}</li>
                    <li>{{"No Other Hidden Charges" | translate}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!---contact us-->
    <section  id='myComponent'>
      <div class="common-section">
        <h1 class="text-center">{{"Contact Us" | translate}}</h1>
        <div class="border-1"></div>
        <div class="container">
          <div class="row mt-5">
            <div class="col-md-4">
              <img class="img-fluid" src="assets/img/8.png" alt="" />
            </div>
            <div class="offset-md-1 col-md-7">
              <div class="contact-form-area">
                <form
                         [formGroup]="ContactForm"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <input
                        type="text"
                        required
                        formControlName="name"
                        class="form-control"
                        name="nm"
                        placeholder="Your Name"
                      />
                    </div>
                  

                  
                    <div class="col-lg-6 ">
                      <input
                        type="number"
                        autocomplete="off"
                        class="form-control"
                        name="phn"
                        formControlName="mobile"
                        placeholder="Your Phone"
                      />
                    </div>
                    <div class="col-lg-12 mt-4">
                        <input
                          type="email"
                          formControlName="email"
                          class="form-control"
                          name="eml"
                          placeholder="Your Email"
                        />
                      </div>
                    <div class="col-12 mt-4">
                      <textarea
                      formControlName="message" required
                        name="msg"
                        class="form-control"
                        cols="30"
                        rows="8"
                        placeholder="Your Message"
                      ></textarea>
                    </div>

                    <input type="hidden" name="site" value="9toys" />
                    <input
                      type="hidden"
                      name="send_from"
                      value="noreply@shahsoftsol.com"
                    />
                    <input
                      type="hidden"
                      name="bcc"
                      value="noreply@shahsoftsol.com ,seller@9toys.com"
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value="Contact Query CropTrails"
                    />
                    <input type="hidden" name="heading" value="Team 9toys" />
                    <input
                      type="hidden"
                      name="text_message"
                      value="Thank you !! Your Query Regarding 9toys has been received. We will contact You soon!!"
                    />
                    <input
                      type="hidden"
                      name="redirect_link"
                      value="https://www.9toys.com/"
                    />
                    <!-- <input type="hidden" name="redirect_success_link" value="https://www.9930i.com/thankyou.html"> -->
                    <input
                      type="hidden"
                      name="redirect_success_link"
                      value="https://www.9toys.com/thankyou.html"
                    />
                    <input
                      type="hidden"
                      name="redirect_failure_link"
                      value="https://www.9toys.com/"
                    />

                    <div class="col-12">
                      <input
                        type="submit"
                        (click)="send()"
                        class="btn btn-block send-btn"
                        value="SEND"
                      />
                      <!-- <button type="submit" name="button" value="SEND" class="btn famie-btn">Send Message</button> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   <!---business-->
   <!-- <section>
     <div class="common-section">
       <div class="container">
         <h4 class="text-center">Start your business with 9toys & reach customer across India</h4>
         <div class="text-center">
           <a href="http://vendor.9toys.in/" class="btn famie-btn">S I G N - U P</a>
        </div>
       </div>
     </div>
   </section> -->
    <!---footer-->
    <footer class="footer-area">
      <div class="container mt-4">
        <div class="row">
          <div class="col-md-4 mt-4">
            <img src="assets/img/seller-log.png" width="150px"/>
          </div>
          <div class="col-md-5 mt-4">
            <h5>{{"Keep in Touch" | translate}}</h5>
            <div class="border-2"></div>
            <!-- <p class="mt-2">9Toys E-retails Private Limited</p> -->
            <p class="mt-2">
              <i class="fa fa-building" aria-hidden="true"></i>
              <span
                > {{"107, 9930 Spaces, 3, Mangal Nagar, Indore, M.P. , India , 452014" | translate}}</span
              >
            </p>
            <p>
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <span>
                <a
                  href="seller@9toys.com"
                  target="_blank"
                  style="
                    color: #fff;
                    text-decoration-color: #fff;
                    font-weight: 300;
                  "
                  > seller@9toys.com</a
                >
              </span>
            </p>
            <p>
              <i class="fa fa-phone" aria-hidden="true"></i
              ><span> +91- 9111139930</span>
            </p>
          </div>
          <div class="col-md-3 mt-4">
            <h5>{{"Stay Connected" | translate}}</h5>
            <div class="border-2"></div>
            <div class="footer-social-info mt-2">
              <div>
                <a
                    href="https://api.whatsapp.com/send?phone=+91-9111139930"
                    target="_blank"
                  >
                  <i class="fab fa-whatsapp"></i>
                  <span> {{"Whatsapp" | translate}}</span>
                </a>
              </div>
              <div>
                <a
                    href="https://www.facebook.com/9toysofficial/"
                    target="_blank"
                  >
                  <i class="fab fa-instagram"></i>
                  <span> {{"Instagram" | translate}}</span>
                </a>
              </div>
              <div>
                <a
                    href="https://www.facebook.com/9toys.in/"
                    target="_blank"
                  >
                  <i class="fab fa-facebook-square"></i>
                  <span> {{"Facebook" | translate}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border border-bottom-0"></div>
      <div class="text-center">
        <p style="font-size: 12px; margin-top: 15px">
          {{"Copyright © 2021 9toys All rights reserved." | translate}}
        </p>
      </div>
    </footer>


    <script>
      function login(){
      fetch("https://bun.9toys.in/company/registerVendor", {
          method: "POST",
          body: JSON.stringify({
            username: document.getElementById("exampleInputEmail1").value,
            password : "",
            comp_num: "01"
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            var message = document.getElementById("msg");
              message.style.color = "red";
            if (data.status == 1) {
              msg.innerHTML = "Otp sent Suceesfully"
           
              }
           
          
          });
        }
    </script>



<ng-template #content2 let-modal>

  <div *ngIf="closemodal">
    <div class="modal-header">
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
        {{"Login" | translate}}
      </h6>
  
      <i class="fas fa-times text-danger cursor-pointer"
        (click)="modal.dismiss('Cross click')"
        
        ></i>
    </div>
    <!-- modal body starts -->
  
     <!-- modal body starts -->
    <div class="modal-body ">
      <div class="container-fluid">
        <form [formGroup]="loginForm" class="form-box">
            <div class="form-group">
              <input required="required" class="form-control pl-3" placeholder="enter 10 digit mobile no or email"  formControlName="username"/>
            </div>
            <div class="form-group text-center">
              <input  id="myInput"   type="password" required="required" class="form-control pl-3" placeholder="password"  formControlName="password"/>
               <i class="far fa-eye cursor-pointer login-mat" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="!visibility"  matTooltip="Show password"></i>
                      <i class="far fa-eye-slash cursor-pointer login-mat" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="visibility"  matTooltip="Hide password"></i>
              
            
              <button class=" login-btn mt-3" (click)="onSubmit()" 
              >{{"Login" | translate}} </button>
              <hr>
              <p class=""><span (click)="modal.dismiss('Cross click')" class="cursor-pointer text-left float-left text-primary">{{"Register for new account!" | translate}}</span>
              <span (click)="openXl(content3);modal.dismiss('Cross click')" class="cursor-pointer text-right float-right">{{"Forgot password?" | translate}}</span>  </p>
              <br/>
            </div>
            
          </form>
      </div>
    </div>
  </div>
  </ng-template>


  
  <ng-template #content3 let-modal>
    <div class="modal-header">
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
        {{"Forgot Password" | translate}}
      </h6>
  
   <h6>   <i class="fas fa-times text-danger cursor-pointer"
        (click)="modal.dismiss('Cross click')"
        
        ></i></h6>
    </div>
    <!-- modal body starts -->
  
     <!-- modal body starts -->
    <div class="modal-body ">
      <div class="container-fluid">
        <form [formGroup]="forgotForm">
          <!-- <div class=" "> -->
            <div class="row ">
              <div class="col-md-8 p-0">
                 <div class="input-group mb-3 mt-3 ">
                           
                            <input type="text" style="padding-left: 2%;" class="form-control" formControlName="username" placeholder="Please Enter Email/Mobile" >
                        </div>
               
              </div>
              <div class="col-lg-4 mt-3">
                <button
                
                 class="login-btn "
                 
                 matTooltip="Send Password"
                 (click)="forgotPassword(); modal.close('Save click');openXl(content2)"
               >
                 {{"Send" | translate}}
               </button>
              </div>
            </div>
           
           
            <!-- <div class="row justify-content-center mt-3 mb-3">
            
            </div> -->
          <!-- </div> -->
        </form>
      </div>
    </div>
  </ng-template>
  



  <ng-template #content4 let-modal >
   
      <div *ngIf="signup == 2">
    <div class="modal-header" >
      <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
        {{"Register" | translate}}
      </h6>
  
      <i class="fas fa-times text-danger cursor-pointer"
        (click)="modal.dismiss('Cross click')"
        
        ></i>
    </div>
    <!-- modal body starts -->
  
     <!-- modal body starts -->
    <div class="modal-body " >
      <div class="container-fluid">
        <form  [formGroup]="otpForm" class="form-box">
            <div class="form-group ">
              <input required="required" class="form-control pl-3" style="width: 60%;" placeholder="enter otp" formControlName="otp"/>
              <button class="cursor-pointer login-btn" style="position: absolute;
              right: 29px;
              top: 18px;"   (click)="onSubmitResendOtp()" disabled  *ngIf="state=== 'start'">{{"Resend in" | translate}} {{time|async}}</button>
               <button class="cursor-pointer login-btn" style="position: absolute;
               right: 29px;
               top: 18px;"   (click)="onSubmitResendOtp()" 
            [disabled]="!loading"
            (click)="startTimer(0.5)" *ngIf="state=== 'stop' ">{{"Resend otp" | translate}}</button>
            </div>
            
              <div class="form-group">
                <input  id="myInput2"  required="required" class="form-control pl-3" type="password" placeholder="enter password" formControlName="password" type="password"/>
                <i class="far fa-eye cursor-pointer login-mat" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="!visibility2" ></i>
                                <i class="far fa-eye-slash cursor-pointer login-mat" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="visibility2" ></i>
              </div>
              <div class="form-group">
                <input  id="myInput3"  required="required" class="form-control pl-3" placeholder="confirm password" type="password"  [class.is-invalid]="otpForm.errors?.misMatch" required
                formControlName="confirmPassword"/>
                  <i class="far fa-eye cursor-pointer login-mat" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="!visibility3" ></i>
                              <i class="far fa-eye-slash cursor-pointer login-mat" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="visibility3"></i>
              </div>
            <div class="form-group text-center">
              <button class=" login-btn" (click)="onSubmitOTP()" [disabled]="!loading">{{"Submit" | translate}} </button>
              <!-- <hr/> -->
              <!-- <p class="">Already exist!<a class="text-primary cursor-pointer" (click)="onSubmit()"></a></p> -->
            </div>
            
          </form>
      </div>
    </div>
</div>

  </ng-template>