<div *ngIf="tmcdetails.length>0" class="mt-5 mb-5 container">
    <div *ngFor="let data of tmcdetails;let i=index">
      <h2>
        {{i+1}}. &nbsp;{{data.tc_data}}
      </h2>
   <br/>
    </div>
</div>
<div *ngIf="tmcdetails.length==0" class="container mt-5 mb-5">
<html *ngIf="project=='ecomtrails'">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>

 /* Font Definitions */
 @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
    {font-family:Calibri;
    panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin-top:0cm;
    margin-right:0cm;
    margin-bottom:10.0pt;
    margin-left:0cm;
    line-height:115%;
    font-size:11.0pt;
    font-family:"Calibri","sans-serif";}
h1
    {mso-style-link:"Heading 1 Char";
    margin-top:12.0pt;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:0cm;
    margin-bottom:.0001pt;
    line-height:115%;
    page-break-after:avoid;
    font-size:16.0pt;
    font-family:"Cambria","serif";
    color:#365F91;
    font-weight:normal;}
h2
    {mso-style-link:"Heading 2 Char";
    margin-top:2.0pt;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:0cm;
    margin-bottom:.0001pt;
    line-height:115%;
    page-break-after:avoid;
    font-size:13.0pt;
    font-family:"Cambria","serif";
    color:#365F91;
    font-weight:normal;}
h3
    {mso-style-link:"Heading 3 Char";
    margin-top:2.0pt;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:0cm;
    margin-bottom:.0001pt;
    line-height:115%;
    page-break-after:avoid;
    font-size:12.0pt;
    font-family:"Cambria","serif";
    color:#243F60;
    font-weight:normal;}
span.Heading2Char
    {mso-style-name:"Heading 2 Char";
    mso-style-link:"Heading 2";
    font-family:"Cambria","serif";
    color:#365F91;}
span.Heading1Char
    {mso-style-name:"Heading 1 Char";
    mso-style-link:"Heading 1";
    font-family:"Cambria","serif";
    color:#365F91;}
span.Heading3Char
    {mso-style-name:"Heading 3 Char";
    mso-style-link:"Heading 3";
    font-family:"Cambria","serif";
    color:#243F60;}
.MsoChpDefault
    {font-family:"Calibri","sans-serif";}
.MsoPapDefault
    {margin-bottom:10.0pt;
    line-height:115%;}
@page WordSection1
    {size:595.3pt 841.9pt;
    margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
    {page:WordSection1;}
</style>

</head>

<body lang=EN-IN>

<div class=WordSection1>
<p class=MsoNormal style='line-height:14.15pt;margin-top: 10px'><a name=page1></a><span
style='font-size:12.0pt;font-family:"Verdana","sans-serif"'>
[VENDOR]: <b>{{compd?.display_name | titlecase}} </b>
[VENDOR'S SITE]: <b>{{compd?.host_name}} </b>

The above represented as [VENDOR] & [VENDOR'S SITE] in the below document.
</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
text-align:center;line-height:150%;text-autospace:none'><span style='font-size:
14.0pt;line-height:150%'>ONLINE SHOPPING SITE CONDITIONS OF USE</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Welcome to [</span><i><span style='font-size:14.0pt;
line-height:150%'>VENDOR'S SITE]. </span></i><span style='font-size:14.0pt;
line-height:150%'>To use the service offered at [</span><i><span
style='font-size:14.0pt;line-height:150%'>VENDOR] </span></i><span
style='font-size:14.0pt;line-height:150%'>or by any of its affiliates, you must
agree to the following conditions. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>HEREINAFTER in this policy, [VENDOR] shall be referred to as
the “Seller” and the variations of “Seller” as and where applicable, whereas
[VENDOR'S SITE] shall be referred to as the “Seller’s website”.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>If you visit or shop at [</span><i><span style='font-size:
14.0pt;line-height:150%'>VENDOR</span></i><span style='font-size:14.0pt;
line-height:150%'>].</span><i><span style='font-size:14.0pt;line-height:150%'>com</span></i><span
style='font-size:14.0pt;line-height:150%'>, you accept these conditions. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Please read them carefully. In addition, when you use any
current or future Seller’s</span><i><span style='font-size:14.0pt;line-height:
150%'> </span></i><span style='font-size:14.0pt;line-height:150%'>service or visit
or purchase from any business affiliated with the Seller, whether or not
included in this Web Site, you will be subject to both this Conditions of Use
agreement and the guidelines and conditions applicable to such service or
business.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>1.<span
class=Heading2Char><span style='line-height:115%;font-family:"Calibri","sans-serif";
color:windowtext'> PRIVACY</span></span></span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>{{"Privacy Policy" | translate}}</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Please review our Privacy Notice, which also governs your
visit to Seller’s website, to fully understand our practices.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>2. ELECTRONIC
COMMUNICATIONS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>Legal Satisfaction</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>When you visit Seller’s website or send emails to us, you are
communicating with us electronically.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. Consent</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>By communicating with us, you consent to receive
communications from us electronically. We may communicate with you by e-mail or
by posting notices on this site. You agree that all agreements, notices, disclosures,
and other communications that we provide to you electronically satisfy any
legal requirement that such communications be in writing.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>3.
COPYRIGHT</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. Copyright
Protection</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All content included on this site, such as text, graphics,
logos, Button icons, images, audio clips, digital downloads, data compilation,
and software is the property of the Seller or has been licensed to it only for
this website by EcomTrails<sup>TM and</sup> protected by the laws in force in
the jurisdiction of, [VENDOR’s country]/Indian and international copyright
laws. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The compilation of all content on this site is the exclusive
property of the Seller unless it has been licensed from EcomTrails<sup>TM </sup>under
an agreement for license and protected by the laws in force in [Vendor’s
jurisdiction], India and international copyright laws. All software used on
this site is the property of M/s OEPP Innovations Pvt. Ltd. / EcomTrails<sup>TM</sup>
or its software suppliers and are protected by the Indian and international
copyright laws.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>4.
TRADEMARKS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>Protected Marks</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All the marks indicated on our site are trademarks of the
Seller.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B
.Protected Graphics</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All graphics, logos, page headers, button icons, scripts, and
service names are trademarks or trade dress of EcomTrails<sup>TM</sup>. Any of
the trademarks and trade dress may not be used in connection with any product
or service that is not the Seller’s website or EcomTrails<sup>TM</sup>, in any
manner that is likely to cause confusion among customers, or in any manner that
disparages or discredits the Seller or EcomTrails<sup>TM</sup>. All other
trademarks not owned by the Seller or its subsidiaries that appear on this site
are the property of their respective owners, who may or may not be affiliated
with, connected to, or sponsored by the Seller or its subsidiaries.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>5.
LICENSE AND SITE ACCESS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The Seller grants you a limited license to access and make
personal use of this site and not to download (other than page caching) or
modify it, or any portion of it, except with express written consent of the
Seller.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>No License for
Commercial Sale</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>This license does not include any resale or commercial use of
this site or its contents; any collection and use of any product listing,
descriptions, or prices; any derivative use of this site or its contents; any downloading
or copying of account information for the benefit of another merchant; or any
use of data-mining, robots, or similar data gathering and extraction tools.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>C .No
Reproduction</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>This site or any portion of this site may not be reproduced,
duplicated, copied, sold, resold, visited, or otherwise exploited for any
commercial purpose without express written consent of the Seller or EcomTrails<sup>TM</sup>
wherever required.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>D .No
Framing</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You may not frame or utilize framing techniques to enclose
any trademark. Logo, or other proprietary information (including images, text,
page layout, or form) or the Seller and our affiliates without express written
consent.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>E. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>Meta tags</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You may not use any Meta tags or any other hidden text
utilizing the Seller’s name or trademarks without the express written consent
of the Seller. Any unauthorized use terminates that permission or license
granted by the Seller.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>6.
YOUR ACCOUNT</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
Protection of Your Account</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>As discussed further in the site {{"Privacy Policy" | translate}}, by using
this site, you agree that you are responsible for maintaining the
confidentiality of your account and password and for restricting access to your
computer, and you agree to accept responsibility for all activities that occur
under your account or password.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. Use by
Children</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>As discussed further in the site {{"Privacy Policy" | translate}}, the Seller does
sell products for children, but it sells them to adults, who can purchase with
a credit card. If you are under age 18, you may use the Seller only with
involvement of a parent or guardian, the Seller and its affiliates reserve the right
to refuse service, terminate accounts, remove or edit content, or cancel orders
in their sole discretion.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span class=Heading1Char><b><span
style='font-size:16.0pt;line-height:150%;font-family:"Calibri","sans-serif";
color:windowtext'>7. REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER</span></b></span><b><span
style='font-size:14.0pt;line-height:150%'> </span></b><span class=Heading1Char><b><span
style='font-size:16.0pt;line-height:150%;font-family:"Calibri","sans-serif";
color:windowtext'>CONTENT</span></b></span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A .Nature
of Content</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Visitors to the Seller’s website may post reviews, comments
and other content’ send e-cards and other communications; and submit
suggestions, ideas, and comments. Questions or other information, as long as
the content is not illegal. Obscene, threatening, defamatory, invasive of
privacy, infringing of intellectual</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>property rights, or otherwise injurious to third parties or
objectionable and does not consist of or contain software viruses, political
campaigning, commercial solicitation, chain letters, mass mailings, or any form
of spam.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B .False
Information</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You may not use a false e-mail address, impersonate any
person or entity, or otherwise mislead as to the origin of a card or other
content. The Seller reserves the right (but not the obligation) to remove or
edit such content but does not regularly review posted content.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>C. Rights
Granted</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>If you do post content or submit material, and unless we
indicate otherwise, you grant the Seller and its affiliates a non-exclusive,
royalty-free, perpetual, irrevocable, and fully sub licensable right to use,
reproduce, modify, adapt, publish, translate, create derivative works from,
distribute, and display such content throughout the world in any media. You
grant the Seller and its affiliates and sublicenses the right to use the name
that you submit in connection with such content if they choose.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>D .Rights
Owned</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>You represent and warrant that you own or otherwise control
all of the rights to the content that you post; that the content is accurate;
that use of the content you supply does not violate this policy and will not cause
injury to any person or entity; and that you will indemnify the Seller or its
affiliates for all claims resulting from content you supply. The Seller has the
right but not the obligation to monitor and edit or remove any activity or
content. The Seller takes no responsibility and assumes no liability for any
content posted by you or any third party.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>8.
COPYRIGHT COMPLAINTS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A
.General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The Seller and its affiliates respect the intellectual
property of others. If you believe that your work has been copied in a way that
constitutes copyright infringement, please refer to the site for instructions
on how to make a claim of Copyright Infringement.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>9.
RISK OF LOSS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>All items purchased from the Seller are made pursuant to a
shipment contract. This means that the risk of loss and title for such items
pass to you upon our delivery to the end carrier.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>10.
PRODUCT DESCRIPTIONS</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The Seller and its affiliates attempt to be as accurate as
possible. However, the Seller makes no warranties that the product descriptions
and any other content of this site are accurate, complete, reliable, current or
error-free. If a product offered by the Seller itself is not as described, your
sole remedy is to return it in unused condition.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>11.
OTHER BUSINESSES</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A
.General</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Parties other than the Seller and its subsidiaries operate
stores, provide services, or sell product lines on this site.</span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. No
Warranties.</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>We are not responsible for examining or evaluating, and we do
not warrant the offerings of, any of these businesses or individuals or the
content of their Web Sites. The Seller does not assume any responsibility or
liability for the actions, product, and content of all these and any other
third parties. You should carefully review their privacy statements and other
conditions of use.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>12. DISCLAIMER
OF WARRANTIES AND LIMITATION OF LIABILITY</span></b></h1>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>This site is provided by the Seller on an “AS IS” “AS
AVAILABLE” basis. The Seller makes no representations or warranties of any
kind, express or implied, as to the operation of this site or the information,
content, materials, or products included on this site. You expressly agree that
your use of this site is at your sole risk.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>To the full extent permissible by applicable law, the Seller disclaims
all warranties express or implied including but not limited to, implied
warranties of merchantability and fitness for a particular purpose. the Seller,
does not warrant that this site, its servers, or e-mails sent from the Seller are
free of viruses or other harmful components, the Seller will not be liable for
any damages of any kind arising from the use of this site, including, but not
limited to direct, indirect, incidental, punitive, and consequential damages.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Certain state laws do not allow limitations on implied
warranties or the exclusion or limitation of certain damages, if these laws
apply to you, some or all of the above disclaimers, exclusions, or limitations
may not apply to you, and you might have additional rights.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>13. APPLICABLE
LAW</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A
.Governing Law</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>By visiting the Seller’s website, you agree that the laws of
the state of [</span><i><span style='font-size:14.0pt;line-height:150%'>Madhya
Pradesh</span></i><span style='font-size:14.0pt;line-height:150%'> and India],
without regard to principles of conflict of laws, will govern these Conditions
of Use and any dispute of any sort that might arise between you and the Seller
and its affiliates.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>14. DISPUTES</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A.
Arbitration and Venue</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>Any dispute relating in any way to your visit to the Seller’s
website or to products you purchase through the Seller’s website shall be
submitted to confidential arbitration in [</span><i><span style='font-size:
14.0pt;line-height:150%'>Indore</span></i><span style='font-size:14.0pt;
line-height:150%'>], [</span><i><span style='font-size:14.0pt;line-height:150%'>Madhya
Pradesh</span></i><span style='font-size:14.0pt;line-height:150%'>], [INDIA] except
that, to the extent you have in any manner violated or threatened to violate the
Seller’s intellectual property rights, the Seller may seek injunctive or other
appropriate relief in any court, and you consent to exclusive jurisdiction and
venue in such courts. </span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>B. </span><span
style='font-family:"Calibri","sans-serif";color:windowtext'>The Finality of
Arbitration</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>The arbitrator’s award shall be binding and may be entered as
a judgment in any court of competent jurisdiction. </span></p>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>C. LIMITATIONS</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>M/s OEPP Innovations Pvt. Ltd. / EcomTrails<sup>TM </sup>which
has provided web hosting services to the Seller shall have no responsibilities,
obligations, liability of any kind which may arise from functioning of the
Seller’s website or otherwise. M/s OEPP Innovations Pvt. Ltd. / EcomTrails<sup>TM
</sup>shall also not be made party to any of the legal proceedings of any kind
against the Seller or the Seller’s website.</span></p>

<h1><b><span style='font-family:"Calibri","sans-serif";color:windowtext'>15. SITE
POLICIES, MODIFICATION, AND SEVERABILITY</span></b></h1>

<h2><span style='font-family:"Calibri","sans-serif";color:windowtext'>A. Other
Policies</span></h2>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:150%;text-autospace:none'><span style='font-size:14.0pt;
line-height:150%'>We reserve the right to make changes to our site, policies,
and these Conditions of Use at any time. If any of these conditions shall be
deemed invalid, void, or for any reason unenforceable, that condition shall be
deemed severable and shall not affect the validity and enforceability of any
remaining condition.</span></p>

</div>

</body>

</html>

<div *ngIf="project=='emall'">

<section class="contentBlock">
    <div class="container">
        <div class="titleBig">
            <h2>Terms and Conditions</h2><br><br>
        </div>
        <div>
             <p>Welcome to Emall !</p><br>
             <p>These terms and conditions outline the rules and regulations for the use of Emall Network LPP's Website, located at emallnet.com.</p><br>
             <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Emall  if you do not agree to take all of the terms and conditions stated on this page.
            </p><br>
             <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p><br>
            <h3><strong>Cookies</strong></h3>

           <p>We employ the use of cookies. By accessing Emall , you agreed to use cookies in agreement with the Emall Network LPP's {{"Privacy Policy" | translate}}.</p><br>
            <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
            
           <h3><strong>License</strong></h3><br>
           <p>Unless otherwise stated, Emall Network LPP and/or its licensors own the intellectual property rights for all material on Emall . All intellectual property rights are reserved. You may access this from Emall  for your own personal use subjected to restrictions set in these terms and conditions.</p><br>

            <p>You must not:</p>
            <ul>
               <li>Republish material from Emall </li>
               <li>Sell, rent or sub-license material from Emall </li>
               <li>Reproduce, duplicate or copy material from Emall </li>
               <li>Redistribute content from Emall </li>
            </ul><br>
            <p>This Agreement shall begin on the date hereof.</p>
            <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Emall Network LPP does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Emall Network LPP,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Emall Network LPP shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

          
            <p>Emall Network LPP reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
            <p>You warrant and represent that:</p>
            <ul>
                <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
               <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
            </ul><br>

            <p>You hereby grant Emall Network LPP a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
            <h3><strong>Hyperlinking to our Content</strong></h3><br>
           <p>The following organizations may link to our Website without prior written approval:</p>
           <ul>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
              <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
        </ul><br>
           <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p><br>
           <p>We may consider and approve other link requests from the following types of organizations:</p>
           <ul>
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                 <li>educational institutions and trade associations.</li>
          </ul><br>

            <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Emall Network LPP; and (d) the link is in the context of general resource information.</p><br>
            <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p><br>
            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Emall Network LPP. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p><br>
          <p>Approved organizations may hyperlink to our Website as follows:</p>
              <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
             </ul><br>
           <p>No use of Emall Network LPP's logo or other artwork will be allowed for linking absent a trademark license agreement.</p><br>
           <h3><strong>iFrames</strong></h3><br>
           <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p><br>
           <h3><strong>Content Liability</strong></h3><br>
           <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p><br>
           <h3><strong>Your Privacy</strong></h3><br>
           <p>Please read {{"Privacy Policy" | translate}}</p><br>
           <h3><strong>Reservation of Rights</strong></h3><br>
           <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p><br><br>
            <h3><strong>Removal of links from our website</strong></h3><br>
          <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p><br>
            <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p><br>
            <h3><strong>Disclaimer</strong></h3><br>

          <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                        <ul>
                             <li>limit or exclude our or your liability for death or personal injury;</li>
                             <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                             <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                             <li>exclude any of our or your liabilities that may not be excluded under  applicable law.</li>
                         </ul><br>
            <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p><br>
           <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p><br><br>
           <!--  <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
            <p>Disclaimer<br>
           To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p><br><br>
            <p>limit or exclude our or your liability for death or personal injury;<br>
            limit or exclude our or your liability for fraud or fraudulent misrepresentation;<br>
            limit any of our or your liabilities in any way that is not permitted under applicable law; or<br>
            exclude any of our or your liabilities that may not be excluded under applicable law.<br>
             The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p><br><br>
             <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p> -->
        </div>
    </div>
</section>

<app-footer></app-footer>
</div>
</div>