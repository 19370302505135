import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material';
import { NinetoysserviceService } from '../ninetoysservice.service';
import {MatSnackBar} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from "../../environments/environment";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {
   favicon=environment.favicon;
           logo=environment.logo;
             loaderAsset=environment.loader;
               error_img = sessionStorage.getItem('error_image');
               website=environment.website;
                              project=environment.project;

  public access_token=sessionStorage.getItem('access_token');
  public user_num=sessionStorage.getItem('user_num');
   public comp_num=sessionStorage.getItem('comp_num');
   public product_no = sessionStorage.getItem('product_no');
   product
   product_category
   product_rate
   product_tags
   product_specification
   product_image
   imageView
   mrpCheck=false;
  constructor(
    private snackbar:MatSnackBar,   
    private route: ActivatedRoute,
     private _location: Location,
    private router: Router,
    private translate: TranslateService,
    private adminservice: NinetoysserviceService, ) { }

  ngOnInit() {
    this.adminservice.getProduct({access_token : this.access_token , user_num : this.user_num , product_no : this.product_no}).subscribe(
      data => {       
        if (data["status"] == 1) {
          this.product = data['product'];
          this.product_category = data['product_category'];
          this.product_rate = data['product_rate'];
          this.product_tags = data['product_tags'];
          this.product_specification = data['product_specification'];
          this.product_image = data['product_image'];  
          this.imageView=this.product_image[0].image_link; 
              if(this.product_rate.length>0){
                for(let n=0;n<this.product_rate.length;n++){
                  if(this.product_rate[n].is_rent==0 || this.product_rate[n].is_rent==1 || this.product_rate[n].is_rent=='0' || this.product_rate[n].is_rent=='1'){
                    if(this.product_rate[n].rate>=this.product.mrp){
                      this.mrpCheck=true;
                    }
                  }
                }
              } 
          
        }else if(data['status']==10) {
          sessionStorage.clear();
          this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out.'),'' ,{
            duration: 3000,
            horizontalPosition:'center',
         });      
       this.router.navigate(['/']);
        }
        else if(data['status']== 0){


        }
      },
      error => {
        this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong please try again. "), "", {
          duration: 3000
        });
      }
    );
  }
  ViewCalender(){
   
    this._location.back();
  }
 
  getThumbnail1(thumbnail1): string {
    return this.adminservice.getThumbnail1(thumbnail1);
  }
  getGalleryImage(thumbnail1): string {
    return this.adminservice.getGalleryImage(thumbnail1);
  }
   Image(image){
    this.imageView=image;
  }
    onImgError(event){
 event.target.src = this.error_img;
//Do other stuff with the event.target
}

getCloseWord(key: string): string {
  var translateValue='';
 this.translate.get(key).subscribe((res: string) => {
   translateValue=res
});
return translateValue;
}


}
