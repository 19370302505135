
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from "@angular/forms";
import { first } from "rxjs/operators";
import { NinetoysserviceService } from "../ninetoysservice.service";
import { MatSnackBar } from "@angular/material";
import { AlertService, AuthenticationService } from "../_services";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { environment } from '../../environments/environment';
import { Title, Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-admin-signin',
  templateUrl: './admin-signin.component.html',
  styleUrls: ['./admin-signin.component.css']
})
export class AdminSigninComponent implements OnInit {
favicon=environment.favicon;
           logo=environment.logo;
             loaderAsset=environment.loader;
               error_img = sessionStorage.getItem('error_image');
               website=environment.website;
                              project=environment.project;
                              title10=environment.admintitle;
               description10=environment.admindescription;

  public visibility = true;
  public visibility2 = true;
 
  public visibility3 = true;
  quickSetting:string;
  loginForm: FormGroup;
  registerForm: FormGroup;
  otpForm: FormGroup;
  otpResendForm: FormGroup;
  forgotForm: FormGroup;
  loading = false;
  submitted = false;
  signup;

  signotp = false;
  user_num;
  returnUrl: string;
  un: string;
  comp_data: { comp_num: any; };
  constructor(
    private snackbar: MatSnackBar,

    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private adminservice: NinetoysserviceService,
       private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private translate : TranslateService
  ) {}
  error_image = sessionStorage.getItem('error_image');
  comp_num1 = sessionStorage.getItem('comp_num');
openXl(content) {
    this.modalService.open(content, { size: "md" });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  serverlink;
  ecomtrails = false;
  ninetoys = false;
   mlm= false;
  maschile= false;
  host_name;
  signId;
  ngOnInit() {
    let l = location.origin;
    var c = l.split("//");
    var host = c[1];
    this.host_name = host;
     this.signId=this.route.snapshot.paramMap.get("id");
     this.un = this.route.snapshot.paramMap.get("un");
  
  if(this.signId!=null){
    if (window.screen.width < 600) { 
     
      if (window.screen.width < 600) { 
        if(this.host_name =='9930web' || this.host_name=='emallnet.com' || this.host_name=='www.emallnet.com' || this.project=='emall'){
          var loc=   window.location.href;
          if(loc.indexOf("?") !== -1){
            var locs=[];
            locs= window.location.href.split("?");
           var rout=locs[1].replace('un', "id");
             window.open('https://mvendor.emallnet.com/#/Register?'+rout,'_self')
            }
            
           else{
            window.open('https://mvendor.emallnet.com/','_self')
           }
      
        }
     else{
      var loc=   window.location.href;
      if(loc.indexOf("?") !== -1){
        var locs=[];
        locs= window.location.href.split("?");
       var rout=locs[1].replace('un', "id");
         window.open('https://mvendor.ecomtrails.com/#/Register?'+rout,'_self')
        }
        else{
         window.open('https://mvendor.ecomtrails.com/','_self')
         
       }
      
  
     }
      
         
       }
    
       
     }
     this.signup = this.signId;
  }
   
    this.titleService.setTitle(this.title10);
    this.meta.addTag({ name: "author", content: this.title10 });
    this.meta.updateTag({
      name: "title",
      content: this.title10
    });
    this.meta.updateTag({
      property: "og:title",
      content: this.title10
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.description10
    });
   
     this.host_name = host;
 
    var s = host.split(".");
    this.serverlink = s[1];
    this.adminservice
    .hostlink({ host_name: host})
    .subscribe(data => {
      if (data["status"] == 1) {
        if (data["result"].comp_num == "01" && data['result'].name == "Signup"){  
       
          if(this.serverlink == 'ecomtrails' || this.host_name =='ecomtrails' || this.project=='ecomtrails'){
            this.ecomtrails = true;
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }else if(this.serverlink == '9toys' || this.host_name =='9toys' || this.project=='9toys'){
            this.ninetoys = true;
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else if(this.serverlink == 'maschile' || this.host_name =='maschile' || this.project=='maschile'){
            this.maschile=true;
            sessionStorage.setItem("database", "maschile");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
           else if(this.serverlink == '9930web' || this.host_name =='9930web'  || this.host_name=='emallnet.com' || this.host_name=='www.emallnet.com' || this.project=='emall'){
            this.maschile=true;
             this.ecomtrails = true;
            // sessionStorage.setItem("database", "maschile");
            sessionStorage.setItem("database", "emall");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else if(this.serverlink == '909corns'){
            this.mlm = true;            
    sessionStorage.setItem("database", "mlm");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else{
             this.maschile=true;
             this.ecomtrails = true;
            sessionStorage.setItem("database", "maschile");

            this.ecomtrails = true;
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }

          }        
         
        }else{
          if(this.serverlink == 'ecomtrails' || this.host_name =='ecomtrails' || this.project=='ecomtrails'){
            this.ecomtrails = true;
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }else if(this.serverlink == '9toys' || this.host_name =='9toys' || this.project=='9toys'){
            this.ninetoys = true;
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else if(this.serverlink == 'maschile' || this.host_name =='maschile' || this.project=='maschile'){
            this.maschile=true;
            sessionStorage.setItem("database", "maschile");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
            else if(this.serverlink == '9930web' || this.host_name =='9930web'  || this.host_name=='emallnet.com' || this.host_name=='www.emallnet.com' || this.project=='emall'){
            this.maschile=true;
             this.ecomtrails = true;
            // sessionStorage.setItem("database", "maschile");
            sessionStorage.setItem("database", "emall");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else if(this.serverlink == '909corns'){
            this.mlm = true;            
    sessionStorage.setItem("database", "mlm");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else{
             this.maschile=true;
             this.ecomtrails = true;
            sessionStorage.setItem("database", "maschile");

             // this.ninetoys = true;
           
            // this.ecomtrails = true;
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }

          } 
         
        }
      }else{
        if(this.serverlink == 'ecomtrails' || this.host_name =='ecomtrails' || this.project=='ecomtrails'){
          this.ecomtrails = true;
          this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
        }else if(this.serverlink == '9toys' || this.host_name =='9toys' || this.project=='9toys'){
          this.ninetoys = true;
          this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
        }
        
          else if(this.serverlink == 'maschile' || this.host_name =='maschile' || this.project=='maschile'){
            this.maschile=true;
            sessionStorage.setItem("database", "maschile");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
           else if(this.serverlink == '9930web' || this.host_name =='9930web'  || this.host_name=='emallnet.com' || this.host_name=='www.emallnet.com' || this.project=='emall'){
            this.maschile=true;
             this.ecomtrails = true;
            // sessionStorage.setItem("database", "maschile");
            sessionStorage.setItem("database", "emall");
            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
          else if(this.serverlink == '909corns'){
            this.mlm = true;            
    sessionStorage.setItem("database", "mlm");

            this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
          }
        else{
            this.maschile=true;
             this.ecomtrails = true;
            sessionStorage.setItem("database", "maschile");

           
          // this.ecomtrails = true;
          this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }

        } 
      }
    });
 //this.signup);
   
    // if(host == "signup.ecomtrails.com"){
    //   this.signup = 1;
 if(this.signId!=null){
     
     this.signup = this.signId;
  }
    // }else{
    //   this.signup = 0;
    // }
    this.loading = true;
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
      // usertype_id : '2'
    });
     this.forgotForm = this.formBuilder.group({
      username:['' , Validators.required],
    
    
    });

     // start for database 28/08/2020
      sessionStorage.setItem("database", "ecomtrails");
           
      this.adminservice.fetch_particular_company_registry_with_sno({comp_num:0,s_no:12}).subscribe(data=>{
      if(data['status']==1){
       let d = data['data'];
         let v = d.value;
         if(v.hostlink=="ecomtrails"){
            sessionStorage.setItem("database", "ecomtrails");
           
         }
         else if(v.hostlink=="9toys"){
            sessionStorage.setItem("database", "9toys");
           
         }
         // else{
         //     sessionStorage.setItem("database", "ecomtrails");
          
         // }
        
      }
      else if(data['status']==10){
      // sessionStorage.clear();
      //  this.snackbar.open(this.getCloseWord('Multiple login with this ID has been detected, Logging you out.'),'' ,{
      //           duration: 3000,
      //           horizontalPosition:'center',
      //   });      
      // this.router.navigate(['//signup']);
      }
      else{

      }
    },
    error=>{
        // this.loading = false;
      }
    );
     // end for database 28/08/2020
   
    this.registerForm = this.formBuilder.group({
      username: ["", Validators.required],
      // password: ['', Validators.required],
      usertype_id: "1"
    });
    this.otpForm = this.formBuilder.group(
      {
        // username: ['', Validators.required],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
        otp: ["", Validators.required],
        username: [this.route.snapshot.queryParams["un"], Validators.required],
        usertype_id: "1"
      },
      { validator: this.PasswordValidator }
    );
    this.otpResendForm = this.formBuilder.group({
      // username: ['', Validators.required],
      password: ["", Validators.required],
      otp: ["", Validators.required],
      username: ["", Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl =
    this.route.snapshot.queryParams["returnUrl"] || "/signup";
    this.loading = true;

  }
  onSubmitSign() {
    this.loading = false;
    if (this.signup == 0 || this.signup == 2) {
      this.signup = 1;

      this.loading = true;
    } else {
      this.loading = true;
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
        this.loading = true;
        return;
      } else {
        this.loading = true;
          //start for check email,mobile format
        let phone = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
    let isPhone = phone.test(this.registerForm.controls.username.value);
      let phoneDig = new RegExp(/^([0-9 ])*$/);
    let isphoneDig=phoneDig.test(this.registerForm.controls.username.value);
    let email = new RegExp("^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
      
    let isemail = email.test(this.registerForm.controls.username.value);
    if(isPhone || isemail){
        //end for check email mobile
      
        this.loading = false;
        let data = this.registerForm.value;
        this.adminservice
          .registerVendor(data)
          .pipe(first())
          .subscribe(
            data => {
              this.loading = true;
              if (data["status"] == 1) {
                this.snackbar.open(". ", "", {
                  duration: 3000
                });
                this.loading = true;

                this.signup = 2;
                this.signotp = true;

                this.otpForm.controls.username = this.registerForm.controls.username;
                this.snackbar.open(this.getCloseWord(
                  "OTP is send to your email/mobile,please verify."),
                  "",
                  {
                    duration: 3000
                  }
                );

               
                this.router.navigate(["/signup"]);
              } else {
                this.loading = true;
                this.snackbar.open(this.getCloseWord("Already exist, Please Try another"), "", {
                  duration: 3000
                });
              }
            },
            error => {
              this.loading = true;
              this.snackbar.open(this.getCloseWord(
                "Something Went wrong please try again."),
                "",
                {
                  duration: 3000
                }
              );
            }
          );

          }
        else{
          if(!isemail){
            if(isphoneDig){
              this.snackbar.open(this.getCloseWord(
                  "Mobile number must have 10 digits."),
                  "",
                  {
                    duration: 3000
                  }
                );
            }
            else{
              this.snackbar.open(this.getCloseWord(
                  "Email is not proper,please try again."),
                  "",
                  {
                    duration: 3000
                  }
                );
            }
            
          }
          else{
            this.snackbar.open(this.getCloseWord(
                  "Mobile number must have 10 digits."),
                  "",
                  {
                    duration: 3000
                  }
                );
          }
        }
      }
    }
  }
  get f() {
    return this.loginForm.controls;
  }
  onSubmitResend() {
    if (this.signup == 0 || this.signup == 2 || this.signup == 1) {
      this.signup = 4;
      this.loading = true;
    } else {
      if (this.otpForm.invalid) {
        this.loading = true;
        return;
      }

      // this.loading = true;
      this.loading = false;
      let data = this.otpForm.value;
      this.adminservice
        .otpVerification(data)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = true;
            if (data["status"] == 1) {
              this.signup = this.signup;
              this.loading = true;
              this.snackbar.open(this.getCloseWord(
                "OTP is send to your email,please verify."),
                "",
                {
                  duration: 3000
                }
              );
            } else {
              this.loading = true;
              
              this.snackbar.open(data["msg"],
                "",
                {
                  duration: 3000
                }
              );
            }
          },
          error => {
            this.loading = true;
            this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
              duration: 3000
            });
          }
        );
    }
  }
  onSubmitResendOtp() {
    let data = this.otpForm.value;
    if (
      this.otpForm.controls.username == null ||
      this.otpForm.controls.username.value == ""
    ) {
      this.snackbar.open(this.getCloseWord("Please fill the username field."), "", {
        duration: 3000
      });
    } else {
      this.loading = false;
      this.adminservice
        .ResendOtpVerification(data)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = true;
            if (data["status"] == 1) {
              this.signup = this.signup;
              this.loading = true;
              this.snackbar.open(this.getCloseWord(
                "OTP is send to your email/mobile,please verify. "),
                "",
                {
                  duration: 3000
                }
              );
            } else {
              this.loading = true;
              this.snackbar.open(this.getCloseWord(
                "Incorrect Credentials Please Try again "),
                "",
                {
                  duration: 3000
                }
              );
            }
          },
          error => {
            this.loading = true;
            this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
              duration: 3000
            });
          }
        );
    }
  }
  get g() {
    return this.otpForm.controls;
  }
  onSubmitOTP() {
    ////console.log("clicked");
    // this.signup=false;
    this.loading = false;

    if (this.otpForm.invalid) {
      this.loading = true;
      this.snackbar.open(this.getCloseWord("OTP Invalid."), "", {
        duration: 3000
      });
    }

    this.loading = false;
    let data = this.otpForm.value;
    ////console.log(data);
    this.authenticationService
      .otpVerified(
        this.g.username.value,
        this.g.password.value,
        this.g.otp.value,
        this.g.usertype_id.value,
        this.g.confirmPassword.value
      )
      .subscribe(
        data => {
          this.loading = true;
          if (data["status"] == 1) {
            this.signup = 0;
            this.loading = true;
            this.snackbar.open(this.getCloseWord("OTP Verified and Login Successful."), "", {
              duration: 3000
            });
             sessionStorage.setItem("user_num", data["user_num"]);
              sessionStorage.setItem("access_token", data["access_token"]);
              sessionStorage.setItem("jwtoken", data["jwtoken"]);
              sessionStorage.setItem("usertype_id", data["usertype_id"]);
              sessionStorage.setItem("comp_num", data["comp_num"]);
              if(this.serverlink == 'maschile' || this.host_name =='maschile' || this.project=='maschile'){
                this.comp_data = {
                  comp_num:'0'
                }
              }
              else{
                this.comp_data ={
                  comp_num:data["comp_num"]
                }
              }
              this.adminservice.Error_image(this.comp_data).subscribe(
                data=>{
                if(data['status']==1 || data['status']=='1'){
                  sessionStorage.setItem("error_image", data['result'].icon_image_link);
                    this.error_img = data['result'].icon_image_link;
                }
                }
              )
              sessionStorage.setItem("username", data["username"]);
              sessionStorage.setItem("email", data["email"]);
              sessionStorage.setItem("mobile", data["mobile"]);
              sessionStorage.setItem("name", data ["name"]);
              var topic = "user_"+data['user_num'];

              this.adminservice.subscribe_topic({topic_name : topic , token : sessionStorage.getItem('noti_token')}).subscribe(data =>{
                if(data['status']=='1'){
      
                }else{
                }
              })
              if (data["comp_num"] == "-1" && data["usertype_id"] == "1") {
                   this.router.navigate(["/quick-company-addition"]);
              } else {
                this.router.navigate(["/dashboard"]);
              }
           
          } else {
            this.loading = true;
            this.snackbar.open(data['msg'], "", {
              duration: 3000
            });
          }
        },
        error => {
          this.loading = true;
          this.snackbar.open(this.getCloseWord("Something Went wrong please try again. "), "", {
            duration: 3000
          });
        }
      );
  }

  onImgError(event) {
    event.target.src = this.error_img;
    //Do other stuff with the event.target
  }
  
  onSubmit() {
    this.loading = false;
    if (this.signup == 1 || this.signup == 2 || this.signup == 4) {
      this.loading = true;
      this.signup = 0;
    } else {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
        this.loading = true;
        return;
      }

      this.loading = false;
      let data = this.loginForm.value;
      this.authenticationService
        .login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = true;
            if (data["status"] == 1) {
              this.snackbar.open(this.getCloseWord("Login Successful."), "ok", {
                duration: 3000
              });
              sessionStorage.setItem("user_num", data["user_num"]);
              sessionStorage.setItem("access_token", data["access_token"]);
              sessionStorage.setItem("jwtoken", data["jwtoken"]);
              sessionStorage.setItem("usertype_id", data["usertype_id"]);
              sessionStorage.setItem("comp_num", data["comp_num"]);
              if(this.serverlink == 'maschile' || this.host_name =='maschile' || this.project=='maschile'){
                this.comp_data = {
                  comp_num:'0'
                }
              }
              else{
                this.comp_data ={
                  comp_num:data["comp_num"]
                }
              }
              this.adminservice.Error_image(this.comp_data).subscribe(
                data=>{
                if(data['status']==1 || data['status']=='1'){
                  sessionStorage.setItem("error_image", data['result'].icon_image_link);
                    this.error_img = data['result'].icon_image_link;
                }
                }
              )
              sessionStorage.setItem("username", data["username"]);
              sessionStorage.setItem("email", data["email"]);
              sessionStorage.setItem("mobile", data["mobile"]);
              sessionStorage.setItem("name", data ["name"]);
              this.adminservice.fetch_particular_company_registry_with_sno({s_no:'91',comp_num:data["comp_num"]}).subscribe(data=>{
                if(data["status"]==1){
                  this.quickSetting=data["data"].value
                }
                else{
                  if(data['usertype_id']=='1'){
                    this.router.navigate(["/quick-setting"]);
                  }
               else{
                this.router.navigate(["/dashboard"]);
               }
                }
              })
                  if (data["comp_num"] == "-1" && data["usertype_id"] == "1") {
                         this.router.navigate(["/quick-company-addition"]);
                    }
                    else if(this.quickSetting=="0"||this.quickSetting==""){
                      if(data["usertype_id"] == "1" ){
                        this.router.navigate(["/quick-setting"]);
                      }
                      this.router.navigate(["/dashboard"]);
                     
                   }
                    else {
                      this.router.navigate(["/dashboard"]);
                    }
               
            } else {
              this.loading = true;
              this.snackbar.open(this.getCloseWord(
                "Incorrect Credentials Please Try again "),
                "",
                {
                  duration: 3000
                }
              );
            }
          },
          error => {
            this.loading = true;
            this.snackbar.open(this.getCloseWord("Something Went wrong please try again. "), "", {
              duration: 3000
            });
          }
        );
    }
  }
  PasswordValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let password = control.get("password");
    let confirmPassword = control.get("confirmPassword");
    if (password.pristine || confirmPassword.pristine) {
      return null;
    }
    return password &&
      confirmPassword &&
      password.value != confirmPassword.value
      ? { misMatch: true }
      : null;
  }

  forgotPassword() {
    let postData = this.forgotForm.value;

  
      if (this.forgotForm.invalid) {
        alert(this.getCloseWord("* fields are required."))
      }  
      else{
        this.loading=false;
        this.adminservice.forgotPassword(postData).subscribe(
          data => {
            this.loading=true;
            if (data["status"] == 1) {

             
              this.snackbar.open(data["msg"], "", {
                duration: 3000
              });
             
            } else {
               this.snackbar.open(this.getCloseWord("Please Try Again"), "", {
                duration: 3000
              });
            }
          },
          error => {
             this.loading=true;
            this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
              duration: 3000
            });
          }
        );
        
      }
   
   
   
  }
  // forgotpassword end
  passvible(){
    this.visibility = !this.visibility;
    
  }
   passvible2(){
    this.visibility2 = !this.visibility2;
    
  }
  passvible3(){
    this.visibility3 = !this.visibility3;
    
  }
  getCloseWord(key: string): string {
    var translateValue='';
   this.translate.get(key).subscribe((res: string) => {
     translateValue=res
 });
 return translateValue;
 }
}

