import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from "@angular/forms";
import { first } from "rxjs/operators";
import { NinetoysserviceService } from "../ninetoysservice.service";
import { MatSnackBar } from "@angular/material";
import { AlertService, AuthenticationService } from "../_services";
import { NgbModal, ModalDismissReasons, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-nineseller',
  templateUrl: './nineseller.component.html',
  styleUrls: ['./nineseller.component.css']
})
export class NinesellerComponent implements OnInit {
  public visibility = true;
  public visibility2 = true;
  
   public visibility3 = true;
 
   loginForm: FormGroup;
   registerForm: FormGroup;
   otpForm: FormGroup;
   otpResendForm: FormGroup;
   forgotForm: FormGroup;
   ContactForm: FormGroup;
   public loading = false;
   submitted = false;
   signup;
   signotp = false;
   user_num;
   returnUrl: string;
   time: BehaviorSubject<string> = new BehaviorSubject('00');
   timer: number;
   interval;
   state:'start' | 'stop' = 'stop';
  public closemodal = true;
  modal: any;
   constructor(
     private snackbar: MatSnackBar,
     private formBuilder: FormBuilder,
     private route: ActivatedRoute,
     private router: Router,
     private adminservice: NinetoysserviceService,
        private modalService: NgbModal,
     private authenticationService: AuthenticationService
   ) {}

   isdisable=true;
   startTimer(duration: number) {
     this.state = 'start';
     clearInterval(this.interval)
     this.timer = duration*60 ;
     
     this.interval = setInterval(() => {
       this.updateTimeValue(); 
     }, 1000);
   }
   stopTimer(){
     clearInterval (this.interval);
     this.time.next('00');
     this.state = 'stop';
   }
   updateTimeValue() {
     
     let seconds: any = this.timer % 60;
     
     //seconds = String('0' + Math.floor(seconds)).slice(-2);
     const text =  seconds;
     this.time.next(text);
     --this.timer;
     if (this.timer <= 0) {
      
       this.state = 'stop' ;
     }
   }
 openXl(content) {
    //  this.modalService.open(content, { size: "md" });
     let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
};
const modalRef = this.modalService.open(content, ngbModalOptions);
//ngbModalOptions);
    //  this.modalService.open(content, { size: "md" });
   }
  //  private ModalDismissReasons(reason: any): string {
  //    if (reason === ModalDismissReasons.ESC) {
  //      return "by pressing ESC";
  //    }
  //     else {
  //      return `with: ${reason}`;
  //    }
  //  }
   
 
   ngOnInit() {
  



       sessionStorage.setItem("database", "9toys");
           
     // var host = location.origin;
     let l = location.origin;
     var c = l.split("//");
     var host = c[1];
     this.adminservice
     .hostlink({ host_name: host})
     .subscribe(data => {
       if (data["status"] == 1) {
         if (data["result"].comp_num == "01" && data['result'].name == "Signup"){
           this.signup = 1;
         }else{
           this.signup = 1;
         }
       }else{
         this.signup = 1;
       }
     });
     
     this.loading = true;
     this.ContactForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      mobile: ["", Validators.required],
      message: ["", Validators.required],
      // usertype_id : '2'
    });

     this.loginForm = this.formBuilder.group({
       username: ["", Validators.required],
       password: ["", Validators.required]
       // usertype_id : '2'
     });
      this.forgotForm = this.formBuilder.group({
       username:['' , Validators.required],
     
     
     });
    
     this.registerForm = this.formBuilder.group({
       username: ["", Validators.required],
       // password: ['', Validators.required],
       usertype_id: "1"
     });
     this.otpForm = this.formBuilder.group(
       {
         // username: ['', Validators.required],
         password: ["", Validators.required],
         confirmPassword: ["", Validators.required],
         otp: ["", Validators.required],
         username: ["", Validators.required],
         usertype_id: "1"
       },
       { validator: this.PasswordValidator }
     );
     this.otpResendForm = this.formBuilder.group({
       // username: ['', Validators.required],
       password: ["", Validators.required],
       otp: ["", Validators.required],
       username: ["", Validators.required]
     });
     // get return url from route parameters or default to '/'
     this.returnUrl =
     this.route.snapshot.queryParams["returnUrl"] || "/signup";
     this.loading = true;
   }

   send(){
     if(this.ContactForm.controls.name.value == null || this.ContactForm.controls.name.value == ''){
        
      this.snackbar.open(this.adminservice.getCloseWord(" Please fill field "), "", {
        duration: 3000
      });
     }
    else {
      // this.loading = true;
      this.loading = false;
      let data = this.ContactForm.value;
      this.adminservice
        .send_contact_us_mail(data).subscribe(
          data => {
            this.loading = true;
            if (data["status"] == 1) {
              this.snackbar.open(this.adminservice.getCloseWord("Successfully send your message. "), "", {
                duration: 3000
              });
              this.ngOnInit();
             
             //  this.router.navigate(["/signup"]);
            } else {
              this.loading = true;
              this.snackbar.open(this.adminservice.getCloseWord(" Please Try another "), "", {
                duration: 3000
              });
            }
          },
          error => {
            this.loading = true;
            this.snackbar.open(this.adminservice.getCloseWord(
              "Something Went wrong please try again. "),
              "",
              {
                duration: 3000
              }
            );
          }
        );
    }
   }

   onSubmitSign() {
    this.loading = false;
    if (this.signup == 0 || this.signup == 2) {
      this.signup = 1;
      this.loading = true;
    } else {
      this.loading = true;
      this.submitted = true;

      // stop here if form is invalid
      // if (this.registerForm.invalid) {
      //   this.loading = true;
      //   return;
      // } 
      
       if(this.registerForm.controls.username.value == null || this.registerForm.controls.username.value == ''){
        this.snackbar.open(this.adminservice.getCloseWord("Please enter 10 digit mobile no. or email"), "ok", {
          duration: 3000
        });
        // setTimeout(() => {
        //   this.modal.dismiss('Cross click');
        //  }, 30000);
        

      //   setTimeout(function () {
      //     this.modal.dismiss('Cross click');
      //  }, 2000);
      
        this.loading = true; 
       }
      else {
        this.loading = true;
          //start for check email,mobile format
        let phone = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
    let isPhone = phone.test(this.registerForm.controls.username.value);
      let phoneDig = new RegExp(/^([0-9 ])*$/);
    let isphoneDig=phoneDig.test(this.registerForm.controls.username.value);
    let email = new RegExp("^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
      
    let isemail = email.test(this.registerForm.controls.username.value);
    if(isPhone || isemail){
        //end for check email mobile
      
        this.loading = false;
        let data = this.registerForm.value;
        this.adminservice
          .registerVendor(data)
          .pipe(first())
          .subscribe(
            data => {
              this.loading = true;
              if (data["status"] == 1) {
                this.snackbar.open(this.adminservice.getCloseWord(". "), "", {
                  duration: 3000
                });
                this.loading = true;

                this.signup = 2;
                this.signotp = true;

                this.otpForm.controls.username = this.registerForm.controls.username;
                this.snackbar.open(this.adminservice.getCloseWord(
                  "OTP is send to your email/mobile,please verify. "),
                  "",
                  {
                    duration: 3000
                  }
                );

               
                this.router.navigate(["/signup"]);
              } else {
                this.loading = false;
                this.snackbar.open(this.adminservice.getCloseWord("Already exist, Please Try another "), "", {
                  duration: 3000
                });
              }
            },
            error => {
              this.loading = true;
              this.snackbar.open(this.adminservice.getCloseWord(
                "Something Went wrong please try again. "),
                "",
                {
                  duration: 3000
                }
              );
            }
          );

          }
        else{
          if(!isemail){
            if(isphoneDig){
              this.snackbar.open(this.adminservice.getCloseWord(
                  "Mobile number must have 10 digits. "),
                  "",
                  {
                    duration: 3000
                  }
                );
            }
            else{
              this.snackbar.open(this.adminservice.getCloseWord(
                  "Email is not proper,please try again. "),
                  "",
                  {
                    duration: 3000
                  }
                );
            }
            
          }
          else{
            this.snackbar.open(this.adminservice.getCloseWord(
                  "Mobile number must have 10 digits. "),
                  "",
                  {
                    duration: 3000
                  }
                );
          }
        }
      }
    }
  }
   get f() {
     return this.loginForm.controls;
   }
   onSubmitResend() {
     if (this.signup == 0 || this.signup == 2 || this.signup == 1) {
       this.signup = 4;
       this.loading = true;
     } else {
       if (this.otpForm.invalid) {
         this.loading = true;
         return;
       }
    
       // this.loading = true;
       this.loading = false;
       let data = this.otpForm.value;
       this.adminservice
         .otpVerification(data)
         .pipe(first())
         .subscribe(
           data => {
             this.loading = true;
             if (data["status"] == 1) {
               this.signup = this.signup;
               this.loading = true;
               this.snackbar.open(this.adminservice.getCloseWord(
                 "OTP is send to your email,please verify. "),
                 "",
                 {
                   duration: 3000
                 }
               );
             } else {
               this.loading = true;
               this.snackbar.open(this.adminservice.getCloseWord(
                 "Incorrect Credentials Please Try again "),
                 "",
                 {
                   duration: 3000
                 }
               );
             }
           },
           error => {
             this.loading = true;
             this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong please try again. "), "", {
               duration: 3000
             });
           }
         );
     }
   }
   onSubmitResendOtp() {
     let data = this.otpForm.value;
     if (
       this.otpForm.controls.username == null ||
       this.otpForm.controls.username.value == ""
     ) {
       this.snackbar.open(this.adminservice.getCloseWord("Please fill the username field. "), "", {
         duration: 3000
       });
     } else {
       this.loading = false;
       this.adminservice
         .ResendOtpVerification(data)
         .pipe(first())
         .subscribe(
           data => {
             this.loading = true;
             if (data["status"] == 1) {
               this.signup = this.signup;
               this.loading = true;
               this.snackbar.open(this.adminservice.getCloseWord(
                 "OTP is send to your email/mobile,please verify. "),
                 "",
                 {
                   duration: 3000
                 }
               );
             } else {
               this.loading = true;
               this.snackbar.open(this.adminservice.getCloseWord(
                 "Incorrect Credentials Please Try again "),
                 "",
                 {
                   duration: 3000
                 }
               );
             }
           },
           error => {
             this.loading = true;
             this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong please try again. "), "", {
               duration: 3000
             });
           }
         );
     }
   }
   get g() {
     return this.otpForm.controls;
   }
   onSubmitOTP() {
     // this.signup=false;
     this.loading = false;
 
     if (this.otpForm.invalid) {
       this.loading = true;
       return;
     }
 
     this.loading = false;
     let data = this.otpForm.value;
     this.authenticationService
       .otpVerified(
         this.g.username.value,
         this.g.password.value,
         this.g.otp.value,
         this.g.usertype_id.value,
         this.g.confirmPassword.value
       )
       .subscribe(
         data => {
           this.loading = true;
           if (data["status"] == 1) {
             this.signup = 0;
             this.loading = true;
             this.snackbar.open(this.adminservice.getCloseWord("OTP Verified and Login Successful."), "", {
               duration: 3000
             });
              sessionStorage.setItem("user_num", data["user_num"]);
               sessionStorage.setItem("access_token", data["access_token"]);
               sessionStorage.setItem("jwtoken", data["jwtoken"]);
               sessionStorage.setItem("usertype_id", data["usertype_id"]);
               sessionStorage.setItem("comp_num", data["comp_num"]);
               sessionStorage.setItem("username", data["username"]);
               sessionStorage.setItem("email", data["email"]);
               sessionStorage.setItem("mobile", data["mobile"]);
               sessionStorage.setItem("name", data ["name"]);
 
               if (data["comp_num"] == "0" && data["usertype_id"] == "1") {
                 this.router.navigate(["/add-company"]);
               } else {
                 this.router.navigate(["/dashboard"]);
               }
             // this.snackbar.open(this.adminservice.getCloseWord("OTP Verified. Please Login."), "", {
             //   duration: 3000
             // });
           } else {
             this.loading = true;
             this.snackbar.open(this.adminservice.getCloseWord("Incorrect Credentials Please Try again "), "", {
               duration: 3000
             });
           }
         },
         error => {
           this.loading = true;
           this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong please try again. "), "", {
             duration: 3000
           });
         }
       );
   }
   onSubmit() {
     //this.signup);
     this.loading = false;
     if (this.signup == 2 || this.signup == 4) {
       this.loading = true;
       this.signup = 0;
     } else {
       this.submitted = true;
 
       // stop here if form is invalid
       if (this.loginForm.invalid) {
         this.loading = true;
         return;
       }
 
       this.loading = false;
      //  let data = this.loginForm.value;
       this.authenticationService
         .login(this.f.username.value, this.f.password.value)
         .pipe(first())
         .subscribe(
           data => {
             this.loading = true;
             if (data["status"] == 1) {
               this.closemodal = false;
              //  this.modal.dismiss('Cross click');
               this.snackbar.open(this.adminservice.getCloseWord("Login Succeful. "), "ok", {
                 duration: 3000
               });
              //  location.reload();
              //  this.modal.dismiss('Cross click');
               this.modalService.dismissAll('Save click');
               sessionStorage.setItem("user_num", data["user_num"]);
               sessionStorage.setItem("access_token", data["access_token"]);
               sessionStorage.setItem("jwtoken", data["jwtoken"]);
               sessionStorage.setItem("usertype_id", data["usertype_id"]);
               sessionStorage.setItem("comp_num", data["comp_num"]);
               sessionStorage.setItem("username", data["username"]);
               sessionStorage.setItem("email", data["email"]);
               sessionStorage.setItem("mobile", data["mobile"]);
               sessionStorage.setItem("name", data ["name"]);
 
               if (data["comp_num"] == "0" && data["usertype_id"] == "1") {
                 this.router.navigate(["/add-company"]);
               } else {
                 this.router.navigate(["/dashboard"]);
               }
             } else {
               this.loading = true;
               this.snackbar.open(this.adminservice.getCloseWord(
                 "Incorrect Credentials Please Try again "),
                 "",
                 {
                   duration: 3000
                 }
               );
             }
           },
           error => {
             this.loading = true;
             this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong please try again. "), "", {
               duration: 3000
             });
           }
         );
     }
   }
   PasswordValidator(
     control: AbstractControl
   ): { [key: string]: boolean } | null {
     let password = control.get("password");
     let confirmPassword = control.get("confirmPassword");
     if (password.pristine || confirmPassword.pristine) {
       return null;
     }
     return password &&
       confirmPassword &&
       password.value != confirmPassword.value
       ? { misMatch: true }
       : null;
   }
 
   forgotPassword() {
     let postData = this.forgotForm.value;
       if (this.forgotForm.invalid) {
         alert("* fields are required.")
       }  
       else{
         this.loading=false;
         this.adminservice.forgotPassword(postData).subscribe(
           data => {
             this.loading=true;
             if (data["status"] == 1) {
               this.snackbar.open(data["msg"], "", {
                 duration: 3000
               });
              
             } else {
                this.snackbar.open(this.adminservice.getCloseWord("Please Try Again"), "", {
                 duration: 3000
               });
             }
           },
           error => {
              this.loading=true;
             this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong please try again. "), "", {
               duration: 3000
             });
           }
         );
         
       }
    
    
    
   }
   // forgotpassword end
    passvible(){
     this.visibility = !this.visibility;
     
   }
    passvible2(){
     this.visibility2 = !this.visibility2;
     
   }
   passvible3(){
     this.visibility3 = !this.visibility3;
     
   }
   goTo(location: string): void {
    window.location.hash = location;
}
 }

function NgbdModalContent(NgbdModalContent: any, ngbModalOptions: NgbModalOptions) {
  throw new Error("Function not implemented.");
}
 