

<div *ngIf="deactivate">

    <button mat-icon-button [matMenuTriggerFor]="menu"> 
        <i class="fas fa-ellipsis-v"></i>
      </button>
      
      <mat-menu #menu="matMenu"> 
        
        <div mat-menu-item  class="text-warning" (click)="verify_deverify_company(data.deact,'0')">
            <i class="fas fa-ban text-warning cursor-pointer"  style="font-size: 18px;" title="Deactivate Company"></i> {{"Deactivate" | translate}} 
        </div>
        <div mat-menu-item class="text-danger" (click)="delete_company_data(data.deact)">
            <i class="fas fa-trash text-danger cursor-pointer"  style="font-size: 18px;" title="Deactivate Company"></i> {{"Delete Data" | translate}}
        </div>
      </mat-menu>
   
  </div>

<div *ngIf="view" >
   
      &nbsp;&nbsp;&nbsp;  <i class="fas fa-user-ninja text-primary cursor-pointer" (click)="viewmore(data)" style="font-size: 18px;"  title="View More details"></i>
</div>
<div
     *ngIf="not_verified">
     
    <span class="badge-warning badge">  {{"Not verified" | translate}} </span>
    </div>