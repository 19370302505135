<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>

 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Shruti;
	panose-1:2 0 5 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Verdana;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */

 p{
	 font-size: 18px!important;
 }
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Verdana","sans-serif";}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:purple;
	text-decoration:underline;}
p.mainhead, li.mainhead, div.mainhead
	{mso-style-name:mainhead;
	mso-style-link:mainheadCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:center;
	line-height:115%;
	font-size:12.5pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.mainheadCar
	{mso-style-name:mainheadCar;
	mso-style-link:mainhead;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.DocumentName, li.DocumentName, div.DocumentName
	{mso-style-name:"Document Name";
	mso-style-link:"Document NameCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:center;
	line-height:115%;
	font-size:14.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.DocumentNameCar
	{mso-style-name:"Document NameCar";
	mso-style-link:"Document Name";
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.parthead, li.parthead, div.parthead
	{mso-style-name:parthead;
	mso-style-link:partheadCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	text-transform:uppercase;
	font-weight:bold;}
span.partheadCar
	{mso-style-name:partheadCar;
	mso-style-link:parthead;
	font-family:"Verdana","sans-serif";
	color:black;
	text-transform:uppercase;
	font-weight:bold;}
p.IntroHeading, li.IntroHeading, div.IntroHeading
	{mso-style-name:"Intro Heading";
	mso-style-link:"Intro HeadingCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	text-transform:uppercase;
	font-weight:bold;}
span.IntroHeadingCar
	{mso-style-name:"Intro HeadingCar";
	mso-style-link:"Intro Heading";
	font-family:"Verdana","sans-serif";
	color:black;
	text-transform:uppercase;
	font-weight:bold;}
p.Schedule, li.Schedule, div.Schedule
	{mso-style-name:Schedule;
	mso-style-link:ScheduleCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	text-transform:uppercase;
	font-weight:bold;}
span.ScheduleCar
	{mso-style-name:ScheduleCar;
	mso-style-link:Schedule;
	font-family:"Verdana","sans-serif";
	color:black;
	text-transform:uppercase;
	font-weight:bold;}
p.sectionhead, li.sectionhead, div.sectionhead
	{mso-style-name:sectionhead;
	mso-style-link:sectionheadCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.sectionheadCar
	{mso-style-name:sectionheadCar;
	mso-style-link:sectionhead;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.Level1Heading, li.Level1Heading, div.Level1Heading
	{mso-style-name:"Level 1 Heading";
	mso-style-link:"Level 1 HeadingCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.Level1HeadingCar
	{mso-style-name:"Level 1 HeadingCar";
	mso-style-link:"Level 1 Heading";
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.Sch1Heading, li.Sch1Heading, div.Sch1Heading
	{mso-style-name:"Sch 1 Heading";
	mso-style-link:"Sch 1 HeadingCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.Sch1HeadingCar
	{mso-style-name:"Sch 1 HeadingCar";
	mso-style-link:"Sch 1 Heading";
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.unithead, li.unithead, div.unithead
	{mso-style-name:unithead;
	mso-style-link:unitheadCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.unitheadCar
	{mso-style-name:unitheadCar;
	mso-style-link:unithead;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.Sch2Heading, li.Sch2Heading, div.Sch2Heading
	{mso-style-name:"Sch 2 Heading";
	mso-style-link:"Sch 2 HeadingCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.Sch2HeadingCar
	{mso-style-name:"Sch 2 HeadingCar";
	mso-style-link:"Sch 2 Heading";
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.italichead, li.italichead, div.italichead
	{mso-style-name:italichead;
	mso-style-link:italicheadCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-style:italic;}
span.italicheadCar
	{mso-style-name:italicheadCar;
	mso-style-link:italichead;
	font-family:"Verdana","sans-serif";
	color:black;
	font-style:italic;}
p.unitbody, li.unitbody, div.unitbody
	{mso-style-name:unitbody;
	mso-style-link:unitbodyCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unitbodyCar
	{mso-style-name:unitbodyCar;
	mso-style-link:unitbody;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Parties1, li.Parties1, div.Parties1
	{mso-style-name:"Parties 1";
	mso-style-link:"Parties 1Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Parties1Car
	{mso-style-name:"Parties 1Car";
	mso-style-link:"Parties 1";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Background1, li.Background1, div.Background1
	{mso-style-name:"Background 1";
	mso-style-link:"Background 1Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Background1Car
	{mso-style-name:"Background 1Car";
	mso-style-link:"Background 1";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level1Number, li.Level1Number, div.Level1Number
	{mso-style-name:"Level 1 Number";
	mso-style-link:"Level 1 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level1NumberCar
	{mso-style-name:"Level 1 NumberCar";
	mso-style-link:"Level 1 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Sch1Number, li.Sch1Number, div.Sch1Number
	{mso-style-name:"Sch 1 Number";
	mso-style-link:"Sch 1 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Sch1NumberCar
	{mso-style-name:"Sch 1 NumberCar";
	mso-style-link:"Sch 1 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level2Numbernotlist, li.Level2Numbernotlist, div.Level2Numbernotlist
	{mso-style-name:"Level 2 Number \(not list\)";
	mso-style-link:"Level 2 Number \(not list\)Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level2NumbernotlistCar
	{mso-style-name:"Level 2 Number \(not list\)Car";
	mso-style-link:"Level 2 Number \(not list\)";
	font-family:"Verdana","sans-serif";
	color:black;}
p.unindentedunitbody, li.unindentedunitbody, div.unindentedunitbody
	{mso-style-name:unindentedunitbody;
	mso-style-link:unindentedunitbodyCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unindentedunitbodyCar
	{mso-style-name:unindentedunitbodyCar;
	mso-style-link:unindentedunitbody;
	font-family:"Verdana","sans-serif";
	color:black;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition, li.Definition, div.Definition
	{mso-style-name:Definition;
	mso-style-link:DefinitionCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.DefinitionCar
	{mso-style-name:DefinitionCar;
	mso-style-link:Definition;
	font-family:"Verdana","sans-serif";
	color:black;}
p.partor, li.partor, div.partor
	{mso-style-name:partor;
	mso-style-link:partorCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:center;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.partorCar
	{mso-style-name:partorCar;
	mso-style-link:partor;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.sectionor, li.sectionor, div.sectionor
	{mso-style-name:sectionor;
	mso-style-link:sectionorCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:center;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.sectionorCar
	{mso-style-name:sectionorCar;
	mso-style-link:sectionor;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.unitor, li.unitor, div.unitor
	{mso-style-name:unitor;
	mso-style-link:unitorCar;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:center;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
span.unitorCar
	{mso-style-name:unitorCar;
	mso-style-link:unitor;
	font-family:"Verdana","sans-serif";
	color:black;
	font-weight:bold;}
p.docularlist0, li.docularlist0, div.docularlist0
	{mso-style-name:docularlist0;
	mso-style-link:docularlist0Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.docularlist0Car
	{mso-style-name:docularlist0Car;
	mso-style-link:docularlist0;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level2Number, li.Level2Number, div.Level2Number
	{mso-style-name:"Level 2 Number";
	mso-style-link:"Level 2 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level2NumberCar
	{mso-style-name:"Level 2 NumberCar";
	mso-style-link:"Level 2 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Sch2Number, li.Sch2Number, div.Sch2Number
	{mso-style-name:"Sch 2 Number";
	mso-style-link:"Sch 2 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Sch2NumberCar
	{mso-style-name:"Sch 2 NumberCar";
	mso-style-link:"Sch 2 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.BodyText1, li.BodyText1, div.BodyText1
	{mso-style-name:"Body Text 1";
	mso-style-link:"Body Text 1Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.BodyText1Car
	{mso-style-name:"Body Text 1Car";
	mso-style-link:"Body Text 1";
	font-family:"Verdana","sans-serif";
	color:black;}
p.docularlist1, li.docularlist1, div.docularlist1
	{mso-style-name:docularlist1;
	mso-style-link:docularlist1Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.docularlist1Car
	{mso-style-name:docularlist1Car;
	mso-style-link:docularlist1;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Parties2, li.Parties2, div.Parties2
	{mso-style-name:"Parties 2";
	mso-style-link:"Parties 2Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Parties2Car
	{mso-style-name:"Parties 2Car";
	mso-style-link:"Parties 2";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Background2, li.Background2, div.Background2
	{mso-style-name:"Background 2";
	mso-style-link:"Background 2Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Background2Car
	{mso-style-name:"Background 2Car";
	mso-style-link:"Background 2";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level3Number, li.Level3Number, div.Level3Number
	{mso-style-name:"Level 3 Number";
	mso-style-link:"Level 3 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level3NumberCar
	{mso-style-name:"Level 3 NumberCar";
	mso-style-link:"Level 3 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Sch3Number, li.Sch3Number, div.Sch3Number
	{mso-style-name:"Sch 3 Number";
	mso-style-link:"Sch 3 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Sch3NumberCar
	{mso-style-name:"Sch 3 NumberCar";
	mso-style-link:"Sch 3 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition1, li.Definition1, div.Definition1
	{mso-style-name:"Definition 1";
	mso-style-link:"Definition 1Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition1Car
	{mso-style-name:"Definition 1Car";
	mso-style-link:"Definition 1";
	font-family:"Verdana","sans-serif";
	color:black;}
p.docularlist2, li.docularlist2, div.docularlist2
	{mso-style-name:docularlist2;
	mso-style-link:docularlist2Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:90.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.docularlist2Car
	{mso-style-name:docularlist2Car;
	mso-style-link:docularlist2;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level4Number, li.Level4Number, div.Level4Number
	{mso-style-name:"Level 4 Number";
	mso-style-link:"Level 4 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:90.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level4NumberCar
	{mso-style-name:"Level 4 NumberCar";
	mso-style-link:"Level 4 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Sch4Number, li.Sch4Number, div.Sch4Number
	{mso-style-name:"Sch 4 Number";
	mso-style-link:"Sch 4 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:90.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Sch4NumberCar
	{mso-style-name:"Sch 4 NumberCar";
	mso-style-link:"Sch 4 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition2, li.Definition2, div.Definition2
	{mso-style-name:"Definition 2";
	mso-style-link:"Definition 2Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:90.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition2Car
	{mso-style-name:"Definition 2Car";
	mso-style-link:"Definition 2";
	font-family:"Verdana","sans-serif";
	color:black;}
p.docularlist3, li.docularlist3, div.docularlist3
	{mso-style-name:docularlist3;
	mso-style-link:docularlist3Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:120.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.docularlist3Car
	{mso-style-name:docularlist3Car;
	mso-style-link:docularlist3;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level5Number, li.Level5Number, div.Level5Number
	{mso-style-name:"Level 5 Number";
	mso-style-link:"Level 5 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:120.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level5NumberCar
	{mso-style-name:"Level 5 NumberCar";
	mso-style-link:"Level 5 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Sch5Number, li.Sch5Number, div.Sch5Number
	{mso-style-name:"Sch 5 Number";
	mso-style-link:"Sch 5 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:120.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Sch5NumberCar
	{mso-style-name:"Sch 5 NumberCar";
	mso-style-link:"Sch 5 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition3, li.Definition3, div.Definition3
	{mso-style-name:"Definition 3";
	mso-style-link:"Definition 3Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:120.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition3Car
	{mso-style-name:"Definition 3Car";
	mso-style-link:"Definition 3";
	font-family:"Verdana","sans-serif";
	color:black;}
p.docularlist4, li.docularlist4, div.docularlist4
	{mso-style-name:docularlist4;
	mso-style-link:docularlist4Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:150.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.docularlist4Car
	{mso-style-name:docularlist4Car;
	mso-style-link:docularlist4;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Level6Number, li.Level6Number, div.Level6Number
	{mso-style-name:"Level 6 Number";
	mso-style-link:"Level 6 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:150.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Level6NumberCar
	{mso-style-name:"Level 6 NumberCar";
	mso-style-link:"Level 6 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Sch6Number, li.Sch6Number, div.Sch6Number
	{mso-style-name:"Sch 6 Number";
	mso-style-link:"Sch 6 NumberCar";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:150.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Sch6NumberCar
	{mso-style-name:"Sch 6 NumberCar";
	mso-style-link:"Sch 6 Number";
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition4, li.Definition4, div.Definition4
	{mso-style-name:"Definition 4";
	mso-style-link:"Definition 4Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:150.0pt;
	text-indent:-30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition4Car
	{mso-style-name:"Definition 4Car";
	mso-style-link:"Definition 4";
	font-family:"Verdana","sans-serif";
	color:black;}
p.unnumberedlist0, li.unnumberedlist0, div.unnumberedlist0
	{mso-style-name:unnumberedlist0;
	mso-style-link:unnumberedlist0Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unnumberedlist0Car
	{mso-style-name:unnumberedlist0Car;
	mso-style-link:unnumberedlist0;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definitionunnumbered, li.Definitionunnumbered, div.Definitionunnumbered
	{mso-style-name:"Definition \(unnumbered\)";
	mso-style-link:"Definition \(unnumbered\)Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.DefinitionunnumberedCar
	{mso-style-name:"Definition \(unnumbered\)Car";
	mso-style-link:"Definition \(unnumbered\)";
	font-family:"Verdana","sans-serif";
	color:black;}
p.unnumberedlist1, li.unnumberedlist1, div.unnumberedlist1
	{mso-style-name:unnumberedlist1;
	mso-style-link:unnumberedlist1Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unnumberedlist1Car
	{mso-style-name:unnumberedlist1Car;
	mso-style-link:unnumberedlist1;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition1unnumbered, li.Definition1unnumbered, div.Definition1unnumbered
	{mso-style-name:"Definition 1 \(unnumbered\)";
	mso-style-link:"Definition 1 \(unnumbered\)Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:30.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition1unnumberedCar
	{mso-style-name:"Definition 1 \(unnumbered\)Car";
	mso-style-link:"Definition 1 \(unnumbered\)";
	font-family:"Verdana","sans-serif";
	color:black;}
p.unnumberedlist2, li.unnumberedlist2, div.unnumberedlist2
	{mso-style-name:unnumberedlist2;
	mso-style-link:unnumberedlist2Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unnumberedlist2Car
	{mso-style-name:unnumberedlist2Car;
	mso-style-link:unnumberedlist2;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition2unnumbered, li.Definition2unnumbered, div.Definition2unnumbered
	{mso-style-name:"Definition 2 \(unnumbered\)";
	mso-style-link:"Definition 2 \(unnumbered\)Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:60.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition2unnumberedCar
	{mso-style-name:"Definition 2 \(unnumbered\)Car";
	mso-style-link:"Definition 2 \(unnumbered\)";
	font-family:"Verdana","sans-serif";
	color:black;}
p.unnumberedlist3, li.unnumberedlist3, div.unnumberedlist3
	{mso-style-name:unnumberedlist3;
	mso-style-link:unnumberedlist3Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:90.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unnumberedlist3Car
	{mso-style-name:unnumberedlist3Car;
	mso-style-link:unnumberedlist3;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition3unnumbered, li.Definition3unnumbered, div.Definition3unnumbered
	{mso-style-name:"Definition 3 \(unnumbered\)";
	mso-style-link:"Definition 3 \(unnumbered\)Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:90.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition3unnumberedCar
	{mso-style-name:"Definition 3 \(unnumbered\)Car";
	mso-style-link:"Definition 3 \(unnumbered\)";
	font-family:"Verdana","sans-serif";
	color:black;}
p.unnumberedlist4, li.unnumberedlist4, div.unnumberedlist4
	{mso-style-name:unnumberedlist4;
	mso-style-link:unnumberedlist4Car;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:120.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.unnumberedlist4Car
	{mso-style-name:unnumberedlist4Car;
	mso-style-link:unnumberedlist4;
	font-family:"Verdana","sans-serif";
	color:black;}
p.Definition4unnumbered, li.Definition4unnumbered, div.Definition4unnumbered
	{mso-style-name:"Definition 4 \(unnumbered\)";
	mso-style-link:"Definition 4 \(unnumbered\)Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:120.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Verdana","sans-serif";
	color:black;}
span.Definition4unnumberedCar
	{mso-style-name:"Definition 4 \(unnumbered\)Car";
	mso-style-link:"Definition 4 \(unnumbered\)";
	font-family:"Verdana","sans-serif";
	color:black;}
.MsoChpDefault
	{font-family:"Verdana","sans-serif";}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 3.0cm 70.85pt 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}

</style>

</head>

<body lang=EN-IN link=blue vlink=purple >

<div class="WordSection1 container mt-3">

<p class=DocumentName><span lang=EN-US>TERMS OF SERVICE FOR EcomTrails<sup>TM</sup></span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
0cm'><span lang=EN-US>&nbsp;</span></p>

<p class=Sch2Number style='margin-left:10px;text-align:justify;text-indent:0cm'><span
lang=EN-US> This agreement shall be governing the “LICENSE TO ACCESS AND USE
EcomTrails<sup>TM</sup> HOSTED ON WEBSITE <a href="http://www.ecomtrails.com">WWW.ECOMTRAILS.COM</a>”
on the following terms and conditions:</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>You by signing up on the website www.signup.ecomtrails.com
explicitly provide your consent to terms and conditions and are bound by this
agreement which are Terms of Service for accessing and using the website and
service provided through www.ecomtrails.com. </span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=EN-US>OEPP Innovations Pvt. Ltd.</span></i><span
lang=EN-US>, a company incorporated under the Indian Companies Act, </span><span
lang=EN-US style='color:red'>2013 </span><span lang=EN-US style='color:windowtext'>having
its registered office at ………</span><span lang=EN-US> (The &quot; <b>Service</b>
<b>Provider</b>&quot;) is the exclusive owner and developer of
www.ecomtrails.com and enjoys all the rights and privileges of the service EcomTrails<sup>TM</sup>
hosted through its own-developed website and service being <a
href="http://www.ecomtrails.com">www.ecomtrails.com</a>. The Service Provider
is to grant non-exclusive, non-transferable and non-sub licensable license to
access and use EcomTrails<sup>TM</sup> on the general terms and conditions
contained herein, unless so specifically agreed in writing between you and the
Service Provider.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>The Service Provider reserves its exclusive right
to unilaterally update and change the present terms and conditions, as and when
required, and thus, you are advised to regularly visit this document for the
updated terms and conditions of the license agreement. In case, where you find
that you are not agreeable to any update or change you are required to cease
from using EcomTrails<sup>TM</sup> from this very moment. Any use of EcomTrails<sup>TM</sup>
shall necessarily amount to acceptance of this agreement.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>This Agreement shall continue to be in force for
the period for which the EcomTrails<sup>TM</sup> has been subscribed with,
however, subject to the termination clause as incorporated in the present
agreement in which case this agreement shall terminate in terms of the
termination clause.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>You warrant to the Service Provider that you
have the right to register, subscribe and operate your account for the purposes
of EcomTrails<sup>TM</sup>.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>All the information submitted by you while
registering and subscribing to EcomTrails<sup>TM</sup> are representations made
by you to the Service Provider, thus, ensure that all the information is true
to your knowledge and nothing contained therein is false. Ensure that any of
your representation does not infringes any other person’s right to intellectual
property including but limited to trademark, copyright etc. </span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>You acknowledge the Service Provider is not
required to verify the authenticity of the information / details as provided by
you. In case it is found that any misrepresentation has been made by you, the
Service Provider reserves its right to cancel / rescind / terminate any and
each of the service.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>As the Service Provider functions on the solely
on the information provided by you, it cannot be held liable for any of the
infringement made by you or made on your behalf. You hereby undertake to indemnify
from any of the losses and expenses incurred by the Service Provider.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>You hereby represent to the Service Provider
that the purposes of you subscribing to EcomTrails<sup>TM </sup>is for legal
business purpose only and any of actions does not amount to a violation of any
of the laws.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>10.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The email address supplied by you while subscribing for EcomTrails<sup>TM</sup>
shall be used for the purposes of communication under any agreement including
this agreement. Any communication made by the Service Provider on this e-mail
address shall be deemed too served upon you for all practical purposes.
Further, any communication made on behalf on your behalf shall only be valid if
it is received from this e-mail address. The Service Provider is not required
to entertain any request / communication made by you from any other mode or any
other through e-mail address. Any communication made by shall be directed to
…..@.... only.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>11.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>You undertake to keep the password safe and secure with yourself. It
is made clear that any of the Service Provider shall not in any case be liable
for actions if your account is unauthorized accessed by any else.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>12.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>Any of the data uploaded, displayed, exhibited, generated, stored by
you including but not limited to any images, literature, audio files,
information etc. is done at your own risk and costs. You are yourself
responsible for all such data and the Service Provider shall in non-case incur
any liability. Despite this, the Service Provider in its discretion reserves
the right to remove any such material / data.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>13.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>You understand that any of the transaction made by you using
EcomTrails<sup>TM</sup> is solely between you and your user. In no case, the
Service Provider shall be responsible for execution of any contract of sale
entered by you as one of the party.-</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>14.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>Any information pertaining to EcomTrails<sup>TM </sup>received by
you either through any of the communications or in use of this service including
but not limited to practice, security programs, business model, is a
confidential information and you are hereby restricted to employ, use,
transmit, communicate any of such information to any other party. Violation of
this condition may result into termination of the services provided to you.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>15.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>All the information submitted by you for performance of the services
is owned by you. Notwithstanding anything to the contrary contained herein, the
Service Provider may use your data to the extent necessary to (a) provide the
services to you and (b) in aggregated and anonymized form for its internal
business purposes; providing and optimizing its services; performing analytics
through its internal system or using third party analytics to provide better
value added services to you; for marketing.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>16.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider is </span><span lang=EN-GB>provising</span><span
lang=EN-US> this service on as it is basis. You while subscribing to EcomTrails<sup>TM</sup>
should ensure that you are satisfied by the service levels provided by the
Service Provider, specifically in terms of uptime and downtime. The Service
Provider, however, undertakes to endeavor to provide better services at all
time.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>17.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider may at any time as necessary shall notify
downtime of its services to at least three hours in advance.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>18.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>Nothing in this Agreement shall operate to assign or transfer any
Intellectual Property Rights from the Service Provider to you, or from you to
the Provider.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>19.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider along with the website hosting service, may
also provide for delivery services of the goods displayed by you on the
following terms and conditions:</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>20.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>For the purposes of enabling a smooth and superior experience, the
Service Provider engages services of third parties being ‘Amazon Web Services’
for cloud hosting and ‘Shipyaari’ for delivery services, hereinafter referred
as “third party services”. Kindly note that the current services are subject to
the performance of these Third Party Services and by subscribing to EcomTrails<sup>TM</sup>,
it shall be deemed that you have perused the terms of conditions of these third
party services.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
0cm'><span lang=EN-US>         Terms of Service of these third party services
can be accessed here:</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
0cm'><span lang=EN-US>….</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>21.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>In respect to the third party services, kindly note that the Service
Provider reserves its right to engage services of another third party as and
when required by it even during an existing subscription services. However, the
Service Provider, wherever it deems fit, would notify change of third party.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>22.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>In any of the dispute arising between you and the third party
service providers, the Service Provider shall not be impleaded. You shall be
liable to the compensate for all the losses suffered by the Service Provider
even if such a claim is arising out of a dispute between you and the third
party service provider.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>23.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>It is understood by you that EcomTrails<sup>TM </sup>can also be
made available to any of you market competitors and the Service Provider has
all the rights including the right to solicit your competitors. This is a
non-exclusive license to use EcomTrails<sup>TM</sup>.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>24.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>You cannot sub-license any of the rights received under this
agreement. That is to say, the services are provided to you only and not anyone
else. Any act to the contrary is a cause for termination of the services to
you.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>25.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider reserves its right to modify the terms of this
agreement at any point of time without any requirement of prior notice. This
right further includes right to terminate all the services even without any
reason and no claim in that respect shall be raised against the Service
Provider.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>26.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider further has right to deny anyone provide
services to anyone and thus, there is obligation to anyone.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>27.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider is in its right to suspend the services of
EcomTrails<sup>TM</sup> in case of violation of any of the terms of this
Agreement, Acceptable Usage Policy or any other agreements or Terms of Service
prevailing between the Service Provider and you including, but not limited to
non-payment of subscription amount and expiry of subscription period.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>28.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>In respect of termination of services of anyone including a
subscriber, the decision of the service provider shall be final and binding.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>29.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>Any illegal act by any you or any of your representatives either in
respect of the use of EcomTrails<sup>TM</sup> or any written or verbal abuse
including threats shall be a valid cause for terminating any of the services.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>30.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>No other person apart from you shall have any right to access or use
EcomTrails<sup>TM</sup>. Also, no other person including any of your user shall
have any right to enforce any of the terms contained in this agreement.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>31.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider never seeks any personal information of anyone.
The Service Provider also does not intend to withhold, access or process any of
the personal information which can be received by it. However, it does not
import that it would be barred from seeking any of such information in future,
if required. Provided that if any such information is received by it in the
future, an express consent shall be received by you in terms of the laws
prevailing at that time.</span></p>

<p class=Sch2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>32.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>You are liable for any of the data that is being sought by you from
your users. You shall be required to comply with all the laws relating to data
processing, intermediaries under the Information Technology Act, 2000 or any
law in your jurisdiction prevailing at all relevant times.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>33.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider shall not be liable to you in respect of any
loss of profits or anticipated savings.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>34.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>The Service Provider is only responsible for failures in
performance, if any, arising from the infrastructure and service that it owns.
For any failure resulting due to a fault, disruption at any of the third party
services used by it the Service Provider shall not be liable.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>35.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>All content included in or made available through EcomTrails<sup>TM</sup>
service such as text, graphics, logos, button icons, images, and audio clips,
digital downloads, data compilation and software is the property of the Service
Provider protected by the laws prevailing in India and State of Madhya Pradesh.</span></p>

<p class=Level2Number style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span lang=EN-US>36.<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang=EN-US>Any dispute arising out of this Agreement shall be subject to the exclusive
jurisdiction of the courts at Indore, Madhya Pradesh, India. Any arbitration
agreement entered into between the parties shall have its seat at Indore and
nowhere else.</span></p>

<p class=MsoBodyText style='text-align:justify'><span lang=EN-US>&nbsp;</span></p>

</div>

</body>

</html>
