<div *ngIf="tmcdetails.length>0" class="container mt-5 mb-5">
     <div *ngFor="let data of tmcdetails;let i=index">
       <h2>
        {{i+1}}. &nbsp;{{data.tc_data}}
        </h2>
    <br/>
    </div>
</div>
<div *ngIf="tmcdetails.length==0" class="mt-5 mb-5">
<html *ngIf="project=='ecomtrails'">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Gautami;
	panose-1:2 0 5 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:Verdana;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Times New Roman","serif";}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Times New Roman","serif";}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Times New Roman","serif";}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Times New Roman","serif";}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Times New Roman","serif";}
@page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:612.0pt 792.0pt;
	margin:71.1pt 72.0pt 44.15pt 72.0pt;}
div.WordSection3
	{page:WordSection3;}
@page WordSection4
	{size:612.0pt 792.0pt;
	margin:71.1pt 72.0pt 44.15pt 72.0pt;}
div.WordSection4
	{page:WordSection4;}
@page WordSection5
	{size:612.0pt 792.0pt;
	margin:71.1pt 72.0pt 72.0pt 72.0pt;}
div.WordSection5
	{page:WordSection5;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>


<body lang=EN-IN>
	<div class="container mb-5">
<div class=WordSection1>

<p class=MsoNormal style='line-height:14.15pt;margin-top: 10px'><a name=page1></a><span
style='font-size:12.0pt;font-family:"Verdana","sans-serif"'>
[VENDOR]:<b>{{compd?.display_name | titlecase}}</b>
[VENDOR'S SITE]: <b>{{compd?.host_name}} </b>

The above represented as [VENDOR] & [VENDOR'S SITE] in the below document.
</span></p>

<p class=MsoNormal><b><span style='font-size:24.0pt;font-family:"Verdana","sans-serif"'>Privacy
Policy</span></b></p>

<p class=MsoNormal style='line-height:13.9pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<!-- <p class=MsoNormal><span style='font-family:"Verdana","sans-serif"'>Effective
date: …., ….</span></p> -->

<p class=MsoNormal style='line-height:2.2pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:11.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:106%'><span style='font-family:"Verdana","sans-serif"'>[VENDOR]
(&quot;Us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the [VENDOR'S SITE] website.</span></p>

<p class=MsoNormal style='margin-right:14.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:106%'><span style='font-family:"Verdana","sans-serif"'>This
page informs you of our policies regarding the collection, use, and disclosure
of personal data when you use our Service and the choices you have associated
with that data.</span><span style='font-size:10.0pt;line-height:106%;
font-family:"Verdana","sans-serif"'> </span><span style='font-family:"Verdana","sans-serif"'>We
use your data to provide and improve the Service. By using the Service, you
agree to the collection and use of information in accordance with this policy.
Unless otherwise defined in this {{"Privacy Policy" | translate}}, terms used in this Privacy
Policy have the same meanings as in our Terms and Conditions.</span></p>

<p class=MsoNormal style='line-height:8.2pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Information
Collection And Use</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:1.0pt;line-height:123%'><span
style='font-family:"Verdana","sans-serif"'>We collect several different types
of information for various purposes to provide and improve our Service to you.</span></p>

<p class=MsoNormal style='line-height:7.4pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:14.0pt;font-family:"Verdana","sans-serif"'>Types
of Data Collected</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:12.0pt;font-family:"Verdana","sans-serif"'>Personal
Data</span></b></p>

<p class=MsoNormal style='line-height:13.65pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:110%'><span style='font-family:"Verdana","sans-serif"'>While
using our Service, we may ask you to provide us with certain personally
identifiable information that can be used to contact or identify you
(&quot;Personal Data&quot;). Personally identifiable information may include,
but is not limited to:</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>Email address</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>First name and
last name</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>Phone number</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>Address</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>Cookies and
Usage Data</span></p>

<p class=MsoNormal style='line-height:11.8pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:12.0pt;font-family:"Verdana","sans-serif"'>Usage
Data</span></b></p>

<p class=MsoNormal style='line-height:13.65pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:28.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:111%'><span style='font-family:"Verdana","sans-serif"'>We
may also collect information that your browser sends whenever you visit our
Service or when you access the Service by or through a mobile device
(&quot;Usage Data&quot;).</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:16.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:106%'><span style='font-family:"Verdana","sans-serif"'>This
Usage Data may include information such as your computer's Internet Protocol
address (e.g. IP address), browser type, browser version, the pages of our
Service that you visit, the time and date of your visit, the time spent on
those pages, unique device identifiers and other diagnostic data.</span></p>

<p class=MsoNormal style='line-height:.05pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:24.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:108%'><span style='font-family:"Verdana","sans-serif"'>When
you access the Service by or through a mobile device, this Usage Data may
include information such as the type of mobile device you use, your mobile
device unique ID, the IP address of your mobile device, your mobile operating
system, the type of mobile Internet browser you use, unique device identifiers
and other diagnostic data.</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Verdana","sans-serif"'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection2>

<p class=MsoNormal style='line-height:9.9pt'><span style='font-size:12.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:11.5pt;font-family:"Verdana","sans-serif"'>Tracking
&amp; Cookies Data</span></b></p>

</div>

<span style='font-size:11.0pt;font-family:"Verdana","sans-serif"'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection3>

<p class=MsoNormal style='margin-right:15.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:111%'><a name=page2></a><span style='font-family:
"Verdana","sans-serif"'>We use cookies and similar tracking technologies to
track the activity on our Service and hold certain information.</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:7.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:106%'><span style='font-family:"Verdana","sans-serif"'>Cookies
are files with small amount of data which may include an anonymous unique identifier.
Cookies are sent to your browser from a website and stored on your device.
Tracking technologies also used are beacons, tags, and scripts to collect and
track information and to improve and analyze our Service.</span></p>

<p class=MsoNormal style='line-height:.05pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:15.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:106%'><span style='font-family:"Verdana","sans-serif"'>You
can instruct your browser to refuse all cookies or to indicate when a cookie is
being sent. However, if you do not accept cookies, you may not be able to use
some portions of our Service.</span></p>

<p class=MsoNormal style='line-height:.05pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Use
of Data</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>[VENDOR'S SITE]
uses the collected data for various purposes:</span></p>

<p class=MsoNormal style='line-height:2.2pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To provide and
maintain the Service</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To notify you
about changes to our Service</span></p>

<p class=MsoListParagraph><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Verdana","sans-serif"'>&#9679;<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Verdana","sans-serif"'>To allow you to
participate in interactive features of our Service when you choose to do so</span></p>

<p class=MsoListParagraph><span style='font-size:10.5pt;font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:1.4pt'><span style='font-size:10.5pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To provide
customer care and support</span></p>

<p class=MsoListParagraph><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To provide
analysis or valuable information so that we can improve the Service</span></p>

<p class=MsoListParagraph><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To monitor the
usage of the Service</span></p>

<p class=MsoListParagraph><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To detect,
prevent and address technical issues</span></p>

<p class=MsoListParagraph><span style='font-size:10.0pt;font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:10.05pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Transfer
Of Data</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>Your
information, including Personal Data, may be transferred to </span><span
style='font-family:"Verdana","sans-serif"'>[VENDOR’S SITE]</span><span
style='font-family:"Verdana","sans-serif"'> and maintained on computers located
outside of your state, province, country or other governmental jurisdiction
where the data protection laws may differ than those from your jurisdiction.</span></p>

<p class=MsoNormal style='line-height:.15pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>If
you are located outside India and choose to provide information to us, please
note that we transfer the data, including Personal Data, to India and process
it there.</span></p>

<p class=MsoNormal style='margin-right:8.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:106%'><span style='font-family:"Verdana","sans-serif"'>Your
consent to this {{"Privacy Policy" | translate}} followed by your submission of such information
represents your agreement to that transfer.</span></p>

<p class=MsoNormal style='margin-right:1.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:108%'><span style='font-family:"Verdana","sans-serif"'>[VENDOR]
will take all steps reasonably necessary to ensure that your data is treated
securely and in accordance with this {{"Privacy Policy" | translate}} and no transfer of your
Personal Data will take place to an organization or a country unless there are
adequate controls in place including the security of your data and other
personal information.</span></p>

<p class=MsoNormal style='line-height:8.2pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Disclosure
Of Data</span></b></p>

<p class=MsoNormal style='line-height:13.45pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:14.0pt;font-family:"Verdana","sans-serif"'>Legal
Requirements</span></b></p>

</div>

<span style='font-size:11.0pt;font-family:"Verdana","sans-serif"'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection4>

<p class=MsoNormal style='line-height:12.7pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:111%'><span style='font-family:"Verdana","sans-serif"'>[VENDOR]
may disclose your Personal Data in the good faith belief that such action is
necessary to:</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To comply with a
legal obligation</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Verdana","sans-serif"'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><a
name=page3></a><span style='font-family:"Verdana","sans-serif"'>&#9679;<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Verdana","sans-serif"'>To protect and defend the rights or
property of CropTrails</span></p>

<p class=MsoNormal style='line-height:2.2pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To prevent or
investigate possible wrongdoing in connection with the Service</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To protect the personal
safety of users of the Service or the public</span></p>

<p class=MsoNormal style='line-height:.85pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>To protect
against legal liability</span></p>

<p class=MsoNormal style='line-height:10.05pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Security
Of Data</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>The
security of your data is important to us, but remember that no method of
transmission over the Internet, or method of electronic storage is 100% secure.
While we strive to use commercially acceptable means to protect your Personal
Data, we cannot guarantee its absolute security.</span></p>

<p class=MsoNormal style='line-height:7.65pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Service
Providers</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>We
may employ third party companies and individuals to facilitate our Service
(&quot;Service Providers&quot;), to provide the Service on our behalf, to
perform Service-related services or to assist us in analyzing how our Service
is used.</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>These
third parties have access to your Personal Data only to perform these tasks on
our behalf and are obligated not to disclose or use it for any other purpose.</span></p>

<p class=MsoNormal style='line-height:8.65pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:14.0pt;font-family:"Verdana","sans-serif"'>Analytics</span></b></p>

<p class=MsoNormal style='line-height:12.7pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-family:"Verdana","sans-serif"'>We may use
third-party Service Providers to monitor and analyze the use of our Service.</span></p>

<p class=MsoNormal style='line-height:2.2pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Verdana","sans-serif"'>Google
Analytics</span></b></p>

<p class=MsoNormal style='line-height:.9pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
margin-left:30.0pt;margin-bottom:.0001pt;line-height:106%'><span
style='font-family:"Verdana","sans-serif"'>Google Analytics is a web analytics
service offered by Google that tracks and reports website traffic. Google uses
the data collected to track and monitor the use of our Service. This data is
shared with other Google services. Google may use the collected data to
contextualize and personalize the ads of its own advertising network.</span></p>

<p class=MsoNormal style='line-height:.15pt'><span style='font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:10.0pt;margin-bottom:
0cm;margin-left:30.0pt;margin-bottom:.0001pt;line-height:96%'><span
style='font-family:"Verdana","sans-serif"'>For more information on the privacy
practices of Google, please visit the Google Privacy &amp; Terms web page: </span><span
style='font-family:"Verdana","sans-serif"'><a
href="https://policies.google.com/privacy?hl=en"><span style='font-family:"Arial","sans-serif";
color:windowtext;text-decoration:none'>&#8203;</span><span style='color:#0000EE'>https://policies.google.com/privacy?hl=en</span></a></span></p>

<p class=MsoNormal style='line-height:3.85pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Links
To Other Sites</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>Our
Service may contain links to other sites that are not operated by us. If you
click on a third party link, you will be directed to that third party's site.
We strongly advise you to review the {{"Privacy Policy" | translate}} of every site you visit.</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>We
have no control over and assume no responsibility for the content, privacy
policies or practices of any third party sites or services.</span></p>

<p class=MsoNormal style='line-height:7.55pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Children's
Privacy</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>Our
Service does not address anyone under the age of 18 (&quot;Children&quot;).</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-size:10.0pt;line-height:
113%;font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>We
do not knowingly collect personally identifiable information from anyone under
the age of 18. If you are a parent or guardian and you are aware that your
Children has provided us with Personal Data, please contact us. If we become
aware that we have collected Personal Data from children without verification
of parental consent, we take steps to remove that information <a name=page4></a>from
our servers.</span></p>

<p class=MsoNormal style='line-height:11.4pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Inter-play
of Web Hosting Service Provider</span></b></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='line-height:113%;font-family:
"Verdana","sans-serif"'>Our website has been enabled by a license to use issued
by M/s OEPP Innovations Pvt. Ltd. through their service called as EcomTrails<sup>TM</sup>.
EcomTrails<sup>TM </sup>by itself does not store or process any data, personal
or otherwise, to its use. All the data which is collected by your use or any of
the customer’s use is the property of [VENDOR] itself. However, to ensure to
ensure efficiency, the data is primarily stored in the servers based in India
but an exclusive right to process this data is reserved with the [VENDOR]. To
clarify, M/s M/s OEPP Innovations Pvt. Ltd. or EcomTrails<sup>TM </sup>are not
involved with any of the functions in regard to your data and any action so </span><span
style='font-family:"Verdana","sans-serif"'>taken</span><span style='line-height:
113%;font-family:"Verdana","sans-serif"'> is at the behest of [VENDOR] only. </span></p>

<p class=MsoNormal style='margin-right:32.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:111%'><span style='line-height:111%;font-family:
"Verdana","sans-serif"'> </span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Changes
to This {{"Privacy Policy" | translate}}</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>We
may update our {{"Privacy Policy" | translate}} from time to time. We will notify you of any
changes by posting the new {{"Privacy Policy" | translate}} on this page.</span></p>

<p class=MsoNormal style='line-height:.1pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>We
will let you know via email and/or a prominent notice on our Service, prior to
the change becoming effective and update the &quot;effective date&quot; at the
top of this {{"Privacy Policy" | translate}}.</span></p>

<p class=MsoNormal style='margin-right:.2pt;text-align:justify;text-justify:
inter-ideograph;line-height:113%'><span style='font-family:"Verdana","sans-serif"'>You
are advised to review this {{"Privacy Policy" | translate}} periodically for any changes. Changes
to this {{"Privacy Policy" | translate}} are effective when they are posted on this page.</span></p>

<p class=MsoNormal style='line-height:7.55pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:18.0pt;font-family:"Verdana","sans-serif"'>Contact
Us</span></b></p>

<p class=MsoNormal style='line-height:13.0pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-family:"Verdana","sans-serif"'>If you have
any questions about this {{"Privacy Policy" | translate}}, please contact us:</span></p>

<p class=MsoNormal style='line-height:2.2pt'><span style='font-size:10.0pt;
font-family:"Verdana","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:30.0pt;text-indent:-18.0pt'><span
style='font-family:"Verdana","sans-serif"'>&#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Verdana","sans-serif"'>By email: …….</span></p>

</div>


</div>
</body>

</html>
<div *ngIf="project=='emall'">
	

<section class="contentBlock mt-2">
    <div class="container">
        <div class="titleBig">
            <h1>{{"Privacy Policy" | translate}} for Emall Network LPP</h1>
        </div>
        <div>
        <p>At Emall, accessible from emallnet.com, one of our main priorities is the privacy of our visitors. This {{"Privacy Policy" | translate}} document contains types of information that is collected and recorded by Emall and how we use it.</p>
        <p>If you have additional questions or require more information about our {{"Privacy Policy" | translate}}, do not hesitate to contact us through email at info@emallnet.com</p><br>
        <h2>Log Files</h2><br>
        <p>Emall follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p><br>
        <h2>Cookies and Web Beacons</h2><br>

        <p>Like any other website, Emall uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p><br>
     <h2>Privacy Policies</h2><br>

        <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Emall, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p><br>
      <p>Note that Emall has no access to or control over these cookies that are used by third-party advertisers.</p>

      <!--   <p>You may consult this list to find the {{"Privacy Policy" | translate}} for each of the advertising partners of Emall. Our {{"Privacy Policy" | translate}} was created with the help of the {{"Privacy Policy" | translate}} Generator</p>
        <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Emall, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p><br>
        <p>Note that Emall has no access to or control over these cookies that are used by third-party advertisers.</p><br> -->
       <h2>Third Party Privacy Policies</h2><br>

        <p>Emall's {{"Privacy Policy" | translate}} does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: {{"Privacy Policy" | translate}} Links.</p>
       <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p><br>
       <h2>Children's Information</h2><br>

        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
       <p>Emall does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p><br>
       <h2>Online {{"Privacy Policy" | translate}} Only</h2><br>
         <p>This {{"Privacy Policy" | translate}} applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Emall. This policy is not applicable to any information collected offline or via channels other than this website.</p><br>
       <h2>Consent</h2><br>
        <p>By using our website, you hereby consent to our {{"Privacy Policy" | translate}} and agree to its Terms and Conditions.</p>
        


        </div>
    </div>
</section>	

<app-footer></app-footer>






</div>
</div>
