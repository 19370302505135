
<div *ngIf="controller">
  <div class="container-fluid">
    <div class="row justify-content-center mt-3">
      <div class="col-lg-11 text-center">
        <div class="text-center controller-heading shadow pt-2 ">
          <h4 class="text-center ">{{"Welcome to Controller"|translate}} </h4> 
      </div>
      </div>
    </div>
    <div>
      <div class="mt-3 mb-3 shadow-sm bg-white">
          <div class="row justify-content-around mb-2 pt-3">
            <div class="col-md-6">
              <h4 class="product-border-heading  p-2">  {{"Company Wise Login Report"|translate}} :- </h4>
            </div>
              <div class="col-md-6">
                <h6 class="warning-text text-muted  p-2 " *ngIf="initialLoad">
                  <span class="text-primary " >!</span>   {{"Select Company, start and End Date to view Login Details."|translate}} 
                </h6>
              </div>
            
             
            <!-- </div> -->
          </div>
          <hr>
          <form [formGroup]="CompForm">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-12 ">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-select
                    placeholder="Company Name"
                    formControlName="comp_num"
                    required
                  >
                    <mat-option
                      *ngFor="let company of compdata"
                      [value]="company.comp_num"
                    >
                      {{ company?.display_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <mat-form-field
                  appearance="fill"
                  class="example-full-width"
              
                >
                  <mat-label>{{"Start Date"|translate}} </mat-label>
                  <input
                    type="date"
                    matInput
                    autocomplete="off"
                    placeholder="start date"
                    formControlName="start_date"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <mat-form-field
                  appearance="fill"
                  class="example-full-width"
                  
                >
                  <mat-label>{{"End Date"|translate}} </mat-label>
                  <input
                    type="date"
                    matInput
                    autocomplete="off"
                    placeholder="end date"
                    formControlName="end_date"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md-2 col-sm-6 col-12 pt-0">
                <button (click)="compWiseLogin()" mat-raised-button class=" order-button"  [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor">
                  {{"Show Data"|translate}} 
                </button>
              </div>
            </div>
           
          </form>
        </div>
          <!-- <mat-spinner *ngIf="loadcomp" style="margin-left:40%"></mat-spinner> -->

          <div
            class="table-responsive shadow-sm"
            
            *ngIf="showcompdata"
          >
            <table class="table ">
              <thead>
                <tr >
                  <th>{{"Sno."|translate}} </th>
                  <th>{{"Username"|translate}} </th>
                  <th>{{"Role"|translate}} </th>
                  <th>
                    {{"Company"|translate}} 
                  </th>
                  <th>{{"Login Time"|translate}} </th>
                  <!-- <th>Gps</th>  -->
                  <th>{{"Ip Address"|translate}} </th>
                </tr>
              </thead>
              <tbody style="height: 300px; overflow: scroll;">
                <tr *ngFor="let clogin of comp_login; let i = index" style="height: 100px; overflow: scroll;">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <span class="row">{{ clogin.name }}</span>
                    <span class="row">{{ clogin.email}}</span>
                    <span class="row">{{ clogin.mobile}}</span>

                  </td>
                  <td *ngIf="clogin.usertype_id == '1'">
                   {{"Admin"|translate}} 
                  </td>
                  <td *ngIf="clogin.usertype_id == '2'">
                      {{"Controller"|translate}} 
                  </td>
                  <td *ngIf="clogin.usertype_id == '3'">
                      {{"Operations Manager"|translate}} 
                  </td>
                  <td *ngIf="clogin.usertype_id == '4'">
                      {{"Catalog Manager"|translate}} 
                  </td>
                  <td *ngIf="clogin.usertype_id == '5'">
                      {{"Book Keeper"|translate}} 
                  </td>
                  <td>{{ clogin.company_name }}</td>
                  <td>
                    <div *ngFor="let data of clogin.res">
                      <p>{{ data.access_on }}</p>
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let data of clogin.res">
                      <p>{{ data.access_ip }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <div *ngIf="notshowcompdata" class="row justify-content-center mt-5">
            <div class="col-md-6 text-center">

              <!-- <img src="https://img.icons8.com/color/96/000000/data-.png"/> -->

              <h4 class="text-center">{{"No Data Available"|translate}} </h4> 
            </div>
            
          </div>
         
        <!-- </div> -->

      
    </div>
  </div>
</div>
 <!-- start for ecomtrails package checkout -->
<div class="container-fluid ">
 <p class="ribbon" *ngIf="project=='ecomtrails' && package_checkout_statement !='' && (vendor || vendorPublish)">
  <span class="text cursor-pointer"  routerLink="/package-checkout"><strong class="bold"> {{package_checkout_statement}}</strong> &nbsp;     <button 
    mat-raised-button
    class="order-button " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
     routerLink="/package-checkout"
  >
    {{"Upgrade Your Package"|translate}} 
  </button>
</span>
</p>


</div>

          <!-- end for ecomtrails package checkout -->
<div *ngIf="vendorPublish" class="">
  <div class="container-fluid">
    
    <div class="row mt-1 justify-content-center w-100 pt-2 pb-2" *ngIf="usertype_id!='7'">
    
      <div class="col-md-12" *ngIf="(ecomtrails==true && web_publish==true) || ecomtrails==false">
        <p class="ribbon" >
          <span class="text cursor-pointer" >   <strong class="bold"> <img *ngIf="(ecomtrails==true && web_publish==true) || ecomtrails==false" 
            src="assets/img/verified.svg"
            height="30"
            width="30"
            class="img-fluid mt-2"
          />   <span *ngIf="ecomtrails==true && web_publish==true" class="verified-card-text text-white pl-2">
           <span *ngIf="project!='ecomtrails'"> {{"Your KYC details are Verified."|translate}}</span> {{"You can apply live mode on Click top right side to Publish your Store Link is"|translate}}  <span class="text-primary border-bottom"> {{ hostd.host_name }} </span>
          
          </span>
           <span *ngIf="ecomtrails==false" class="verified-card-text text-white pl-2">
            {{"Congratulations!! You are enabled for sales, your KYC details are Verified."|translate}} 
          
           </span></strong> 
        </span>
        </p>
          </div>
          <!-- <div class="col-md-6 col-8 verified-card-text">
           
          </div> -->
        <!-- </div>
      </div> -->
    </div>
  </div>
</div>

<div *ngIf="vendor">
  <div class="container-fluid" >


<div class="container-fluid mt-3 mb-3">
  <div class="row">
    <div class="col-lg-4 shadow">
      <div class="row">
        <div class="col-lg-5 p-3 text-center">
          <img src="https://img.icons8.com/external-sbts2018-flat-sbts2018/58/000000/external-growth-lean-thinking-sbts2018-flat-sbts2018.png" height="70"/>
        </div>
        <div class="col-lg-7 p-4">
          <h6 >{{ total_sell | currency:"INR"}}</h6>
          <p class="font-weight-600">{{"Total Sales"|translate}} </p>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="shadow cursor-pointer"  routerLink="/order-vendor-new">
        <div class="row">
          <div class="col-lg-5 text-center p-3">
            <img src="https://img.icons8.com/clouds/100/000000/new.png" height="70"/>
          </div>
          <div class="col-lg-7 p-4">
            <h6>{{ count_new_order?count_new_order:'0' }}</h6>
            <p class="font-weight-600">{{"New Orders"|translate}} </p>
          </div>
        </div>

      </div>
    </div>
    <div class="col-lg-4 shadow cursor-pointer"  routerLink="/order-vendor-new">
        <div class="row">
          <div class="col-lg-5 text-center p-3">
            <img src="https://img.icons8.com/fluency/48/000000/shipped.png" height="70"/>
          </div>
          <div class="col-lg-7 p-4">
            <h6>{{ count_complete }}</h6>
            <p class="font-weight-600">{{"Delievered Orders"|translate}} </p>
          </div>
        </div>
    </div>
  </div>
</div>

    <div class="container-fluid test vendo" *ngIf="usertype_id!='7'">
      <div class="row mt-2">
      
        <div class="col-lg-12 shadow">
  <div class="row mt-2">
   <div class="col-lg-12">
    <legend class="product-border-heading" [style.color]="headingcolor">&nbsp;{{"Products"|translate}} </legend>
   </div>
    <div class="col-lg-3 p-2">
      <div class="row">
        <div class="col-lg-4 text-center p-3">
            <img src="https://img.icons8.com/cute-clipart/64/000000/bookmark-ribbon.png" height="40px"/>
         
         </div>
        <div class="col-lg-8 p-2">
          <h6>{{ in_stock }}</h6>
          <p class="font-weight-600">{{"In Stock"|translate}}</p>
        </div>
      </div>
     </div>
    <div class="col-lg-3 border-left">
      <div class="row">
        <div class="col-lg-4 p-3 text-center">
          <img src="https://img.icons8.com/color/48/000000/pancake.png" height="40"/>
        </div>
        <div class="col-lg-8 p-2">
          <h6>{{ out_of_stock }}</h6>
          <p class="font-weight-600">{{"Out Of Stock"|translate}} </p>
        </div>
      </div>
     
    </div>
    <div class="col-lg-3 border-left">
      <div class="row">
        <div class="col-lg-4 p-3 text-center">
          <img src="https://img.icons8.com/external-flat-icons-pause-08/64/000000/external-box-management-flat-icons-pause-08.png" height="40"/>
        </div>
        <div class="col-lg-8 p-2">
          <h6>{{ active_product_count }}</h6>
          <p class="font-weight-600">{{"Active Listing"|translate}} </p>
        </div>
      </div>
    
     
    </div>
    <div class="col-lg-3 border-left">
    <div class="row">
      <div class="col-lg-4 p-3 text-center">
        <img src="https://img.icons8.com/fluency/48/000000/similar-items.png" height="40"/>
      </div>
      <div class="col-lg-8 p-2">
        <h6>{{ inactive_product_count }}</h6>
        <p class="font-weight-600">{{"Inactive Listing"|translate}} </p>
      
      </div>
    </div>
     
    </div>
  </div>
  </div>
  <!-- <div class="col-lg-4 ">
    <div style="display: block; cursor: pointer;" class="shadow p-4">
      <canvas baseChart
                  [data]="demodoughnutChartData"
                  [labels]="doughnutChartLabels"
                  [colors]="chartColors"
                  [chartType]="doughnutChartType"
                  (chartHover)="chartHovered($event)"
                  (chartClick)="chartClicked($event)"></canvas>
  </div>
</div> -->
        </div>
      
       
      </div>
      <div class="container-fluid shadow pb-3 mt-3" >
   
        <div class="row">
          <div class="col-lg-8 ">
            <legend class="product-border-heading mt-2" [style.color]="headingcolor">&nbsp;{{"Users"|translate}} </legend>
          </div>
          <div class="col-lg-4">
            <p class="mt-2">Note : {{daysinfo?daysinfo:'Today'}} & {{info?info:'City'}}  wise Live users are shown.</p>
          </div>
          <div class="col-md-10">
            <div class="row ">
              <div class="col-xl-3 col-lg-4">
                <div class="card ">
                    <div class="card-statistic-3 p-4">
                        <div class="mb-4">
                            <h5 class="card-title mb-0">All Users <span class="float-right"></span></h5>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-5">
                                <h2 class="d-flex align-items-center mb-0" style="font-size: 16px!important;">
                                  {{total?total:'0'}}
                                </h2>
                            </div>
                            <div class="col-lg-7 text-right">
                              {{info?info:'City'}} wise
                            </div>
                        </div>
                        <div class="progress mt-1 " data-height="8" style="height: 8px;">
                            <div class="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 text-center mt-5" *ngIf="!user_count">
              <p>{{nodata}}</p>
            </div>
                <div class="col-xl-2 col-lg-4" *ngFor="let count of user_count">
                    <div class="card">
                        <div class="card-statistic-3 p-4 text-center">
                            <div class="mb-2">
                              <h6 class="text-center"> {{count.count}}</h6>
                                <h5 class="card-title mb-0">Live Users</h5>
                            </div>
                            <div class="row align-items-center mb-2 d-flex">
                                <div class="col-12 text-center small">
                                    {{count.key?count.key:'NA'}}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
               
                
            </div>
        </div>
         
        
         
          <div class="col-lg-2 ">
            <form [formGroup]="timeform">
        <div class="row">
          
          <div class="col-lg-12">
            <div class="border mt-2 " title="show active users city,state and country wise">
              <mat-select  formControlName="group_by" (selectionChange)="areaSelect($event.value)" placeholder="&nbsp;&nbsp;City">
                <mat-option  value="city"> &nbsp;&nbsp;City</mat-option>
                <mat-option  value="state"> &nbsp;&nbsp;State</mat-option>
                <mat-option  value="country"> &nbsp;&nbsp;Country</mat-option>
              </mat-select>
        </div>
          </div>
          <div class="col-lg-4 " title="enter ingiger value">
            <input class="form-control mt-2 "  type="number" placeholder="integer" formControlName="time" />
          </div>
          <div class="col-lg-8" title="show active users by years, months, last week, hours & minuts">
            <div class="border mt-2">
              <mat-select  formControlName="time_unit" (selectionChange)="optionSelect($event.value)" placeholder="Days">
                <mat-option  value="year"> &nbsp;&nbsp;Year</mat-option>
                <mat-option  value="month"> &nbsp;&nbsp;Month</mat-option>
                <mat-option  value="days"> &nbsp;&nbsp;Days</mat-option>
                <mat-option  value="week"> &nbsp;&nbsp;Week</mat-option>
                <mat-option  value="hours"> &nbsp;&nbsp;Hours</mat-option>
                <mat-option  value="minutes"> &nbsp;&nbsp;Minutes</mat-option>
              </mat-select>
        </div>
          </div>
          <div class="col-lg-12 mt-2">
            <button class="order-button w-100" (click)="getTime()" mat-raised-button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">Fetch</button>
          </div>
        </div>
      </form>
          </div>
         
         
        </div>
    </div> 

  </div>

  <div class="container-fluid ">
    <div class="animated fadeIn pt-3">
        <div class="row justify-content-end">
            <div class="col-sm-5">
              <div class="row justify-content-around">
                <div class="border col-md-5 col-5 mt-2">
                      <mat-select  value='{{current_month}}'  placeholder="Select Month">
                        <mat-option *ngFor="let d of datamonth" (click)="update_sale_chart_month(d.no)" value="{{d.no}}">{{d.name}}</mat-option>
                      </mat-select>
                </div>
                <div class="border col-md-5 col-5 mt-2">
                
                  <mat-select value='{{current_year}}'  >
                    <mat-option *ngFor="let d of datayear" (click)="update_sale_chart_year(d.year)" value="{{d.year}}" >{{d.year}}</mat-option>
                  </mat-select>
              </div>
              </div>
            </div>
          </div>
        <div class="chart-wrapper border-success border " style="height:300px;margin-top:10px; margin-bottom: 2%;" *ngIf="salechart">
            <canvas
              baseChart
              class="chart"
              [datasets]="saleChartData"
              [labels]="saleChartLabels"
              [options]="saleChartOptions"
              [colors]="saleChartColours"
              [legend]="saleChartLegend"
              [chartType]="saleChartType"
            >
          </canvas>
        </div>
        <div *ngIf="nosale" style="margin-top:40px;">
          <mat-card>{{"No record"|translate}} </mat-card>
        </div>
     
    </div>
  </div>
</div>

<div *ngIf="vnotverified" >
  <div class="container-fluid" >
   
          <p class="ribbon" *ngIf="project=='ecomtrails' && package_checkout_statement !=''">
            <span class="text cursor-pointer"  routerLink="/package-checkout"><strong class="bold">{{package_checkout_statement}}</strong> &nbsp;  <button 
              mat-raised-button
              class="order-button " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
               routerLink="/package-checkout"
            >
              {{"Upgrade Your Package"|translate}} 
            </button></span>
          </p>

          <!-- end for ecomtrails package checkout -->
    
    <div class="row">
      <div class="col-md-12 text-info" style="font-size: 22px;">
        <marquee *ngIf="ecomtrails==true" onmouseover="this.stop();" onmouseout="this.start();" class="cursor-pointer" matTooltip="hover to stop! then read">
         <q> {{"We are in process of verification of your KYC details, before your webstore can be hosted. Till then you can add your products, brands and other details. Patience is appreciated."|translate}} </q>
        </marquee>

         <marquee *ngIf="ecomtrails==false" onmouseover="this.stop();" onmouseout="this.start();" class="cursor-pointer" matTooltip="hover to stop! then read">
        <q>{{"KYC verification under proces, please add products until then."|translate}}</q>
        </marquee>
      </div>
    </div>
  </div>
</div>


