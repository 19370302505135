import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NinetoysserviceService } from '../ninetoysservice.service';
// import {MatSnackBar} from '@angular/material';
// import { AmazingTimePickerService } from "amazing-time-picker";
import { MatSnackBar } from "@angular/material/snack-bar";
// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [DatePipe]
})
export class NotificationComponent implements OnInit {
 public access_token=sessionStorage.getItem('access_token');
  public user_num=sessionStorage.getItem('user_num');
  public comp_num = sessionStorage.getItem('comp_num');
  public usertype_id = sessionStorage.getItem('usertype_id');
  registerForm: FormGroup;
  registerForm2: FormGroup;
  imgHeight=0;
  imgWidth=0;
  image : File;
  thumbnail1 : File;
  thumbnail2 : File;
  imagePath;
  imageURL;
  is_checked;
  loading;


  dropdown;
  box;
  dropdown2;
  box2;
  minimumTime="00:00";

  fileData: File = null;
  previewUrl:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

    minDate = new Date();
    minTime;
    minDate2;


  constructor(
  	private snackbar:MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: NinetoysserviceService,
        // private atp: AmazingTimePickerService,
      //  private ngxTimepicker: NgxMaterialTimepickerModule	,
       private datePipe: DatePipe

    ) { 
   this.minDate2 = formatDate(this.minDate, 'dd-MM-yyyy', 'en-US', '+0530');
 }

  ngOnInit() {
    
  	   this.minTime= this.minDate.getHours() + ':' + (this.minDate.getMinutes()+5);
     

  	this.registerForm = this.formBuilder.group({
      title : ['' , Validators.required],
      message : [''],  
      image : [''],
      app_date:this.minDate,
      app_time:this.minTime
     });
     this.minimumTime=this.minTime;
  }

  get f() { 
    return this.registerForm.controls; 
  }
  onFileChanged1(fileInput: any) {
     this.previewUrl = null;
   this.fileData = null;
   this.image = null;    

    this.fileData = <File>fileInput.target.files[0];
    this.image = <File>fileInput.target.files[0];  

      let siz11 = Math.ceil(this.image.size / 1024);

    var checkimg11 = this.image.name.toLowerCase();
     if (!checkimg11.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)) {
      this.snackbar.open(this.adminService.getCloseWord("Only jpg,png,jpeg file format support. "), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
     this.previewUrl = null;
                this.fileData = null;
               this.image = null;   
                this.registerForm.get('image').setValue('');
 this.registerForm.controls.image = null;
      return false;
    }

    if (siz11 > 2048) {
      this.snackbar.open(this.adminService.getCloseWord("Only 2MB and less size file can upload. "), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
      this.previewUrl = null;
                this.fileData = null;
               this.image = null;  
                this.registerForm.get('image').setValue('');
 this.registerForm.controls.image = null; 

      return false;
    }

  
 let img = new Image();
      
      img.src = window.URL.createObjectURL(this.fileData);
      img.onload = () => {
       this.imgHeight=img.height;
       this.imgWidth=img.width;
       
        this.previewT();
     }
  }
 
  previewT() {
    // Show preview 
    var mimeType2 = this.fileData.type;
    if (mimeType2.match(/image\/*/) == null) {
      return;
    }
 
    var reader2 = new FileReader();      
    reader2.readAsDataURL(this.fileData); 
    reader2.onload = (_event) => { 
       if( this.imgHeight > 5000 && this.imgWidth > 7000 ) {
             this.snackbar.open(this.adminService.getCloseWord('Image height should < 5000 and Width should < 7000. '),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              }); 
             
             
              this.previewUrl = null;
                this.fileData = null;
               this.image = null; 
                this.registerForm.get('image').setValue('');
 				this.registerForm.controls.image = null;   

              return;
          }
          else if( this.imgWidth > 7000   ) {
              this.snackbar.open(this.adminService.getCloseWord('Image Width should < 7000. '),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              }); 
             this.previewUrl = null;
               this.fileData = null;
               this.image = null;   
                this.registerForm.get('image').setValue('');
 				this.registerForm.controls.image = null; 

              return;
          }
           else if( this.imgHeight > 5000 ) {
              this.snackbar.open(this.adminService.getCloseWord('Image height should < 5000. '),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              });
             this.previewUrl = null;
               this.fileData = null;
               this.image = null;  
                this.registerForm.get('image').setValue('');
 				this.registerForm.controls.image = null;  

              return;
          }
          else{
               this.previewUrl = reader2.result; 
          }

    }
  }
   
getFirst(e){
	this.dropdown=false;
	this.box=false;
	if(e==1){
		this.dropdown=true;

	}
	if(e==2){
		this.box=true;
	}
}

getSecond(e){
	this.dropdown2=false;
	this.box2=false;
	if(e==1){
		this.dropdown2=true;

	}
	if(e==2){
		this.box2=true;
	}
}

onChangeDemo(checked: boolean) {
    if (checked) {
      this.is_checked = true;
    } else {
      this.is_checked = false;
    }
  }

  //  open() {
  //     const amazingTimePicker = this.atp.open({
  //     time:this.registerForm.value.app_time
  //     });
  //     amazingTimePicker.afterClose().subscribe(time => {
  //     this.registerForm.patchValue({
  //           app_time: time,
  //       });
  //     });
  //   }
    search2(e){
  
       if(this.datePipe.transform(this.registerForm.value.app_date, 'MM/dd/yyyy')==this.datePipe.transform(this.minDate, 'MM/dd/yyyy')){
       this.minimumTime=this.minTime;
       this.registerForm.get('app_time').setValue(this.minimumTime);
     }
     else{
        this.minimumTime="00:00";
     }
    
  }
}
