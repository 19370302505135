import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NinetoysserviceService } from '../ninetoysservice.service';
import {MatSnackBar} from '@angular/material';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import {
  HttpClient,
} from "@angular/common/http";
import {MatSort} from '@angular/material/sort';
import { WindowRefService } from "../window-ref.service";
import { environment } from "../../environments/environment";
import {
  switchMap,
  startWith,
} from "rxjs/operators";
import { timer, Observable, Subject, interval, Subscription } from "rxjs";
import { TimeInterval } from "rxjs/internal/operators/timeInterval";

@Component({
   selector: 'app-package-checkout',
  templateUrl: './package-checkout.component.html',
  styleUrls: ['./package-checkout.component.css'],
   providers: [WindowRefService]
})
export class PackageCheckoutComponent implements OnInit {
 @ViewChild(MatSort, {static: true}) sort: MatSort;
myRecharge:FormGroup;
couponForm: FormGroup;
package_d;
package_activation=0;
  public opencoupen = false;

  functionality=true;
  sub_user=true;
  shipping=true;
  coupon=true;
  member=true;
  package_details;
  minDate=new Date();
  expiry=0;
  package_expiry:any;
  new_package_amount=0;
  package_amount=1;

  // public subscription: Subscription;
 favicon=environment.favicon;
           logo=environment.logo;
            loaderAsset=environment.loader;
            error_img = sessionStorage.getItem('error_image');
            website=environment.website;
            project=environment.project;

  member_no;
  public paymentStatus = "Pending";
  public api;
  public subscription: Subscription;
  public flag = 0;
  makePay=false;
  private foo: any = null;
  public counts=0;
  data={productinfo:sessionStorage.getItem("productinfo")};
  previewFlag = sessionStorage.getItem('previewFlag');
  rechargeT='add';
  loader=false;
  usertypeid = sessionStorage.getItem('usertype_id');

  public access_token=sessionStorage.getItem('access_token');
  public user_num=sessionStorage.getItem('user_num');  
  public comp_num=sessionStorage.getItem('comp_num');  
  buis_update;
	allResult;
	loading=true;
	buisness_details;
	address_details;
	updateAddres;
	bank_details;
	razorpayId;
  razorpay_order_id;
 rz_key;
 offset=1;
        page_items=20;
        pages=1;

amount=0;
request_amount;
list_amount=[];
sum_amount=0;
txn_id:123;
  coupon_count=1;

total_amount=0;
disc_price=0;
 paymentOptions=[];
 actual_data=[];
  constructor( 
  	private snackbar:MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: NinetoysserviceService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private winRef: WindowRefService,
     private http: HttpClient
    ) 
  { }
   openXl(content) {
      this.modalService.open(content, { size: 'md' });
    }
     private getDismissReason(reason: any): string { 
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }

  public fillPaymentForm = {
    firstname: "",
    email: "",
    key: "",
    hash: "",
    txnid: "",
    amount: "",
    phone: "",
    curl:"",
    productinfo: "",
    surl: "",
    furl: "",
    service_provider: "payu_paisa",
    udf1: "",
    udf2: "",
    udf3: "",
    udf4: "",
    udf5: ""

  };
  public fillPaytmForm = {

    MID: "",
    WEBSITE: "",
    ORDER_ID: "",
    CUST_ID: "",
    MOBILE_NO: "",
    EMAIL: "",
    INDUSTRY_TYPE_ID: "",
    CHANNEL_ID: "",
    TXN_AMOUNT: "",
    CALLBACK_URL: "",
    CHECKSUMHASH: "",
  };

  ngOnInit(): void {
     this.couponForm=this.fb.group({
       coupon_code:['']
     });
     this.myRecharge=this.fb.group({
      comp_num:this.comp_num,
      user_num:this.user_num,
      access_token:this.access_token,
      amount2:[''],
      amount:[''],
      disc_price:[0],
      total_amount:[''],
      package_cup_no:[0],
      coupon_code:[''],
      member_no:sessionStorage.getItem('buisness_no'),
      payment_method_id: "",
      package_id:[''],
      package_activation:this.package_activation,

    key: "",
    currency: "INR",
    receipt: "rcptid_111",
    payment_capture: "1",
    name: "",
    email: "",
    mobile: ""
    });
     if(this.usertypeid!='2'){
         this.makePay=true;
      this.myRecharge.get('payment_method_id').setValue('2');
     }
     this.fetch_package();
     this.fetch_package_by_company(this.comp_num);
     this.getPaymentOptions();
     this.companyList();
  }
action(rechargeT){
  if(rechargeT=='fetch'){
    this.makePay=false;
  }
  this.rechargeT=rechargeT;
}
getPaymentOptions() {
    let postData = {
      user_num: sessionStorage.getItem("user_num"),
      access_token: sessionStorage.getItem("access_token"),
      comp_num: 0
    };
    this.adminService.paymentListAdmin(postData).subscribe(data => {
      if(data['status']==1){
      this.paymentOptions = data["result"];
      }
      else if(data['status']==10){
        sessionStorage.clear();
         this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
      }); 
  }

fetch_package(){
		this.loading=false;
      let postData = {
      	user_num:this.user_num,
      	access_token:this.access_token,
        comp_num:0
      };
      if(this.usertypeid=='2' || this.comp_num=='0'){
        postData['pay']=1;
      }
      this.adminService.fetchPackage(postData).subscribe(
        data => {
          if (data["status"] == 1) {
             this.loading=true;
            this.allResult = data["result"];
             // this.fetch_member_transaction(this.offset);
          }
          else if(data['status']==10){
            sessionStorage.clear();
             this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                      duration: 3000,
                      horizontalPosition:'center',
              });      
            this.router.navigate(['/']);
            }
          else {
          	this.loading=true;
          }
        },
        error => {
        	this.loading=true;
        }
      );
    }

    getPack3(e,comp_num){
      ////comp_num);
this.myRecharge.get('comp_num').setValue(comp_num);
this.fetch_package_by_company(comp_num);
////e);
////this.myRecharge.value);
    }
    getPack(e,allResult){
      ////allResult);
// this.myRecharge.get('price').setValue(price);
////e);
////this.myRecharge.value);
    }
     getPack2(e,price,allResult,package_d){
      ////allResult);
      this.myRecharge.get('amount').setValue(price);
       this.myRecharge.get('disc_price').setValue(0);
      this.new_package_amount=price;
this.myRecharge.get('total_amount').setValue(JSON.stringify(price));

this.myRecharge.get('total_amount').setValue(JSON.stringify(price));
this.myRecharge.get('amount').setValue(JSON.stringify(price));
////e);
////this.myRecharge.value);
this.package_d=package_d;
    }

    getPaymentDetails(mode){
      ////mode);
      this.makePay=true;
      this.myRecharge.get('payment_method_id').setValue(mode);
      ////this.myRecharge.value);
     if(mode == 3 || mode=='3'){

 this.paymentPayumoney();
  

    }
    else if(mode == 4 || mode == '4'){
      this.paymentPaytm();
    }
    }

      paymentPaytm(){
    this.loader = false;
   
    this.adminService.payment(1).subscribe(data => {
      this.loader = false;
      this.fillPaytmForm = {
            MID: data["MID"],
            WEBSITE: data["WEBSITE"],
            ORDER_ID:data["ORDER_ID"],
            CUST_ID: data["CUST_ID"],
            MOBILE_NO:data["MOBILE_NO"],
            EMAIL: data["EMAIL"],
            INDUSTRY_TYPE_ID: data["INDUSTRY_TYPE_ID"],
            CHANNEL_ID: data["CHANNEL_ID"],
            TXN_AMOUNT: data["TXN_AMOUNT"],
            CALLBACK_URL: data["CALLBACK_URL"],
            CHECKSUMHASH: data["CHECKSUMHASH"],
         
            // curl:"https://ecomtrails.com/ecom_api/index.php/transaction/transaction_of_orders"
          };
      sessionStorage.setItem("productinfo", data["productinfo"]);
  this.razorpayId = data["productinfo"];
     this.razorpayId = data["productinfo"];
      error => {}
   } );
    // this.loader = true;
  }
    paytm2(form){
    form.submit();
     window.open("https://securegw-stage.paytm.in/order/process", "_blank");
    this.navigateTo(this.razorpayId);
  }
    paymentPayumoney() {
    this.loader = false;
    this.adminService.payment(1).subscribe(data => {
      this.loader = false;
      if(data['status']==1){
        // if(1){
      this.fillPaymentForm = {
            // key: "TR4L7npm",
            // key:"ETUuRBef",
            key:data["mkey"],
            // key:"gtKFFx",
            txnid: data["txnid"],
             // amount: "15",
            amount: data["amount"],
               productinfo: data["productinfo"],
                firstname: data["name"],
            email: data["mailid"],
            phone: data["phoneno"],
            udf1: data["udf1"],
            udf2:data["udf2"],
            udf3:  data["udf3"],
            udf4:data["udf4"],
            udf5: data["udf5"],
            surl:data["success"],
            furl:data["failure"],
            curl:data["cancel"],
              hash: data["hash"],
            service_provider:"payu_paisa",
        
          };
      sessionStorage.setItem("productinfo", data["udf3"]);
  this.razorpayId = data["udf3"];

     this.razorpayId = data["productinfo"];
   }

   else if(data['status']==10){
    sessionStorage.clear();
     this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
              duration: 3000,
              horizontalPosition:'center',
      });      
    this.router.navigate(['/']);
    }

  
      error => {}
   } );
  }
paymentPayumoney2(e,r){
    e.submit();
    window.open("https://sandboxsecure.payu.in/_payment", 'formresult', 'scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no');
  this.navigateTo(r);
  

  }
    recharge(){
     if(this.myRecharge.invalid){
       if(this.myRecharge.controls.package_id.value==''){
          alert("Please choose Package");
       }
       else{
          alert("Please fill required fields");
       }
      //   this.snackbar.open(this.adminService.getCloseWord("Please fill required fields","ok", {
      //     duration: 3000
      //   }); 
      }
      else{
        var res=false;
        if(this.expiry !=1 && this.new_package_amount>this.package_amount){//upgrade package
          res=confirm('Do you want to upgrade package by today');
          if(res){
            this.package_activation=1;
          }
          else{
            res =true;
             this.package_activation=0;
          }
        }
        else  if(this.expiry !=1 && this.new_package_amount<=this.package_amount)//package will change after expire current
        {
           res =true;
           this.package_activation=0;
        }
        else{//package upgrade today
          res =true;
        }
        if(res){
          this.myRecharge.get('package_activation').setValue(this.package_activation);
    	var post= { comp_num: sessionStorage.getItem("comp_num"),
              package_activation:this.package_activation,
                  user_num:this.user_num,
                access_token:this.access_token,
                member_no:this.myRecharge.controls.member_no.value,
                amount:this.myRecharge.controls.amount2.value,
                 total_amount:this.myRecharge.controls.total_amount.value,
                 receipt:this.myRecharge.controls.receipt.value,
                payment_capture:this.myRecharge.controls.payment_capture.value,
                currency:this.myRecharge.controls.currency.value,
               // amount:this.myRecharge.controls.amount2.value,
               
                };
    	  this.loading = false;
   ////post);
   ////this.myRecharge.value);
   if(this.myRecharge.controls.package_cup_no.value==0){
     this.myRecharge.removeControl('package_cup_no');
   }

    this.adminService.package_transaction_initiate(this.myRecharge.value).subscribe(data => {
      this.loading = true;
      // var orders = data["order_id"];
      this.amount = data["amount"];
      this.rz_key=data['key'];
      this.razorpay_order_id = data["razorpay_order_id"];
      this.razorpayId = data["packageinfo"];
////data["packageinfo"]);
//// this.razorpayId);
      sessionStorage.setItem("productinfo", data["packageinfo"]);
   // this.modalService.dismissAll('Save click');
           if(this.myRecharge.controls.payment_method_id.value==2 || this.myRecharge.controls.payment_method_id.value=='2'){
              this.initPay();
           }
      this.navigateTo(this.razorpayId);
    });
  }
    // this.loading = true;
  }
    }



  public initPay(): void {
    var rzp1 = new this.winRef.nativeWindow.Razorpay({
      // key: "rzp_test_9XUwfPpg7b1vBo", //testing
      // key: "rzp_live_pEf6WmxW8zCcr9", //live
      key:this.rz_key,
      amount: this.myRecharge.controls.total_amount.value,
      name:'',
      order_id: this.razorpay_order_id,
      handler: this.paymentResponseHander.bind(this),
      prefill: {
        name: '',
        email: '',
        contact:''
      },
      notes: {},
      theme: {
        color: "#d4474d"
      },
      callback_url:
        environment.NodebaseUrl + "user/package_transaction_razorpay"
    });
    rzp1.open();
  }

  navigateTo(id) {
   this.loader=true;
   	this.getPaymentStatus();
  }

  getPaymentStatus() {
 var post={
productinfo:this.razorpayId,
method:"package_transaction"
  };
     this.api = interval(7000).pipe(startWith(0), switchMap(() => this.adminService.payment_status_check(post)));  
    // this.api=setInterval(() => {this.adminService.payment_status_check(this.data)}, 5000);
      // if(this.counts<50){
    this.subscription = this.api.subscribe(data => {
       this.counts++;
        if (data["status"] == 1) {
            this.paymentStatus = "Success";
             // if( this.paymentStatus == "Success" || this.counts > 70){
               if( this.paymentStatus == "Success" ){
             this.loader=false;
               this.snackbar.open(this.adminService.getCloseWord("Recharge Successful"),"ok", {
     duration: 5000
     }); 
               this.subscription.unsubscribe();
            }
            setTimeout(()=>{
                              this.router.navigate(["/dashboard"]);

            
            },2000);
        }
        else if(data['status']==10){
          sessionStorage.clear();
           this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                    duration: 3000,
                    horizontalPosition:'center',
            });      
          this.router.navigate(['/']);
          }
        
        else if (data["status"] == 0) {
            this.paymentStatus = "Pending";
            // if(  this.counts > 50){
            //   this.subscription.unsubscribe();
            // }
        } else {
        	this.loader=false;
          this.paymentStatus = "Failed";
           if( this.paymentStatus == "Failed"){
          
           // if( this.paymentStatus == "Failed" || this.counts > 30){
             this.subscription.unsubscribe();
          }
         
          
           setTimeout(()=>{
                              this.router.navigate(["/dashboard"]);

            
           },3000);
        }
      if(this.flag==1) {
      	this.loader=false;
          setTimeout(()=>{
           
                            this.router.navigate(["/dashboard"]);

          },4000);
      }
     
    });
  // }
  }
ngOnDestroy() {
 if(this.subscription){
    this.subscription.unsubscribe();
}
}

  paymentResponseHander(response) {
    let postData = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_signature: response.razorpay_signature,
      razorpay_order_id: response.razorpay_order_id
    };
    this.adminService.package_transaction_razorpay(postData).subscribe(
      data => {
        if (data["status"] == "1") {
        }

        else if(data['status']==10){
          sessionStorage.clear();
           this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                    duration: 3000,
                    horizontalPosition:'center',
            });      
          this.router.navigate(['/']);
          }
      },
      error => {
        // this.placeOrder = false;
      }
    );
  }

 check(form){
  }

companyList(){
  this.adminService.get_company_all_with_kyc({user_num : this.user_num,access_token : this.access_token}).subscribe(
      data=>{
        if(data['status'] == 1){
         var z=0;
          // this.data = data['result'];
          for(let count=0;count<data['result'].length;count++){
             // if(this.data[count].verify==true){
               // this.actual_data[count].display_name=this.data[count]?.current?.displayInfo?.display_name;
          this.actual_data.push({comp_num:JSON.stringify(data['result'][count].comp_num),name:data['result'][count]?.current?.displayInfo?.display_name});
            // }
          }
          ////this.actual_data);
        }
        else if(data['status'] == 0){
        }
        else if(data['status'] == 10){
          sessionStorage.clear();
          this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
            duration: 3000,
            horizontalPosition:'center',
              });      
          this.router.navigate(['/']);
        }       
      },
      error => {
        this.snackbar.open(this.adminService.getCloseWord("Failed Try Again"), "Ok", {
          duration: 3000
        });
        this.router.navigate(["/Admin"]);
});
}
  // start for package
    fetch_package_by_company(comp_num){
      if(this.project=='ecomtrails'){
        this.adminService
      
      .fetch_package_by_company({ comp_num:  comp_num,user_num:this.user_num,access_token:this.access_token })
      .subscribe(data => {
        if (data["status"] == 1) {
         
         this.package_details=data['result'];
         this.package_amount=this.package_details?.price;
        
var event= this.minDate;
     var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var from_date2
  =year+'-'+month+'-'+day
  ;
 
 var splitExp=[];
 var splitNew=[];
 ////console.log(this.package_expiry);
 splitExp = this.package_expiry.split("-");
  splitNew = from_date2.split("-");
  if(this.package_details?.package_id==1){
     this.expiry=1;//free tier
       // this.functionality=false;//not allowed web publish
  }
  else{
          if(splitNew.length==splitNew.length){
            if(parseInt(splitExp[0])==parseInt(splitNew[0])){ //equal year
               if(parseInt(splitExp[1])==parseInt(splitNew[1])){ //equal month
                      if((parseInt(splitExp[2])-parseInt(splitNew[2]))<=5 ){ //equal or 5 day diff
                      }
                         if((parseInt(splitExp[2])-parseInt(splitNew[2]))<0 ){ //equal or 5 day diff
                        this.expiry=1;//expired or near
                          this.functionality=false;
                      }
                }
                else if(parseInt(splitExp[1])<parseInt(splitNew[1])){//less month
                  this.expiry=1;//expired
                    this.functionality=false;//not allowed web publish
                }
            }
            else if(parseInt(splitExp[0])<parseInt(splitNew[0]) && splitExp[0]!='0000'){//less year
              this.expiry=1;//expired
                this.functionality=false;//not allowed web publish
        
            }
          }
       }
      }

      else if(data['status']==10){
        sessionStorage.clear();
         this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
      else{
          this.expiry=1;//free tier
            this.functionality=false;//not allowed web publish    
      }
    });
    }
  }

// end for package

// start for coupon
coupon_code(){
     this.myRecharge.get('package_cup_no').setValue(0);
     this.myRecharge.get('coupon_code').setValue('');
    if(this.couponForm.controls.coupon_code.value==null || this.couponForm.controls.coupon_code.value==''){
      this.snackbar.open(this.adminService.getCloseWord("ENTER COUPON CODE"),"",{duration: 5000,horizontalPosition: "center"});
    }
    else{
    if(this.coupon_count==1){
    this.coupon_count = 0;
 // this.myRecharge.get('package_cup_no').setValue(couponform.value.coupon);
 this.myRecharge.get('coupon_code').setValue(this.couponForm.controls.coupon_code.value);
       var post={
         user_num:this.user_num,
         access_token:this.access_token,
         comp_num:this.myRecharge.controls.comp_num.value,
         coupon_code:this.myRecharge.controls.coupon_code.value,
         package_id:this.myRecharge.controls.package_id.value
       }
    this.adminService.fetch_package_coupon_code(post).subscribe(data => {
        if (data["status"] == 10) {
           this.snackbar.open(this.adminService.getCloseWord("Multiple login with this ID has been detected, Logging you out. "),"",{duration: 5000,horizontalPosition: "center"});
          this.router.navigate(["/"]);
        }
      if(data['status'] == 1){

         this.myRecharge.get('package_cup_no').setValue(data['package_cup_no']);
  this.myRecharge.get('disc_price').setValue(data['discount_price']);
          this.myRecharge.get('amount2').setValue(JSON.stringify(data['total_amount']));
     this.myRecharge.get('total_amount').setValue(JSON.stringify(data['total_amount']));
      this.myRecharge.get('coupon_code').setValue(this.couponForm.controls.coupon_code.value);
  
//         discount_price: 15.6
// msg: "COUPON IS AVAILABLE"
// package_amount: 156
// package_cup_no: 5
// status: 1
// total_amount: 140.4
    //     this.promo_cod2=data['disc_price'];
    //     this.coupon_log_id=data['id'];
    //     this.promo_cod=true;
    //     this.sendDataToAPI.coupon_log_id = data['id'];
    // this.sendDataToAPIRazorpay.coupon_log_id = data['id'];
       
    //      this.save_promo2=JSON.parse(this.shipping_amt)+this.promo_cod2;
    //     this.save_promo=parseInt(this.discount_promo)+this.promo_cod2;
        this.snackbar.open(this.adminService.getCloseWord(
          "Coupon applied succesfully"),
          "",
          {
            duration: 5000,
            horizontalPosition: "center"
          }
        );
      // this.cc = data["result"];
      // this.temp_amt = this.fees;
      // this.fees = this.sendDataToAPIRazorpay.amount;
      }
      else if(data['status']==10){
        sessionStorage.clear();
         this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
      else{
        this.couponForm.get('coupon_code').setValue('');
         this.myRecharge.get('package_cup_no').setValue(0);
         this.myRecharge.get('amount2').setValue( this.myRecharge.controls.amount.value);
         this.myRecharge.get('total_amount').setValue(this.myRecharge.controls.amount.value);
         this.myRecharge.get('disc_price').setValue(0);
   this.myRecharge.get('coupon_code').setValue('');
   
        // this.promo_cod=false;
        this.coupon_count = 1;
        this.snackbar.open(
          data['msg'],
          "",
          {
            duration: 5000,
            horizontalPosition: "center"
          }
        );
      }
    });
    }
    }
  }
disab(){
    this.coupon_count=1;
     this.myRecharge.get('package_cup_no').setValue(0);
     this.myRecharge.get('coupon_code').setValue('');
     this.myRecharge.get('total_amount').setValue(this.myRecharge.controls.amount.value);
     this.myRecharge.get('amount2').setValue( this.myRecharge.controls.amount.value);
    this.myRecharge.get('disc_price').setValue(0);
     this.couponForm.get('coupon_code').setValue('');
       
    // this.total_amount = this.temp_amt;
    // this.promo_cod=false;
    //  this.sendDataToAPI.coupon_log_id = '';
    // this.sendDataToAPIRazorpay.coupon_log_id = '';
    
  }
// end for coupon
  

}