import { Component, OnInit,EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators , FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';

import { first } from 'rxjs/operators';
import { NinetoysserviceService } from '../ninetoysservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';
// import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
// import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';

import { Option } from "../option.model";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public access_token=sessionStorage.getItem('access_token');
  buttontextcolor = this.cookie.get('buttontextcolor');
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor')
  public user_num=sessionStorage.getItem('user_num');  
  public comp_num=sessionStorage.getItem('comp_num');
  userdata;
  updateAddres;
  regaddress;
  flagEmail=false;
  flagMobile=false;
  public is_confirm = false;
  public visibility = true;
  public visiblityoff = false;
  passwordChange=false;


    countries;
    states;
    cities;
    country;
  public visibility2 = true;
  public visiblityoff2 = false;
  phone;
no_update_profile=false;
  public visibility3 = true;
  public visiblityoff3 = false;
  public changepasssbox =false;
  public shownamebox = false;
  Changepassword:FormGroup;
 registerForm2:FormGroup;
  state_fetch;
  city_fetch;
  country_fetch;
  country_fetch2;
  state_fetch2;
  city_fetch2;
  constructor(   private adminService: NinetoysserviceService,
    private router:Router,
    private snackbar : MatSnackBar,
    private route : ActivatedRoute,
    private cookie: CookieService,
    private formbuilder : FormBuilder) { }

  ngOnInit() {
     this.phone = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
   
      this.registerForm2 = this.formbuilder.group({
      address_no: [""],
      user_num: this.user_num,
      access_token: this.access_token,
      line1: [""],
      line2: [""],
      landmark: [""],
      pin_code: [""],
      city_id: [""],
      state: [{ value: "", disabled: true }],
      city: [{ value: "", disabled: true }],
      country_id: [""],
      state_id: [""],
      mobile: [""],
      email: [""],
      name: [""]
    });
    this.Changepassword = this.formbuilder.group({
      
      old_password : ['', Validators.required],
      new_password : ['', Validators.required],
      confirm_password : ['', Validators.required],
      
      },
       { validator: this.PasswordValidator }
      );
    this.adminService.viewCompDetailsInfo({access_token:this.access_token,user_num:this.user_num, comp_num:this.comp_num}).subscribe(data=>{
        if(data['status']==1 || data['status']==2){ 
          this.userdata = data['userDetails'];
          this.regaddress = data['userAddress'];
          this.updateAddres=this.regaddress;
            this.registerForm2
              .get("name")
              .setValue(this.updateAddres?.receiver_name);
            this.registerForm2.get("line1").setValue(this.updateAddres?.line1);
            this.registerForm2.get("line2").setValue(this.updateAddres?.line2);
            this.registerForm2
              .get("landmark")
              .setValue(this.updateAddres?.landmark);
            this.registerForm2
              .get("pin_code")
              .setValue(this.updateAddres?.pin_code);
            this.registerForm2
              .get("country_id")
              .setValue(this.updateAddres?.country_id);
            this.registerForm2
              .get("state_id")
              .setValue(this.updateAddres?.state_id);
            this.registerForm2
              .get("city_id")
              .setValue(this.updateAddres?.city_id);
            this.state_fetch2 = this.updateAddres?.state;
            this.city_fetch2 = this.updateAddres?.city;
              this.registerForm2.get("state").setValue(this.state_fetch2);

          this.registerForm2.get("city").setValue(this.city_fetch2);
  this.registerForm2.get("email").setValue(this.userdata?.email);
         
             if(this.userdata?.email==null || this.userdata?.email==''){
                 this.registerForm2.get("email").setValue(this.updateAddres?.email);
         
            }
            this.getStates(this.updateAddres?.country_id);
            this.country = this.updateAddres?.country_id;
            this.getCity(
              this.updateAddres?.state_id,
              this.updateAddres?.country_id
            );
              this.registerForm2.get("mobile").setValue(this.userdata?.mobile);
         
           
               if(this.userdata?.mobile==null || this.userdata?.mobile==''){
                this.registerForm2
              .get("mobile")
              .setValue(this.updateAddres?.contact_no);
            }
            this.registerForm2.get("address_no").setValue(this.updateAddres?.address_no);
           if( this.registerForm2.controls.email.value==null ||  this.registerForm2.controls.email.value==''){
          this.flagEmail=true;
        }
        else{
          this.flagEmail=false;
        }
          if( this.registerForm2.controls.mobile.value==null ||  this.registerForm2.controls.mobile.value==''){
          this.flagMobile=true;
        }
        else{
          this.flagMobile=false;
        }
          
        }
        else if(data['status']==10){
        sessionStorage.clear();
         this.snackbar.open(this.adminService.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                  duration: 5000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
  
        else if(data['status']== 0){
             this.userdata = data['userDetails'];
          this.regaddress = data['userAddress'];
        }
     
      }
      );
 

  }
  get f() {
    return this.Changepassword.controls;
    
  }

  passvible(){
    this.visibility = !this.visibility;
    
  }
  passvible2(){
    this.visibility2 = !this.visibility2;
    
  }
  passvible3(){
    this.visibility3 = !this.visibility3;
    
  }
  ChangePassword(){
    let data = this.Changepassword.value;
    data.access_token = this.access_token;
    data.user_num = this.user_num;
    if(this.Changepassword.invalid){
      if(this.Changepassword.controls.new_password.value!=this.Changepassword.controls.confirm_password.value){
         this.snackbar.open(this.adminService.getCloseWord("Confirm password doesn't match with new password."),"",{
        duration:3000,
         });
      }
      else{
         this.snackbar.open(this.adminService.getCloseWord("please fill required fields."),"",{
        duration:3000,
         });
      }
     
    }
   else{
    this.adminService.reset_password(data).subscribe(data =>{
      if(data['status'] ==1){
       this.snackbar.open(this.adminService.getCloseWord("password changed successfully"),"",{
      duration:3000,
       }
       
       );
        this.passwordChange=!this.passwordChange
      
        this.router
                  . navigateByUrl("/", {
                    skipLocationChange: true
                  })
                  .then(() => this.router.navigate(["/user-profile"]));
           
       // this.router.navigate(['//user-profile']);
      //  location.reload();
      }
      else if(data['status']==0){
        this.snackbar.open(this.adminService.getCloseWord("Old password is incorrect."),"",{
          duration:3000,
           });
      }
          });
   }
  
   }
   showchangepass(){
    this.router.navigate(['//user-profile']);
   }
   checkConfirmPassword() {
  

    if(this.Changepassword.controls.confirm_password.value != this.Changepassword.controls.new_password.value){
      this.is_confirm=true;
    }
    else if(this.Changepassword.controls.confirm_password.value == null){
      this.is_confirm=false;
    }
    else{
      this.is_confirm=false;
    }
  }


PasswordValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let password = control.get("new_password");
    let confirmPassword = control.get("confirm_password");
    if (password.pristine || confirmPassword.pristine) {
      return null;
    }
    return password &&
      confirmPassword &&
      password.value != confirmPassword.value
      ? { misMatch: true }
      : null;
  }

  // constructor(
  //   private adminService: NinetoysserviceService,
  //   private router:Router,
  //   public snackbar : MatSnackBar
  // ) { }

  // ngOnInit() {
    // alert("hi");
    
  // }
save(){
  this.snackbar.open(this.adminService.getCloseWord('check')); 
}
showchangepass2(){
  this.passwordChange=!this.passwordChange;
}
cancel_u(){
  this.no_update_profile=false;
}
load = false;
 update() {
   
    if (this.registerForm2.invalid) {
      alert("* fields are required.");
      // return;
    } 
   //  else if(  !this.phone.test(this.registerForm2.controls.mobile.value)
   // ){
   //    alert('Mobile no. must have 10 digits.');

   //  }
    else if( this.registerForm2.controls.state.value=="" ||

         this.registerForm2.controls.city.value==""
        ){
 alert("Please fill correct pin code.");
     
    }
    else {
      this.load = true;
      let postData = this.registerForm2.value;
      this.adminService.update_profile_vendor(postData).subscribe(
        data => {
          if (data["status"] == 1) {
            this.load = false;
            this.no_update_profile=true;
              
            this.snackbar.open(this.adminService.getCloseWord("Updated Successfully "), "", {
              duration: 3000,
              horizontalPosition: "center"
            });
           
              this.router
                . navigateByUrl("/", {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(["/user-profile"]));
            
          } else {
            this.load = false;
            this.snackbar.open(data['msg'], "", {
              duration: 3000
            });
          }
        },
        error => {
          this.snackbar.open(this.adminService.getCloseWord("Something Went wrong please try again. "), "", {
            duration: 3000
          });
        }
      );
    }
  }


  getCountry() {
    let postData = { user_num: this.user_num, access_token: this.access_token };
    this.adminService.getCountry(postData).subscribe(data => {
      this.countries = data["result"];
    });
  }

  getStates(country_id) {
    this.country = country_id;
    let postData = {
      user_num: this.user_num,
      access_token: this.access_token,
      country_id: country_id
    };

    this.adminService.getState(postData).subscribe(data => {
      this.states = data["result"];
    });
  }
  getCity(state_id, country_id) {
    var postData = null;

    if (country_id == null) {
      postData = {
        user_num: this.user_num,
        access_token: this.access_token,
        country_id: this.country,
        state_id: state_id
      };
    } else {
      postData = {
        user_num: this.user_num,
        access_token: this.access_token,
        country_id: country_id,
        state_id: state_id
      };
    }

    this.adminService.getCity(postData).subscribe(data => {
      this.cities = data["result"];
    });
  }
 pincode_api2(data) {
    let postData = { pin_code: data };
    this.adminService.pincode_api(postData).subscribe(data => {
      if (data["status"] == 1) {
        if (data["result"]["PostOffice"] != null) {
          this.country_fetch2 = data["result"]["PostOffice"][0]["Country"];
          this.state_fetch2 = data["result"]["PostOffice"][0]["State"];
          this.city_fetch2 = data["result"]["PostOffice"][0]["District"];
          this.registerForm2.get("state").setValue(this.state_fetch2);

          this.registerForm2.get("city").setValue(this.city_fetch2);

          this.adminService
            .pincode_country_state_city_api({
              Country: this.country_fetch2,
              State: this.state_fetch2,
              District: this.city_fetch2
            })
            .subscribe(data => {
              if (data["status"] == 1) {
                this.registerForm2
                  .get("state_id")
                  .setValue(data["state"]["state_id"]);
                this.registerForm2
                  .get("country_id")
                  .setValue(data["country"]["country_id"]);
                this.registerForm2
                  .get("city_id")
                  .setValue(data["city"]["city_id"]);
              }
            });
        } else {
          this.registerForm2.get("state").setValue("");

          this.registerForm2.get("city").setValue("");
          this.registerForm2
            .get("state_id")
            .setValue(this.updateAddres?.state_id);
          this.registerForm2
            .get("country_id")
            .setValue(this.updateAddres?.country_id);
          this.registerForm2.get("city_id").setValue(this.updateAddres?.city_id);
        }

      
        // this.countries = data['result'];
        // this.bankForm.get('ifsc').setValue(this.bankInfo.ifsc);
      }
    });
  }
update_enable(){
  this.no_update_profile=true;
}
}
