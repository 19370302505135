import { Component, OnInit } from '@angular/core';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { Router, ActivatedRoute } from '@angular/router';
import {OverlayContainer} from "@angular/cdk/overlay";
import { FormBuilder, FormGroup, Validators , FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NinetoysserviceService } from '../ninetoysservice.service';
import {MatSnackBar} from '@angular/material';
import { Option } from "../option.model";
@Component({
  selector: 'app-menifest',
  templateUrl: './menifest.component.html',
  styleUrls: ['./menifest.component.css']
})
export class MenifestComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private adminService: NinetoysserviceService,
    private overlayContainer: OverlayContainer,
    private router:Router,
    // private readonly styleManager: StyleManagerService,
    private snackbar : MatSnackBar
  ) { }

  ngOnInit(): void {
  }

}
