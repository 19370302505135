

<div class="container-fluid" *ngIf="actionform=='add'">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="row ml-2">
        <h2 class=" product-border-heading" [style.color]="headingcolor">
         {{"Add Company Pickup Address"|translate}}
        </h2>
      </div>
      <form [formGroup]="regAddForm">
        <div class="card background mt-2">
          <div class="container">
            <div class=" mt-3">

              <div class="row justify-content-center">

                <div class="col-md-6">


                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Email"|translate}}</mat-label>
                    <input matInput placeholder="Email" (keyup)="getfillform($event.target.value)"
                      formControlName="email" required>
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>


                </div>

                <div class="col-md-6">

                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Contact Number"|translate}}</mat-label>
                    <input matInput type="number" placeholder="Contact Number" (keyup)="getfillform($event.target.value)" formControlName="contact_no" required>
                    <mat-icon matSuffix>fiber_pin</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">

                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Address Line"|translate}} 1</mat-label>
                    <input matInput placeholder="Address Line 1" formControlName="line1" required>
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>


                </div>
                <div class="col-md-6">

                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Address Line"|translate}} 2</mat-label>
                    <input matInput placeholder="Address Line 2" formControlName="line2" required>
                    <mat-icon matSuffix>fiber_pin</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Contact Person"|translate}}</mat-label>
                    <input matInput placeholder="Contact Person" formControlName="receiver_name" required>
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Pin Code"|translate}}</mat-label>
                    <input matInput type="number" placeholder="Pin Code" (keyup)="pincode_api($event.target.value)"
                      formControlName="pin_code" required>

                    <mat-icon matSuffix>fiber_pin</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100" appearance="fill">
                    <mat-label>{{"Country"|translate}}</mat-label>
                    <mat-select formControlName="country_id" (selectionChange)="getStates($event.value)" required>
                      <mat-option value="{{country.country_id}}" *ngFor="let country of countries;i as index;">
                        {{country.country}}</mat-option>
                    </mat-select>
                    <i class="fas fa-flag" matSuffix></i>
                    <!-- <mat-icon matSuffix>flag</mat-icon> -->
                  </mat-form-field>

                </div>
                <div class="col-md-6">


                  <mat-form-field class="w-100" appearance="fill">
                    <mat-label>{{"State"|translate}}</mat-label>
                    <mat-select formControlName="state_id" (selectionChange)="getCity($event.value,null)" required>
                      <mat-option value="{{state.state_id}}" *ngFor="let state of states">{{state.state}}</mat-option>
                    </mat-select>
                    <i class="fas fa-street-view" matSuffix></i>
                    <!-- <mat-icon matSuffix>streetview</mat-icon> -->
                  </mat-form-field>
                </div>
                <div class="col-md-6">


                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"City"|translate}}</mat-label>
                    <mat-select formControlName="city_id" required>
                      <mat-option value="{{city.city_id}}" *ngFor="let city of cities">{{city.city}}</mat-option>
                    </mat-select>
                    <i class="fas fa-city" matSuffix></i>
                    <!-- <mat-icon matSuffix>location_city</mat-icon> -->
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Landmark"|translate}}</mat-label>
                    <input matInput placeholder="Landmark" formControlName="landmark">
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">

              </div>


              <div class="justify-content-end row  mt-2 mr-2">
                <button (click)="goBack();" mat-raised-button style="margin-bottom: 20px;"
                class="order-button text-white text-right">
                {{"Cancel"|translate}}
              </button> &nbsp;
                <button (click)="onSubmit();" mat-raised-button style="margin-bottom: 20px;"
                  class="order-button text-white text-right">
                  {{"Save"|translate}}
                </button>
              </div>


            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="actionform=='update'">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="row ml-2">
        <h2 class=" product-border-heading" [style.color]="headingcolor">
         {{"Update Company Pickup Address"|translate}}
        </h2>
      </div>
      <form [formGroup]="regAddForm">
        <div class="card background mt-2">
          <div class="container">
            <div class=" mt-3">

              <div class="row justify-content-center">

                <div class="col-md-6">

                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Contact Person"|translate}}</mat-label>
                    <input matInput placeholder="Contact Person" formControlName="receiver_name" required>
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>

                </div>

                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Pin Code"|translate}}</mat-label>
                    <input matInput type="number" placeholder="Pin Code" (keyup)="pincode_api($event.target.value)"
                      formControlName="pin_code" required>

                    <mat-icon matSuffix>fiber_pin</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">

                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Address Line"|translate}} 1</mat-label>
                    <input matInput placeholder="Line 1" formControlName="line1" required>
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>


                </div>
                <div class="col-md-6">

                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Address Line"|translate}} 2</mat-label>
                    <input matInput placeholder="Line 2" formControlName="line2" required>
                    <mat-icon matSuffix>fiber_pin</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Email"|translate}}</mat-label>
                    <input matInput placeholder="Contact Person" formControlName="email" required>
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Contact Number"|translate}}</mat-label>
                    <input matInput type="number" placeholder="Contact Number" formControlName="contact_no" required>
                    <mat-icon matSuffix>fiber_pin</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100" appearance="fill">
                    <mat-label>{{"Country"|translate}}</mat-label>
                    <mat-select formControlName="country_id" (selectionChange)="getStates($event.value)">
                      <mat-option value="{{country.country_id}}" *ngFor="let country of countries;i as index;">
                        {{country.country}}</mat-option>
                    </mat-select>
                    <i class="fas fa-flag" matSuffix></i>
                    <!-- <mat-icon matSuffix>flag</mat-icon> -->
                  </mat-form-field>
                </div>
                <div class="col-md-6">


                  <mat-form-field class="w-100" appearance="fill">
                    <mat-label>{{"State"|translate}}</mat-label>
                    <mat-select formControlName="state_id" (selectionChange)="getCity($event.value,null)">
                      <mat-option value="{{state.state_id}}" *ngFor="let state of states">{{state.state}}</mat-option>
                    </mat-select>
                    <i class="fas fa-street-view" matSuffix></i>
                    <!-- <mat-icon matSuffix>streetview</mat-icon> -->
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"City"|translate}}</mat-label>
                    <mat-select formControlName="city_id">
                      <mat-option value="{{city.city_id}}" *ngFor="let city of cities">{{city.city}}</mat-option>
                    </mat-select>
                    <i class="fas fa-city" matSuffix></i>
                    <!-- <mat-icon matSuffix>location_city</mat-icon> -->
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Landmark"|translate}}</mat-label>
                    <input matInput placeholder="Contact Person" formControlName="landmark">
                    <mat-icon matSuffix>recent_actors</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">

              </div>


              <div class="justify-content-end row  mt-2 mr-2">
                <button (click)="update()" mat-raised-button style="margin-bottom: 20px;"
                  class="order-button text-white text-right">
                  {{"update"|translate}}
                </button>
              </div>


            </div>

          </div>
        </div>
      </form>

    </div>
  </div>
</div>