// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output,Inject, LOCALE_ID } from "@angular/core";
// import {jspdf} from 'jspdf';
// import {jsPDF} from 'jspdf'
// import html2canvas from 'html2canvas';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material';
import { formatDate,DatePipe,DecimalPipe } from '@angular/common';
import { Location } from "@angular/common";
import { GridApi, GridOptions , GridReadyEvent} from 'ag-grid-community';
 import * as moment from 'moment';
 import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {HttpClient,} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { saveAs } from 'file-saver-es';
import { MAT_DATE_LOCALE } from '@angular/material'; 
import { TranslateService } from '@ngx-translate/core';
export const DD_MM_YYYY_Format = {
    parse: {
        from_date: 'DD/MM/YYYY',
        to_date: 'DD/MM/YYYY',
    },
    display: {
        from_date: 'DD/MM/YYYY',
        to_date: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MM YYYY',
    },
};
@Component({
    selector: 'button-customer-verification',
    templateUrl: './button-customer-verification.component.html',
    providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}]
  })
  export class ButtonCustomerVerificationComponent {
    myDate = new Date();
    myDate1;
  // from_date: any;
  // to_date: any;
  from_date=new Date();
  to_date=new Date();
  result=[];
  member_id: any;
  nodata;
  page_items: any;
  list_amount_sum: any;
  list_amount: any;
  sum_amount: any;

    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
    referral_ask =false;
  // public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
  error_img = sessionStorage.getItem('error_image');
    data: any;
    deactivatef=false;
    activate=false;
    profile = false;
  allResult: any;
  buis_update: boolean;
  buisness_details: any;
  updateAddres: any;
  bank_details: any;
  loading: boolean;
  view_profile;
  Statement=[];

  constructor(
 private route: ActivatedRoute,
 private snackbar:MatSnackBar,
 private _decimalPipe:DecimalPipe,
 private fb:FormBuilder,
 private router: Router,
 private datePipe: DatePipe,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 private http: HttpClient,@Inject(LOCALE_ID) private locale: string,
 private translate: TranslateService
 ) {
  this.myDate1 = this.datePipe.transform(this.myDate, 'ddMMyy');
  }
  openXl(content) {
    this.modalService.open(content, { size: 'lg' });
  }
  openXlL(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  openXl1(content) {
    this.modalService.open(content, { size: 'md' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  Report_date;
  Report_date2=new Date();
     FormU : FormGroup;
      FormU2 : FormGroup;
     from_date_tally=new Date();
    to_date_tally=new Date();
  
    currentDate=new Date();
     minDate = new Date();
      minDate2 = new Date();
     

  public gridApi: GridApi;
  public gridColumnApi;
  public columnDefs; 
  public columnDefsW;
  public defaultColDef;
  public gridOptions:GridOptions;
  public sortingOrder;
  profile_member_id;
  rowData:any = [];
  rowDataW:any=[];
 public params: any;
 onGridReady(params:GridReadyEvent) {
   this.gridApi = params.api;
   this.gridColumnApi = params.columnApi;
   params.api.sizeColumnsToFit();
   window.addEventListener('resize', function () {
     setTimeout(function () {
       params.api.sizeColumnsToFit();
     });
   });

   params.api.sizeColumnsToFit();
 }


// agInit(params:any):void{
// this.params = params;
// }



    ngOnInit(): void {
      // this.FormU = this.fb.group({
      //   from_date:  this.minDate2,
      //   to_date:  this.minDate,
      // });
      // this.getColConfig();
      this.minDate2=new Date((this.minDate2.getFullYear()), (this.minDate2.getMonth()-1), 1);
      this.from_date=this.minDate2;
      var report_date= this.Report_date2;
      this.Report_date =((report_date.getDate())+' / '+(report_date.getMonth()+1) +' / '+ (report_date.getFullYear()));
     
    }

    onImgError(event) {
      event.target.src = this.error_img;
      //Do other stuff with the event.target
    }

  agInit(params: any): void {
    this.params = params;
    this.data = params.value;
    var data1= params.data;
   this.Statement = this.data?.statement;
   this.minDate2=new Date((this.minDate2.getFullYear()), (this.minDate2.getMonth()-1), 1);
   this.from_date=this.minDate2;
   this.FormU = this.fb.group({
    from_date: this.minDate2,
    to_date: this.minDate,
  });
  
    if(this.params.label =='deactive_member'){
this.deactivatef = true;

    }
    if(this.params.label =='deactive-member'){
        this.deactivatef = true;
this.activate = true;

    }
    if(this.params.label =='view'){
      this.view = true;


  }
  if(this.params.label == 'view_user'){
      this.profile = true;
  }
  }
  ledger_statement(){
    ////console.log("chek");
    this.getColConfig();
  }
  VIEW(user_num){
    this.router.navigate(['//reg-cutomer-profile',user_num]);
  }
  get_user_num(user_num){
    var new_user = user_num.toString();
    //new_user);
    this.view_profile = new_user;
    //this.view_profile)
    this.getColConfig();
    this.loadApi();
    this.loadApiW();
    this.fetch_customer();
  }
  deactivate(buisness_no){
    var res = confirm(this.adminservice.getCloseWord("Are you sure you want to deactivate this customer."));
 if(res){
   this.adminservice
 .customer_information_verification({
   access_token: this.access_token,
   user_num: this.user_num,
   buisness_no: buisness_no,
   action: 'deactivate'
 })
 .subscribe(
   data => {
     if (data["status"] == 1) {
       this.snackbar.open(this.adminservice.getCloseWord("This customer is deactivated successfully. "), "", {
         duration: 3000,
         horizontalPosition: "center"
       });
       this.router
         .navigateByUrl("/", { skipLocationChange: true })
         .then(() => this.router.navigate(["/customer-verification"]));

     } else if (data["status"] == 10) {
       sessionStorage.clear();
       this.snackbar.open(this.adminservice.getCloseWord(
         "Multiple login with this ID has been detected, Logging you out. "),
         "",
         {
           duration: 5000,
           horizontalPosition: "center"
         }
       );
       this.router.navigate(["/"]);
     } else {

       this.snackbar.open(this.adminservice.getCloseWord("Something went wrong please try again. "), "", {
         duration: 5000,
         horizontalPosition: "center"
       });
     }
   },
   error => {
     this.snackbar.open(this.adminservice.getCloseWord(
       "Something went wrong due to network, please try again. "),
       "",
       {
         duration: 5000,
         horizontalPosition: "center"
       }
     );
   }
 );

}
}
fetch_customer_registration_by_id(id,bank_detail_id,bank_detail_no,address_id,address_no,buisness_no){
    this.adminservice
    .fetch_customer_registration_by_id({
      access_token: this.access_token,
      user_num: this.user_num,
     id:id,
     bank_detail_id:bank_detail_id,
     bank_detail_no:bank_detail_no,
     address_id:address_id,
     address_no:address_no,
     buisness_no:buisness_no
    })
    .subscribe(
      data => {
        // this.loading = false;
        if (data["status"] == 1) {
         this.verify(buisness_no);
        } else if (data["status"] == 10) {
          sessionStorage.clear();
          this.snackbar.open(this.adminservice.getCloseWord(
            "Multiple login with this ID has been detected, Logging you out. "),
            "",
            {
              duration: 5000,
              horizontalPosition: "center"
            }
          );
          this.router.navigate(["/"]);
        } else {
        //   this.loading = false;

          this.snackbar.open(this.adminservice.getCloseWord("There is new updation found.Please verify again."), "", {
            duration: 5000,
            horizontalPosition: "center"
          });
         
this.ngOnInit();
        }
      },
      error => {
    //   this.loading=false;
        this.snackbar.open(this.adminservice.getCloseWord(
          "Something went wrong due to network, please try again. "),
          "",
          {
            duration: 5000,
            horizontalPosition: "center"
          }
        );
        // this.loading = false;
      }
    );
  }

  verify(buisness_no){
    this.adminservice
  .customer_information_verification({
    access_token: this.access_token,
    user_num: this.user_num,
    buisness_no: buisness_no,
    action: 'verify'
  })
  .subscribe(
    data => {
      if (data["status"] == 1) {
        this.snackbar.open(this.adminservice.getCloseWord("This customer is verified successfully. "), "", {
          duration: 3000,
          horizontalPosition: "center"
        });
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/customer-verification"]));
this.ngOnInit();
      } else if (data["status"] == 10) {
        sessionStorage.clear();
        this.snackbar.open(this.adminservice.getCloseWord(
          "Multiple login with this ID has been detected, Logging you out. "),
          "",
          {
            duration: 5000,
            horizontalPosition: "center"
          }
        );
        this.router.navigate(["/"]);
      } else {

        this.snackbar.open(this.adminservice.getCloseWord("Something went wrong please try again. "), "", {
          duration: 5000,
          horizontalPosition: "center"
        });
      }
    },
    error => {
      this.snackbar.open(this.adminservice.getCloseWord(
        "Something went wrong due to network, please try again. "),
        "",
        {
          duration: 5000,
          horizontalPosition: "center"
        }
      );
    }
  );
}

fetch_customer(){
  // this.getColConfig();
    let postData = {
      user_num:this.user_num,
      access_token:this.access_token,
      comp_num:this.comp_num,
      member_id:this.view_profile
    };
    ////console.log(this.view_profile);

    this.adminservice.fetch_customer_registration(postData).subscribe(
      
//       data => {
//         if (data["status"] == 1) {
//           this.allResult = data["result"];
// if(this.allResult.buisness_no!='0' && this.allResult.buisness_no!=0 && this.allResult!=null){
// this.buis_update=true;
//  sessionStorage.setItem("buisness_no",this.allResult.buisness_no);
//   this.buisness_details=this.allResult['buisness_details'];
//   this.profile_member_id = this.buisness_details.customer_reg_num;
//   this.updateAddres=this.buisness_details['address'];
// if(this.buisness_details['bank_details']!=null){
// this.bank_details=this.buisness_details['bank_details'];

//            }
// }
           
//         } else {
//           this.loading=true;   
//         }
//       },
    
       error => {
        this.loading=true; 
      }
    );
  }
  
// for ledger
  downloadReport(){
    var event= this.from_date;
       var day=event.getDate();
    var month=event.getMonth()+1;
    var year =event.getFullYear();
    var from_date2 =year+'-'+month+'-'+day
    ;
  
    var event= this.to_date;
       var day=event.getDate();
    var month=event.getMonth()+1;
    var year =event.getFullYear();
    var to_date2=year+'-'+month+'-'+day;
  var id1 = this.view_profile
  ////console.log(id1 + "id");
         let postData = {
        user_num: this.user_num,
        comp_num:this.comp_num,
        engine:this.FormU?.controls?.engine?.value,
        from_date:from_date2,
        to_date:to_date2,
        member_no:id1,
        access_token: this.access_token,
       
      };
 
     this.adminservice.member_ledger_report(postData).subscribe(
        data => {

          if(data['status']==0){
            this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
              duration: 3000
            });
          }
  
          else{
              if (data.headers.get("content-type").search("pdf") != -1) {
                var blob = new Blob([data.body], { type: "application/pdf" });
                if(new Blob([data.body], { type: "application/pdf" }) !=null){
                  saveAs(blob, this.member_id+'_'+"ledger statement"+"_"+this.myDate1);
                  this.snackbar.open(this.adminservice.getCloseWord("Report Download Successfully"),"", {
                    duration: 3000
                    }); 
                }
              else{
                this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
                  duration: 3000
                });
              }
            
            } else {
      
              this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
                duration: 3000
              });
    
            }
          }
       

        },
        error => {
          this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong, please try again. "), "", {
            duration: 3000
          });
        }
      );
  
  
     
  }

// for wallet

downloadMemberprofile(){
  var event= this.from_date;
     var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var from_date2 =year+'-'+month+'-'+day
  ;

  var event= this.to_date;
     var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var to_date2=year+'-'+month+'-'+day;
var id1 = this.view_profile
////console.log(id1 + "id");
let postData = {
  user_num:this.user_num,
  access_token:this.access_token,
  comp_num:this.comp_num,
  member_id:this.view_profile
};

   this.adminservice.fetch_customer_registration_report(postData).subscribe(
      data => {

        if(data['status']==0){
          this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
            duration: 3000
          });
        }

        else{
            if (data.headers.get("content-type").search("pdf") != -1) {
              var blob = new Blob([data.body], { type: "application/pdf" });
              if(new Blob([data.body], { type: "application/pdf" }) !=null){
                saveAs(blob, this.profile_member_id+'_'+"member profile from"+"_"+this.myDate1);
                this.snackbar.open(this.adminservice.getCloseWord("Form Download Successfully"),"", {
                  duration: 3000
                  }); 
              }
            else{
              this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
                duration: 3000
              });
            }
          
          } else {
    
            this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
              duration: 3000
            });
  
          }
        }
     

      },
      error => {
        this.snackbar.open(this.adminservice.getCloseWord("Something Went wrong, please try again. "), "", {
          duration: 3000
        });
      }
    );


   
}

// for wallet

downloadReportW(){
  var event= this.from_date;
     var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  ;
  var from_date2 = year+'-'+month+'-'+day

  var event= this.to_date;
     var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var to_date2=year+'-'+month+'-'+day
  ;
  var id1 = this.view_profile
       let postData = {
      user_num: this.user_num,
      comp_num:this.comp_num,
      engine:this.FormU.controls?.engine?.value,
      from_date:from_date2,
      to_date:to_date2,
    member_no: id1,
      access_token: this.access_token,
     
    };

   this.adminservice.member_wallet_report(postData).subscribe(
      data => {
        if(data['status']==0){
          this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
            duration: 3000
          });
        }

        else{
          if (data.headers.get("content-type").search("pdf") != -1) {
            var blob = new Blob([data.body], { type: "application/pdf" });
            if(new Blob([data.body], { type: "application/pdf" }) !=null){
              saveAs(blob, this.member_id+"_wallet statement_"+this.myDate1);
              this.snackbar.open(this.adminservice.getCloseWord("Report Download Successfully"),"", {
                duration: 3000
                }); 
            }
            else{
              this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
                duration: 3000
              });
            }
          
          } else {
    
            this.snackbar.open(this.adminservice.getCloseWord("NO Data Available "), "", {
              duration: 3000
            });
  
          }
        }
       

      },
   
    );


   
}

  getColConfig(){
    // for ledger 
    this.columnDefs=[
      {
        headerName:"Date & Time",
        field:"date",
        // resizable:true,
        sortable: true, filter: true,
        cellRenderer:(params) => {
          return  formatDate(params.value, 'dd/ MM/yyyy hh:mm a', this.locale);
          // return moment(params.value).format('DD/MM/YYYY',this.locale);
        },
        sortingOrder:["asc","desc"]
      },
      {
        headerName:"Particulars",
        // resizable:true,
        field:"particulars",
        sortable: true, filter: true,
     
        cellRenderer:(params) => {
          return '' + " " + params.value;
        },
        sortingOrder:["asc","desc"]
      },
      {
        headerName:"Voucher no",
        // resizable:true,
        field:"V_no",
        sortable: true, filter: true,
     
        cellRenderer:(params) => {
          return '' + " " + params.value;
        },
        sortingOrder:["asc","desc"]
      },
      {
        headerName:"Dr./Cr.",
        // resizable:true,
        field:"drcr",
        sortable: true, filter: true,
        cellClass:params => {
          if(params.value === 'drcr'){
            return 'text-danger'
          }
        },
        // cellRenderer:(params) => {
        //   return '<i class="fas fa-rupee-sign"></i>' + " " + params.value;
        // },
        sortingOrder:["asc","desc"]
      },
      {
        headerName:"Amount",
        // resizable:true,
        field:"amount",
        sortable: true, filter: true,
     
        cellRenderer:(params) => {
          return '<i class="fas fa-rupee-sign"></i>' + " " + params.value;
        },
        sortingOrder:["asc","desc"]
      },
    
      {
        headerName:"Balance",
        // resizable:true,
        sortable: true, filter: true,
        field:"Balance",
       
        cellRenderer:(params) => {
          return '<i class="fas fa-rupee-sign"></i>' + " " + params.value;
        },
        sortingOrder:["asc","desc"]
      },
     
    ];


// for wallet 
    this.columnDefsW=[
   
      {
        headerName:"Date & Time",
      
        field:"Doa",
        // resizable:true,
        sortable: true, filter: true,
         
        cellRenderer:(params) => {
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        sortingOrder:["asc","desc"]
      },
      {
        headerName:"Particulars",
        sortable: true, filter: true,
        cellStyle: {textAlign: 'center'},
        field:"particulars",
        resizable:true,
        cellRenderer:(params) => {
          return " " + params.value;
        },
      },
        {
          headerName:"Transanction Id",
          sortable: true, filter: true,
          cellStyle: {textAlign: 'center'},
          field:"txn_id",
          resizable:true,
          cellRenderer:(params) => {
            return '&nbsp; &nbsp;' + " " + " " + params.value;
          },
        
        sortingOrder:["asc","desc"]
      },
      {
        headerName:"Dr./Cr.",
        sortable: true, filter: true,
        cellStyle: {textAlign: 'center'},
        field:"crdr",
        resizable:true,
        cellRenderer:(params) => {
          return '&nbsp; &nbsp;' + " " + " " + params.value;
        },
      
      sortingOrder:["asc","desc"]
    },
    
      {
        headerName:"Amount",
        resizable:true,
        field:"amount",
        sortable: true, filter: true,
     
        cellRenderer:(params) => {
          return '<i class="fas fa-rupee-sign"></i>' + " " + params.value;
        },
        sortingOrder:["asc","desc"]
      },
    
      {
        headerName:"Balance",
        resizable:true,
        sortable: true, filter: true,
        field:"Balance",
       
        cellRenderer:(params) => {
          return '<i class="fas fa-rupee-sign"></i>' + " " + params.value;
        },
        sortingOrder:["asc","desc"]
      },
     
    ];
   
    this.loadApi();
    this.loadApiW();
    this.setColumnDefsW(this.columnDefsW);
    this.setColumnDefs(this.columnDefs);
   
   }
    // for ledger
   loadApi(){
    var event= this.from_date;
    var day=event.getDate();
 var month=event.getMonth()+1;
 var year =event.getFullYear();
 var from_date2 =year+'-'+month+'-'+day
 ;

 var event= this.to_date;
 ////console.log(event +"2")
    var day=event.getDate();
    ////console.log(day);
 var month=event.getMonth()+1;
 var year =event.getFullYear();
 var to_date2=year+'-'+month+'-'+day;
     const TempRow =[];
     let postEData = {
       
      user_num:this.user_num,
      access_token:this.access_token,
      comp_num:this.comp_num,
      member_no:this.view_profile,
       from_date:from_date2,
        to_date:to_date2,
    };
    this.adminservice.fetch_order_summary(postEData).subscribe(
      data => {
        if (data["status"] == 1) {
    this.result = data['result'];
    this.nodata = this.result;
    // this.nodata = true;
    this.member_id = data['member_id'];
    ////console.log(this.member_id)
   
   const response = data['result'] as any as Array<object>;
   response.forEach(element => {
    var decimal_bal = 
    this._decimalPipe.transform(element['bal'],"1.2-2");
    var decimal_amt = 
    this._decimalPipe.transform(element['amt'],"1.2-2");
       const obj = {
         date:element['date'],
         particulars:element['part'],
         V_no: element['voucher'],
         amount:decimal_amt,
         drcr:element['drcr'],
         Balance:decimal_bal
       };
        TempRow.push(obj);
   
   });
   this.rowData = TempRow;
   this.setColumnDefs(this.columnDefs);
           
        } else {
       
   
         
        }
      },
      error => {
       
      }
    );
   
   }
  //  for wallet
   loadApiW(){
    var event= this.from_date;
    ////console.log(event+"3")
    var day=event.getDate();
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var from_date2 =day+'-'+month+'-'+year;
  var event= this.to_date;
    var day=event.getDate();
    ////console.log(event+"4")
  var month=event.getMonth()+1;
  var year =event.getFullYear();
  var to_date2=day+'-'+month+'-'+year
  ;
     const TempRow =[];
     let postData = {
      user_num:this.user_num,
      access_token:this.access_token,
      comp_num:this.comp_num,
      member_no:this.view_profile,
      offset:1,
      page_items:this.page_items,
      from_date:from_date2,
      to_date:to_date2,
      list:1
    };
    //postData);
    this.adminservice.fetch_member_wallet_list(postData).subscribe(
      data => {
        if (data["status"] == 1) {
           this.loading=true;
  this.list_amount=data['result_list'];
  this.member_id = data['result']?.member_id;
  ////console.log(this.member_id);
  this.list_amount_sum=data['result'];
  if(this.list_amount.length>0){
    this.sum_amount=data['result'].sum;
  
  }
  //this.list_amount);
  
  const response = data['result_list'] as any as Array<object>;
  //  var i=1
  response.forEach(element => {
  
    if(element['order_id'] && element['order_id'] !=0 && element['order_id'] != -1){
     var particulars= "order number"
     var txn_id = element['order_number']
    }
   else if(element['recharge_id'] !=0 && element['recharge_id'] !=-1 && element['recharge_id']){
     var particulars = "By recharge "
      var txn_id  = element['recharge_id']
    }
    else if(element['withdrawal_request_id'] !=0 && element['withdrawal_request_id'] !=-1 && element['withdrawal_request_id']){
      var txn_id  = element['withdrawal_request_id']
      var particulars = "By wallet withdrawal "
    }
    else if(element['withdrawal_request_id'] == -1 && element['recharge_id'] == -1 && element['order_id'] == -1){
      var txn_id  =element['id']
      var particulars = element['month']
    }
   
   
  
    if(element['amount'] >=0 ){
  
      var crdr = 'Credit'
    }
   else{
  
    var crdr = 'Debit'
   }
    
   var decimal_amount = 
  this._decimalPipe.transform(element['amount'],"1.2-2");
  var decimal_balance = 
  this._decimalPipe.transform(element['balance'],"1.2-2");
  
       const obj = {
        //  sno:i,
         txn_id:txn_id,
         amount:decimal_amount,
         crdr:crdr,
         particulars:particulars,
         Balance:decimal_balance,
         Doa:element['doa']
       };
        TempRow.push(obj);
        // i++;
  
  });
  this.rowDataW = TempRow;
  this.setColumnDefsW(this.columnDefsW);
  // //this.datavalue);
           
        } else {
          this.loading=true;
  
         
        }
      },
      error => {
        this.loading=true;
  
       
      }
    );
  
  }
// for ledger
   setColumnDefs(Columns){
    const colDefs: any[] = Columns.map(column =>{
      const colDefsprop = {
        headerName:column?.headerName,
        field:column?.field,
        width: column?.Width,
        minWidth: column?.width,
        filter : true,
        suppressMenu:column?.suppressMenu,
        sortable :column?.sortable,
        cellRenderer: column?.cellRenderer 
      }
  
      // let items=[];
      if(column.headerName == 'sno'){
       Object.assign(colDefsprop,{
        width:100,
       });
  
      };
      if(column.headerName == 'Amount Request'){
        Object.assign(colDefsprop,{
           width:100,
          sortable:true,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agTextColumnFilter',
          floatingfilterComponentParam:{
            suppressFilterButton: true,
          },
          
        });
   
       };
       if(column.headerName == 'Date'){
        Object.assign(colDefsprop,{
           
          sortable:true,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agDateColumnFilter',
       
          floatingfilterComponentParam:{
            suppressFilterButton: true, 
            
          },
          
        });
   
       };
     
  
       if (column.cellRenderer){
         return Object.assign(colDefsprop,{
           cellRenderer:column.cellRenderer
         })
       }
       return colDefsprop;
     
    })
    this.columnDefs = colDefs
    this.gridOptions = {
      defaultColDef:{
        filter:true,sortable:true,
      },
      floatingFilter:false,
      columnDefs:this.columnDefs,
      pagination:true,
      paginationPageSize:5
    }
  
  }
  // for wallet
   setColumnDefsW(ColumnsW){
    const colDefs: any[] = ColumnsW.map(column =>{
      const colDefsprop = {
        headerName:column?.headerName,
        field:column?.field,
        width: column?.Width,
        minWidth: column?.width,
        filter : true,
        suppressMenu:column?.suppressMenu,
        sortable :column?.sortable,
        cellRenderer: column?.cellRenderer 
      }
   
      if(column.headerName == 'Date & Time'){
       Object.assign(colDefsprop,{
         width:170,
       });
   
      };
      if(column.headerName == 'Particulars'){
        Object.assign(colDefsprop,{
          sortable:true,
          width:160,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agTextColumnFilter',
          floatingfilterComponentParam:{
            suppressFilterButton: true,
          },
          
        });
   
       };
       if(column.headerName == 'Voucher no'){
        Object.assign(colDefsprop,{
           width:140,
          sortable:true,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agDateColumnFilter',
       
          floatingfilterComponentParam:{
            suppressFilterButton: true, 
            
          },
          
        });
   
       };
       if(column.headerName == 'Dr./Cr.'){
        Object.assign(colDefsprop,{
         width:110,
          sortable:true,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agTextColumnFilter',
          floatingfilterComponentParam:{
            suppressFilterButton: true,
          },
          
        });
   
       };
       if(column.headerName == 'Amount'){
        Object.assign(colDefsprop,{
         width:140,
          sortable:true,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agTextColumnFilter',
          floatingfilterComponentParam:{
            suppressFilterButton: true,
          },     
        });
       };
       if(column.headerName == 'Balance'){
        Object.assign(colDefsprop,{
        width:145,
          sortable:true,
          filter:'agTextColumnFilter',
          floatingfilterComponent:'agTextColumnFilter',
          floatingfilterComponentParam:{
            suppressFilterButton: true,
          },  
        });
       };
   
       if (column.cellRenderer){
         return Object.assign(colDefsprop,{
           cellRenderer:column.cellRenderer
         })
       }
       return colDefsprop;
     
    })
    this.columnDefsW = colDefs
    this.gridOptions = {
      defaultColDef:{
        filter:true,sortable:true,
      },
      floatingFilter:false,
      columnDefs:this.columnDefsW,
      pagination:true,
      paginationPageSize:7
    }
   
   }

   addEvent(event: MatDatepickerInputEvent<Date>){
    this.from_date = event.value;
    //this.from_date);
          
  }

  addEvent2(event: MatDatepickerInputEvent<Date>){
    this.to_date = event.value;
          
  }
}