import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { NinetoysserviceService } from "../ninetoysservice.service";
import { MatSnackBar } from "@angular/material";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, BaseChartDirective, Label } from "ng2-charts";
import { environment } from '../../environments/environment';
import { Title, Meta } from "@angular/platform-browser";
import { GreatingDialogComponent } from "../greating-dialog/greating-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from 'rxjs/Rx';
import 'rxjs/add/observable/interval';
@Component({
  selector: "app-admin-home",
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.css"]
})
export class AdminHomeComponent implements OnInit {
    // Doughnut
    in_stock=0;
    in_stock1 = sessionStorage.getItem('instock');
    outofstock= sessionStorage.getItem('outofstock');
    activstock1 = sessionStorage.getItem('count_active');
    inactivestock= sessionStorage.getItem('count_inactive');
     doughnutChartLabels:string[] = [ 'In Stock', 'Out Of Stack', 'Active Listing', 'Incative Listing'];
     demodoughnutChartData:string[] = [this.in_stock1,this.outofstock,this.activstock1,this.inactivestock];
     doughnutChartType:string = 'doughnut';
     public chartColors: Array<any> = [
      {
        backgroundColor: ['#00a65a', '#d9534f', '#f39c12', '#00c0ef'],
        hoverBackgroundColor: ['#00a65a', '#d9534f', '#f39c12', '#00c0ef'],
        borderWidth: 2,
      }
    ];
  courierOptions: any;
  user_count: any;
  total: any;
  infodata: any;
  info='City';
  daydata: any;
  daysinfo='Today';
  nodata: string;
    // events
    public chartClicked(e:any):void {
      console.log(e);
    }
   
    public chartHovered(e:any):void {
      console.log(e);
    }
 project=environment.project;

 favicon=environment.favicon;
           logo=environment.logo;
             loaderAsset=environment.loader;
               error_img = sessionStorage.getItem('error_image');
               title10=environment.admintitle;
               description10=environment.admindescription;
  public android_token = sessionStorage.getItem("android_token");
  public user_id = sessionStorage.getItem("user_id");
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
  public comp_id = sessionStorage.getItem("comp_id");
  public usertype_id = sessionStorage.getItem("usertype_id");
  public vendor = false;
  public controller = false;
  public vnotverified = false;
  public reg_customer = false;
  package_checkout_statement='';
  package_details;
  available_packages=[];
  total_sell=0;
  active_product_count = 0;
  inactive_product_count = 0;
  count_new_order = 0;
  count_return = 0;
  count_complete = 0;
  company_name = 0;
 
  out_of_stock = 0;
  package_expiry;
  web_publish=true;
  minDate=new Date();
  expiry=0;
  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private cookie: CookieService,
    private router: Router,
    private adminservice: NinetoysserviceService,
    private translate: TranslateService
  ) {
    this.countProductActiveness();
   
  }
  openDialog(): void {
    let dialogRef = this.dialog.open(GreatingDialogComponent, {
      width: '550px',
      height:'360px',
      
      // data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  radioModel: string = "Month";

  // lineChart1
  public lineChart1Data: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: "Series A"
    }
  ];
  public lineChart1Labels: Array<any> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ];
  public lineChart1Options: any = {
    tooltips: {
      enabled: false
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "transparent",
            zeroLineColor: "transparent"
          },
          ticks: {
            fontSize: 2,
            fontColor: "transparent"
          }
        }
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
            min: 40 - 5,
            max: 84 + 5
          }
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4
      }
    },
    legend: {
      display: false
    }
  };
  public lineChart1Colours: Array<any> = [
    {
      backgroundColor: "",
      borderColor: "rgba(255,255,255,.55)"
    }
  ];
  public lineChart1Legend = false;
  public lineChart1Type = "line";

  // lineChart2
  public lineChart2Data: Array<any> = [
    {
      data: [1, 18, 9, 17, 34, 22, 11],
      label: "Series A"
    }
  ];
  public lineChart2Labels: Array<any> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ];
  public lineChart2Options: any = {
    tooltips: {
      enabled: false
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "transparent",
            zeroLineColor: "transparent"
          },
          ticks: {
            fontSize: 2,
            fontColor: "transparent"
          }
        }
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
            min: 1 - 5,
            max: 34 + 5
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4
      }
    },
    legend: {
      display: false
    }
  };
  public lineChart2Colours: Array<any> = [
    {
      // grey
      backgroundColor: "",
      borderColor: "rgba(255,255,255,.55)"
    }
  ];
  public lineChart2Legend = false;
  public lineChart2Type = "line";

  // lineChart3
  public lineChart3Data: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: "Series A"
    }
  ];
  public lineChart3Labels: Array<any> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ];
  public lineChart3Options: any = {
    tooltips: {
      enabled: false
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false
        }
      ],
      yAxes: [
        {
          display: false
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4
      }
    },
    legend: {
      display: false
    }
  };
  public lineChart3Colours: Array<any> = [
    {
      backgroundColor: "rgba(255,255,255,.2)",
      borderColor: "rgba(255,255,255,.55)"
    }
  ];
  public lineChart3Legend = false;
  public lineChart3Type = "line";

  // barChart1
  public barChart1Data: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40, 78, 81, 80, 45, 34, 12, 40, 12, 40],
      label: "Series A"
    }
  ];
  public barChart1Labels: Array<any> = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16"
  ];
  public barChart1Options: any = {
    tooltips: {
      enabled: false
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
          barPercentage: 0.6
        }
      ],
      yAxes: [
        {
          display: false
        }
      ]
    },
    legend: {
      display: false
    }
  };
  public barChart1Colours: Array<any> = [
    {
      backgroundColor: "rgba(255,255,255,.3)",
      borderWidth: 0
    }
  ];
  public barChart1Legend = false;
  public barChart1Type = "bar";

  // mainChart

  public mainChartElements = 27;
  public mainChartData1: Array<number> = [];
  public mainChartData2: Array<number> = [];
  public mainChartData3: Array<number> = [];

  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,
      label: "Current"
    },
    {
      data: this.mainChartData2,
      label: "Previous"
    },
    {
      data: this.mainChartData3,
      label: "BEP"
    }
  ];
  /* tslint:disable:max-line-length */
  public mainChartLabels: Array<any> = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Monday",
    "Thursday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  /* tslint:enable:max-line-length */
  public mainChartOptions: any = {
    tooltips: {
      enabled: false,

      intersect: true,
      mode: "index",
      position: "nearest",
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          return {
            backgroundColor:
              chart.data.datasets[tooltipItem.datasetIndex].borderColor
          };
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            callback: function(value: any) {
              return value.charAt(0);
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            stepSize: Math.ceil(250 / 5),
            max: 250
          }
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    },
    legend: {
      display: false
    }
  };
  public mainChartColours: Array<any> = [
    {
      // brandInfo
      backgroundColor: "",
      borderColor: "",
      pointHoverBackgroundColor: "#fff"
    },
    {
      // brandSuccess
      backgroundColor: "transparent",
      borderColor: "",
      pointHoverBackgroundColor: "#fff"
    },
    {
      // brandDanger
      backgroundColor: "transparent",
      borderColor: "",
      pointHoverBackgroundColor: "#fff",
      borderWidth: 1,
      borderDash: [8, 5]
    }
  ];
  public mainChartLegend = false;
  public mainChartType = "line";

  // social box charts

  public brandBoxChartData1: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: "Facebook"
    }
  ];
  public brandBoxChartData2: Array<any> = [
    {
      data: [1, 13, 9, 17, 34, 41, 38],
      label: "Twitter"
    }
  ];
  public brandBoxChartData3: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: "LinkedIn"
    }
  ];
  public brandBoxChartData4: Array<any> = [
    {
      data: [35, 23, 56, 22, 97, 23, 64],
      label: "Google+"
    }
  ];

  public brandBoxChartLabels: Array<any> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ];
  public brandBoxChartOptions: any = {
    tooltips: {
      enabled: false
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false
        }
      ],
      yAxes: [
        {
          display: false
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    },
    legend: {
      display: false
    }
  };
  public brandBoxChartColours: Array<any> = [
    {
      backgroundColor: "rgba(255,255,255,.1)",
      borderColor: "rgba(255,255,255,.55)",
      pointHoverBackgroundColor: "#fff"
    }
  ];
  public brandBoxChartLegend = false;
  public brandBoxChartType = "line";

  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  usertypeid = sessionStorage.getItem('usertype_id');
public greettime = sessionStorage.getItem('greettime');
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
  public vendorPublish = false;
  hostd;
  d = new Date();
  current_month = this.d.getMonth() + 1;
  current_year = this.d.getFullYear();
  //new change
  datayear = [
    { year: this.current_year },
    // { year: this.current_year - 1 },
    // { year: this.current_year + 1 }
  ];
  datamonth = [
    { name: "January", no: 1 },
    { name: "February", no: 2 },
    { name: "March", no: 3 },
    { name: "April", no: 4 },
    { name: "May", no: 5 },
    { name: "June", no: 6 },
    { name: "July", no: 7 },
    { name: "August", no: 8 },
    { name: "September", no: 9 },
    { name: "October", no: 10 },
    { name: "November", no: 11 },
    { name: "December", no: 12 }
  ];
  selectedYear;
  CompForm: FormGroup;
  compdata;
  public saleChartData: ChartDataSets[] = [];

  public saleChartLabels: Label[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];

  public saleChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };

  public saleChartColors: Color[] = [
    {
      borderColor: "#fff",
      backgroundColor: "transparent"
    }
  ];
  ecomtrails = false;
  ninetoys = false;
  maschile = false;
  mlm = false;
  public saleChartLegend = true;
  public saleChartType = "line";
  public saleChartPlugins = [];
  public nosale = false;
  aggregator:FormGroup;
  sub_domain: FormGroup;
  timeform:FormGroup;
  checked_aggregator=false;
  checked_sub=true;
  interval:any;
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  areaSelect(e){
 this.infodata= e;
  }
  optionSelect(f){
    this.daydata = f;
  }
  getTime(){
      this.adminservice.live_user_count(this.timeform.value).subscribe(data=>{
         if(data['status']=='1'){
           this.info = this.infodata;
           this.daysinfo = this.daydata;
           this.total = data['total'];
           this.user_count = data['result'];
         }
         else{
             this.nodata ='No data available'
         }
      })
    
  }
  ngOnInit() {
    this.timeform=this.formBuilder.group({
     time:['1'],
     time_unit:['days'],
     group_by:['city'],
     access_token:this.access_token,
     comp_num:this.comp_num,
     user_num:this.user_num
    }),
    this.interval = setInterval(() => {
      this.adminservice.live_user_count(this.timeform.value).subscribe(data=>{
         if(data['status']=='1'){
           this.total = data['total'];
           this.user_count = data['result'];
         }
      })
    }, 30000);
    
   this.getCourierOptions();

    if(sessionStorage.getItem('greettime') == '1'){
      this.openDialog();
    }

    this.titleService.setTitle(this.title10);
    this.meta.addTag({ name: "author", content: this.title10 });
    this.meta.updateTag({
      name: "title",
      content: this.title10
    });
    this.meta.updateTag({
      property: "og:title",
      content: this.title10
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.description10
    });
     if(this.usertypeid!='2' && this.comp_num!='0' && this.project=='ecomtrails'){
      this.fetch_package_by_company();
    }
    // this.fetch_package_by_company();
    this.aggregator=this.formBuilder.group({
      aggregator:'0',
    });
    this.sub_domain=this.formBuilder.group({
      sub_domain:'1',
    });
  
     if(sessionStorage.getItem('database')=="ecomtrails"){
        this.ecomtrails=true;
      }
      else if(sessionStorage.getItem('database')=="9toys"){
        this.ninetoys=true;
      }

       else if(sessionStorage.getItem('database')=="maschile"){
        this.maschile=true;
      }
     
      else{
        this.ecomtrails=true;
      }
       
    if (this.usertype_id == "2") {
      this.controller = true;
        if(this.controller==true){
       this.compSettingsAggregator();
       this.compSettingsSubDomain();
    }
   
      this.vendor = false;
      this.vnotverified = false;
      this.CompForm = this.formBuilder.group({
        comp_num: ["", [Validators.required]],
        start_date: ["", [Validators.required]],
        end_date: ["", [Validators.required]]
      });
      this.adminservice
        .get_company_list({
          user_num: sessionStorage.getItem("user_num"),
          access_token: sessionStorage.getItem("access_token")
        })
        .subscribe(
          data => {
            if (data["status"] == 1) {
              this.compdata = data["data"];
            } else if (data["status"] == 0) {
            } else if (data["status"] == 10) {
              this.snackbar.open(this.getCloseWord(
                "Multiple login with this ID has been detected, Logging you out."),
                "",
                {
                  duration: 1500
                }
              );
              this.router.navigate(["/"]);
            }
          },
          error => {
            this.snackbar.open(this.getCloseWord("Failed Try Again"), "Ok", {
              duration: 1500
            });
            // this.router.navigate(["/home"]);
          }
        );
    } else if (this.usertype_id == "1" || this.usertype_id == "7") {
      sessionStorage.setItem(
        "comp_num",
        sessionStorage.getItem("comp_num")
      );
      sessionStorage.setItem("previewFlag", "1");
      this.adminservice
        .checkCompanyStatus({
          access_token: this.access_token,
          user_num: this.user_num
        })
        .subscribe(data => {
          if (data["status"] == 1) {
            if (data["verify"] == false) {
              this.vnotverified = true;
              this.controller = false;
              this.vendor = false;
            } else {
              this.vnotverified = false;
              this.controller = false;
              this.vendor = true;
              this.adminservice
                .fetch_company_type({
                  comp_num: this.comp_num
                })
                .subscribe(data => {
                  if (data["status"] == 1) {
                    if (data["data"].value == "1") {
                      this.adminservice
                        .get_host_link_of_comapny({
                          comp_num: this.comp_num
                        })
                        .subscribe(datan => {
                          if (datan["status"] == 1) {
                            this.hostd = datan["result"];
                            sessionStorage.setItem('store_name',this.hostd?.host_name);
                           
                            this.vendorPublish = true;
                          } else {
                          }
                        });
                    }
                  } else {
                    this.vendorPublish = false;
                  }
                });
            }
          }
        });

      this.current_month;
      this.adminservice
        .monthWiseSale({
          user_num: this.user_num,
          access_token: this.access_token,
          month: this.current_month,
          year: this.current_year,
          comp_num : this.comp_num
        })
        .subscribe(
          data => {
            if (data["status"] == 10) {
              sessionStorage.clear();
              this.snackbar.open(this.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                duration: 3000,
                horizontalPosition:'center',
        });  
              this.router.navigate(["/"]);
            }

            if (data["status"] == 1) {
              this.nosale=false;
              this.get_sale_monthly(data);
            }

            if(data['status']== 0){
              this.salechart = false;
              this.nosale = true;
            }
          },

          error => {
           
          }
        );
    }

    else if(this.usertype_id == '6'){
      this.reg_customer = true;
    }

    // generate random values for mainChart
    for (let i = 0; i <= this.mainChartElements; i++) {
      this.mainChartData1.push(this.random(50, 200));
      this.mainChartData2.push(this.random(80, 100));
      this.mainChartData3.push(65);
    }
    this.countProductActiveness();
  }
  setMaxAreaLimit(max_login_wise) {
    if (max_login_wise < 10) {
      if (max_login_wise < 5) {
        max_login_wise = 5;
      } else {
        max_login_wise = 10;
      }
    } else {
      if (max_login_wise < 100) {
        if (max_login_wise % 10 != 0) {
          max_login_wise = Math.ceil(max_login_wise / 10) * 10;
        } else {
          max_login_wise = max_login_wise + 5;
        }
      } else {
        if (max_login_wise % 100 != 0) {
          max_login_wise = Math.ceil(max_login_wise / 100) * 100;
        } else {
          max_login_wise = max_login_wise + 100;
        }
      }
    }
  }
  label_arr;
  value_arr;
  max_login_wise;
  public salechart = false;
  get_sale_monthly(data) {
    let values = data["result"];
    this.label_arr = [];
    this.value_arr = [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ];

    for (let i = 0; i < values.length; i++) {
      this.value_arr[i] = values[i].sell;
      this.label_arr[i] = values[i].date;
      this.max_login_wise = Math.max.apply(this, this.value_arr);
      this.max_login_wise = this.setMaxAreaLimit(
        Math.max.apply(this, this.value_arr)
      );
    }
    this.saleChartData = [
      {
        label: "Total Sale",
        data: this.value_arr,
        backgroundColor: "lightblue",
        fill: true,
        borderColor: "",
        pointBorderColor: "#fff",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#fff",
        pointBorderWidth: 5,
        pointHoverRadius: 1,
        pointHoverBorderWidth: 1,
        pointRadius: 1
      }
    ];

    this.saleChartOptions = {
      legend: {
        display: false
      },
      title: {
        text: "Monthly Sale",
        display: true,
        fontSize: 15,
        fontColor: "#707070",
        fontFamily: "Helvetica",
        fontStyle: "normal"
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Month"
            }
          }
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Gross Revenue"
            },
            ticks: {
              suggestedMax: this.max_login_wise,
              beginAtZero: true
            }
          }
        ]
      },
      tooltips: {
        mode: "index",
        intersect: false
      },
      responsive: true,
      maintainAspectRatio: false
    };

    this.saleChartLabels = this.label_arr;

    this.salechart = true;
  }
  update_sale_chart_month(month){
    this.current_month = month;
    this.adminservice
        .monthWiseSale({
          user_num: this.user_num,
          access_token: this.access_token,
          month:this.current_month,
          year: this.current_year,
          comp_num : this.comp_num
        })
        .subscribe(
          data => {
            if (data["status"] == 10) {
              sessionStorage.clear();
              this.snackbar.open(this.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                duration: 3000,
                horizontalPosition:'center',
        });  
              this.router.navigate(["/"]);
            }

            if (data["status"] == 1) {
              this.nosale=false;
              this.get_sale_monthly(data);
            }
            if(data['status']== 0){
              this.salechart = false;
              this.nosale = true;
            }
          },

          error => {
            
          }
        );

  }
  update_sale_chart_year(year){
    this.current_year = year;
    this.adminservice
        .monthWiseSale({
          user_num: this.user_num,
          access_token: this.access_token,
          month: this.current_month,
          year: this.current_year,
          comp_num : this.comp_num
        })
        .subscribe(
          data => {
            if (data["status"] == 10) {
              sessionStorage.clear();
              this.snackbar.open(this.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                duration: 3000,
                horizontalPosition:'center',
        });  
              this.router.navigate(["/"]);
            }

            if (data["status"] == 1) {
              this.nosale=false;

              this.get_sale_monthly(data);
            }

            if(data['status']== 0){
              this.salechart = false;
              this.nosale = true;
            }
          },

          error => {
            
          }
        );

  }
  publish_company() {
    this.adminservice
      .publish_company({
        comp_num: this.comp_num
      })
      .subscribe(data => {
        if (data["status"] == 1) {
          if(this.ecomtrails==true){
             this.snackbar.open(this.getCloseWord("Your Company is Published Successfully."), "", {
            duration: 3000,
            horizontalPosition: "center"
          });
          }
         
          this.router
            . navigateByUrl("/", {
              skipLocationChange: true
            })
            .then(() => this.router.navigate(["/dashboard"]));
        } else {
          this.snackbar.open(this.getCloseWord("Unable To publish try again."), "", {
            duration: 3000,
            horizontalPosition: "center"
          });
        }
      });
  }
  countProductActiveness() {
    this.adminservice
      .countProductActiveness({
        comp_num: this.comp_num,
        user_num: this.user_num,
        access_token: this.access_token
      })
      .subscribe(data => {
        if (data["status"] == 1) {
          this.in_stock = data["in_stock"];
          sessionStorage.setItem('instock',data["in_stock"]);
          this.out_of_stock = data["out_of_stock"];
          sessionStorage.setItem('outofstock',data["out_of_stock"]);
          this.active_product_count = data["count_active"];
          sessionStorage.setItem('count_active',data["count_active"]);
          this.total_sell=data["total_sell"];
          this.inactive_product_count = data["count_inactive"];
          sessionStorage.setItem('count_inactive',data["count_inactive"]);
          this.count_new_order = parseInt(data["count_new_order"]["count"]);
          // count_return;
          this.count_complete = parseInt(data["count_complete"]["count"]);
         
        } else {
        }
      });
  }

  preview() {
    this.router.navigate(["/preview-mode"]);
  }
  data_input;
  comp_login;
  showcompdata;
  notshowcompdata;
  public initialLoad = true;
  compWiseLogin() {
    this.data_input = this.CompForm.value;
    this.data_input.access_token = sessionStorage.getItem("access_token");
    this.data_input.user_num = sessionStorage.getItem("user_num");
    this.adminservice.companywiseLoginReport(this.data_input).subscribe(
      data => {
        if (data["status"] == 10) {
          sessionStorage.clear();
          this.snackbar.open(this.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
            duration: 3000,
            horizontalPosition:'center',
    });  
          this.router.navigate(["/"]);
        }

        if (data["status"] == 1) {
          this.initialLoad = false;
          this.comp_login = data["data"];
          this.showcompdata = true;
          this.notshowcompdata = false;
        }

        if (data["status"] == 0) {
          this.initialLoad = false;
          this.showcompdata = false;
          this.notshowcompdata = true;
        }
      },

      error => {
        this.initialLoad = false;
      }
    );
  }
  aggregator_mode(e){
    //e);
    if (e.checked==false){
this.aggregator.get('aggregator').setValue('0');
    }
    else{
this.aggregator.get('aggregator').setValue('1');

    }

    this.add_particular_registry(36,'aggregator',this.aggregator.controls.aggregator.value);
  }

  add_particular_registry(s_no,parameter,value){
    let post={
          user_num: sessionStorage.getItem("user_num"),
          access_token: sessionStorage.getItem("access_token"),
          comp_num:0,
          s_no:s_no,
          parameter:parameter,
          value:value
        };
        //post);
     this.adminservice
        .add_particular_company_registry(post)
        .subscribe(
          data => {
            if (data["status"] == 1) {
               this.snackbar.open(this.getCloseWord(
                "Successfully update changes."),
                "",
                {
                  duration: 1500
                }
              );
            } else if (data["status"] == 0) {
            } else if (data["status"] == 10) {
              this.snackbar.open(this.getCloseWord(
                "Multiple login with this ID has been detected, Logging you out."),
                "",
                {
                  duration: 1500
                }
              );
              this.router.navigate(["/"]);
            }
          },
          error => {
            this.snackbar.open(this.getCloseWord("Failed Try Again"), "Ok", {
              duration: 1500
            });
            // this.router.navigate(["/home"]);
          }
        );
  }
  sub_domain_mode(e){
    //e);
      if (e.checked==false){
this.sub_domain.get('sub_domain').setValue('0');

    }
    else{
      this.sub_domain.get('sub_domain').setValue('1');

    }
    this.add_particular_registry(50,'sub_domain',this.sub_domain.controls.sub_domain.value);

  }
 compSettingsAggregator(){
     this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num:  0,s_no:36 })
      .subscribe(data => {
        if (data["status"] == 1) {
         let d = data['data'];
         let v = (d.value);
         // //v);
         this.aggregator.get('aggregator').setValue('0');


      }
      else{
            this.add_particular_registry(36,'aggregator',this.aggregator.controls.aggregator.value);

      }
    });
    }

     compSettingsSubDomain(){
     this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num:  0,s_no:50 })
      .subscribe(data => {
        if (data["status"] == 1) {
         let d = data['data'];
         let v = (d.value);
         this.sub_domain.get('sub_domain').setValue(v);

      }
      else{
            this.add_particular_registry(50,'sub_domain',this.sub_domain.controls.sub_domain.value);

      }
    });
    }

    fetch_package_by_company(){
      if(this.usertype_id!='2'){
        this.adminservice.fetch_package_by_company({ comp_num:  this.comp_num,user_num:this.user_num,access_token:this.access_token })
      .subscribe(data => {
        if (data["status"] == 1) {
          this.available_packages=data['available_packages'];
         
               this.package_details=data['result'];
               if(this.package_details?.web_publish=='N'){
                 this.web_publish=false;//not allowed web publish
               }

               this.package_expiry=this.package_details?.expiry;

                var event= this.minDate;
                     var day=event.getDate();
                  var month=event.getMonth()+1;
                  var year =event.getFullYear();
                  var from_date2
                  =year+'-'+month+'-'+day
                  ;
                 
                 var splitExp=[];
                 var splitNew=[];
                 splitExp = this.package_expiry.split("-");
                  splitNew = from_date2.split("-");
                if(this.package_details?.package_id==1){
                     this.expiry=2;//free tier
                     // this.web_publish=false;//not allowed web publish
                     this.package_checkout_statement="You are using Free tier package,Please upgrade package to use all functionality.";
                      
                  
                    
                }
                else{
                        if(splitNew.length==splitExp.length){
                   
                                   
                            if(parseInt(splitExp[0])==parseInt(splitNew[0])){ //equal year
                               if(parseInt(splitExp[1])==parseInt(splitNew[1])){ //equal month

                                      if((splitExp[2]-splitNew[2])<=5 ){ //equal or 5 day diff
                                        if(this.available_packages.length<2){
                                           this.expiry=1;//expired or near
                                           this.package_checkout_statement="Your package is going to expire,Please upgrade package to use all functionality.";
                   
                                        }
                                       
                                      }
                                         if((parseInt(splitExp[2])-parseInt(splitNew[2]))<0 ){ //equal or 5 day diff
                                            this.expiry=1;//expired or near
                                             
                                             this.web_publish=false;
                     
                                          }

                                      

                                }
                                else if(parseInt(splitExp[1])<parseInt(splitNew[1])){//less month
                                 
                                    this.expiry=1;//expired
                                    this.web_publish=false;//not allowed web publish
                                   this.package_checkout_statement="Your package expired,Please upgrade package to use all functionality.";
                    //             
                                }

                            }
                            else if(parseInt(splitExp[0])<parseInt(splitNew[0]) && splitExp[0]!='0000'){//less year
                                this.expiry=1;//expired
                                this.web_publish=false;//not allowed web publish
                                this.package_checkout_statement="Your package expired,Please upgrade package to use all functionality.";
                    //        

                            }
                        }
                }
                

      }
      else{
          this.expiry=2;//free tier
            // this.web_publish=false;//not allowed web publish
        this.package_checkout_statement="You are using Free tier package,Please upgrade package to use all functionality.";
         
      }
    });
}
    }
    getCloseWord(key: string): string {
      var translateValue='';
     this.translate.get(key).subscribe((res: string) => {
       translateValue=res
   });
   return translateValue;
   }

   getCourierOptions() {
    let postData = {
      user_num: sessionStorage.getItem("user_num"),
      access_token: sessionStorage.getItem("access_token"),
      comp_num: sessionStorage.getItem('comp_num')
      // comp_num: -1
    };
    this.adminservice.courierListAdmin(postData).subscribe(data => {
      if (data["status"] == 1) {
        this.courierOptions = data["result"];
        
        if (this.courierOptions.length > 0) {

          for (let count = 0; count < this.courierOptions.length; count++) {
            console.log(this.courierOptions[count].courier_method_id,'id');
            if(this.courierOptions[count].courier_method_id == 4 || this.courierOptions[count].courier_method_id == '4'){
               sessionStorage.setItem('couriermethodid4','4');
            }
            else{
              sessionStorage.setItem('couriermethodid4','0');
            }
            
           
       
          }
         
       
       
        }
        
      }
      else{
        sessionStorage.setItem('couriermethodid4','0');
      }
    });
    
  }
}
