import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { first } from 'rxjs/operators';
import { NinetoysserviceService } from '../ninetoysservice.service';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {

  public access_token = sessionStorage.getItem('access_token');
  public user_num = sessionStorage.getItem('user_num');
  public comp_num = sessionStorage.getItem('comp_num');
  previewFlag = sessionStorage.getItem("previewFlag");
  public package_id = sessionStorage.getItem("package_id");
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
  host_name;
  method = 'fetch';

  constructor(
    private adminservice: NinetoysserviceService,
    private _formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private cookie: CookieService,
    private router: Router
  ) { }
  ecomtrails = false;
  ecomtrails2 = true;
  ninetoys = false;
  maschile = false;
  mlm = false;
  dataall;

  ngOnInit(): void {

    if (sessionStorage.getItem('database') == "ecomtrails") {
      this.ecomtrails = true;
    }
    else if (sessionStorage.getItem('database') == "9toys") {
      this.ninetoys = true;
    }

    else if (sessionStorage.getItem('database') == "maschile") {
      this.maschile = true;
    }
    else if (sessionStorage.getItem('database') == "mlm") {
      this.mlm = true;
    }
    else {
      this.ecomtrails = true;
    }

    this.getPackages()

    this.regAddForm = this._formBuilder.group({
      user_num: this.user_num,
      access_token: this.access_token,
      comp_num: this.comp_num,
      package_id: this.package_id,
      product_count: [],
      order_count:[],
      price: [''],
      web_publish: [''],
      commission: [''],
      sub_user: [''],
      member: [''],
      coupen: [''],
      online_payment: [''],
      shipping: [''],
      package_name:[''],
      monthly_period:[''],
      allowed_checkout:['']

    });

  }

  regAddForm: FormGroup;
  packagedata: any;
  getPackages() {
    let postData = {
      user_num: sessionStorage.getItem("user_num"),
      access_token: sessionStorage.getItem("access_token"),
      // comp_num: sessionStorage.getItem("comp_num"),
      package_id: sessionStorage.getItem("package_id"),
      pay:1

    };
    this.adminservice.fetchPackage(postData).subscribe(data => {
      this.packagedata = data["result"];
      ////this.packagedata)
    }
    );
  }

  action(c) {
    this.method = c;
  }
allowed_checkout;
  updateaction(package_id, price, product_count, web_publish, commission, sub_user, member, coupen, online_payment, shipping,package_name,monthly_period,order_count,allowed_checkout) {
    this.method = 'update';
    
    this.regAddForm.get('price').setValue(price);
    this.regAddForm.get('product_count').setValue(product_count);
    this.regAddForm.get('package_id').setValue(package_id);
    this.regAddForm.get('package_name').setValue(package_name);
    this.regAddForm.get('monthly_period').setValue(monthly_period);
    this.regAddForm.get('order_count').setValue(order_count);

     if(allowed_checkout=='N'){
      
      allowed_checkout= false
     }

  if ( allowed_checkout === 'Y') {
  
    allowed_checkout = true
  }
    if(shipping=='N'){
      
      shipping= false
     }

  if ( shipping === 'Y') {
  
    shipping = true
  }
  if(online_payment=='N'){
      
    online_payment= false
   }

if ( online_payment === 'Y') {

  online_payment = true
}
if(web_publish=='N'){
      
  web_publish= false
 }

if ( web_publish === 'Y') {

  web_publish = true
}
if(commission=='N'){
      
  commission= false
 }

if ( commission === 'Y') {

  commission = true
}
if(sub_user=='N'){
      
  sub_user= false
 }

if ( sub_user === 'Y') {

  sub_user = true
}
if(member=='N'){
      
  member= false
 }

if ( member === 'Y') {

  member = true
}
if(coupen=='N'){
      
  coupen= false
 }

if ( coupen === 'Y') {

  coupen = true
}
  this.regAddForm.get('shipping').setValue(shipping);
  this.regAddForm.get('online_payment').setValue(online_payment);
  this.regAddForm.get('web_publish').setValue(web_publish);
  this.regAddForm.get('commission').setValue(commission);
  this.regAddForm.get('sub_user').setValue(sub_user);
  this.regAddForm.get('member').setValue(member);
  this.regAddForm.get('coupen').setValue(coupen);
this.regAddForm.get('allowed_checkout').setValue(allowed_checkout);


  }

  removePackage(package_id) {
    var res = confirm("Are you sure you want to delete this package.");
    if (res) {
      let postData = {
        user_num: sessionStorage.getItem("user_num"),
        access_token: sessionStorage.getItem("access_token"),
        package_id: package_id,
        comp_num: this.comp_num

      };
      this.adminservice.delete_package(postData).subscribe(data => {
        if (data["status"] == "1") {
          this.ngOnInit();
        } else if (data["status"] == "10") {
          if (this.previewFlag == "1") {
            if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "localhost:4209")) {
              // if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "www.ecomtrails.com")) {
              this.router
                .navigateByUrl("/", {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(["/preview/home"]));
            } else {
              this.router
                .navigateByUrl("/", {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(["/home"]));
            }
          } else {
            this.router
              .navigateByUrl("/", {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(["/home"]));
          }
        } else {
          if (sessionStorage.getItem("access_token") == "") {
            if (this.previewFlag == "1") {
              if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "localhost:4209")) {
                // if ((sessionStorage.getItem("comp_num") == "0") && (this.host_name != "www.ecomtrails.com")) {
                this.router
                  .navigateByUrl("/", {
                    skipLocationChange: true
                  })
                  .then(() => this.router.navigate(["/preview/home"]));
              } else {
                this.router
                  .navigateByUrl("/", {
                    skipLocationChange: true
                  })
                  .then(() => this.router.navigate(["/home"]));
              }
            } else {
              this.router
                .navigateByUrl("/", {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(["/home"]));
            }
          }
        }
      });
    }

  }

  onSubmit(){
   

  let data2 = this.regAddForm.value;
  // if(data2.shipping==''){
      
  //   data2.shipping= "N"
  // ////data2);
  //  }

if ( data2.shipping === false) {

  data2.shipping = "N"
  ////data2);
}
if ( data2.shipping === true) {

  data2.shipping = "Y"
  ////data2);
}
if ( data2.online_payment === false) {

  data2.online_payment = "N"
  ////data2);
}
if ( data2.online_payment === true) {

  data2.online_payment = "Y"
  ////data2);
}

if ( data2.coupen === false) {

  data2.coupen = "N"
  ////data2);
}
if ( data2.coupen === true) {

  data2.coupen = "Y"
  ////data2);
}
if ( data2.member === false) {

  data2.member = "N"
  ////data2);
}
if ( data2.member === true) {

  data2.member = "Y"
  ////data2);
}
if ( data2.sub_user === false) {

  data2.sub_user = "N"
  ////data2);
}
if ( data2.sub_user === true) {

  data2.sub_user = "Y"
  ////data2);
}
if ( data2.commission === false) {

  data2.commission = "N"
  ////data2);
}
if ( data2.commission === true) {

  data2.commission = "Y"
  ////data2);
}
if ( data2.web_publish === false) {

  data2.web_publish = "N"
  ////data2);
}
if ( data2.web_publish === true) {

  data2.web_publish = "Y"
  ////data2);
}

if ( data2.allowed_checkout === false) {

  data2.allowed_checkout = "N"
}
if ( data2.allowed_checkout === true) {

  data2.allowed_checkout = "Y"
}
 
////data2);
if(this.method=='update'){
this.adminservice.update_package(data2)
      .pipe(first())
      .subscribe(
          data2 => {
              if (data2['status']==1) {
                this.snackbar.open(this.adminservice.getCloseWord('Updated Successfully '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
                });
                
                this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
                this.router.navigate(["/package"]));             
                         
                      

              }
              else if(data2['status']==10){
                sessionStorage.clear();
                 this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                          duration: 3000,
                          horizontalPosition:'center',
                  });      
                this.router.navigate(['/']);
                }
              
              else {
                this.snackbar.open(this.adminservice.getCloseWord('Unable to update.'),'' ,{
                  duration: 3000,
                  
                });
                
              }
          },
          error => {
              this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '),'' ,{
                duration: 3000,
                
              });
              
          });
}
 else{
  if(data2.shipping==''){
      
          data2.shipping= "N"
        ////data2);
         }
    
      if ( data2.shipping === true) {
      
        data2.shipping = "Y"
        ////data2);
      }
      if(data2.online_payment==''){
        
        data2.online_payment= "N"
      ////data2);
       }
    
    if ( data2.online_payment === true) {
    
      data2.online_payment = "Y"
      ////data2);
    }
    if(data2.coupen==''){
        
      data2.coupen= "N"
    ////data2);
     }
    
    if ( data2.coupen === true) {
    
    data2.coupen = "Y"
    ////data2);
    }
    if(data2.member==''){
        
    data2.member= "N"
    ////data2);
    }
    
    if ( data2.member === true) {
    
    data2.member = "Y"
    ////data2);
    }
    if(data2.sub_user==''){
        
    data2.sub_user= "N"
    ////data2);
    }
    
    if ( data2.sub_user === true) {
    
    data2.sub_user = "Y"
    ////data2);
    }
    if(data2.commission==''){
        
    data2.commission= "N"
    ////data2);
    }
    
    if ( data2.commission === true) {
    
    data2.commission = "Y"
    ////data2);
    }
    if(data2.web_publish==''){
        
    data2.web_publish= "N"
    ////data2);
    }
    
    if ( data2.web_publish === true) {
    
    data2.web_publish = "Y"
    ////data2);
    }

     if(data2.allowed_checkout==''){
        
      data2.allowed_checkout= "N"
    ////data2);
     }
    
    if ( data2.allowed_checkout === true) {
    
    data2.allowed_checkout = "Y"
    ////data2);
    }
     this.adminservice.add_package(data2)
      .pipe(first())
      .subscribe(
          data2 => {
              if (data2['status']==1) {
                this.snackbar.open(this.adminservice.getCloseWord('Added Successfully '),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
                });
                
                this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
                this.router.navigate(["/package"]));             
                         
                      

              }
              else if(data2['status']==10){
                sessionStorage.clear();
                 this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                          duration: 3000,
                          horizontalPosition:'center',
                  });      
                this.router.navigate(['/']);
                }
              else {
                this.snackbar.open(this.adminservice.getCloseWord('Unable to add.'),'' ,{
                  duration: 3000,
                  
                });
                
              }
          },
          error => {
              this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '),'' ,{
                duration: 3000,
                
              });
              
          });

 }

}




//   onSubmit() {
  
//     let data2 = this.regAddForm.value;
//     if (this.method == 'update') {
      
//       this.adminservice.update_package(data2)
//         .pipe(first())
//         .subscribe(
//           data2 => {
//             if (data2['status'] == 1) {
//               this.snackbar.open(this.adminservice.getCloseWord('Updated Successfully '), '', {
//                 duration: 3000,
//                 horizontalPosition: 'center',
//               });

//               this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
//                 this.router.navigate(["/package"]));



//             } else {
//               this.snackbar.open(this.adminservice.getCloseWord('Unable to update.'), '', {
//                 duration: 3000,

//               });

//             }
//           },
//           error => {
//             this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '), '', {
//               duration: 3000,

//             });

//           });
//     }
//     if(data2.shipping==''){
      
//       data2.shipping= "N"
//     ////data2);
//      }

//   if ( data2.shipping === true) {
  
//     data2.shipping = "Y"
//     ////data2);
//   }
//   if(data2.online_payment==''){
    
//     data2.online_payment= "N"
//   ////data2);
//    }

// if ( data2.online_payment === true) {

//   data2.online_payment = "Y"
//   ////data2);
// }
// if(data2.coupen==''){
    
//   data2.coupen= "N"
// ////data2);
//  }

// if ( data2.coupen === true) {

// data2.coupen = "Y"
// ////data2);
// }
// if(data2.member==''){
    
// data2.member= "N"
// ////data2);
// }

// if ( data2.member === true) {

// data2.member = "Y"
// ////data2);
// }
// if(data2.sub_user==''){
    
// data2.sub_user= "N"
// ////data2);
// }

// if ( data2.sub_user === true) {

// data2.sub_user = "Y"
// ////data2);
// }
// if(data2.commission==''){
    
// data2.commission= "N"
// ////data2);
// }

// if ( data2.commission === true) {

// data2.commission = "Y"
// ////data2);
// }
// if(data2.web_publish==''){
    
// data2.web_publish= "N"
// ////data2);
// }

// if ( data2.web_publish === true) {

// data2.web_publish = "Y"
// ////data2);
// }
//       this.adminservice.add_package(data2)
//         .pipe(first())
//         .subscribe(
//           data2 => {

//             if (data2['status'] == 1) {
//               this.snackbar.open(this.adminservice.getCloseWord('Added Successfully '), '', {
//                 duration: 3000,
//                 horizontalPosition: 'center',
//               });

//               this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
//                 this.router.navigate(["/package"]));



//             }
//             else {
//               this.snackbar.open(this.adminservice.getCloseWord('Unable to add.'), '', {
//                 duration: 3000,

//               });

//             }
//           },
//           error => {
//             // this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '), '', {
//             //   duration: 3000,

//             // });

//           });

    
//   }






  // send(): void {

  //   if (this.regAddForm.invalid) {
  //     this.snackbar.open(this.adminservice.getCloseWord('Please fil required  '), '', {
  //       duration: 3000,
  //       horizontalPosition: 'center'
  //     });
  //   } else {
  //     let data2 = this.regAddForm.value;
  //     data2.access_token = this.access_token;
  //     data2.user_num = this.user_num;
  //     data2.comp_num = this.comp_num;
  //     // data2.depo_id = this.address_no;

  //     this.adminservice.add_package(data2)
  //       .pipe(first())
  //       .subscribe(
  //         data => {
  //           if (data['status'] == 1) {
  //             this.snackbar.open(this.adminservice.getCloseWord('Successfully Add Package'), '', {
  //               duration: 3000,

  //             });
  //             this.router.navigate(['//dashboard']);
  //             // this.registerForm2.reset(this.pincode)
  //           }
  //           else if (data['status'] == 10) {
  //           } else if (data['status'] == 0) {
  //             this.snackbar.open(this.adminservice.getCloseWord('Unable to add'), '', {
  //               duration: 3000,
  //             });
  //           }
  //         });
  //   }
  // }

  // update(): void {
  //   {

  //     let data2 = this.regAddForm.value;
  //     data2.access_token = this.access_token;
  //     data2.user_num = this.user_num;
  //     data2.comp_num = this.comp_num;

  //     // data2.package_id = this.package_id;

  //     this.adminservice.update_package(data2)
  //       .pipe(first())
  //       .subscribe(
  //         data2 => {
  //          {
  //             this.snackbar.open(this.adminservice.getCloseWord('Successfully update'), '', {
  //               duration: 3000,

  //             });
  //             this.router.navigate(['//package']);
  //             // this.registerForm2.reset(this.pincode)
  //           }
  //          {
  //           }  {
  //             this.snackbar.open(this.adminservice.getCloseWord('Unable to add'), '', {
  //               duration: 3000,
  //             });
  //           }
  //         });
  //   }
  // }

  // color = 'accent';
  // checked: string ="Y";
  // disabled = false;
  // web_publish: any = [];
  //   onChange(value) {
  //   if (value.checked === true) {
  //     this.checked = "Y";
  //     ////this.checked);
  //   } else {
  //     this.checked = "N";
  //     ////this.checked);
  //   }
  // }

}


