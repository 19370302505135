
<div class="bg">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="w3-main"> 
                    <div class="agile-info"> 
                     
                     <h2>404</h2> 
                     <h3>{{"Page Not Found" | translate}}</h3>
                     <p>

                       {{"This Site is not Currently Active Please Contact Your Vendor" | translate}}
                       <a href="https://www.ecomtrails.com" target="_blank">{{"Go Back to Website" | translate}}</a>
                    </div> 
                   </div> 
            </div>
        </div>
      
    </div>
    <div class="justify-content-center row border-top">
            <p class="text-muted footer-bottom-text">
              <sub class="cursor-pointer"
                >{{"powered by" | translate}}
                <a
                  href="https://www.ecomtrails.com/"
                  target="_blank"
                  class="powerby text-primary cursor-pointer"
                  matTooltip="www.ecomtrails.com"
                  >{{"EcomTrails.com" | translate}}</a
                ></sub
              >
            </p>
          </div>
    
  