<link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet">
<div class="container">
<div class="container-fluid">
    <div class="row mt-3 pl-4 justify-content-left" *ngIf="no_update_profile==false">
        <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
            {{"User profile" | translate}}
         </h2>
    </div>
    <div class="row mt-3 pl-4 justify-content-left" *ngIf="no_update_profile==true">
      <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
          {{"Update profile" | translate}}
       </h2>
  </div>
   
    <div class="row justify-content-center mx-auto ">
       <!-- start for update -->
<div class="col-md-10" *ngIf="no_update_profile==true">
  <mat-card>
    <div *ngIf="no_update_profile==true">
      <div class="container">
          <form [formGroup]="registerForm2">
            <div class="row mt-2">
              <div class="col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"Contact Person Name" | translate}}</mat-label>
                  <input
                    matInput
                    placeholder="Contact Person Name"
                    formControlName="name"
                   style="text-transform: capitalize;"
                    required
                  />
                  <i class="fas fa-user-check" matSuffix></i>
                </mat-form-field>
              </div>
              <div *ngIf="flagMobile==true" class="col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"Contact No." | translate}}</mat-label>
                  <input
                    matInput
                    placeholder="Contact No."
                    formControlName="mobile"  type="number"
                    required
                  />
                  <i class="fas fa-phone-alt" matSuffix></i>
                </mat-form-field>
              </div>
              <div *ngIf="flagEmail==true" class="col-md-6 ">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"Email" | translate}}</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email" 
                  />
                  <i class="fas fa-envelope" matSuffix></i>
                </mat-form-field>
              </div>
            </div>
          
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"Address Line" | translate}}1</mat-label>
                  <input
                    matInput
                    placeholder="Address Line1"
                    formControlName="line1"
                    required
                    style="text-transform: capitalize;"
                  />
                  <i class="fas fa-street-view" matSuffix></i>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{"Address Line" | translate}}2</mat-label>
                    <input
                      matInput
                      placeholder="Address Line2"
                      formControlName="line2"
                      style="text-transform: capitalize;"  
                    />
                    <i class="fas fa-street-view" matSuffix></i>
                  </mat-form-field>
                </div>
           
              <div class="col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"Landmark" | translate}}</mat-label>
                  <input
                    matInput
                    placeholder="Landmark"
                    formControlName="landmark"
                     style="text-transform: capitalize;"
                  />
                  <i class="fas fa-street-view" matSuffix></i>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"Pin Code" | translate}}</mat-label>
                  <input (keyup)="pincode_api2($event.target.value)"
                    matInput type="number"
                    placeholder="Pin Code"
                    formControlName="pin_code"
                    required
                  />
                  <mat-icon matSuffix>fiber_pin</mat-icon>
                </mat-form-field>
              </div>
            
              <div class="col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
       <mat-label>City</mat-label>
       <input
         matInput
         placeholder="City"
         formControlName="city"
         required 
       />
       <i class="fas fa-street-view" matSuffix></i>
     </mat-form-field>
   </div>

              <div class="col-md-6">             
                  <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>{{"State" | translate}}</mat-label>
                  <input
                    matInput
                    placeholder="State"
                    formControlName="state"
                    required 
                  />
                  <i class="fas fa-street-view" matSuffix></i>
                </mat-form-field>
              </div>
            </div>
            <div class=" text-right ">
              <button
                class="order-button " [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
                (click)="cancel_u(); "
                mat-raised-button
              >
                {{"Cancel" | translate}}
              </button>
              &nbsp; &nbsp;
              <button [disabled]="load"
                class="order-button " [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
                (click)="update()"
                mat-raised-button
              >
              <i class="fas fa-spinner fa-spin" *ngIf="load"></i>  {{"Update" | translate}}
              </button> 
            </div>
          </form>
        </div>
    </div>
  </mat-card>
</div>
          <!-- end for update -->
        <div class="col-md-10 mt-3">
            <mat-card *ngIf="no_update_profile==false" class="mb-2">
<div class="row ">
    <div class="col-md-4 text-center">
       <div class="user-profile mt-5">
           <img src="assets/img/user.svg" height="175" width="175" class="img-fluid">
       </div>
    </div>
    <div class="col-md-7 ">
      <div class=" editprofile">
      <span  class="material-icons cursor-pointer"  (click)="update_enable()" matTooltip="edit profile">{{"edit" | translate}}</span>  
    </div>
        <div class="row ">
            <div class="col-md-1 col-2">
              <i class="far fa-user"></i>
            </div>
            <div class="col-md-10 col-10 text-uppercase"> 
                <h5 *ngIf="regaddress?.receiver_name ==null">{{"N. A." | translate}}</h5>
                <h5 *ngIf="regaddress?.receiver_name !=null">{{regaddress?.receiver_name}}</h5>
            </div>
        </div>
        <hr>
        <div class="row mt-1">
            <div class="col-md-1 col-2">
              <i class="fas fa-street-view" class="text-warning"></i>
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="col-md-10 col-10 text-titlecase">
                <h6>  {{regaddress?.line1}} {{regaddress?.line2}},<br/> {{regaddress?.landmark}}, {{regaddress?.city}}, {{regaddress?.state}}, {{regaddress?.country}},  <br />{{regaddress?.pincode}}</h6>
            </div>
        </div>
        <div class="row mt-1">
            <div *ngIf="userdata?.email" class="col-md-1 col-2">
              <i class="fas fa-envelope-open-text"></i>
            </div>

            <div class="col-md-10 col-10 text-lowercase">
              {{userdata?.email}}
                </div>
        </div>
        <div class="row mt-1">
            <div *ngIf="userdata?.mobile" class="col-md-1 col-2">
              <i class="fas fa-phone"></i>
            </div>
            <div class="col-md-10 col-10">
              {{userdata?.mobile}}
                <!-- <h6 *ngIf="regaddress?.contact_no ==null"> {{userdata?.mobile}}</h6>
                <h6 *ngIf="regaddress?.contact_no !=null">{{regaddress?.contact_no}}</h6> -->
            </div>
        </div>
        <div class="row  justify-content-end m-0 cursor-pointer" >
          <!-- <span *ngIf="passwordChange==false" mat-raised-button class="order-button cursor-pointer text-white" (click)="showchangepass2()" matTooltip="Change Password">Change Password?</span>   -->
          <button *ngIf="passwordChange==false" mat-raised-button class="order-button cursor-pointer" [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor" (click)="showchangepass2()" matTooltip="Change Password">{{"Change Password" | translate}}?</button>
         
          <button *ngIf="passwordChange==true" [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor" (click)="showchangepass2()" mat-raised-button class="order-button cursor-pointer " matTooltip="cancel">{{"cancel" | translate}}</button>
         
          <!-- <span *ngIf="passwordChange==true" (click)="showchangepass2()" mat-raised-button class="order-button cursor-pointer text-white" matTooltip="cancel">cancel</span> -->
      </div>
    </div>
</div>
<div class="container" *ngIf="passwordChange==true">
  <div class="row justify-content-center" >
     <div class="col-md-11 mt-2 mb-1 col-11">
         <div class="row  justify-content-between pb-2 cursor-pointer" >
             <h6 class="font-weight-500  pl-2 text-primary cursor-pointer" matTooltip="Change Password">{{"Change Password" | translate}}</h6>  
            <!--  <span (click)="showchangepass()" class="border  pl-2 pr-2 border" matTooltip="cancel">cancel</span> -->
         </div>
         <div class="row">
           <div class="col-md-5">
             <form [formGroup]= "Changepassword">
               <div class="row mt-2" >
                   <mat-form-field appearance="fill" class="example-full-width">
                       <mat-label>{{"Enter Current Password" | translate}}</mat-label>
                       <input matInput id="myInput"   required formControlName="old_password" type="password">
                       <i class="far fa-eye cursor-pointer" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="!visibility" ></i>
                       <i class="far fa-eye-slash cursor-pointer" onclick="myFunction()" (click)="passvible()" matSuffix *ngIf="visibility" ></i>
                       <!-- <mat-icon matSuffix>lock</mat-icon> -->
                   </mat-form-field>
               </div>
               <div class="row" >
                   <mat-form-field appearance="fill" class="example-full-width">
                       <mat-label>{{"Enter New Password" | translate}}</mat-label>
                       <input (keyup)="checkConfirmPassword()" matInput id="myInput2"   required formControlName="new_password" type="password">
                       <i class="far fa-eye cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="!visibility2" ></i>
                       <i class="far fa-eye-slash cursor-pointer" onclick="myFunction2()" (click)="passvible2()" matSuffix *ngIf="visibility2" ></i>                     
                       <!-- <mat-icon matSuffix>lock</mat-icon> -->
                   </mat-form-field>
               </div>
               <div class="row" >
                   <mat-form-field appearance="fill" class="example-full-width">
                       <mat-label>{{"Confirm Password" | translate}}</mat-label>
                       <input matInput id="myInput3" (keyup)="checkConfirmPassword()"  required formControlName="confirm_password" type="password">
                       <i class="far fa-eye cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="!visibility3" ></i>
                     <i class="far fa-eye-slash cursor-pointer" onclick="myFunction3()" (click)="passvible3()" matSuffix *ngIf="visibility3" ></i>
                     <mat-hint class="text-danger" *ngIf="is_confirm">{{"Confirm Password do not match with Password" | translate}}</mat-hint>
                       <!-- <mat-icon matSuffix>lock</mat-icon> -->
                   </mat-form-field>
               </div>
              
               <div class="row mb-2" >
                   <button mat-raised-button class="order-button cursor-pointer" (click)="ChangePassword()" [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor">{{"Change Password" | translate}}</button>
               </div>
           </form>
           </div>
           <div class="col-md-7 d-none d-md-block d-lg-block">
             <div class="card mt-2">
               <img src="https://static.goanywhere.com/img/blog-images/2017/08/Untitled%20design.jpg" height="220"/>
             </div>
           </div>
         </div>
     </div>
 </div>
</div>
</mat-card>
        </div>
    </div>
</div>
</div>
<!-- start for password -->
 

<!-- end for password -->
