<div>
    <button mat-raised-button class="" (click)="saveDetail(data.buisness_no,data.name,data.last_name,data.referee);openXl(contentRecharge)" style="height: 30px; border: 1px solid #831551; overflow: hidden; color: #000000; margin-top: 5px;"><span style="position: relative;
        top: -5px;
        font-weight: 600;">{{"Recharge" | translate}}</span></button>
</div>


<style>
    .example-full-width{
        width: 100%;
    }
    button:hover{
      background: #831551;
      color: white!important;
    }
</style>


<ng-template #contentRecharge let-modal>
    <div class="modal-header">
     <h6 [style.color]="headingcolor" class="modal-title" id="modal-basic-title" align="center">
       {{"Wallet Recharge" | translate}}
     </h6>
    
    
  
     
     <i class="fas fa-times cursor-pointer text-danger"
       title="close modal"
       
       (click)="modal.dismiss('Cross click')"
       ></i>
    </div>
    <!-- modal body starts -->
    <div class="modal-body ">
     <!-- start table -->
    <form  [formGroup]="myRecharge">
   
       
       <div class="row justify-content-center">
         <div  class="col-lg-10  font-weight-500">
          {{"Member Code" | translate}} : {{myRecharge.controls.customer_reg_num.value}}
          <br />
           {{"Member Name" | translate}} : {{myRecharge.controls.receiver_name.value}}&nbsp;{{myRecharge.controls.receiver_last_name.value}}
            <mat-form-field appearance="fill" class="example-full-width mt-2">
             <mat-label class="mat-label">Enter Amount</mat-label>
             <input 
              
               matInput
               placeholder="Enter Amount"
               formControlName="amount"
               required 
             />
            
             <mat-icon matSuffix>toc</mat-icon>
           </mat-form-field>
  
            <mat-form-field appearance="fill" class="example-full-width mt-2">
             <mat-label class="mat-label">{{"Enter Reference/UTR No." | translate}}</mat-label>
             <input 
              required
          
               matInput
               placeholder="Enter Reference No./UTR No."
               formControlName="txn_id"
               
             />
            
             <mat-icon matSuffix>toc</mat-icon>
           </mat-form-field>
          
    <mat-form-field appearance="fill" class="example-full-width mt-2">
             <mat-label class="mat-label">{{"Enter Transaction Date" | translate}}</mat-label>
         
              <input
                  matInput
                  [matDatepicker]="picker"
                  autocomplete="off"
                  [max]="minDate"
                  
                  placeholder="Enter Transaction Date"
                  formControlName="txn_date"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              
            
            
           </mat-form-field>
          
          </div>
    
        
        
       </div>
     
    
      </form>
          <div class="row mt-2">
                    <div class="col-md-12 text-center"> 
                        <button 
                          mat-raised-button
                          class="order-button text-white self-accept"
                          (click)="recharge()"
                          >
                       {{"Make Recharge" | translate}} 
                       </button>
                       
                    </div>
                 
                 </div>
    
    </div>
    </ng-template>
  