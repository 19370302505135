
<div class="container-fluid">
    <div class="row justify-content-end">
        <div class="col-lg-12 text-right">
         <span class="text-danger cursor-pointer" (click)="onNoClick()"><i class="fas fa-times"></i></span>
        </div>
        <div class="col-lg-12 text-center" style="overflow: hidden;">
            <div class="greet_box">
            <img src="https://api.ertrails.in/user/get_greeting">
        </div>
        </div>
    </div>
</div>