import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('tokenObject')) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/signup'], {queryParams: {returnUrl:state.url}});
        this.router.navigate(['/signup/:id'], {queryParams: {returnUrl:state.url}});
        this.router.navigate(['/'], {queryParams: {returnUrl:state.url}});
        return false;
    }
}