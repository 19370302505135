import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthGuard } from './_guards';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertComponent } from './_directives';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlertService, AuthenticationService, UserService } from './_services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminSigninComponent } from "./admin-signin/admin-signin.component";
import { TermConditionsComponent } from './term-conditions/term-conditions.component';
import { HostRoutesComponent } from "./host-routes/host-routes.component";
import { AgGridModule } from 'ag-grid-angular';
import {
  MatSnackBarModule, MatButtonModule,
  MatToolbarModule,
  MatGridListModule, MatSidenavModule,
  MatListModule, MatProgressSpinnerModule, MatTableModule,
  MatOptionModule, MatSelectModule, MatCheckboxModule, MatMenuModule,
  MatRadioModule,
} from "@angular/material"; import { MatTabsModule } from '@angular/material/tabs';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import {
  MatCardModule, MatStepperModule, MatDialogModule, MatRippleModule, MatDatepickerModule, MatNativeDateModule, MatChipsModule, MatPaginatorModule
} from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { ChartsModule } from 'ng2-charts';
import { MainRootComponent } from './main-root/main-root.component';
import { ViewProductComponent } from './view-product/view-product.component';
import { ProfileComponent } from './profile/profile.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NoPageSetupComponent } from './no-page-setup/no-page-setup.component';
import { NotificationComponent } from './notification/notification.component';
import { AddProductVariantComponent } from './add-product-variant/add-product-variant.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NinesellerComponent } from './nineseller/nineseller.component';
import { ReturnOrderComponent } from './return-order/return-order.component';
import { CompanyMultiPickupAddressComponent } from './company-multi-pickup-address/company-multi-pickup-address.component';
import { AddCompanyPickupAddressComponent } from './add-company-pickup-address/add-company-pickup-address.component';
import { AddMultiplePincodeComponent } from './add-multiple-pincode/add-multiple-pincode.component';
import { MultiplePincodeComponent } from "./multiple-pincode/multiple-pincode.component";
import { PackageComponent } from "./package/package.component";
import { PackageCheckoutComponent } from "./package-checkout/package-checkout.component";
import { FormatsDialogComponent } from './formats-dialog/formats-dialog.component'
import { AppInfoComponent } from './app-info/app-info.component'
import { MenifestComponent } from './menifest/menifest.component';
import { ReturnRefundPolicyComponent } from './return-refund-policy/return-refund-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PackageCouponsComponent } from './package-coupons/package-coupons.component';
import { TermsOfServiceVendorComponent } from './terms-of-service-vendor/terms-of-service-vendor.component';
import { TermConditionsEcomComponent } from './term-conditions-ecom/term-conditions-ecom.component';
import { PrivacyPolicyEcomComponent } from './privacy-policy-ecom/privacy-policy-ecom.component';
import { ReturnRefundPolicyEcomComponent } from './return-refund-policy-ecom/return-refund-policy-ecom.component';
import { ShippingPolicyEcomComponent } from './shipping-policy-ecom/shipping-policy-ecom.component';
import { CatalogueShareComponent } from './catalogue-share/catalogue-share.component';
import { ButtonComponent } from './_ag-grid-module/button.component';
import { Button2Component } from './cust-grid-module/button2.component';
import { ButtonCustomerVerificationComponent } from './customer-verification/button-customer-verification.component';
import { ButtonNonRegisterCustomerComponent } from './non-register-customer/button-non-register-customer.component';
import { ButtonViewUserComponent } from './view-users/button-view-user-component';
import { ButtonTagsViewComponent } from './tags-view/button-tags-view.component';
import { ButtonViewBrandsComponent } from './view-brands/button-view-brands.component';
import { ButtonTaxViewComponent } from './tax-view/button-tax-view.component';
import { RegCustomerProfileComponent } from './reg-customer-profile/reg-customer-profile.component';
import { ButtonMemberWalletAdminComponent } from './member-wallet-admin/button-member-wallet-admin.component';
import { ButtonViewCompanyListComponent } from './view-company-list/button-view-company-list.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { TimeslotsComponent } from './timeslots/timeslots.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SelectslotComponent } from './selectslot/selectslot.component';
import { SidebarModule } from 'ng-sidebar';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
export function HttpLoaderFactory(https: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(https, "../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AdminLoginComponent,
    AdminHomeComponent,
    MainRootComponent,
    ButtonViewBrandsComponent,
    RegCustomerProfileComponent,
    ButtonCustomerVerificationComponent,
    ButtonViewCompanyListComponent,
    ButtonTaxViewComponent,
    ButtonNonRegisterCustomerComponent,
    ButtonTagsViewComponent,
    ButtonViewUserComponent,
    ButtonMemberWalletAdminComponent,
    AppComponent,
    ButtonComponent,
    Button2Component,
    CatalogueShareComponent,
    AlertComponent,
     TermsOfServiceVendorComponent,
    ViewProductComponent,
    ProfileComponent,
    PackageCouponsComponent,
    NinesellerComponent,
    NoPageSetupComponent,
    TermConditionsComponent,
     PrivacyPolicyComponent,
    NotificationComponent,
    AddProductVariantComponent,
    ReturnOrderComponent,
    FormatsDialogComponent,
    AppInfoComponent,
    ReturnRefundPolicyComponent,
    MenifestComponent,
    AdminSigninComponent,
    CompanyMultiPickupAddressComponent,
    AddCompanyPickupAddressComponent,
    AddMultiplePincodeComponent,
    MultiplePincodeComponent,
    PackageComponent,
    PackageCheckoutComponent,
    HostRoutesComponent,
    TermConditionsEcomComponent,
    PrivacyPolicyEcomComponent,
    ReturnRefundPolicyEcomComponent,
    ShippingPolicyEcomComponent,
    TimeslotsComponent,
    SelectslotComponent,
  ],
  imports: [
    AgGridModule.withComponents([ButtonComponent, ButtonViewBrandsComponent, Button2Component, ButtonCustomerVerificationComponent, ButtonViewUserComponent, ButtonNonRegisterCustomerComponent, ButtonTagsViewComponent, ButtonTaxViewComponent, ButtonMemberWalletAdminComponent, ButtonViewCompanyListComponent]),
    PdfViewerModule, SidebarModule,
    CdkTreeModule,
    MatGridListModule,
    MatSnackBarModule,
    MatRippleModule,
    BrowserModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatExpansionModule,
    MatSidenavModule,
    MatFormFieldModule,
    CommonModule,
    HttpClientModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatStepperModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatGridListModule,
    FlexLayoutModule,
    MatOptionModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatTabsModule,
    MatListModule,
    MatRadioModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    ChartsModule,
    NgbModule,
    PDFExportModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatButtonToggleModule,
  ],
  providers: [
    AlertService,
    AuthGuard,
    AuthenticationService,
    UserService,
    MessagingService,
    AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {

  }
}