


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit,ElementRef,ViewChild, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'button-tax-view',
    templateUrl: './button-tax-view.component.html',
  })
  export class ButtonTaxViewComponent {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
  @ViewChild('content') content: ElementRef;
    data: any;
    deactivatef=false;
 edit=false;
 tax_products;
 registerForm:FormGroup;
 registerForm2:FormGroup;
  resV1=false;
   taxException;
   taxExceptionNo;
   taxdata2;
  constructor(
 private route: ActivatedRoute,
 private formBuilder : FormBuilder,
 private snackbar:MatSnackBar,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'sm' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
      this.registerForm = this.formBuilder.group({
        rate : [''],
        user_num:this.user_num,
        // comp_num:this.comp_num,
        access_token:this.access_token,
        tax_num:['']
        
      });
      this.registerForm2 = this.formBuilder.group({
        rate : [''],
        user_num:this.user_num,
        // comp_num:this.comp_num,
        access_token:this.access_token,
        
      });
    }
  agInit(params: ICellRendererParams): void {
      //params);
    this.params = params;
    this.data = params.value;
    var data1= params.data;

    if(this.params.label =='delete'){
      this.delete = true;


    }
    if(this.params.label =='edit'){
      this.edit = true;
      
      
          }
  }
 
  deletingtax(tax_num){
    this.resV1=false;
    var res = confirm("Are you sure you want to delete this tax.");
    this.resV1=false;
    if(res){
       this.resV1=false;

     
     this.adminservice.delete_tax({access_token:this.access_token,user_num:this.user_num, tax_num: tax_num}).subscribe(data=>{
      
       if(data['status']==1){ 
          this.modalService.dismissAll('Save click');
           this.snackbar.open(this.adminservice.getCloseWord('This tax is deleted successfully. '),'' ,{
            duration: 3000,
           horizontalPosition:'center',
       }); 
       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
       this.router.navigate(['//view-tax']));          
         
       }
       else if(data['status']==10){
       sessionStorage.clear();
        this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                 duration: 3000,
                 horizontalPosition:'center',
         });      
       this.router.navigate(['/']);
       }
       else if(data['status']==2){
       
        this.modalService.open(this.content, { size: 'md' });
         this.tax_products = data['result'];    
         
         this.taxException=true;
         this.taxExceptionNo=false;
        this.snackbar.open(this.adminservice.getCloseWord('Firstly you have to delete these Products. '),'' ,{
                 duration: 3000,
                 horizontalPosition:'center',
         }); 
        
       }
       else{
         this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again.'),'' ,{
           duration: 3000,
           horizontalPosition:'center',
         }); 
 
       }
     },
     error=>{
         // this.loading = false;
       }
     );

    }
   }
   gettaxDetails(tax_num){
    this.adminservice.fetch_tax({access_token:this.access_token,user_num:this.user_num,tax_num:tax_num}).subscribe(data=>{
     if(data['status']==1){ 
       this.taxdata2 = data['result'];   
        this.registerForm.get('rate').setValue(this.taxdata2.rate);
       
       this.registerForm.get('tax_num').setValue(this.taxdata2.tax_num);
       
       
       
     }
     else if(data['status']==10){
     sessionStorage.clear();
      this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
               duration: 3000,
               horizontalPosition:'center',
       });      
     this.router.navigate(['/']);
     }
     else{

     }
   },
   error=>{
     
     }
   );

 }


 
 onSubmit(){
  if (this.registerForm.invalid) {
    return;
}   

let data2 = this.registerForm.value;


this.adminservice.update_tax(data2)
    .pipe(first())
    .subscribe(
        data2 => {
          this.modalService.dismissAll()
            if (data2['status']==1) {
              this.snackbar.open(this.adminservice.getCloseWord('tax is updated Successfully '),'' ,{
                duration: 3000,
                horizontalPosition:'center',
              });
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/view-tax"]));  
            }else {
              this.snackbar.open(this.adminservice.getCloseWord('Unable to update tax'),'' ,{
                duration: 3000,
                
              });
              
            }
        },
        error => {
            this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '),'' ,{
              duration: 3000,
              
            });
            
        });
}



}