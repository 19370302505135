


// import { Component, OnInit } from '@angular/core';
import { NinetoysserviceService } from '../ninetoysservice.service';
import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl,AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'button-view-brands',
    templateUrl: './button-view-brands.component.html',
  })
  export class ButtonViewBrandsComponent {
    invoice = false;
    track= false;
    view= false;
    delete = false;
    deactive_member=false;
    referral_ask =false;
  public params: any;
  invoicenot = false;
  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num"); 
  public usertype_id = sessionStorage.getItem('usertype_id');
  error_img = sessionStorage.getItem('error_image');
  registerForm : FormGroup;
    data: any;
    deactivatef=false;
    activate=false;
    profile = false;
  allResult: any;
  buis_update: boolean;
  buisness_details: any;
  updateAddres: any;
  bank_details: any;
  loading: boolean;
  fileData: File = null;
  previewUrl:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  view_profile;
  image;
  branddata2;
  imgHeight=0;
  imgWidth=0;
  constructor(
 private route: ActivatedRoute,
 private formBuilder:FormBuilder,
 private snackbar:MatSnackBar,
 private router: Router,
 private adminservice: NinetoysserviceService,private modalService: NgbModal,
 ) {
    
  }
  openXl(content) {
    this.modalService.open(content, { size: 'lg' });
  }
   private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(): void {
      // .log(this.usertype_id + "user Type");
      this.registerForm = this.formBuilder.group({
        name : [''],
        addition_details : [''],      
        image : [''],
        brand_id:[''],
        about_brand:['']
       });
      // this.adminservice
      // .fetch_particular_company_registry_with_sno({ comp_num:this.comp_num,s_no:25})
      // .subscribe(data => {
      //   if (data["status"] == 1) {
      //      let d = data['data'];
      //      let v = d.value;
      //    if(v== '1' || v== '2'){
      //      this.referral_ask=true;
      //    }
      //    else{
      //      this.referral_ask==false;
      //    }
       
      //   } else {
      //    this.referral_ask==false;
      //   }
 
      // });
    }

    onImgError(event) {
      event.target.src = this.error_img;
      //Do other stuff with the event.target
    }

    
  agInit(params: ICellRendererParams): void {
      //.log(params);
    this.params = params;
    // .log(this,params)
    this.data = params.value;
    //console.log(this.data)
    // .log(this.data);
    //.log(this.data);
    //.log(this.data.id);
    var data1= params.data;

    if(this.params.label =='image'){
        //.log('track');
this.deactivatef = true;

    }
    if(this.params.label =='action'){
this.activate = true;

    }
    if(this.params.label =='view'){
      this.view = true;


  }
  if(this.params.label == 'view_user'){
      this.profile = true;
  }
  }
  
  getbrandImage(image):string{
    return this.adminservice.getbrandImage(image);
  }
  getBrandDetails(brand_id){
    this.registerForm.get('brand_id').setValue(brand_id);
     
     this.adminservice.fetch_brand({access_token:this.access_token,user_num:this.user_num,brand_id:brand_id}).subscribe(data=>{
      if(data['status']==1){ 
        this.branddata2 = data['result'];   
         this.registerForm.get('name').setValue(this.branddata2.name);
        if(this.branddata2.addition_details !=null && this.branddata2.addition_details !='null' && this.branddata2.addition_details !=''){
      this.registerForm.get('addition_details').setValue(this.branddata2.addition_details);
       
      }
       if(this.branddata2.about_brand!=null && this.branddata2.about_brand!='null' && this.branddata2.about_brand!=''){
        this.registerForm.get('about_brand').setValue(this.branddata2.about_brand);
      
      }
            this.previewUrl=this.branddata2.image_location;
             
        // this.registerForm.get('brand_id').setValue(this.branddata2.brand_id);
     
      }
      else if(data['status']==10){
      sessionStorage.clear();
       this.snackbar.open(this.adminservice.getCloseWord('Multiple login with this ID has been detected, Logging you out. '),'' ,{
                duration: 3000,
                horizontalPosition:'center',
        });      
      this.router.navigate(['/']);
      }
      else{

      }
    },
    error=>{
        // this.loading = false;
      }
    );
 
  }
  get f() { 
    return this.registerForm.controls; 
  }
  onFileChanged1(fileInput: any) {
     this.previewUrl = null;
                this.fileData = null;
               this.image = null;    

    this.fileData = <File>fileInput.target.files[0];
    this.image = <File>fileInput.target.files[0];    

     let siz11 = Math.ceil(this.image.size / 1024);

    var checkimg11 = this.image.name.toLowerCase();
     if (!checkimg11.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)) {
      this.snackbar.open(this.adminservice.getCloseWord("Only jpg,png,jpeg file format support. "), "", {
        duration: 3000,
        horizontalPosition: "right"
      });
     this.previewUrl = null;
                this.fileData = null;
               this.image = null;  
                this.registerForm.get('image').setValue('');
 // this.registerForm.controls.image = null; 
      return false;
    }

    if (siz11 > 2048) {
      this.snackbar.open(this.adminservice.getCloseWord("Only 2MB and less size file can upload. "), "", {
        duration: 3000,
        horizontalPosition: "right"
      });
      this.previewUrl = null;
                this.fileData = null;
               this.image = null; 
                this.registerForm.get('image').setValue('');
 // this.registerForm.controls.image = null;  

      return false;
    }


  let img = new Image();
      
      img.src = window.URL.createObjectURL(this.fileData);
      img.onload = () => {
       this.imgHeight=img.height;
       this.imgWidth=img.width;
        this.previewT();
     }
    // this.previewT();
  }
 
  previewT() {
    // Show preview 
    var mimeType2 = this.fileData.type;
    if (mimeType2.match(/image\/*/) == null) {
      return;
    }
 
    var reader2 = new FileReader();      
    reader2.readAsDataURL(this.fileData); 
    reader2.onload = (_event) => { 
       if( this.imgHeight > 5000 && this.imgWidth > 7000 ) {
             this.snackbar.open(this.adminservice.getCloseWord('Image height should < 5000 and Width should < 7000. '),'' ,{
                duration: 5000,
                horizontalPosition:'right',
              }); 
             
             
              this.previewUrl = null;
                this.fileData = null;
               this.image = null;
                this.registerForm.get('image').setValue('');
 // this.registerForm.controls.image = null;    

              return;
          }
          else if( this.imgWidth > 7000   ) {
              this.snackbar.open(this.adminservice.getCloseWord('Image Width should < 7000. '),'' ,{
                duration: 5000,
                horizontalPosition:'right',
              }); 
             this.previewUrl = null;
               this.fileData = null;
               this.image = null;   
                this.registerForm.get('image').setValue('');
 this.registerForm.controls.image = null; 

              return;
          }
           else if( this.imgHeight > 5000 ) {
              this.snackbar.open(this.adminservice.getCloseWord('Image height should < 5000. '),'' ,{
                duration: 5000,
                horizontalPosition:'right',
              });
             this.previewUrl = null;
               this.fileData = null;
               this.image = null;  
                this.registerForm.get('image').setValue('');
 // this.registerForm.controls.image = null;  

              return;
          }
          else{
               this.previewUrl = reader2.result; 
          }
     
    
    }
  }
   
  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
  }
  onSubmit(){
    if (this.registerForm.invalid) {
      return;
  }   

  let data2 = this.registerForm.value;
 
   const formData2 = new FormData();

  if (this.image) {
    formData2.append('image', this.image, this.image.name);        
  }
  // formData2.append('comp_num', '0');
     formData2.append('name', this.registerForm.controls.name.value);
     formData2.append('addition_details', this.registerForm.controls.addition_details.value);
     formData2.append('about_brand', this.registerForm.controls.about_brand.value);
  formData2.append('user_num', this.user_num);
  formData2.append('access_token', this.access_token);
  formData2.append('brand_id', this.f.brand_id.value);
  formData2.append('comp_num',this.comp_num);
//console.log(formData2)
//console.log(this.registerForm.controls.value,'value');
  // this.adminservice.UpdateBrand(formData2).subscribe(
  //         data2 => {
  //             if (data2['status']==1) {
  //               this.snackbar.open(this.adminservice.getCloseWord(data2['msg']),'' ,{
  //                 duration: 3000,
  //                 horizontalPosition:'center',
  //               });
                
  //               this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
  //               this.router.navigate(["/view-brands"]));             
                         
                      

  //             }else {
  //               this.snackbar.open(this.adminservice.getCloseWord('Unable to update Brand'),'' ,{
  //                 duration: 3000,
                  
  //               });
                
  //             }
  //         },
  //         error => {
  //             this.snackbar.open(this.adminservice.getCloseWord('Something Went wrong please try again. '),'' ,{
  //               duration: 3000,
                
  //             });
              
  //         });
}

}